import {
  ResponseSbnProductDetail,
  ResponseOfferedProductSBN,
  ResponseSBNEarlyRedeem,
  BodyBuyPreviewSBN,
  BodyBuySBN,
  ResponseBuySBNPreview,
  PeriodFilter,
  SortFilter,
  RespSbnProductHistory,
  ResponseSBNStatus,
  BodyPreviewRedeemption,
  BodyRedeemption,
  ResponseRedemption,
  ResVerifyTokenStockbit,
  ResponseAuthUrlSB,
  ResponseUpcomingSBN,
  BodyGenerateUrlStockbit,
  ResponseSellPreviewSecondarySBN,
  BodySellPreviewSBN,
  BodyPostSellSecondaryMarketSBN,
  ResponseSellSecondaryMarketSBN,
} from '../type.d';
import { ResponsePortfolioSBN } from 'services/portfolio.type';
import http from 'core/http';

const { PUBLIC_URL } = process.env;

const listApi = {
  sbnProductDetail: (id: string) => `/sbn/products/${id}`,
  /** @deprecated */
  sbnProductOffered: '/sbn/products/offered',
  sbnQuotaDetail: (idProduct: string) => `/sbn/products/${idProduct}/quota`,
  sbnEarlyRedemptionDetail: (idProduct: string) =>
    `/sbn/products/${idProduct}/early-redeem`,
  sbnBuyPreview: '/sbn/buy/preview',
  sbnBuy: '/sbn/buy',
  sbnStatusRegis: '/sbn/stats',

  sbnHistoryProduct: '/sbn/products/histories',
  sbnRedeem: '/sbn/redeem',
  sbnPreviewRedeem: '/sbn/redeem/preview',
  verifyTokenStockbit: '/sbn/register/token',
  generateUrlStockbit: '/sbn/register/auth-url',
  regisSbn: '/sbn/register',
  regisBankSbn: '/user/sbn/banks',
  /** @deprecated */
  sbnUpcomingProduct: '/sbn/products/upcoming',
  /**
   * List Of Porto that allow to Input SBN Product in Top UP SBN
   */
  sbnPortfolio: '/portfolios/sbn',
  sbnPreviewSell: '/sbn/secondary-markets/sell/preview',
  sbnSellSecondary: '/sbn/secondary-markets/sell',
  sbnProductPerdana: '/bonds/sbn/products/available',
};

export const getDetailProductSBN = (id: string): ResponseSbnProductDetail => {
  return http.get(listApi.sbnProductDetail(id));
};

/** @deprecated */
export const getSbnProductOffered = (
  syariah = 0
): ResponseOfferedProductSBN => {
  return http.get(listApi.sbnProductOffered);
};

export const getSbnEarlyRedemptionById = (
  productId: string
): ResponseSBNEarlyRedeem => {
  return http.get(listApi.sbnEarlyRedemptionDetail(productId));
};

export const postPreviewBuySBN = (
  body: BodyBuyPreviewSBN
): ResponseBuySBNPreview => {
  return http.post(listApi.sbnBuyPreview, body);
};

export const postBuySBN = (body: BodyBuySBN) => {
  return http.post(listApi.sbnBuy, body);
};

export const postPreviewSellSecondarySBN = (
  body: BodySellPreviewSBN
): ResponseSellPreviewSecondarySBN => {
  return http.post(listApi.sbnPreviewSell, body);
};

export const postSellSecondaryMarketSBN = (
  body: BodyPostSellSecondaryMarketSBN
): ResponseSellSecondaryMarketSBN => {
  return http.post(
    listApi.sbnSellSecondary,
    // Data Body
    {
      order_code: body.order_code,
      all: body.all,
      units: body.units,
      category_id: body.category_id,
      serie_id: body.serie_id,
    },
    {},
    {
      headers: {
        'x-secure-key': body.secure_key,
      },
    }
  );
};

export const getStatusRegisSBN = (): ResponseSBNStatus => {
  return http.get(listApi.sbnStatusRegis);
};

export const postSbnRedeemption = (
  body: BodyRedeemption
): ResponseRedemption => {
  return http.post(listApi.sbnRedeem, body);
};

export const postSbnPreviewRedeemption = (body: BodyPreviewRedeemption) => {
  return http.post(listApi.sbnPreviewRedeem, body);
};

export const postSbnVerifyTokenStockbit = (
  token: string
): ResVerifyTokenStockbit => {
  return http.post(listApi.verifyTokenStockbit, { token });
};

export const postGenerateUrlStockbit = (
  body?: BodyGenerateUrlStockbit
): ResponseAuthUrlSB => {
  return http.post(listApi.generateUrlStockbit, {
    exit_callback_url: `${PUBLIC_URL}/sbn/sync/stockbit/exit`,
    ...body,
  });
};

export const postRegisSbn = (securitiesId: string) => {
  return http.post(listApi.regisSbn, { securities_id: securitiesId });
};

interface BodyRegisBankSBn {
  bank_account_name: string;
  bank_account_number: string;
  file_prove: string;
  liveness_id: string;
}

export const postRegisBankSBN = (body: BodyRegisBankSBn) => {
  return http.post(listApi.regisBankSbn, body);
};

/** @deprecated */
export const getSbnUpcomingProduct = (): ResponseUpcomingSBN => {
  return http.get(listApi.sbnUpcomingProduct);
};

export const getSbnPortfolio = (): ResponsePortfolioSBN => {
  return http.get(listApi.sbnPortfolio);
};

export const getHistoryProduct = (
  period: PeriodFilter,
  sort: SortFilter
): RespSbnProductHistory => {
  return http.get(listApi.sbnHistoryProduct, { period, sort });
};

/** New end point to fetch data product SBN,
 * now the name is SBN Perdana
 */
export const getSbnProductPerdana = () => {
  return http.get(listApi.sbnProductPerdana);
};
