import { Toast } from '@bibitid/uikit-v1';
import { notifClick } from 'utils';
import Crisp from 'core/Crisp/utils';
import { goTo } from 'utils/History';
import { dispatch } from 'store/redux';
import querystring from 'querystring';
import {
  genericLoadingDialog,
  setFeatureMatrix,
  setLoadingBiometric,
} from 'entities/common.reducer';
import { doubleClick } from 'utils/double-click';
import {
  postData as postDataDefault,
  basePostMessage as basePostMessageDefault,
} from 'core/Parent';
import history from 'utils/History';
import { getInstance } from './CallbackMapper';

import type { Mapper } from './CallbackMapper';
import { isNumber } from 'utils/number';
import {
  removeCurrentInstitution,
  setCurrentInstitution,
} from 'features/institution/utils';
import { getEnv } from 'core/env';
import queryClient from 'network/queryClient';
import { sendToDebugger } from 'devtools/components/tools/NativeDebugger/NativeDebuggerProvider';

const callbackMapper: Mapper = getInstance();

/**
 * Determine redirect into home or not
 * when user landing into page on first boot and press back native
 */
const shouldGoToHomeWhenPrevHistoryEmpty = () => {
  const allowedPaths = ['/premium/wealth-specialist'];
  const currentPath = history.location.pathname ?? '';
  const havePrevHistory = history.length > 2;
  const inAllowedPath = allowedPaths.find((allowedPath) =>
    currentPath.includes(allowedPath)
  );

  if (havePrevHistory || !inAllowedPath) {
    return false;
  }

  return true;
};

/**
 * Register early listener (push from native) from native at booting app
 */
export function startPredefinedEvent(
  postData = postDataDefault,
  basePostMessage = basePostMessageDefault
) {
  const dblClickInstance = doubleClick(
    () => {
      // single click
      Toast.show({
        content: 'Tekan tombol kembali lagi untuk keluar.',
        duration: 2000,
      });
    },
    () => {
      // double click
      postData('__EXIT__', { fn: '__EXIT__' });
    }
  );

  /*
    'global_network_change' 
    listen incoming changes network
  */
  callbackMapper.register(
    'global_network_change',
    function (err: any, data: any) {
      // TODO: Implement something on network change
    }
  );

  /*
    'global_set_native' 
    listen incoming set native command
  */
  callbackMapper.register('global_set_native', function (err: any, data: any) {
    if (window.document && window.document.changeAppToNative)
      window.document.changeAppToNative();
  });

  /*
    'set_devinfo' 
    listen incoming data deviceInfo to window
  */
  callbackMapper.register('set_devinfo', function (err: any, data: any) {
    if (!window.document.__deviceInfo) {
      window.document.__deviceInfo = data;
    }
  });

  /*
    'global_set_native' 
    listen incoming data deviceInfo to window
  */
  callbackMapper.register('open_support', function (err: any, data: any) {
    Crisp.showLiveSupport();
  });

  /*
    'set_feature_matrix'
  */
  callbackMapper.register('set_feature_matrix', function (err: any, data: any) {
    dispatch(setFeatureMatrix(data));
  });

  /**
   * 'nativeNavigate',
   * listen incoming data to redirect page from native.
   *
   * When user open link app.bibit.id without param `institution_id` from external app and redirect into app,
   * native will be inject param `?institution_id=0`.
   *
   * sample: app.bibit.id/profile > app.bibit.id/profile?institution_id=0
   */
  callbackMapper.register('nativeNavigate', function (err: any, data: any) {
    if (!data) return;

    const { reload, ...rest } = data;

    /** Current url pathname before redirecting from native */
    const currentPathname = history?.location?.pathname;
    const currentState = history?.location?.state;

    /** Incoming redirect url pathname */
    const redirectPathname = rest?.pathname;

    // We need to use `history replace` when current pathname = incoming redirect pathname.
    // Because we got issue when we need to redirect back, we must click back twice.
    //
    // Sample history stack when we use history push :
    // - `Giftcard Page` (incoming redirect url)
    // - `Giftcard Page` (current url before leave app)
    // - ... (another history stack)
    //
    // Expected history stack when we use history replace :
    // - `Giftcard Page` (current url before leave app) will be replace with `Giftcard Page` (incoming redirect url)
    // - ... (another history stack)
    if (currentPathname === redirectPathname) {
      rest.replace = true;
      rest.state = currentState;
    }

    // find gopay state
    const gopayReload = rest?.state?.refreshGopay
      ? String(rest?.state?.refreshGopay) === 'true'
      : false;

    if (gopayReload) {
      // invalidate react-query gopay state
      queryClient.refetchQueries({ queryKey: ['Account Gopay'], exact: false });
      queryClient.refetchQueries({
        queryKey: ['Payment Method'],
        exact: false,
      });
      queryClient.refetchQueries({
        queryKey: ['Cart Payment Channels'],
        exact: false,
      });

      queryClient.refetchQueries({
        queryKey: ['Get Gift Card Payment Method'],
        exact: false,
      });

      return;
    }

    const shouldReload = isNumber(reload) && Number(reload) === 1;
    if (!shouldReload) {
      return goTo(rest);
    }

    // if you wondering why we need to reload, thats good.
    // we cover some ability to make auto switch account between individu and institution
    // this is the rules
    //
    // Native will send `nativeNavigate` and the data looks like {"pathname":"/orders/INVOICE_NUMBER/detail","search":"?institution_id=0", "reload": "1"}
    // Then we want to deeplink into Path with reload.
    // Why reload, because we put code `Auto Switch` in AuthProvider just to makesure executed only in initial App.

    const objSearch = Object.fromEntries(new URLSearchParams(data.search));

    const containInstitutionId =
      isNumber(objSearch?.institution_id) &&
      Number(objSearch?.institution_id) > 0;

    if (!containInstitutionId) {
      removeCurrentInstitution();
    }

    if (containInstitutionId) {
      setCurrentInstitution(Number(objSearch?.institution_id));
    }

    return goTo(rest);
  });

  /*
    'set__constantinfo'
    listen incoming data to set necessary information into window object
    */
  callbackMapper.register('set_constantinfo', function (err: any, data: any) {
    if (data.isWebview && !window.document.isWebview) {
      window.document.isWebview = data.isWebview;
    }
    if (data.VERSION && !window.document.VERSION) {
      window.document.VERSION = data.VERSION;
    }
    if (data.BUILD_NUMBER && !window.document.BUILD_NUMBER) {
      window.document.BUILD_NUMBER = data.BUILD_NUMBER;
    }
  });

  /*
    'global_backhandler_pressed'
    listen incoming command from backhandler native
  */
  callbackMapper.register(
    'global_backhandler_pressed',
    function (err: any, data: any) {
      const { PublicUrl } = getEnv();

      // Check if current domain is staging domain (not production)
      const isStagingDomain = window.location.href.includes('ui.lab.bibit.id');

      // check if currently at staging home
      const stagingHome =
        window.location.pathname.split('/').length <= 3 &&
        !window.location.pathname.split('/')[2];

      // differentiate between staging home and production home
      const homePath = isStagingDomain ? `${PublicUrl}/` : '/';

      /**
       * Crisp
       */
      const $crisp = window.$crisp as any;

      // check if crisp is visible / opened
      if ($crisp && $crisp.is('chat:opened')) {
        return Crisp.hideLiveSupport();
      }

      if (!window.history) {
        return false;
      }

      // Handle staging route whitelisting
      if (isStagingDomain && stagingHome) {
        return dblClickInstance();
      }

      if (window?.location?.pathname === '/') {
        return dblClickInstance();
      }

      if (shouldGoToHomeWhenPrevHistoryEmpty()) {
        return history.replace(homePath);
      }

      if (window?.location?.pathname !== '/' && history.length < 2) {
        return postData('_goBack', { fn: '_goBack' });
      }

      let params = new URLSearchParams(window.location.search);
      const newUserParam = params.get('new-user');
      const fromRegisParams = params.get('from-regis');

      // Read query params from verifyRegistration page
      // when query params = `/?new-user=1&from-regis=1` --> replaced with `/?new-user=1`
      // when query params = `/?from-regis=1` --> replaced with `/`
      if (fromRegisParams) {
        let currentUrl = new URL(window.location.href);

        // serialized new search param
        let serialized = newUserParam
          ? querystring.stringify({
              'new-user': newUserParam,
            })
          : '';

        let newParams = serialized ? `?${serialized}` : '';
        let replacedUrl = `${currentUrl.origin}${currentUrl.pathname}${newParams}`;
        window.location.replace(replacedUrl);

        return dblClickInstance();
      }

      return postData('_goBack', { fn: '_goBack' });
    }
  );

  /*
    'global_notification_handler'
    listen incoming data from notification native click
  */
  callbackMapper.register(
    'global_notification_handler',
    function (err: any, data: any) {
      let datastring: string = JSON.stringify({
        id: 'drain_var_notification',
        data: {
          fn: 'drainValue',
        },
      });
      basePostMessage(datastring, '*');
      notifClick();
    }
  );

  /*
    'set_loading_challenge_biometric'
  */
  callbackMapper.register(
    'set_loading_challenge_biometric',
    function (err: any, data: any) {
      dispatch(setLoadingBiometric(true));
    }
  );

  /*
    'showLoadingDialog',
    listen incoming loading status from native and show the generic loading dialog
  */
  callbackMapper.register('showLoadingDialog', function (err: any, data: any) {
    if (data?.loadingType === 'no-message') {
      dispatch(
        genericLoadingDialog({
          visible: data?.visible,
          message: '',
        })
      );
    } else {
      dispatch(
        genericLoadingDialog({
          visible: data?.visible ?? true,
          message: data?.message ?? 'Datamu Sedang diproses...',
        })
      );
    }
  });

  callbackMapper.register(
    'requestOtpFromClipboard',
    function (err: any, data: { otp?: string }) {
      if (data?.otp) {
        queryClient.setQueryData(['OTP Clipboard'], `${data?.otp}`);
        sendToDebugger({
          idevent: 'requestOtpFromClipboard',
          status: 'success',
          response: data,
          type: 'event',
        });
      }
    }
  );

  /**
   * listen incoming native state
   * - active: the web view is currently in screen
   * - background: the web view is currently running in background
   */
  callbackMapper.register(
    'appState',
    function (err: any, data: { state?: 'active' | 'background' }) {
      if (data?.state) {
        queryClient.setQueryData(['appState'], data?.state);
        sendToDebugger({
          idevent: 'appState',
          status: 'success',
          response: data,
          type: 'event',
        });
      }
    }
  );
}
