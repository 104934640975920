import React, { useCallback, useContext, useState } from 'react';
import Storage from 'core/Storage';
import {
  DEFAULT_STATUS_BAR_COLOR,
  STATUS_BAR_COLOR_KEY,
  STATUS_BAR_COLOR_TEMP_KEY,
} from '../constants';
import { StatusBarApp } from '../types';
import { setAppStatusBarColor } from '../utils/setAppStatusBarColor';
import { getThemeColor } from 'core/theme/utils/getThemeColor';
import type { ThemeColor } from 'core/theme/mapped-color.type';
import { ThemeTypes } from 'core/theme/ThemeProvider';
import { getTheme } from 'utils/theme';

interface State {
  /** Status bar color with code hex */
  statusBarColor: StatusBarApp;
}

interface Function {
  /**
   * Set status bar color into context and local storage with code hex and object params
   * - backgroundColor
   * - foregroundColor
   *
   * we need to access current status bar color from local storage
   * and used on utils/StatusBarCrisp or another functions
   */
  onSetStatusBarColor: (
    color:
      | StatusBarApp
      /**
       * If the status bar requires support for both dark mode and light mode,
       * utilize the color function to retrieve the color based on the theme.
       */
      | ((color: ThemeColor, theme: ThemeTypes) => StatusBarApp)
  ) => void;

  /**
   * Set temp status bar color into local storage with code hex with object params
   * - backgroundColor
   * - foregroundColor
   */
  setTempCurrentStatusBarColor: (
    color:
      | StatusBarApp
      | ((color: ThemeColor, theme: ThemeTypes) => StatusBarApp)
  ) => void;

  /**
   * Get temp status bar color into local storage with code hex with object params
   * - backgroundColor
   * - foregroundColor
   */
  getTempCurrentStatusBarColor: () => Promise<StatusBarApp>;
}

const ContextState = React.createContext<State | undefined>(undefined);
const ContextFunction = React.createContext<Function | undefined>(undefined);

/**
 * Context for handling status bar native
 */
export const StatusBarContext: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [statusBarColor, setStatusBarColor] = useState<State['statusBarColor']>(
    {
      backgroundColor: DEFAULT_STATUS_BAR_COLOR().backgroundColor,
      foregroundColor: DEFAULT_STATUS_BAR_COLOR().foregroundColor,
    }
  );

  const handleStatusBarAndAppBarColor = async (
    backgroundColor: string,
    foregroundColor: 'dark' | 'light'
  ) => {
    setStatusBarColor((prevState) => {
      if (
        prevState.backgroundColor === backgroundColor &&
        prevState.foregroundColor === foregroundColor
      )
        return { ...prevState };

      setAppStatusBarColor({ backgroundColor, foregroundColor });
      const setStatusBarColorStorage = async () => {
        await Storage.setObject(STATUS_BAR_COLOR_KEY, {
          backgroundColor,
          foregroundColor,
        });
      };
      setStatusBarColorStorage();

      return { backgroundColor, foregroundColor };
    });
  };

  const handleSetStatusBarColor = useCallback(
    async (
      color:
        | StatusBarApp
        | ((color: ThemeColor, theme: ThemeTypes) => StatusBarApp)
    ) => {
      /**
       * If the status bar requires support for both dark mode and light mode,
       * utilize the color function to retrieve the color based on the theme.
       */
      if (typeof color === 'function') {
        const theme = getTheme();
        const themeColor = getThemeColor();
        const { backgroundColor, foregroundColor } = color(themeColor, theme);
        handleStatusBarAndAppBarColor(backgroundColor, foregroundColor);
        return;
      }

      /**
       *  If not, handle the status bar's behavior accordingly.
       */
      const { backgroundColor, foregroundColor } = color;
      handleStatusBarAndAppBarColor(backgroundColor, foregroundColor);
    },
    []
  );

  const setTempCurrentStatusBarColor = useCallback(
    async (
      color:
        | StatusBarApp
        | ((color: ThemeColor, theme: ThemeTypes) => StatusBarApp)
    ) => {
      /**
       * If the status bar requires support for both dark mode and light mode,
       * utilize the color function to retrieve the color based on the theme.
       */
      if (typeof color === 'function') {
        const theme = getTheme();
        const themeColor = getThemeColor();
        const { backgroundColor, foregroundColor } = color(themeColor, theme);
        await Storage.setObject(STATUS_BAR_COLOR_TEMP_KEY, {
          backgroundColor,
          foregroundColor,
        });
        return;
      }

      /**
       *  If not, handle the status bar's behavior accordingly.
       */

      const { backgroundColor, foregroundColor } = color;

      await Storage.setObject(STATUS_BAR_COLOR_TEMP_KEY, {
        backgroundColor,
        foregroundColor,
      });
    },
    []
  );

  const getTempCurrentStatusBarColor = useCallback(async () => {
    const tempColor: StatusBarApp = await Storage.getObject(
      STATUS_BAR_COLOR_TEMP_KEY
    );
    return tempColor;
  }, []);

  return (
    <ContextState.Provider
      value={{
        statusBarColor,
      }}
    >
      <ContextFunction.Provider
        value={{
          onSetStatusBarColor: handleSetStatusBarColor,
          setTempCurrentStatusBarColor,
          getTempCurrentStatusBarColor,
        }}
      >
        {children}
      </ContextFunction.Provider>
    </ContextState.Provider>
  );
};

export default StatusBarContext;

export const useStatusBarState = () => {
  const ctx = useContext(ContextState);
  if (!ctx) {
    throw new Error('Context useStatusBarState out of bound');
  }

  return ctx;
};

export const useStatusBarFunction = () => {
  const ctx = useContext(ContextFunction);
  if (!ctx) {
    throw new Error('Context useStatusBarFunction out of bound');
  }

  return ctx;
};
