/** Endpoints api for feature Bibit Plus */
const endpoints = {
  companyDetail: (symbol: string) => `/stocks/companies/${symbol}`,
  stockCompaniesDetailStats: (symbol: string) =>
    `/stocks/companies/${symbol}/stats`,
  watchlists: `/watchlists`,
  stockPortfoliosSymbol: (symbol: string) => `/stocks/portfolios/${symbol}`,
  rdnDetail: '/rdn',
  rdnCashOnHand: '/rdn/cash-on-hand',
  stockBuy: '/stocks/orders/buy',
  stockBuyPreview: '/stocks/orders/buy/preview',
  stockSell: '/stocks/orders/sell',
  stockSellPreview: '/stocks/orders/sell/preview?v=2',
  stockExercise: '/stocks/orders/exercise',
  stockExercisePreview: '/stocks/orders/exercise/preview',
  stockFee: '/stocks/fees',
  stockOrderList: '/stocks/orders/v2',
  stockCompaniesSymbolCorpActions: (symbol: string) =>
    `/stocks/companies/${symbol}/corp-actions`,
  stockCompaniesChart: (symbol: string) => `/stocks/companies/${symbol}/charts`,
  stockCompaniesRelated: (symbol: string) =>
    `/stocks/companies/${symbol}/related`,
  stocksCompaniesExerciseable: (symbol: string) =>
    `stocks/exercises/${symbol}/exerciseable`,
  stockOrderBook: (symbol: string) => `stocks/orderbooks/${symbol}`,
  stockCompanyKeyStats: (symbol: string) =>
    `stocks/companies/${symbol}/key-stats?v=2`,
  stockFinancialStatement: (symbol: string) =>
    `/stocks/companies/${symbol}/fin-statements`,
  stockFinancialStatementChart: (symbol: string) =>
    `/stocks/companies/${symbol}/fin-statements/charts`,
  stockCategorizationDetailById: (id: number) => `/stocks/categories/${id}`,
};

export default endpoints;
