import * as React from 'react';
import styled from 'styled-components';
import { Flex, Modal, Text } from '@bibitid/uikit-v1';
import ExpiredModalIllustration from '../../assets/ExpiredModalIllustration';
import Analytics from 'utils/Analytics';
import { useFetchQueryDetailRecurring } from 'features/recurring/hooks';
import { useRecurringContextFunction } from 'features/recurring/contexts/RecurringContext';
import { useFlexibleSipRedirect } from '../../hooks/useFlexibleSipModal';

const ExpiredModalWrapper = styled(Flex)`
  img {
    width: 180px;
    height: 120px;
    margin-bottom: 16px;
  }

  h4 {
    margin: 0;
    margin-bottom: 8px;
  }

  p {
    text-align: center;
    margin: 0;
  }
`;

interface ModalProps {
  visible?: boolean;
  recurringId?: number;
  onClose?: () => void;
}

const ExpiredModal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  visible = false,
  recurringId,
  onClose,
}) => {
  const { redirect } = useFlexibleSipRedirect();
  const { setRecurringOrderExpiredModal } = useRecurringContextFunction();

  const { data: SIPDetail } = useFetchQueryDetailRecurring(
    recurringId || 0,
    !!recurringId
  );
  const SIPDetailData = SIPDetail?.data?.data;

  React.useEffect(() => {
    if (!visible) return;

    Analytics.logEventNavigation({
      eventName: 'navigate',
      parameter: {
        page: 'recurring',
        view: 'schedule_expired',
        data: {
          sip_id: SIPDetailData?.id,
          robo: SIPDetailData?.is_robo,
          porto_id: SIPDetailData?.portfolio?.id,
          channel: SIPDetailData?.payment,
        },
      },
    });
  }, [
    visible,
    SIPDetailData?.id,
    SIPDetailData?.is_robo,
    SIPDetailData?.payment,
    SIPDetailData?.portfolio?.id,
  ]);

  const handleClickTopUp = () => {
    Analytics.logEventAction({
      eventName: 'recurring-action-top-up-porto',
      parameter: {
        action: 'top_up_porto',
        context: 'recurring_action',
        trigger: 'click',
        data: {
          sip_id: SIPDetailData?.id,
        },
      },
    });

    setRecurringOrderExpiredModal(undefined);

    if (!recurringId) {
      return redirect(`/portfolio`);
    }

    redirect(`/portfolio/${SIPDetailData?.portfolio?.id}/details`);
  };

  const handleClose = () => {
    Analytics.logEventAction({
      eventName: 'recurring-action-close-expired_modal',
      parameter: {
        action: '',
        context: 'recurring_action',
        trigger: 'click',
      },
    });

    setRecurringOrderExpiredModal(undefined);
    onClose?.();
  };

  return (
    <Modal visible={visible} onClose={handleClose}>
      <Modal.Header showBackButton={false} showClose />
      <Modal.Body>
        <ExpiredModalWrapper direction='column' align='center'>
          <ExpiredModalIllustration />
          <Text type='heading16b' htmlTag='h4'>
            Pembayaran SIP Melewati Batas Waktu
          </Text>
          <Text type='body14r' htmlTag='p'>
            Batas pembayaran Flexible SIP kamu sudah terlewat, tapi kamu masih
            bisa top up dari portofolio.
          </Text>
        </ExpiredModalWrapper>
      </Modal.Body>
      <Modal.Footer primaryButton='Top Up' onPrimaryClick={handleClickTopUp} />
    </Modal>
  );
};

export default ExpiredModal;
