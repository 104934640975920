import { Toast } from '@bibitid/uikit-v1';
import storage from 'core/Storage';
import { showDevtools } from 'devtools/constants/common';
import React, { createContext, PropsWithChildren, useContext } from 'react';
import { OVERRIDE_REMOTE_CONFIG_STORAGE } from './constants/common';
import {
  defaultRemoteConfigValue,
  RemoteConfig,
  RemoteConfigKey,
  remoteConfigValueOptions,
} from './constants/defaultRemoteConfigValue';
import { useOverrideRemoteConfigQuery, useRemoteConfigQuery } from './hooks';

type ContextValue = {
  values: RemoteConfig;
  loading: boolean;
  setOverrideValue: <Keys extends RemoteConfigKey>(
    key: Keys,
    overrideValue: RemoteConfig[Keys]
  ) => void;
  removeOverrideValue: (key: RemoteConfigKey) => void;
};

/**
 * Init remote config context
 */
const RemoteConfigContext = createContext<ContextValue>({
  values: defaultRemoteConfigValue,
  loading: false,
  setOverrideValue: () => {
    return;
  },
  removeOverrideValue: () => {
    return;
  },
});

/**
 * Remote Config Provider
 */
const RemoteConfigProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { data: values = defaultRemoteConfigValue, isFetching } =
    useRemoteConfigQuery();

  const {
    data: overrideRemoteConfig,
    refetch: refetchOverrideRemoteConfigDataStorage,
    isFetching: overrideRemoteConfigIsFetching,
  } = useOverrideRemoteConfigQuery(showDevtools);

  const handleSetOverrideValue = async <Keys extends RemoteConfigKey>(
    key: Keys,
    overrideValue: RemoteConfig[Keys]
  ) => {
    if (overrideRemoteConfigIsFetching) return;

    const newOverrideRemoteConfig = overrideRemoteConfig || {};

    newOverrideRemoteConfig[key] = { overrideValue } as RemoteConfig[Keys];

    try {
      await storage.set(
        OVERRIDE_REMOTE_CONFIG_STORAGE,
        JSON.stringify(newOverrideRemoteConfig)
      );
      await refetchOverrideRemoteConfigDataStorage();
      Toast.show({
        content: `Success change ${key} value to ${overrideValue}`,
        position: 'bottom',
      });
    } catch (err) {}
  };

  const handleRemoveOverrideValue = async (key: RemoteConfigKey) => {
    if (overrideRemoteConfigIsFetching) return;
    const newOverrideRemoteConfig = overrideRemoteConfig || {};

    delete newOverrideRemoteConfig[key];

    try {
      await storage.set(
        OVERRIDE_REMOTE_CONFIG_STORAGE,
        JSON.stringify(newOverrideRemoteConfig)
      );
      await refetchOverrideRemoteConfigDataStorage();
      Toast.show({
        content: `Success change ${key} value to Remote Config Server`,
        position: 'bottom',
      });
    } catch (err) {}
  };

  return (
    <RemoteConfigContext.Provider
      value={{
        values: {
          ...values,
          ...overrideRemoteConfig,
        },
        loading: isFetching,
        setOverrideValue: handleSetOverrideValue,
        removeOverrideValue: handleRemoveOverrideValue,
      }}
    >
      {children}
    </RemoteConfigContext.Provider>
  );
};

export const useRemoteConfigContext = () => {
  return useContext(RemoteConfigContext);
};

/**
 * Get value remote config based on key
 * @example
 * const experimentA = useRemoteConfigValue('exp_A')
 * // return 123
 */
export const useRemoteConfigValue = <Keys extends RemoteConfigKey>(
  key: Keys
) => {
  const { values } = useRemoteConfigContext();
  const value = values[key];
  const selectedKey = remoteConfigValueOptions[key];

  if (typeof value === 'object') {
    return value.overrideValue as (typeof selectedKey)[number];
  }

  return value as (typeof selectedKey)[number];
};

export default RemoteConfigProvider;
