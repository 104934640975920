import React from 'react';
import { useHistory } from 'react-router-dom';
import getSafely from 'utils/safely';
import InvestmentCompanyLogo from 'features/common/icons/InvestmentCompanyLogo';
import { isValid, parseISO, format } from 'utils/date';
import Analytics from 'utils/Analytics';
import {
  orderInstantRedemptionSellStatus,
  orderStatus,
  orderStatusSell,
  orderStatusRedemptionSBN,
  orderStatusSecondaryMarketSBN,
  SellOrderStatusType,
  orderStatusInsti,
  orderStatusSellInsti,
} from 'features/common/components/CardMutualFundHistory/constants';
import {
  determineCurrencyLetter,
  numberToGeneralValueFormat,
} from 'utils/stringHelper';
import classNames from 'classnames';
import { useTheme } from 'hooks';
import { WsTransaction } from 'features/premium/types';
import StampIcon from 'assets/images/bea-materai/stamp.svg';
import { SBNLogo } from 'features/sbn';
import { SeriTypeSBN } from 'features/sbn/type';
import { useFetchPremiumStats } from 'features/premium/networks/resolver';
import { useUserInInstitution } from 'features/institution/hooks';
import { Text } from '@bibitid/uikit-v1';

interface Props {
  currencyExchange?: {
    currency: string;
    exchange_rate: number;
  };
  paymentMethod: string;
  typeMethod?: 'buy' | 'sell';
  name: string;
  portoName: string;
  amount: number;
  status: SellOrderStatusType;
  date: string;
  typePorto: string;
  /** transaction code, if robo it would be robogroup */
  code: string;
  ojkCode: string;
  estAmount: number;
  /** transaction code in EACH item of robo */
  codeInRobo: string;
  typeNumber: number;
  portfolioId?: number;
  autoDebetStatus: string;
  iconSbnUrl?: SeriTypeSBN;
  invoiceNumber: string;
  isDisbursementInstantRedemption?: boolean;
  isSbn?: boolean;
  /** only for SBN */
  serieCode?: string;
  /** only for sbn, secondary market */
  typeSbn?: string;
  /** sbn have early redemption */
  isRedeem?: boolean;
  isStampDuty?: boolean;
  /** Transactions from wealth specialist */
  wsTransaction?: WsTransaction;
  removeFR?: boolean;
  isIfa?: boolean;
}

export const getOrderStatusStringPremiumTrans = (
  typeMethod: Props['typeMethod'],
  wsTransaction?: WsTransaction
) => {
  /** Handle status buy transaction premium dibatalkan user */
  if (typeMethod === 'buy' && wsTransaction?.status === 2) {
    return 'Pembelian Dibatalkan';
  }

  /** Handle status sell transaction premium dibatalkan user */
  if (typeMethod === 'sell' && wsTransaction?.status === 2) {
    return 'Penjualan Dibatalkan';
  }

  /** Handle status sell transaction premium expired */
  if (typeMethod === 'sell' && wsTransaction?.status === 3) {
    return 'Expired';
  }

  return '';
};

/**
 * Simple Card List Mutual Fund to show history transaction product
 *
 * currently used in :
 * - /reksadana/{symbol}/history
 * - /orders/history
 */
const CardMutualFundHistory: React.FC<React.PropsWithChildren<Props>> = ({
  currencyExchange,
  paymentMethod,
  typeMethod,
  name,
  portoName,
  amount,
  status,
  date,
  typePorto,
  code,
  ojkCode,
  estAmount,
  codeInRobo,
  typeNumber,
  portfolioId,
  autoDebetStatus,
  iconSbnUrl,
  invoiceNumber,
  isDisbursementInstantRedemption = false,
  isSbn,
  serieCode,
  typeSbn,
  isRedeem,
  isStampDuty = false,
  wsTransaction,
  removeFR,
  isIfa = false,
}) => {
  const history = useHistory();
  const { theme } = useTheme();

  const isPremiumTrans = !!wsTransaction && !isIfa;
  const isIfaTrans = !!wsTransaction && isIfa;

  const inInstitution = useUserInInstitution();

  const { data: statsData } = useFetchPremiumStats();
  const premiumStatus = statsData?.data?.data?.status ?? 0;

  const isTrueAmount = () => {
    if (typeSbn === 'sbn-sell-secondary-market') {
      return true;
    }

    if (status !== 'accept' && typeMethod === 'sell') {
      return false;
    }

    return true;
  };
  const amountHelper = () => {
    const currency = getSafely(['currency'], currencyExchange) || '';
    const isInternationalCurrency = !!currency && currency !== 'IDR';
    const theMantissa = isInternationalCurrency ? 2 : 0;
    const newAmount = isTrueAmount() ? amount : estAmount;
    return numberToGeneralValueFormat(newAmount, {
      mantissa: theMantissa,
    });
  };

  const orderStatusString = () => {
    if (['failed', 'expired'].includes(autoDebetStatus)) {
      return 'Pembayaran Autodebit Gagal';
    }

    if (typeNumber === 8 || (inInstitution && typeNumber === 1)) {
      return 'Penerimaan Dividen';
    }

    if (typeNumber === 25) {
      return 'Likuidasi Berhasil';
    }

    /** Handle order status premium transaction */
    if (
      isPremiumTrans &&
      !!getOrderStatusStringPremiumTrans(typeMethod, wsTransaction)
    ) {
      return getOrderStatusStringPremiumTrans(typeMethod, wsTransaction);
    }

    if (isStampDuty && status === 'accept') {
      return 'Pembayaran Berhasil';
    }

    if (typeMethod === 'buy') {
      if (inInstitution) {
        return orderStatusInsti[status];
      }

      return orderStatus[status];
    }

    // Instant Redemption Sell Status
    if (typeMethod === 'sell' && isDisbursementInstantRedemption) {
      return orderInstantRedemptionSellStatus[status];
    }

    if (isSbn && isRedeem) {
      return orderStatusRedemptionSBN[status];
    }

    if (isSbn && typeSbn === 'sbn-sell-secondary-market') {
      return orderStatusSecondaryMarketSBN[status];
    }

    if (isSbn && typeSbn === 'sbn-coupon-disbursement') {
      return 'Penerimaan Kupon';
    }

    if (inInstitution) {
      return orderStatusSellInsti[status];
    }

    return orderStatusSell[status];
  };

  const getCodeTransaction = () => {
    if (typePorto === 'robo' && paymentMethod === 'manual') {
      return codeInRobo;
    }

    if (typeMethod === 'sell' && typePorto === 'robo') {
      return codeInRobo;
    }
    return code;
  };

  const typePortoHelper = () => {
    switch (typePorto) {
      case 'robo':
        return 'Dana Pensiun';
      case 'single':
        return 'Dana Tabungan';
      default:
        return 'Dana Tabungan';
    }
  };

  const currency = getSafely(['currency'], currencyExchange) || '';
  const isInternationalCurrency = !!currency && currency !== 'IDR';
  const thePrefix = isInternationalCurrency
    ? `${determineCurrencyLetter(currency)}`
    : determineCurrencyLetter(currency);

  const orderWrapperClassNames = () => {
    // if it's sbn coupon, then it's green colored amount
    if (isSbn && typeSbn === 'sbn-coupon-disbursement') return 'hisNew beli';

    if (typeMethod === 'buy') {
      return isStampDuty ? 'hisNew stamp' : 'hisNew beli';
    }
    return 'hisNew jual';
  };

  const orderNameTitle = () => {
    if (isSbn) {
      return `${serieCode}`;
    }
    if (isStampDuty) {
      return 'Bea Meterai';
    }
    return name;
  };

  const handleGotoDetailOrder = () => {
    const codeTrx = getCodeTransaction();

    let analyticState = 'normal';
    if (premiumStatus === 1) analyticState = 'almost eligible';
    if (premiumStatus > 1) analyticState = 'eligible';

    if (isSbn) {
      Analytics.logEventAction({
        eventName: 'buy_sbn_action',
        parameter: {
          action: 'sbn_choose_order',
          context: 'buy_sbn.tnc_sbn',
          trigger: 'click',
          data: {
            state: analyticState,
            product_id: serieCode,
            product_name: name,
          },
        },
      });
    }

    // premium transactions
    if (isPremiumTrans) {
      Analytics.logEventAction({
        eventName: 'premium_history_action',
        parameter: {
          action: 'order',
          trigger: 'choose',
          context: 'history.list',
          data: {
            state: analyticState,
            is_robo: typePorto === 'robo',
            portfolio_id: portfolioId,
            order_no: codeTrx,
          },
        },
      });
    }

    // normal transactions
    if (!isSbn && !isPremiumTrans) {
      Analytics.logEventAction({
        eventName: 'order_action',
        parameter: {
          action: 'order',
          trigger: 'choose',
          context: 'history.list',
          data: {
            state: analyticState,
            is_robo: typePorto === 'robo',
            portfolio_id: portfolioId,
            order_no: codeTrx,
          },
        },
      });
    }

    if (!invoiceNumber) {
      return history.push(`/orders/${codeTrx}/detail`, { isUseBack: true });
    }

    if (typeNumber === 25) return;

    // typeNumber 4 and 8 is cashback & dividend, might not have
    // invoiceNumber, use codeTrx instead
    if (
      typeMethod === 'sell' ||
      typeNumber === 4 ||
      typeNumber === 8 ||
      isStampDuty
    ) {
      history.push(`/orders/${codeTrx}/detail`, { isUseBack: true });
    } else if (typeMethod === 'buy') {
      // handle all manual payment redirect with transcode
      if (paymentMethod === 'manual') {
        const searchParams = new URLSearchParams();
        searchParams.append('transcode', codeTrx);
        return history.push(
          `/orders/${invoiceNumber}/detail?${searchParams.toString()}`,
          { isUseBack: true }
        );
      }
      history.push(`/orders/${invoiceNumber}/detail`, { isUseBack: true });
    }
  };
  return (
    <>
      {!removeFR && (
        <div
          className={classNames(orderWrapperClassNames(), {
            'card-mutual-fund-history-institution': inInstitution,
          })}
          onClick={handleGotoDetailOrder}
        >
          <div
            className={classNames('hisNewLogo', {
              '--dark': theme === 'dark',
              premium: isPremiumTrans && !inInstitution,
              ifa: isIfaTrans && !inInstitution,
            })}
          >
            {iconSbnUrl && !isStampDuty && <SBNLogo type={iconSbnUrl} />}
            {!iconSbnUrl && !isStampDuty && (
              <InvestmentCompanyLogo
                type='investment-manager'
                ojkCode={ojkCode}
              />
            )}
            {isStampDuty && <img src={StampIcon} alt='stamp' />}
          </div>
          <div className='hisNewBox'>
            <div className='hisNewProd'>
              <Text type='body2' className='hisNewProd'>
                {orderNameTitle()}
              </Text>
              <Text type='heading14b'>
                {thePrefix}
                {amountHelper()}
              </Text>
            </div>
            <Text type='caption1' color={'primary'} className='hisNewStat'>
              {orderStatusString()}
              {isPremiumTrans && ' • via Wealth Specialist'}
              {isIfaTrans && ' • via Financial Advisor'}
            </Text>
            <Text type='caption1' color='secondary' className='hisNewX'>
              <div>
                {!!date && isValid(parseISO(date))
                  ? format(parseISO(date), 'd MMM yyyy')
                  : '-'}
              </div>
              {!isStampDuty && (
                <>
                  <div>·</div>
                  <div>{portoName || typePortoHelper()}</div>
                </>
              )}
            </Text>
          </div>
        </div>
      )}
    </>
  );
};

export default CardMutualFundHistory;
