import { Text } from '@bibitid/uikit-v1';
import React from 'react';
import { format } from 'utils/date';
import { DescriptionProps } from '../types';

const Description: React.FC<DescriptionProps> = ({ type, time }) => {
  const parseTime = format(new Date(time * 1000), 'dd MMMM yyyy HH:mm');

  if (
    type === 'allocationPage' ||
    type === 'stockDetailPage' ||
    type === 'stockPortfolioPage'
  ) {
    return (
      <Text type='caption1' htmlTag='span'>
        RDN Wallet sedang dalam pemeliharaan. Data terakhir{' '}
        <Text htmlTag='span' type='caption2'>
          {parseTime}
        </Text>{' '}
        <Text htmlTag='span' type='caption2'>
          WIB.
        </Text>
      </Text>
    );
  }

  return (
    <Text type='caption1'>
      Bibit Saham sedang dalam pemeliharaan sehingga order saham kamu tidak
      dapat ditampilkan. Silakan coba beberapa saat lagi.
    </Text>
  );
};

export default Description;
