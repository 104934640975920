import React from 'react';
import './BibitLoader.css';
import Loading from '../loader/Loading';

const BibitLoader: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className='bibit-loader' data-testid='splashscreen'>
      <Loading />
    </div>
  );
};

export default BibitLoader;
