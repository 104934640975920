import LazyModal from 'features/common/LazyModal';
import React, { lazy } from 'react';
import { IModalReturnPeriod } from './ModalReturnByPeriod';

const ModalLazy = lazy(() => import('./ModalReturnByPeriod'));

const LazyModalReturnByPeriod = (props: IModalReturnPeriod) => {
  return <LazyModal component={ModalLazy} visible={props.isOpen} {...props} />;
};

export default LazyModalReturnByPeriod;
