import React, { FC, useState, useEffect } from 'react';
import { ModalNeutralButton, Spinner } from 'features/common/components';
import useHomeOnboardingContext from '../hooks/useHomeOnboardingContext';
import { InfoProductList } from './components';
import { useFetchBestProductByCategory } from './networks/resolvers';
import { Modal, ButtonNew, ActivityIndicator, Text } from '@bibitid/uikit-v1';
import ProductListByCategoryModal from './ProductListByCategoryModal';
import RecommendationCard from 'features/robo/RoboRecommendationAllItems/RecommendationCard';
import style from './BestProductListModal.module.css';
import MutualFundDetailModal from '../MutualFundDetailModal';
import usePortfolioFlow from './hooks/usePortfolioFlow';
import { useAuth, useProfileData } from 'hooks';
import Analytics from 'utils/Analytics';
import { useDispatch } from 'react-redux';
import { discoverPageSetSortBy } from 'features/discover/discoverPage.reducer';
import { useUserInInstitution } from 'features/institution/hooks';

type ProductSymbol = string;

/**
 * this modal show product list based on selected protfolio
 */
const BestProductListModal: FC = () => {
  const dispatch = useDispatch<any>();

  const {
    onboardingModalStep,
    onboardingModalVisible,
    portfolioTypeName,
    productBestOf: { typeBestOfProductList, infoMessage },
    handleCloseHomeOnboardingModal,
    setOnboardingModalStep,
  } = useHomeOnboardingContext();

  const {
    showMutualFundDetailById,
    onCustomNavigateMutualFundDetail,
    onCloseMutualFundDetailModal,
    onCreatePortfolio,
    isCreatingGoalLoading,
  } = usePortfolioFlow();

  /** state to handle model discover open */
  const [openDiscoverMore, setOpenDiscoverMore] = useState(false);

  /** Selected product symbol Ex: 'RD31' */
  const [selectedProduct, setSelectedProduct] = useState<ProductSymbol>('');

  // show modal only onboardingModalStep is product_list_best_of
  const visible =
    onboardingModalVisible && onboardingModalStep === 'product_list_best_of';

  const { isLogin } = useAuth();
  const { data: dataProfile } = useProfileData(isLogin);
  const institution = useUserInInstitution(isLogin);

  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;

  const userSyariah = profileDetail?.preference?.syariah;

  const { data, isLoading } = useFetchBestProductByCategory(
    typeBestOfProductList,
    visible,
    institution ? 0 : userSyariah
  );

  useEffect(() => {
    // send event navigation when this modal visible
    if (visible)
      Analytics.logEventNavigation({
        eventName: 'navigate',
        parameter: {
          page: 'search',
          view: 'group',
        },
      });
  }, [visible]);

  const handleContinueCreatePortfolio = async () => {
    await onCreatePortfolio({
      selectedProductSymbol: selectedProduct,
      setSelectedProductSymbol: setSelectedProduct,
    });

    // Reset Discover more modal
    setOpenDiscoverMore(false);
  };

  const handleClickProduct = (roboCategory: string, symbol: string) => {
    setSelectedProduct(symbol);
  };

  /**
   * show discover more modal
   */
  const onDiscoverMoreClick = () => {
    setOpenDiscoverMore(true);

    // If Bibit Darurat
    // Instant Redemption list will be on top of the list of Discover More list
    // By setting sort_by = 8
    if (
      portfolioTypeName === 'Bibit Darurat' &&
      typeBestOfProductList === 'moneymarket'
    ) {
      dispatch(discoverPageSetSortBy('8'));
    }

    Analytics.logEventAction({
      eventName: 'hero_onboard_action',
      parameter: {
        action: 'more_moneymarket',
        context: 'group.more_moneymarket',
        trigger: 'click',
      },
    });
  };

  /**
   * handle close discover more modal
   */
  const closeDiscoverMore = () => {
    setOpenDiscoverMore(false);
  };

  const onClickBack = () => {
    // Reset symbol
    setSelectedProduct('');

    if (portfolioTypeName === 'Bibit Darurat')
      // Back to create portfolio modal
      return setOnboardingModalStep('create_portfolio');

    // Else : close modal
    return handleCloseHomeOnboardingModal();
  };

  const renderHeader = () => {
    /** switch title to display by category */
    const title = () => {
      switch (typeBestOfProductList) {
        case 'debt':
          return 'Obligasi';
        case 'equity':
          return 'Saham';
        case 'moneymarket':
          return 'Pasar Uang';
        case 'syariah':
          return 'Syariah';
        default:
          return '';
      }
    };

    return (
      <div className='text-center'>
        <Text type='subtitle2'>Pilih Produk</Text>
        <Text
          type='body1'
          color='#b5b5b5'
          className='font-12 -margin-top-4 text-center'
        >
          {title()}
        </Text>
      </div>
    );
  };

  const handleOnConfirmCompare = () => {
    handleCloseHomeOnboardingModal();
    setOpenDiscoverMore(false);
  };

  const mutualFundDetailModalVisible = !!showMutualFundDetailById;

  const reksadanaId = showMutualFundDetailById || '';

  const onCloseExploreRdnModal = () => {
    onCloseMutualFundDetailModal();
    handleOnConfirmCompare();
  };

  return (
    <div>
      <ActivityIndicator loading={isCreatingGoalLoading} size='large' />
      <Modal
        visible={visible}
        onClose={handleCloseHomeOnboardingModal}
        onClickBack={onClickBack}
        full={true}
      >
        <Modal.Header
          showClose={false}
          showBackButton={true}
          title={renderHeader()}
          centerTitle
        />
        <Modal.Body>
          <div className={style['bit-modal-select-wrapper']}>
            {infoMessage && <InfoProductList infoMessage={infoMessage} />}
            <div className={style['bit-modal-item-container']}>
              {isLoading && <Spinner isInsideModal={true} />}
              {data?.map((item) => {
                return (
                  <RecommendationCard
                    key={item.id}
                    isInsideModal={true}
                    fundData={item}
                    data-symbol={item.symbol}
                    onChange={handleClickProduct}
                    selected={item.symbol === selectedProduct}
                    onCustomNavigateMutualFundDetail={
                      onCustomNavigateMutualFundDetail
                    }
                  />
                );
              })}
            </div>
            {true && (
              <ModalNeutralButton
                className={style['bit-discover-button']}
                onClick={onDiscoverMoreClick}
                content={'Discover More'}
                testId='best-product-list-modal-btn-test'
              />
            )}
            {/* Fixed button */}
            <ProductListByCategoryModal
              title={renderHeader()}
              isOpen={openDiscoverMore}
              category={typeBestOfProductList}
              onClose={closeDiscoverMore}
              onProductSelected={handleClickProduct}
              selectedProduct={selectedProduct}
              handleContinueCreatePortfolio={handleContinueCreatePortfolio}
              onCustomNavigateMutualFundDetail={
                onCustomNavigateMutualFundDetail
              }
              onConfirmCompare={handleOnConfirmCompare}
              isBibitDarurat={
                portfolioTypeName === 'Bibit Darurat' &&
                typeBestOfProductList === 'moneymarket'
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer
          primaryButton={
            <ButtonNew
              variant='primary'
              disabled={!selectedProduct}
              onClick={handleContinueCreatePortfolio}
              content={'Lanjut'}
              testId='best-product-list-modal-btn-test'
            />
          }
        />
      </Modal>
      <MutualFundDetailModal
        mutualFundDetailModalVisible={mutualFundDetailModalVisible}
        onClose={onCloseMutualFundDetailModal}
        reksadanaId={reksadanaId}
        onCloseExploreRdnModal={onCloseExploreRdnModal}
      />
    </div>
  );
};

export default BestProductListModal;
