import history from 'utils/History';
import { Toast } from '@bibitid/uikit-v1';
import {
  setStatusBarWhenClosingCrisp,
  setStatusBarWhenOpeningCrisp,
} from 'core/statusbar/utils/StatusBarCrisp';
import { postData as postDatav3 } from 'core/Parent';
import { getDeviceInfo } from 'core/DeviceInfo/deviceInfo';
import './Crisp.css';

type OnSupportErrorType = (onHide: Function) => void;
type SessionCrispType = {
  email?: string;
  fullname?: string;
  phone?: string;
  CRISP_TOKEN_ID?: string;
};

let resolveFn: (value: any) => void;
let isSessionLoaded = new Promise((resolve) => {
  resolveFn = resolve;
});

const noop = () => {};

window.$crisp = [];
window.$crisp.is = noop;
window.$crisp.get = noop;
window.$crisp.onClose = noop;

// This callback gets executed once $crisp is fully loaded and all methods (not only $crisp.push()) are available
window.CRISP_READY_TRIGGER = function () {
  const { $crisp } = window;

  $crisp.push([
    'on',
    'session:loaded',
    function () {
      $crisp.push(['do', 'chat:hide']);
      let sessionId = $crisp.get('session:identifier');
      if (!sessionId) {
        $crisp.push(['do', 'chat:open']);
      } else {
        $crisp.push(['do', 'chat:close']);
      }

      $crisp.push(['do', 'chat:hide']);
      resolveFn(true);
    },
  ]);
  $crisp.push([
    'on',
    'chat:opened',
    function () {
      // Tell native that crisp is being opened
      postDatav3('setThirdPartyModalStatus', {
        fn: 'setThirdPartyModalStatus',
        data: { isOpen: true },
        timeout: 0,
      });

      $crisp.push(['do', 'chat:show']);
    },
  ]);
  $crisp.push([
    'on',
    'chat:closed',
    function () {
      $crisp.push(['do', 'chat:hide']);

      // Tell native that crisp will be closed
      postDatav3('setThirdPartyModalStatus', {
        fn: 'setThirdPartyModalStatus',
        data: { isOpen: false },
        timeout: 0,
      });

      if ($crisp.onClose) {
        $crisp.onClose();
        //reset crisp onclose function
        $crisp.onClose = undefined;
      }
    },
  ]);
  $crisp.push([
    'on',
    'helpdesk:queried',
    function () {
      let links = document.links,
        i,
        length;
      for (i = 0, length = links.length; i < length; i++) {
        links[i].target === '_blank' && links[i].removeAttribute('target');
      }
    },
  ]);
};

/**
 * Set new session crisp
 *
 * @param {SessionCrispType} session
 */
function setNewSession(session: SessionCrispType): void {
  const { $crisp } = window;
  const { email, fullname, phone } = session;

  getDeviceInfo().then((deviceInfo: any) => {
    try {
      if (deviceInfo) {
        let output = Object.keys(deviceInfo).map((name) => {
          let deviceInfoValue = deviceInfo[name] ? deviceInfo[name] : '';
          const deviceInfoKey = name && name.toLowerCase();
          return [deviceInfoKey, String(deviceInfoValue)];
        });
        $crisp.push(['set', 'session:data', [output]]);
      }
    } catch (err) {
      console.error(err);
    }
  });

  if (!!email) $crisp.push(['set', 'user:email', email]);
  if (!!phone) $crisp.push(['set', 'user:phone', phone]);
  if (!!fullname) $crisp.push(['set', 'user:nickname', fullname]);
}

/**
 * Set new session and inject CRISP_TOKEN_ID
 *
 * @param {SessionCrispType} session session crisp
 */
function createNewSession(session: SessionCrispType) {
  setNewSession(session);
}

/**
 * Add tag `premium_user` for premium user
 */
export const premiumUserTagSegment = () => {
  const { $crisp } = window;
  $crisp.push(['set', 'session:segments', [['premium_user']]]);
};

/**
 * Reset current season
 */
function destroyCurrentSession() {
  const { $crisp } = window;
  $crisp.push(['do', 'session:reset']);
}

/**
 * Check network if its available to access crisp
 *
 * @param {Function} fnSuccess Callback success to next request
 * @param {Function} fnError Callback handling error
 */
function healtyCheckSocketCrisp(fnSuccess: Function, fnError: Function) {
  function onHide() {
    Toast.clear();
  }

  /* start loading */
  Toast.show({
    content: 'Harap tunggu',
    duration: 0,
    maskClassName: 'toast-crisp',
    icon: 'loading',
  });

  isSessionLoaded
    .then(() => {
      if (typeof fnSuccess == 'function') {
        onHide();
        // set status bar into light when crisp open
        setStatusBarWhenOpeningCrisp();
        return fnSuccess();
      }
    })
    .catch((err) => {
      console.error(err);
      onHide();
      Toast.show({
        content: 'Terjadi kesalahan, Coba lagi',
        maskClassName: 'toast-crisp',
        icon: 'failed',
      });
      if (typeof fnError == 'function') return fnError();
    });
}

/**
 * Common interface to open live chatbox crisp
 *
 * @param {OnSupportErrorType} handleError Handle error function if network is in trouble
 */
function showLiveSupport(
  handleError?: OnSupportErrorType,
  settings?: {
    onClose?: Function;
  }
) {
  let onError: OnSupportErrorType = () => {
    history.push('bantuan');
  };

  if (typeof handleError === 'function') onError = handleError;

  healtyCheckSocketCrisp(() => {
    const { $crisp } = window;

    // handle close crisp with calling param onclose and setStatusBarWhenClosingCrisp
    if (settings?.onClose) {
      const handleCloseCrisp = () => {
        setStatusBarWhenClosingCrisp();
        settings.onClose?.();
      };

      $crisp.onClose = handleCloseCrisp;
    } else {
      // handle close crisp with calling setStatusBarWhenClosingCrisp
      $crisp.onClose = setStatusBarWhenClosingCrisp;
    }

    $crisp.push(['do', 'chat:open']);
  }, onError);
}

const hideLiveSupport = () => {
  const { $crisp } = window;

  // check if crisp is visible / opened
  if ($crisp && $crisp.is('chat:opened')) {
    // close crisp chat
    $crisp.push(['do', 'chat:close']);
    // hide crisp small button
    $crisp.push(['do', 'chat:hide']);
    return;
  }
};

const utils = {
  setNewSession,
  destroyCurrentSession,
  createNewSession,
  showLiveSupport,
  hideLiveSupport,
};

export default utils;
