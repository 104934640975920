import React, { lazy } from 'react';
import LazyLoadWrapper from 'features/common/lazyload/LazyLoadWrapper';
import StockBuyContextProvider from './contexts';

const StockBuyPagelLazy = lazy(() => import('./StockBuyPage'));

const StockBuyPage: React.FC<React.PropsWithChildren<unknown>> = () => (
  <LazyLoadWrapper>
    <StockBuyContextProvider>
      <StockBuyPagelLazy />
    </StockBuyContextProvider>
  </LazyLoadWrapper>
);

export default StockBuyPage;
