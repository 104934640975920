import React, { lazy } from 'react';
import LazyLoadWrapper from 'features/common/lazyload/LazyLoadWrapper';
import StockExerciseContextProvider from './contexts';

const StockExercisePageLazy = lazy(() => import('./StockExercisePage'));

const StockExercisePage: React.FC<React.PropsWithChildren<unknown>> = () => (
  <LazyLoadWrapper>
    <StockExerciseContextProvider>
      <StockExercisePageLazy />
    </StockExerciseContextProvider>
  </LazyLoadWrapper>
);

export default StockExercisePage;
