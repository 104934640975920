const endpoints = {
  portfolioCategorySymbol: (categoryId: string, symbol: string) =>
    `/portfolios/category/${categoryId}/${symbol}`,
  portfolioCategorySymbolOrderCode: (
    categoryId: string,
    symbol: string,
    orderCode: string
  ) => `/portfolios/${categoryId}/products/${symbol}/orders/${orderCode}`,
  portfoliosCategoryMutualFundsSymbol: (categoryId: string, symbol: string) =>
    `/portfolios/${categoryId}/mutual-funds/${symbol}/switchable`,
  portfoliosCategorBondsSymbol: (
    categoryId: string,
    symbol: string,
    orderCode: string
  ) => `/portfolios/${categoryId}/bonds/${symbol}/switchable/${orderCode}`,
  portfoliosCategoryMutualfundsSwitch: (categoryId: string, symbol: string) =>
    `/portfolios/${categoryId}/mutual-funds/${symbol}/switches`,
  portfoliosCategoryBondsSwitch: (categoryId: string, symbol: string) =>
    `/portfolios/${categoryId}/bonds/${symbol}/switches`,
};

export default endpoints;
