import styled from 'styled-components';

const StockAvatarContainer = styled.div<{ hideBackground?: boolean }>`
  width: inherit;
  height: inherit;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
  background-color: ${({ hideBackground = false }) =>
    hideBackground ? 'initial' : 'var(--violet-high)'};
  color: var(--violet-default);

  ${({ hideBackground }) =>
    hideBackground &&
    `
    display: flex;
    align-items: center;
  `}

  & > img {
    width: inherit;
    height: inherit;
  }
`;

export default StockAvatarContainer;
