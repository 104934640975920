import { useState, useEffect } from 'react';
import { getAvailablePortoRecurring } from 'features/recurring/networks/services';
import { PortoRecurring } from 'services/recurring.type.d';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

/**
 * Fetching Portofolio who enable to create recurring
 *
 * @param enable
 */
export const useFetchPortoRecurring = () => {
  const [data, setData] = useState<PortoRecurring[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);

  useEffect(() => {
    if (data.length > 0 || loading) return;

    setLoading(true);
    getAvailablePortoRecurring()
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, [data.length, loading]);

  return { data, loading, error };
};

export const useFetchQueryPortoRecurring = (enabled = true) => {
  return useQuery(
    ['List Porto Recurring'],
    () => getAvailablePortoRecurring(),
    {
      enabled,
    }
  );
};
