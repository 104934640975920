import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import * as portfolioActions from '../../entities/portfolio.reducer';
import { addMultipleReksadanaEntry } from '../../entities/reksadana.reducer';
import { handleResponseGeneral } from 'utils/http-response';
import IsEmpty from 'utils/isEmpty';
import Storage from 'core/Storage';
import {
  sellModalSetData,
  sellModalSetVisible,
  sellWarningSetVisible,
} from '../sell/sellModal.reducer';
import getSafely from '../../utils/safely';
import {
  fetchGoalDetail,
  fetchDataPortfolio,
  getPortfolioByCategory,
} from '../../services/portfolio';
import { getDetailPortoByCategorySymbol } from 'features/switchPortfolio/networks/services';
import {
  postCreateSharedPortfolio,
  postReverseSharedPortfolio,
} from 'features/shared-portfolio/networks';
import {
  fetchGoalOnTrackStatus,
  postGoalRecalculateAmount,
  fetchGoalChartProgress,
} from '../../services/goalSettings';
import { openErrorModal } from '../errors/errorModal.reducer';
import { getRecurringListPorto } from 'features/recurring/networks/services';
import axios from 'axios';
import queryClient from 'network/queryClient';

const {
  addMultiplePortfolioCategoriesEntry,
  addPortfolioCategoriesEntry,
  addRoboPortfolioEntry,
  addMultiplePortfolioItemEntryFromCategories,
} = portfolioActions;

const defaultState = {
  loading: true,
  err_portofolio_categories_detail: '',
  err_portofolio_categories: null,
  totalPortoValue: 0,
  totalInvested: 0,
  totalBalance: 0,
  totalProfitLoss: 0,
  totalReturn: 0,
  categories: [],
  items: [],
  isRobo: false,
  isGoal: false,
  isPensionFund: false,
  templateId: null,
  goalSettingInfo: null,
  isRecurring: false,
  recurringDate: 1,
  pageCategoryId: null,
  currentRoboId: null,
  isPartner: false,
  isShared: false,
  isAdmin: false,
  isDefault: false,
  sharedPortfolioId: null,
  hasInvited: false,
  isSharedCreator: false,
  categoryTitle: 'Portofolio',
  robocategory: 'moneymarket',
  categoryDetailChart: {
    current: [],
    invested: [],
  },
  detail: {
    isRobo: false,
    visible: false,
    fundId: null,
    period: '5Y',
    data: null,
  },
  recurringDetail: {
    loading: false,
    visible: false,
    recurringId: '',
    investmentValue: 0,
    chosenDate: 1,
    chosenPaymentMethod: 'manual',
    chosenPortoProduct: null,
  },
  goal: {
    statusLoading: false,
    onTrack: 0,
    progressTrack: null,
    goalTrack: null,
    loadingRecalculate: false,
  },
  goalRecurring: {
    visible: false,
    recurringAmount: 0,
    scheduleValue: [1], // Array<Number> Value yg mungkin tanggal 1 - 31
    chosenPaymentMethod: 'gopay', // Value yg mungkin: 'gopay', 'manual'
    goalRecurringSetPaymentMethodVA: '', // cuma untuk payment method 'automatic'
    loading: false,
  },
  recurringItems: [],
  showRoboRecommendation: false,
  totalRecurringActive: 0,
  totalRecurringNonactive: 0,
  portfolioHome: {
    totalPortoValue: 0,
    totalProfitLoss: 0,
    totalReturnPercentage: 0,
    listPortfolio: [],
  },
  quickAccess: {
    chooseSingleProductModalVisible: false,
    modalType: '', // recurring or sell
    exploreModalVisible: false,
    exploreModalType: '', // recurring or topup
    recurringModalWarningVisible: false,
  },
  chooseProductSellModalVisible: false,
  portoContainSbn: 0,
  axiosSource: null,
  type: {
    id: 0,
  },
};

// Action creators
const [
  portfolioPageLoading,
  portfolioCategoriesLoaded,
  portfolioCategoriesDetailRequest,
  portfolioCategoriesDetailFailure,
  portfolioCategoriesDetailLoaded,
  portfolioDetailModalOpened,
  portfolioDetailModalClosed,
  portfolioDetailModalChangePeriod,
  portfolioCategoriesDetailChangeRoboCategory,
  portfolioRecurringDetailVisible,
  portfolioRecurringDetailLoading,
  portfolioRecurringDetailLoaded,
  goalDetailLoaded,
  goalDetailStatusLoading,
  goalDetailStatusLoaded,
  goalRecurringSetVisibility,
  goalRecurringSetDate,
  goalRecurringChangeAmount,
  goalRecurringSetPaymentMethod,
  goalRecurringSetPaymentMethodVA,
  goalDetailChartLoading,
  goalDetailChartLoaded,
  portfolioPageChangeIconAndName,
  resetCreateRecurringInGoal,
  setLoadingRecurringGoal,
  registerPortfolioToPage,
  setSharedPortfolio,
  setSharedPortfolioCreator,
  setHasInvitedPortfolio,
  setPortfolioHomePage,
  setPortfolioAxiosResource,
  resetIsGoalPortfolio,
  chooseProductModalVisible,
  chooseProductSbnModalVisible,
  chooseProductMutualFundModalVisible,
  quickAccessTopupVisible,
  quickAccessSellVisible,
  exploreModalVisible,
  fetchPortfolioFailed,
  recurringModalWarningVisible,
  setActiveRecurring,
  setLoadingRecalculatePortoGoal,
  setCategoryId,
  setIsRoboReducer,
] = [
  'PORTFOLIO_PAGE_LOADING',
  'PORTFOLIO_CATEGORIES_LOADED',
  'PORTFOLIO_CATEGORIES_DETAIL_REQUEST',
  'PORTFOLIO_CATEGORIES_DETAIL_FAILURE',
  'PORTFOLIO_CATEGORIES_DETAIL_LOADED',
  'PORTFOLIO_DETAIL_MODAL_OPENED',
  'PORTFOLIO_DETAIL_MODAL_CLOSED',
  'PORTFOLIO_DETAIL_MODAL_CHANGE_PERIOD',
  'PORTFOLIO_CATEGORIES_DETAIL_CHANGE_ROBO_CATEGORY',
  'PORTFOLIO_RECURRING_DETAIL_VISIBLE',
  'PORTFOLIO_RECURRING_DETAIL_LOADING',
  'PORTFOLIO_RECURRING_DETAIL_LOADED',
  'GOAL_DETAIL_LOADED',
  'GOAL_DETAIL_STATUS_LOADING',
  'GOAL_DETAIL_STATUS_LOADED',
  'GOAL_RECURRING_SET_VISIBILITY',
  'GOAL_RECURRING_SET_DATE',
  'GOAL_RECURRING_SET_AMOUNT',
  'GOAL_RECURRING_SET_PAYMENT_METHOD',
  'GOAL_RECURRING_SET_PAYMENT_METHOD_VA',
  'GOAL_DETAIL_CHART_LOADING',
  'GOAL_DETAIL_CHART_LOADED',
  'PORTFOLIO_PAGE_CHANGE_ICON_AND_NAME',
  'ACHIEVED_PROGRESS_SET_VISIBLE',
  'RESET_CREATE_RECURRING_IN_GOAL',
  'SET_LOADING_RECURRING_GOAL',
  'REGISTER_PORTOFOLIO_TO_PAGE',
  'SET_SHARED_PPRTFOLIO',
  'SET_SHARED_CREATOR',
  'SET_HAS_INVITED_PORTFOLIO',
  'SET_PORTFOLIO_HOME_PAGE',
  'SET_PORTFOLIO_AXIOS_SOURCE',
  'RESET_IS_GOAL_PORTFOLIO',
  'CHOOSE_PRODUCT_MODAL_VISIBLE',
  'CHOOSE_PRODUCT_SBN_MODAL_VISIBLE',
  'CHOOSE_PRODUCT_MUTUAL_FUND_MODAL_VISIBLE',
  'QUICK_ACCESS_TOPUP_VISIBLE',
  'QUICK_ACCESS_SELL_VISIBLE',
  'EXPLORE_MODAL_VISIBLE',
  'FETCH_PORTFOLIO_FAILED',
  'RECURRING_MODAL_WARNING_VISIBLE',
  'SET_ACTIVE_RECURRING',
  'SET_LOADING_RECALCULATE_PORTO_GOAL',
  'SET_CATEGORY_ID',
  'SET_IS_ROBO_REDUCER',
].map(createAction);

export {
  portfolioPageLoading,
  portfolioCategoriesLoaded,
  portfolioCategoriesDetailRequest,
  portfolioCategoriesDetailFailure,
  portfolioCategoriesDetailLoaded,
  portfolioDetailModalOpened,
  portfolioDetailModalClosed,
  portfolioDetailModalChangePeriod,
  portfolioCategoriesDetailChangeRoboCategory,
  portfolioRecurringDetailVisible,
  portfolioRecurringDetailLoading,
  portfolioRecurringDetailLoaded,
  goalRecurringSetVisibility,
  goalRecurringSetDate,
  goalRecurringSetPaymentMethod,
  goalRecurringSetPaymentMethodVA,
  portfolioPageChangeIconAndName,
  goalRecurringChangeAmount,
  resetCreateRecurringInGoal,
  setLoadingRecurringGoal,
  registerPortfolioToPage,
  setSharedPortfolio,
  setSharedPortfolioCreator,
  setHasInvitedPortfolio,
  resetIsGoalPortfolio,
  fetchPortfolioFailed,
  exploreModalVisible,
  recurringModalWarningVisible,
  setLoadingRecalculatePortoGoal,
  setCategoryId,
  setIsRoboReducer,
};

// Selectors
export const getPortfolioCategories = (state) =>
  state.portfolioPage.categories.map(
    (item) => state.entities.portfolio.categories.byId[item]
  );

export const getDefaultSingleFundsPorto = (state) => {
  const allPortfolioIds = getSafely(
    ['entities', 'portfolio', 'categories', 'allIds'],
    state,
    []
  );
  const defaultDanaTabungan = allPortfolioIds.find((id) => {
    const portfolioData = getSafely(
      ['entities', 'portfolio', 'categories', 'byId', id],
      state,
      {}
    );
    return (
      !portfolioData.robo &&
      !portfolioData.is_partner_category &&
      !!portfolioData.is_default
    );
  });
  return defaultDanaTabungan;
};

export const getPortfolioItems = (state) => {
  if (state.portfolioPage.isRobo) {
    return state.entities.portfolio.robo.byId[
      state.portfolioPage.pageCategoryId
    ];
  }

  return state.entities.portfolio.categories.byId[
    state.portfolioPage.pageCategoryId
  ];
};

export const getPortfolioDetailItems = (state) => {
  return state.portfolioPage.items.map(
    (item) => state.entities.portfolio.items.byId[item]
  );
};

export const getPortfolioRoboItem = (state) => {
  if (state.entities.portfolio.robo.byId[state.portfolioPage.pageCategoryId]) {
    return state.entities.portfolio.robo.byId[
      state.portfolioPage.pageCategoryId
    ].results.find(
      (item) => item.robocategory === state.portfolioPage.robocategory
    );
  }
};

export const getPortfolioCategoriesDetailItems = (state) => {
  if (
    state?.entities?.portfolio?.categories?.byId[
      state?.portfolioPage?.pageCategoryId
    ]
  ) {
    return state?.entities?.portfolio?.categories?.byId[
      state.portfolioPage.pageCategoryId
    ]?.results?.find(
      (item) => item.robocategory === state.portfolioPage.robocategory
    );
  }
};

export const getPortfolioRoboItemSafe = (state) => {
  //this func same as getPortfolioRoboItem() but with safely.
  const roboByid = getSafely(
    ['entities', 'portfolio', 'robo', 'byId'],
    state,
    {}
  );
  const pageCategoryId = getSafely(
    ['portfolioPage', 'pageCategoryId'],
    state,
    0
  );
  const actualData = roboByid[pageCategoryId];
  if (!IsEmpty(actualData)) {
    const results = getSafely(['results'], actualData, []);
    const robocategory = getSafely(
      ['portfolioPage', 'robocategory'],
      state,
      ''
    );
    return results.find((item) => item.robocategory === robocategory);
  }
  return {};
};

export const getPortfolioItemSafe = (state) => {
  //this func same as getPortfolioRoboItem() but with safely.
  const isRobo = getSafely(['portfolioPage', 'isRobo'], state, false);
  const databyId = getSafely(
    ['entities', 'portfolio', isRobo ? 'robo' : 'categories', 'byId'],
    state,
    {}
  );
  const pageCategoryId = getSafely(
    ['portfolioPage', 'pageCategoryId'],
    state,
    0
  );
  const actualData = databyId[pageCategoryId];
  if (!IsEmpty(actualData)) {
    const results = getSafely(['results'], actualData, []);
    const robocategory = getSafely(
      ['portfolioPage', 'robocategory'],
      state,
      ''
    );
    return results.find((item) => item.robocategory === robocategory);
  }
  return {};
};

export const getPortfolioItemDetail = (state) => ({
  ...state.portfolioPage.detail.data,
  ...state.entities.reksadana.byId[state.portfolioPage.detail.fundId],
});

export const countGoalPortfolios = (state) => {
  const categories = getPortfolioCategories(state);
  return categories.reduce((currTotal, currValue) => {
    if (Boolean(currValue.is_goal)) return currTotal + 1;
    return currTotal;
  }, 0);
};

export const countNonPartnerPortfolios = (state) => {
  const categories = getPortfolioCategories(state) || [];

  const filteredCategory = categories.filter((category) => {
    if (category.is_partner_category) {
      return false;
    }
    if (category.is_shared && !category.is_admin) {
      return false;
    }

    return true;
  });
  return filteredCategory.length;
};

// Reducers
const portfolioPage = createReducer(
  {
    [portfolioPageLoading]: (state) => {
      return update(state, {
        loading: { $set: true },
        isGoal: { $set: false },
        err_portofolio_categories: { $set: null },
      });
    },
    [portfolioCategoriesLoaded]: (state, payload) => {
      return update(state, {
        loading: { $set: false },
        sharedPortfolioId: { $set: null },
        isShared: { $set: false },
        isAdmin: { $set: false },
        isDefault: { $set: false },
        totalPortoValue: { $set: payload?.totalporto }, // This is all realised portfolio + pending items value
        totalInvested: { $set: payload?.invested },
        totalBalance: { $set: payload?.mutual_fund_market_value },
        totalProfitLoss: { $set: payload?.profitloss },
        totalReturn: { $set: payload?.percentage },
        categoryTitle: { $set: 'Portofolio' },
        templateId: { $set: 0 },
        categories: { $set: payload?.result?.map((item) => item?.id) },
        portoContainSbn: { $set: payload?.is_contain_sbn },
      });
    },
    [portfolioCategoriesDetailRequest]: (state) => {
      return update(state, {
        err_portofolio_categories_detail: { $set: '' },
      });
    },
    [portfolioCategoriesDetailFailure]: (state, { err }) => {
      return update(state, {
        err_portofolio_categories_detail: { $set: err },
      });
    },
    [portfolioCategoriesDetailLoaded]: (state, payload) => {
      if (payload.isRobo) {
        return update(state, {
          loading: { $set: false },
          isRobo: { $set: true },
          isShared: { $set: !!payload.is_shared },
          isAdmin: { $set: !!payload.is_admin },
          isDefault: { $set: payload.is_default },
          sharedPortfolioId: { $set: payload.shared_porto_id },
          isGoal: { $set: !!payload.goalsetting },
          isPensionFund: { $set: !payload.is_goal },
          goalSettingInfo: { $set: payload.goalsetting },
          totalPortoValue: { $set: payload.totalporto }, // This is all realised portfolio + pending items value
          totalInvested: { $set: payload.invested },
          totalBalance: { $set: payload.mutual_fund_market_value },
          portoContainSbn: { $set: payload.is_contain_sbn },
          totalProfitLoss: { $set: payload.profitloss },
          totalReturn: { $set: payload.percentage },
          pageCategoryId: { $set: payload.pageCategoryId },
          isPartner: { $set: payload.is_partner_category },
          currentRoboId: { $set: payload.roboid },
          categoryTitle: { $set: payload.categoryname },
          templateId: { $set: payload.templateid },
          type: { $set: payload.type },
          isRecurring: { $set: payload.recurring.status === 1 }, // Mark if this portfolio is in user's recurring list
          categoryRecurringDate: {
            $set: getSafely(['recurring', 'recurring_type_value'], payload),
          },
          recurringItems: {
            $set: getSafely(['recurring', 'items'], payload, []),
          },
          categoryRecurringId: {
            $set: getSafely(['recurring', 'id'], payload),
          },
          goalRecurring: {
            recurringAmount: {
              $set: getSafely(['goalsetting', 'recurring_amount'], payload),
            },
          },
          totalRecurringActive: {
            $set: payload.recurring?.total_active_recurrings || 0,
          },
          totalRecurringNonactive: {
            $set: payload.recurring?.total_inactive_recurrings || 0,
          },
        });
      }

      return update(state, {
        loading: { $set: false },
        isRobo: { $set: false },
        isShared: { $set: !!payload.is_shared },
        isAdmin: { $set: !!payload.is_admin },
        isDefault: { $set: payload.is_default },
        sharedPortfolioId: { $set: payload.shared_porto_id },
        isGoal: { $set: !!payload.goalsetting },
        isPensionFund: { $set: false },
        goalSettingInfo: !!payload.goalsetting
          ? { $set: payload.goalsetting }
          : { $set: null },
        pageCategoryId: { $set: payload.pageCategoryId },
        isPartner: { $set: payload.is_partner_category },
        totalPortoValue: { $set: payload.totalporto }, // This is all realised portfolio + pending items value
        totalInvested: { $set: payload.invested },
        totalBalance: { $set: payload.mutual_fund_market_value },
        portoContainSbn: { $set: payload.is_contain_sbn },
        totalProfitLoss: { $set: payload.profitloss },
        totalReturn: { $set: payload.percentage },
        categoryTitle: { $set: payload.categoryname },
        templateId: { $set: payload.templateid },
        type: { $set: payload.type },
        items: {
          $set: payload.results
            .filter((item) => item && item.product && item.product.symbol)
            .map((item) => item.product.symbol),
        },
        isRecurring: { $set: payload.recurring?.status === 1 },
      });
    },
    [portfolioCategoriesDetailChangeRoboCategory]: (state, payload) => {
      return update(state, {
        robocategory: { $set: payload },
      });
    },
    [portfolioDetailModalOpened]: (state, payload) => {
      return update(state, {
        detail: {
          visible: { $set: true },
          fundId: { $set: payload.fundId },
          data: { $set: payload.data },
          isRobo: { $set: payload.isRobo },
        },
      });
    },
    [portfolioDetailModalChangePeriod]: (state, payload) => {
      return update(state, {
        detail: {
          period: { $set: payload },
        },
      });
    },
    [portfolioDetailModalClosed]: (state, payload) => {
      return update(state, {
        detail: {
          visible: { $set: false },
        },
      });
    },
    [portfolioRecurringDetailVisible]: (state, payload) => {
      return update(state, {
        recurringDetail: {
          visible: { $set: payload.visible },
          recurringId: { $set: payload.recurringId },
        },
      });
    },
    [portfolioRecurringDetailLoading]: (state) => {
      return update(state, {
        recurringDetail: {
          loading: { $set: true },
        },
      });
    },
    [portfolioRecurringDetailLoaded]: (state, payload) => {
      return update(state, {
        recurringDetail: {
          loading: { $set: false },
          investmentValue: { $set: payload.amount },
          chosenPaymentMethod: { $set: payload.payment },
          chosenDate: { $set: [payload.date] },
          chosenPortoProduct: {
            $set: Boolean(payload.portfolio.roboid)
              ? null
              : payload.products[0],
          },
          chosenTemplateId: { $set: payload.portfolio.templateid },
        },
      });
    },
    [goalDetailLoaded]: (state, payload) => {
      let paymentMethodRecurring = getSafely(
        ['recurring', 'payment'],
        payload,
        'gopay'
      );
      const recurringAmount = getSafely(['recurring_amount'], payload);
      if (recurringAmount > 10000000) {
        paymentMethodRecurring = 'manual'; // force payment method to 'manual' if recurring amount morethan 10 million
      }

      let newState = {
        categoryTitle: { $set: getSafely(['portfolio', 'name'], payload) },
        templateId: { $set: getSafely(['portfolio', 'templateid'], payload) },
        pageCategoryId: { $set: getSafely(['portfolio', 'id'], payload) },
        goal:
          typeof payload === 'object' && payload !== null
            ? { $merge: payload }
            : { $set: payload },
        isPensionFund: {
          $set: !!(
            !getSafely(['portfolio', 'is_goal'], payload, true) &&
            getSafely(['portfolio', 'is_robo'], payload, true)
          ),
        },
        categoryRecurringDate: {
          $set: getSafely(['recurring', 'date'], payload),
        },
        goalRecurring: {
          scheduleValue: {
            $set: [
              getSafely(
                ['recurring', 'date'],
                payload,
                defaultState.goalRecurring.scheduleValue[0]
              ),
            ],
          }, // Array<Number> Value yg mungkin tanggal 1 - 31
          chosenPaymentMethod: { $set: paymentMethodRecurring }, // Value yg mungkin: 'gopay', 'manual'
          recurringAmount: { $set: recurringAmount },
        },
      };

      return update(state, newState);
    },
    [goalDetailStatusLoading]: (state) => {
      return update(state, {
        goal: {
          statusLoading: { $set: true },
        },
      });
    },
    [goalDetailStatusLoaded]: (state, payload) => {
      return update(state, {
        goal: {
          statusLoading: { $set: false },
          onTrack: { $set: payload.ontrack },
          progressTrack: { $set: payload?.output?.progress || null },
          goalTrack: { $set: payload?.output?.goal || null },
        },
      });
    },
    [goalRecurringSetVisibility]: (state, payload) => {
      return update(state, {
        goalRecurring: {
          visible: { $set: payload },
        },
      });
    },
    [goalRecurringSetDate]: (state, payload) => {
      return update(state, {
        goalRecurring: {
          scheduleValue: { $set: payload },
        },
      });
    },
    [goalRecurringSetPaymentMethod]: (state, payload) => {
      return update(state, {
        goalRecurring: {
          chosenPaymentMethod: { $set: payload },
        },
      });
    },
    [goalRecurringChangeAmount]: (state, payload) => {
      return update(state, {
        goalRecurring: {
          recurringAmount: { $set: payload },
        },
      });
    },
    [goalRecurringSetPaymentMethodVA]: (state, payload) => {
      return update(state, {
        goalRecurring: {
          chosenPaymentMethod: { $set: payload.method },
          chosenPaymentMethodVA: { $set: payload.selectedva },
        },
      });
    },
    [goalDetailChartLoading]: (state) =>
      update(state, {
        goal: {
          chartLoading: { $set: true },
        },
      }),
    [goalDetailChartLoaded]: (state, payload) => {
      const newState = {
        goal: {
          chartLoading: { $set: false },
        },
      };
      if (payload) {
        newState.goal.projectionData = { $set: payload.goal };
        newState.goal.progressData = { $set: payload.progress };
      }

      return update(state, newState);
    },
    [portfolioPageChangeIconAndName]: (state, payload) =>
      update(state, {
        categoryTitle: { $set: payload.categoryTitle },
        templateId: { $set: payload.templateId },
      }),
    [resetCreateRecurringInGoal]: (state, payload) => {
      return update(state, {
        goalRecurring: {
          visible: { $set: defaultState.goalRecurring.visible },
          scheduleValue: { $set: defaultState.goalRecurring.scheduleValue },
          chosenPaymentMethod: {
            $set: defaultState.goalRecurring.chosenPaymentMethod,
          },
          goalRecurringSetPaymentMethodVA: {
            $set: defaultState.goalRecurring.goalRecurringSetPaymentMethodVA,
          },
        },
      });
    },
    [setLoadingRecurringGoal]: (state, payload) => {
      return update(state, {
        goalRecurring: {
          loading: { $set: payload },
        },
      });
    },
    [registerPortfolioToPage]: (state, payload) => {
      return update(state, {
        categories: { $set: payload.map((item) => item.id) },
      });
    },
    [setSharedPortfolio]: (state, payload) => {
      return update(state, {
        isShared: { $set: payload },
      });
    },
    [setSharedPortfolioCreator]: (state, payload) => {
      return update(state, {
        isSharedCreator: { $set: payload },
      });
    },
    [setHasInvitedPortfolio]: (state, payload) => {
      return update(state, {
        hasInvited: { $set: payload },
      });
    },
    [setPortfolioHomePage]: (state, payload) => {
      return update(state, {
        portfolioHome: {
          totalPortoValue: { $set: payload.totalporto },
          totalProfitLoss: { $set: payload.profitloss },
          totalReturnPercentage: { $set: payload.percentage },
          listPortfolio: { $set: payload.listPortfolio },
        },
      });
    },
    [setPortfolioAxiosResource]: (state, payload) => {
      return update(state, {
        axiosSource: { $set: payload },
      });
    },
    [resetIsGoalPortfolio]: (state) => {
      return update(state, {
        isGoal: { $set: false },
      });
    },
    [chooseProductModalVisible]: (state, payload) => {
      return update(state, {
        quickAccess: {
          chooseSingleProductModalVisible: { $set: payload.isOpen },
          modalType: { $set: payload.type || state.quickAccess.modalType }, // recurring or sell
        },
      });
    },
    [chooseProductSbnModalVisible]: (state, payload) => {
      return update(state, {
        quickAccess: {
          chooseProductSbnModalVisible: { $set: payload.isOpen },
        },
      });
    },
    [chooseProductMutualFundModalVisible]: (state, payload) => {
      return update(state, {
        quickAccess: {
          chooseProductMutualFundModalVisible: { $set: payload.isOpen },
        },
      });
    },
    [quickAccessTopupVisible]: (state, payload) => {
      return update(state, {
        quickAccess: {
          quickAccessTopupModalVisible: { $set: payload.isOpen },
        },
      });
    },
    [quickAccessSellVisible]: (state, payload) => {
      return update(state, {
        quickAccess: {
          quickAccessSellModalVisible: { $set: payload.isOpen },
        },
      });
    },
    [exploreModalVisible]: (state, payload) => {
      return update(state, {
        quickAccess: {
          exploreModalVisible: { $set: payload.isOpen },
          exploreModalType: {
            $set: payload.type || state.quickAccess.exploreModalType,
          },
        },
      });
    },
    [recurringModalWarningVisible]: (state, payload) => {
      return update(state, {
        quickAccess: {
          recurringModalWarningVisible: { $set: payload },
        },
      });
    },
    [setActiveRecurring]: (state, payload) => {
      return update(state, {
        totalRecurringActive: { $set: payload.activeRecurring },
        totalRecurringNonactive: { $set: payload.inactiveRecurring },
      });
    },
    [fetchPortfolioFailed]: (state, { err }) => {
      return update(state, {
        loading: { $set: false },
        err_portofolio_categories: { $set: err },
      });
    },
    [setLoadingRecalculatePortoGoal]: (state, payload) => {
      return update(state, {
        goal: {
          loadingRecalculate: { $set: payload },
        },
      });
    },
    [setCategoryId]: (state, payload) => {
      return update(state, {
        pageCategoryId: { $set: payload },
      });
    },
    [setIsRoboReducer]: (state, payload) => {
      return update(state, {
        isRobo: { $set: payload },
      });
    },
  },
  defaultState
);

export function setChooseProductVisible(isOpen, type) {
  return (dispatch) => {
    // run this when execute setChooseProductVisible(false)
    if (!isOpen) {
      dispatch(
        chooseProductModalVisible({
          isOpen: false,
        })
      );
    }

    // run this when execute setChooseProductVisible(open, 'sell')
    if (type === 'sell') {
      dispatch(
        chooseProductModalVisible({
          isOpen: true,
          type: 'sell',
        })
      );
    }

    // run this when execute setChooseProductVisible(open, 'recurring')
    if (type === 'recurring') {
      dispatch(
        chooseProductModalVisible({
          isOpen: true,
          type: 'recurring',
        })
      );
    }
  };
}

/**
 * choose sbn product for quick access sell flow
 * only for bibit plus
 */
export function setChooseProductSbnVisible(isOpen) {
  return (dispatch) => {
    dispatch(
      chooseProductSbnModalVisible({
        isOpen: isOpen,
      })
    );
  };
}

/**
 * choose mutual fund product for quick access sell flow
 * only for bibit plus
 */
export function setChooseProductMutualFundVisible(isOpen) {
  return (dispatch) => {
    dispatch(
      chooseProductMutualFundModalVisible({
        isOpen: isOpen,
      })
    );
  };
}

/**
 * choose product type for quick access topup flow
 * only for bibit plus
 */
export function setQuickAccessTopupVisible(isOpen) {
  return (dispatch) => {
    dispatch(
      quickAccessTopupVisible({
        isOpen: isOpen,
      })
    );
  };
}

/**
 * choose product type for quick access topup flow
 * only for bibit plus
 */
export function setQuickAccessSellVisible(isOpen) {
  return (dispatch) => {
    dispatch(
      quickAccessSellVisible({
        isOpen: isOpen,
      })
    );
  };
}

/**
 * Getting list of portfolio categories
 *
 * @param {boolean} cancelation - if true, previous request would be replaced/canceled by new request
 */
export function getPortfolioCategoryData(cancelation = true) {
  return (dispatch, getState) => {
    const axiosSource = getState().portfolioPage.axiosSource;

    // cancel request before send new request
    if (axiosSource && cancelation) {
      axiosSource.cancel('cancel request');
    }

    // create and store calcel token
    const source = axios.CancelToken.source();

    dispatch(setPortfolioAxiosResource(source));

    dispatch(portfolioPageLoading());

    return fetchDataPortfolio(source.token)
      .then((response) => {
        const portfolioCategoriesData = getSafely(
          ['data', 'data'],
          response,
          {}
        );

        dispatch(setPortfolioCategoryData(portfolioCategoriesData));
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        dispatch(fetchPortfolioFailed({ err }));
      });
  };
}

/**
 * Set portfolio category data
 */
export function setPortfolioCategoryData(portfolioCategoriesData = {}) {
  return (dispatch) => {
    dispatch(
      addMultiplePortfolioCategoriesEntry(portfolioCategoriesData?.result ?? [])
    );
    dispatch(portfolioCategoriesLoaded(portfolioCategoriesData));
    dispatch(registerPortfolioToPage(portfolioCategoriesData?.result ?? []));
    dispatch(
      setPortfolioHomePage({
        totalporto: portfolioCategoriesData?.totalporto ?? 0,
        profitloss: portfolioCategoriesData?.profitloss ?? 0,
        percentage: portfolioCategoriesData?.percentage ?? 0,
        listPortfolio: portfolioCategoriesData?.result ?? [],
      })
    );

    // reset porto is goal
    dispatch(resetIsGoalPortfolio());
  };
}

/**
 * Get the user's goal detailed data
 */
export function getPortfolioGoalDetail(categoryId) {
  return async (dispatch) => {
    dispatch(setCategoryId(categoryId));
    try {
      const goalDetailResponse = await fetchGoalDetail(categoryId);
      const parsedGoalDetailResponse =
        handleResponseGeneral(goalDetailResponse);
      const parsedGoalDetailData = getSafely(
        ['data'],
        parsedGoalDetailResponse
      );

      dispatch(
        goalDetailLoaded({
          ...parsedGoalDetailData,
        })
      );
      return parsedGoalDetailData;
    } catch (error) {
      const parsedResponse = handleResponseGeneral(error.response);

      if (parsedResponse) {
        // Open modal if it is not form error
        const errorModalObj = {
          type: parsedResponse?.type,
          message: parsedResponse?.message,
        };
        dispatch(openErrorModal(errorModalObj));
      }
    }
  };
}

/**
 * Get the user's goal detailed data
 */
export function getPortfolioGoalStatus(categoryId) {
  return async (dispatch) => {
    try {
      // Set loading state on
      dispatch(goalDetailStatusLoading());

      const goalDetailStatusResponse = await fetchGoalOnTrackStatus(categoryId);

      const parsedGoalDetailStatusResponse = handleResponseGeneral(
        goalDetailStatusResponse
      );
      const parsedGoalDetailStatusData = getSafely(
        ['data'],
        parsedGoalDetailStatusResponse
      );

      dispatch(goalDetailStatusLoaded(parsedGoalDetailStatusData));
      return parsedGoalDetailStatusData;
    } catch (error) {
      dispatch(goalDetailStatusLoaded({ ontrack: 0 }));
    }
  };
}

export function getPortfolioRecurringDetail(categoryId) {
  return async (dispatch) => {
    return getRecurringListPorto(categoryId)
      .then((res) => {
        const recurringData = res.data.data;

        const activeRecurring = recurringData.total_active_recurrings;
        const inactiveRecurring = recurringData.total_inactive_recurrings;

        /**
         * special handle untuk non-robo category.
         * karena data recurring di robo sudah di provide saat
         * fetch data portfolio detail
         */
        if (!recurringData.is_robo) {
          dispatch(
            setActiveRecurring({
              activeRecurring,
              inactiveRecurring,
            })
          );
        }
      })
      .catch((err) => {});
  };
}

/**
 * Getting user portfolio based on the category
 * @param {String|Int} categoryId - Category ID
 * @param {Func=} callback - callback function
 */
export function getPortfolioCategoriesDetailData(categoryId, callback) {
  return async (dispatch, getState) => {
    dispatch(portfolioPageLoading());

    const axiosSource = getState().portfolioPage.axiosSource;

    // cancel request before send new request
    if (axiosSource) {
      axiosSource.cancel('cancel request');
    }

    // create and store calcel token
    const source = axios.CancelToken.source();
    dispatch(setPortfolioAxiosResource(source));

    dispatch(portfolioCategoriesDetailRequest());

    /**
     * disini kita harus fetch recurring sebenernya hanya untuk porto non-robo.
     * Karena ketika fetch porto detail robo, kita udh dapet data recurring sedangkan
     * di non-robo tidak. perlu untuk keperluan quick-access
     */
    dispatch(getPortfolioRecurringDetail(categoryId));

    return getPortfolioByCategory(categoryId, source.token)
      .then((response) => {
        const portfolioItemData = response.data.data;

        const portoGoal = portfolioItemData.goalsetting; // berbentuk object {}

        /**
         * If porto goal setting, we fetch:
         * - Goal Status
         * - Goal Detail
         *
         * // TODO: find better fetch for Goal Status and Goal Detail
         */
        if (!!portoGoal) {
          dispatch(getPortfolioGoalStatus(categoryId));
          dispatch(getPortfolioGoalDetail(categoryId));
        }

        // Robo specific portfolio category handling
        if (portfolioItemData.robo) {
          dispatch(
            addRoboPortfolioEntry({
              categoryId: categoryId,
              data: portfolioItemData,
            })
          );

          const categoryDetailData = {
            ...portfolioItemData,
            pageCategoryId: categoryId,
            isRobo: true,
          };

          dispatch(portfolioCategoriesDetailLoaded(categoryDetailData));
          callback && callback();
          return dispatch(
            addMultipleReksadanaEntry(
              portfolioItemData.results.reduce(
                (data, currValue) =>
                  data.concat(
                    currValue.items.map((portoItem) => ({
                      ...portoItem.product,
                      currency_exchange: portoItem.currency_exchange,
                    }))
                  ),
                []
              )
            )
          );
        }

        // Non robo portfolio category
        dispatch(
          addPortfolioCategoriesEntry({
            ...portfolioItemData,
            id: categoryId,
          })
        );

        // Non robo portfolio category handling
        dispatch(
          addMultiplePortfolioItemEntryFromCategories(portfolioItemData.results)
        );
        dispatch(
          portfolioCategoriesDetailLoaded({
            ...portfolioItemData,
            pageCategoryId: categoryId,
            isRobo: false,
            items: [],
          })
        );
        callback && callback();
        return dispatch(
          addMultipleReksadanaEntry(
            portfolioItemData.results.reduce(
              (data, currValue) =>
                data.concat(
                  currValue.items.map((portoItem) => ({
                    ...portoItem.product,
                    currency_exchange: portoItem.currency_exchange,
                  }))
                ),
              []
            )
          )
        );
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          const errorMessage =
            err?.response?.data?.message || 'Something is wrong';

          dispatch(portfolioCategoriesDetailFailure({ err: errorMessage }));
        }
      });
  };
}

/**
 * Getting portfolio item detail data
 * @param {String} categoryId - Portfolio category ID
 * @param {String} fundId - Portfolio item product ID
 */
export function getPortfolioItemDetailData(categoryId, fundId, isRobo = false) {
  return (dispatch) => {
    return getDetailPortoByCategorySymbol(categoryId, fundId)
      .then((response) => {
        const portfolioItemData = response.data.data;
        const modalData = {
          fundId,
          data: portfolioItemData,
          isRobo,
        };
        dispatch(portfolioDetailModalOpened(modalData));
      })
      .catch(() => {});
  };
}

/**
 * Open top up modal and close portfolio detail modal
 * Add guard to show SellFirstWarning (sellWarningSetVisible)
 * @param {String} fundId - The Fund ID user want to buy
 */
export function openSellModal(modalData) {
  return async (dispatch) => {
    dispatch(portfolioDetailModalClosed());

    dispatch(sellModalSetData(modalData));

    const warningIsHidden = await Storage.getObject('hide_sell_warning');

    if (warningIsHidden) {
      dispatch(sellModalSetVisible(true));
    } else {
      dispatch(sellWarningSetVisible(true));
    }
  };
}

/**
 * Open top up modal and close portfolio detail modal
 * @param {String} fundId - The Fund ID user want to buy
 */
export function openRoboSellModalAmount(modalData) {
  return async (dispatch, getState) => {
    dispatch(sellModalSetData(modalData));

    dispatch(sellModalSetVisible(true));
  };
}

/**
 * Recalculate goal porto recurring amount
 */
export function goalRecalculateRecurringAmount(portfolioId) {
  return async (dispatch, getState) => {
    try {
      const chosenPaymentMethod = getSafely(
        ['portfolioPage', 'goalRecurring', 'chosenPaymentMethod'],
        getState()
      );
      const chosenPaymentMethodVA = getSafely(
        ['portfolioPage', 'goalRecurring', 'chosenPaymentMethodVA'],
        getState()
      );
      const scheduleValue = getSafely(
        ['portfolioPage', 'goalRecurring', 'scheduleValue'],
        getState()
      );

      const paymentMethod =
        chosenPaymentMethodVA && chosenPaymentMethod === 'automatic'
          ? chosenPaymentMethodVA
          : chosenPaymentMethod;

      const goalRecurringData = {
        portfolio: portfolioId,
        payment: paymentMethod,
        schedule_value: scheduleValue[0],
      };
      dispatch(setLoadingRecalculatePortoGoal(true));
      const rawResponse = await postGoalRecalculateAmount(
        portfolioId,
        goalRecurringData
      );

      const parsedResponse = handleResponseGeneral(rawResponse);
      const parsedData = getSafely(['data'], parsedResponse);

      if (parsedData) {
        dispatch(goalDetailLoaded(parsedData));
      }

      /** Invalidate porto details */
      queryClient.invalidateQueries(['Portfolio Category Detail', portfolioId]);
      queryClient.invalidateQueries(['Portfolio Goal Detail', portfolioId]);

      return parsedData;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoadingRecalculatePortoGoal(false));
    }
  };
}

/**
 * Get progress on goal in portfolio
 * @param {String} portfolioId - Goal portfolio id
 */
export function getGoalProgressChart(portfolioId) {
  return async (dispatch, getState) => {
    try {
      dispatch(goalDetailChartLoading());

      // const portfolioId = getSafely(['portfolioPage', 'pageCategoryId'], getState());
      const rawResponse = await fetchGoalChartProgress(portfolioId);

      const parsedResponse = handleResponseGeneral(rawResponse);
      const parsedData = getSafely(['data'], parsedResponse);

      dispatch(goalDetailChartLoaded(parsedData));

      return parsedData;
    } catch (error) {
      dispatch(goalDetailChartLoaded());
      console.error(error);
    }
  };
}

/**
 * create shared portfolio
 * @param {number} categoryId - portfolioId
 */
export function createSharedPortfolio(categoryId) {
  return async (dispatch) => {
    try {
      await postCreateSharedPortfolio(categoryId);
      return dispatch(setSharedPortfolio(true));
    } catch (error) {
      throw error;
    }
  };
}

/**
 * reverse shared portfolio
 * @param {number} sharedPortfolioId - sharedPortfolioId
 */
export function reverseSharedPortfolio(sharedPortfolioId) {
  return async (dispatch) => {
    try {
      await postReverseSharedPortfolio(sharedPortfolioId);
      dispatch(portfolioPageLoading());
      return dispatch(setSharedPortfolio(false));
    } catch (error) {
      throw error;
    }
  };
}

export default portfolioPage;
