import { getEnv } from 'core/env';
import { isInsideNativeContainer } from 'utils/validator';

const { ShowAnalyticsLog } = getEnv();
export const initClevertap = () => {
  if (isInsideNativeContainer()) return;
  if (typeof window.clevertap?.setLogLevel === 'function') {
    window.clevertap?.setLogLevel(ShowAnalyticsLog ? 3 : 0);
  }
};
