import React from 'react';
import {
  EmittenData,
  EmittenDescription,
  EmittenIconWrapper,
  EmittenTitle,
  EmittenWrapper,
} from '../StockTable.styled';
import StockIcon from 'features/explore/components/StockIcon/StockIcon';
import { TableDefinitionConfig } from '../StockTable.type';

type Props = {
  config: TableDefinitionConfig;
  iconUrl?: string;
  value?: string | number;
  symbol?: string | number;
};

const TableBodyEmitten: React.FC<Props> = ({
  config,
  iconUrl,
  value,
  symbol,
}) => {
  return (
    <EmittenWrapper {...config}>
      <EmittenIconWrapper>
        <StockIcon iconUrl={iconUrl} symbol={String(symbol) ?? ''} />
      </EmittenIconWrapper>
      <EmittenData>
        <EmittenTitle
          type='body2'
          color='primary'
          style={{ paddingBottom: '2px' }}
        >
          {symbol ?? '-'}
        </EmittenTitle>
        <EmittenDescription type='body11r' color='secondary' htmlTag='p'>
          {value ?? '-'}
        </EmittenDescription>
      </EmittenData>
    </EmittenWrapper>
  );
};

export default TableBodyEmitten;
