import React, { SyntheticEvent } from 'react';
import { ChevronLeft } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { goBack } from 'utils/History';

interface BackButtonProps {
  /** Size of back button, in px */
  size?: number;

  /** This governs what back button will do if clicked */
  onClick?: Function;

  /** Determine chevron left icon color */
  color?: string;

  /** add custom className */
  className?: string;

  testId?: string;
}

/**
 * Our app back button element
 */
const BackButton: React.FC<React.PropsWithChildren<BackButtonProps>> = ({
  size = 20,
  onClick,
  color = 'var(--icon-default)',
  className,
  testId,
}) => {
  const history = useHistory();

  /**
   * On back button click, trigger back on history object
   */
  const handleClick = (e: SyntheticEvent) => {
    if (!onClick) {
      return goBack(history);
    }
    return onClick(e);
  };

  return (
    <div
      className={className ? `backBigger ${className}` : 'backBigger'}
      onClick={handleClick}
      data-testid={testId ?? 'back-btn'}
    >
      <ChevronLeft color={color} size={size} />
    </div>
  );
};

export default BackButton;
