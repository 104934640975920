export const decideTextColor = (value: number) => {
  if (value > 0) {
    return 'greenDefault';
  }
  if (value < 0) {
    return 'redDefault';
  }

  return 'secondary';
};
