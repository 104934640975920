import queryClient from 'network/queryClient';
import Storage from 'core/Storage';
import {
  InstitutionProfileNotification,
  StorageInstitution,
} from '../types/institution.type';
import { apiInstance } from 'core/http';
import { INSTITUTION_PROFILE_NOTIF_KEY } from '../hooks/notificationInstitution';

export const getCurrentInstitution = () => {
  const institution = localStorage.getItem('current_institution');
  if (!institution) return null;

  try {
    return JSON.parse(institution) as StorageInstitution;
  } catch (error) {
    return null;
  }
};

export const setCurrentInstitution = (id: number) => {
  localStorage.setItem(
    'current_institution',
    JSON.stringify({
      institution_id: id,
    })
  );

  apiInstance.defaults.headers.common['x-institution-id'] = id;
  queryClient.invalidateQueries(['current_institution']);
  queryClient.invalidateQueries(['institution_profile', id]);
};

export const removeCurrentRoleInstitution = () => {
  return localStorage.removeItem('role_institution');
};

export const removeCurrentInstitution = () => {
  localStorage.removeItem('current_institution');

  // remove role institution local storage
  removeCurrentRoleInstitution();

  delete apiInstance.defaults.headers.common['x-institution-id'];
  queryClient.invalidateQueries(['current_institution']);
};

export const getInstitutionProfileNotification = () => {
  return Storage.getObject(
    INSTITUTION_PROFILE_NOTIF_KEY
  ) as Promise<InstitutionProfileNotification>;
};

export const setInstitutionProfileNotification = (
  notification: InstitutionProfileNotification
) => {
  Storage.setObject(INSTITUTION_PROFILE_NOTIF_KEY, notification);
  queryClient.invalidateQueries([INSTITUTION_PROFILE_NOTIF_KEY]);
};

export const removeInstitutionProfileNotification = async () => {
  Storage.removeObject(INSTITUTION_PROFILE_NOTIF_KEY);
  queryClient.invalidateQueries([INSTITUTION_PROFILE_NOTIF_KEY]);
};

export const setCurrentRoleInstitution = (role: string) => {
  localStorage.setItem('role_institution', role);
};

export const getCurrentRoleInstitution = () => {
  return localStorage.getItem('role_institution');
};
