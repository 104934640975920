import React, { FC } from 'react';
import { Modal, ButtonNew, Flex } from '@bibitid/uikit-v1';
import { ChooseInvestmentTarget } from 'features/portfolio/components';
import { useCreatePortoModal } from 'features/portfolio/modals/CreatePortfolioRoboModal/context/CreatePortfolioModalProvider';
import useHomeOnboardingContext from 'features/home/components/Modals/onboarding-modal-flow/hooks/useHomeOnboardingContext';

import styles from './ChooseInvestmentTargetModal.module.css';

const ChooseInvestmentTargetModal: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const {
    createPortfolioRoboModalVisible,
    step,
    templateId,
    closeModal,
    setTemplateId,
    setCreatePortfolioRoboModalVisible,
    resetCreatePortfolioRoboModal,
  } = useCreatePortoModal();
  const {
    setOnboardingModalStep,
    setOnboardingModalVisible,
    setPortfolioTemplateId,
    setPortfolioTypeName,
    setPortfolioTypeId,
    setTriggeredFromModalFlow,
  } = useHomeOnboardingContext();

  const visible = createPortfolioRoboModalVisible && step === 'choose';

  const handleCloseModal = () => {
    closeModal();
    resetCreatePortfolioRoboModal();
  };

  /** Choose Icon -> Set portfolio template ID */
  const handleChooseIcon = (id: string | undefined) => {
    setTemplateId(id);
  };

  const handleClickApply = () => {
    if (!templateId) return;

    setOnboardingModalStep('create_portfolio');
    setPortfolioTemplateId(templateId);
    setTriggeredFromModalFlow('robo_voucher');

    if (templateId === '2') {
      /** when templateId is 2, portfolio type name is Bibit Pensiun */
      setPortfolioTypeName('Bibit Pensiun');

      // portfolio type id 3 for bibit pension
      setPortfolioTypeId('3');
    } else {
      /** when templateId is not 2, portfolio type name is Bibit Goals */
      setPortfolioTypeName('Bibit Goals');

      // portfolio type id 2 for bibit Goal
      setPortfolioTypeId('2');
    }

    setCreatePortfolioRoboModalVisible(false);
    setOnboardingModalVisible(true);
  };

  return (
    <Modal visible={visible} onClose={handleCloseModal}>
      <Modal.Header
        title='Apa Tujuan Investasi Kamu?'
        showBackButton={false}
        showClose={true}
      />
      <Modal.Body>
        <Flex direction='column' justify='center'>
          <div className={styles['bit-target-container']}>
            <ChooseInvestmentTarget
              usePension={true}
              onItemSelected={handleChooseIcon}
              defaultTemplateId={templateId}
            />
          </div>
        </Flex>
      </Modal.Body>
      <Modal.Footer
        primaryButton={
          <ButtonNew
            content={'Terapkan'}
            variant={'primary'}
            disabled={!!!templateId}
            onClick={handleClickApply}
            testId='choose-investment-target-btn-test'
          />
        }
      />
    </Modal>
  );
};

export default ChooseInvestmentTargetModal;
