import connectedDevicesEndpoints from 'features/profile/connected-devices/networks/endpoint';
import profileChangeEmailEndpoints from 'features/profile/setting/ChangeEmail/networks/endpoints';
import eStatementEndpoints from 'features/profile/tax/network/endpoints';
import beneficiaryEndpoints from 'features/profile/setting/AhliWaris/networks/endpoints';
import institutionEStatementEndpoints from 'features/institution/pages/e-statement/pages/InstitutionEStatementPage/network/endpoint';
import commonEndpoints from 'services/index';
import { matchPath } from 'react-router';

const userAccessScope: {
  pathname: string[];
  endpoints: string[];
  scope: string[];
  type?: 'personal' | 'institution' | 'both';
}[] = [
  {
    pathname: ['/logged-device'],
    endpoints: [
      connectedDevicesEndpoints.getConnectedDevices,
      connectedDevicesEndpoints.clearConnectedDevices,
    ],
    scope: ['auth:access:list', 'auth:access:clear'],
  },
  {
    pathname: ['/profile/settings/email'],
    endpoints: [profileChangeEmailEndpoints.changeEmail],
    scope: ['auth:email:change'],
  },
  {
    pathname: [
      '/tax-download',
      '/tax-report',
      '/transaction-history',
      '/ownership-sbn',
    ],
    endpoints: [
      eStatementEndpoints.taxRequest,
      eStatementEndpoints.transRequest,
      eStatementEndpoints.proofOwnershipSBN(':symbol'),
    ],
    scope: ['user:report:tax', 'user:report:trans', 'user:report:sbn_poo'],
  },
  {
    pathname: ['/ahli-waris', '/tambah-ahli-waris', '/data-ahli-waris'],
    endpoints: [
      beneficiaryEndpoints.getAhliWaris,
      beneficiaryEndpoints.addAhliWaris,
      beneficiaryEndpoints.editAhliWaris(':id'),
      beneficiaryEndpoints.deleteAhliWaris(':id'),
    ],
    scope: [
      'user:beneficiary:list',
      'user:beneficiary:add',
      'user:beneficiary:update',
      'user:beneficiary:delete',
    ],
  },
  {
    pathname: ['/institution/e-statement'],
    endpoints: [institutionEStatementEndpoints.institutionRequestTaxReport],
    scope: ['institution:report:tax'],
  },
  {
    pathname: ['/profile/settings/changebank', '/profile/settings/bankaccount'],
    endpoints: [
      commonEndpoints.getBankListUser(1),
      commonEndpoints.userChangeBankRequest,
      commonEndpoints.deactiveBank,
    ],
    scope: ['user:bank:list', 'user:bank:request', 'user:bank:deactivate'],
  },
  {
    pathname: [
      '/profile/user/detail',
      '/profile/user/detail/change',
      '/profile/data-renewal',
    ],
    endpoints: [
      commonEndpoints.userProfilePersonal,
      commonEndpoints.userProfileUpdate,
    ],
    scope: ['user:profile:personal', 'user:profile:update'],
    type: 'personal',
  },
];

export const findScopeByPathname = (
  pathname: string,
  isInstitution: boolean
) => {
  const scope = userAccessScope.find((scope) =>
    scope.pathname.find((path) => {
      const match = matchPath(pathname, {
        path,
        exact: true,
      });

      return !!match;
    })
  );

  if (scope?.type === 'personal') {
    return !isInstitution ? scope : undefined;
  } else if (scope?.type === 'institution') {
    return isInstitution ? scope : undefined;
  } else {
    return scope;
  }
};

export const findScopeByEndpoints = (endpoints: string) => {
  const scope = userAccessScope.find((scope) =>
    scope.endpoints.find((endpoint) => {
      const match = matchPath(endpoints, {
        path: endpoint,
        exact: true,
      });

      return !!match;
    })
  );

  return scope;
};
