import React, { useContext, useState } from 'react';
import { StockBuyPreview } from 'features/stock/types';

export type StockBuyStep = 'buy_preview' | 'buy_success' | 'buy_fee';

interface ContextState {
  buyLot: number;
  buyUnitPrice: number;
  stockBuyModalStep?: StockBuyStep;
  stockBuyPreviewData?: StockBuyPreview;
}

interface ContextFunction {
  setBuyLot: (lot: number) => void;
  setBuyUnitPrice: (price: number) => void;
  setStockBuyModalStep: (step?: StockBuyStep) => void;
  setStockBuyPreviewData: (data: StockBuyPreview) => void;
}

const StockBuyContextState = React.createContext<ContextState | undefined>(
  undefined
);
const StockBuyContextFunction = React.createContext<
  ContextFunction | undefined
>(undefined);

const StockBuyContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  /**
   * total lot that user want to order
   */
  const [buyLot, setBuyLot] = useState(0);

  /**
   * price offered by users
   */
  const [buyUnitPrice, setBuyUnitPrice] = useState(0);

  /**
   * determine the modal that must be shown
   */
  const [stockBuyModalStep, setStockBuyModalStep] = useState<
    StockBuyStep | undefined
  >();

  const [stockBuyPreviewData, setStockBuyPreviewData] = useState<
    StockBuyPreview | undefined
  >(undefined);

  return (
    <StockBuyContextState.Provider
      value={{
        buyLot,
        buyUnitPrice,
        stockBuyModalStep,
        stockBuyPreviewData,
      }}
    >
      <StockBuyContextFunction.Provider
        value={{
          setBuyLot,
          setBuyUnitPrice,
          setStockBuyModalStep,
          setStockBuyPreviewData,
        }}
      >
        {children}
      </StockBuyContextFunction.Provider>
    </StockBuyContextState.Provider>
  );
};

export default StockBuyContextProvider;

export const useStockBuyContextState = () => {
  const ctx = useContext(StockBuyContextState);

  if (!ctx) {
    throw new Error('StockBuyContextState out of boundary');
  }

  return ctx;
};

export const useStockBuyContextFunction = () => {
  const ctx = useContext(StockBuyContextFunction);

  if (!ctx) {
    throw new Error('StockBuyContextFunction out of boundary');
  }

  return ctx;
};
