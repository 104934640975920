import React from 'react';
import InvestmentCompanyLogo from 'features/common/icons/InvestmentCompanyLogo';
import getSafely from 'utils/safely';
import classNames from 'classnames';
import { ReksaDana } from 'entities/reksadana.reducer';
import { numberToPercentagePointChangeFormat } from 'utils/stringHelper';
import TopIcon from './assets/top-icon.svg';
import IconWrapper from 'utils/IconWrapper/IconWrapper';
import InstantLogo from 'assets/images/bank-jago/instant-redemption/instant-logo/InstantLogo';
import { IntroInstantRedemptionModal } from 'features/bankjago/components/modals';

interface CardProps {
  fundData: ReksaDana;
  isComparing?: boolean;
  selected?: boolean;
  onClick: (roboCategory: string, symbol: string) => void;
  showModalInstantRedemption: boolean;
  toggleActiveModalInstantRedemption: () => void;
  isInsti?: boolean;
}

const ReksaDanaAverageYieldCard: React.FC<
  React.PropsWithChildren<CardProps>
> = ({
  fundData,
  isComparing,
  selected,
  onClick,
  showModalInstantRedemption,
  toggleActiveModalInstantRedemption,
  isInsti,
}) => {
  const {
    name,
    avg_yield,
    investment_manager,
    is_instant_redemption,
    is_top_product,
  } = fundData;

  const averageYield: number = getSafely(['percentage'], avg_yield);
  const averageYieldText = numberToPercentagePointChangeFormat(averageYield, {
    forceSign: false,
  });

  const handleClick = () => {
    const { robocategory, symbol } = fundData;
    onClick(robocategory, symbol);
  };

  return (
    <>
      <div
        className={classNames({
          'bit-filter-result-row': true,
          'bit-filter-expanse': true,
          'card-selected': selected,
          'card-reksa-shake': isComparing,
        })}
      >
        <div>
          <div className='bit-filter-reksa' onClick={handleClick}>
            <InvestmentCompanyLogo
              type='investment-manager'
              size='square'
              ojkCode={getSafely(['ojkCode'], investment_manager, '')}
            />
            <div>
              <h3>{name}</h3>
            </div>
          </div>
          <div className='wrapper-return-icon'>
            <div className='wrapper-images'>
              {is_top_product === 1 && (
                <IconWrapper
                  backgroundColor={{
                    default: 'var(--green-low)',
                    inModal: 'var(--modal-green-low)',
                  }}
                  $size='20px'
                  className='instant-redemption-rdcard'
                  $borderRadius='50%'
                >
                  <img
                    src={TopIcon}
                    alt=''
                    style={{ width: '12px', height: '12px' }}
                  />
                </IconWrapper>
              )}
              {is_instant_redemption && !isInsti && (
                <IconWrapper
                  backgroundColor={{
                    default: 'var(--green-low)',
                    inModal: 'var(--modal-green-low)',
                  }}
                  $size='20px'
                  className='instant-redemption-top-mutual-fund'
                  $borderRadius='50%'
                >
                  <InstantLogo
                    alt='instant'
                    style={{ display: 'flex' }}
                    onClick={toggleActiveModalInstantRedemption}
                  />
                </IconWrapper>
              )}
            </div>
            <div
              className={classNames('bit-filter-right-result no-min-width', {
                null_result: averageYieldText === '-',
              })}
            >
              <p>{averageYieldText}</p>
            </div>
          </div>
        </div>
      </div>
      <IntroInstantRedemptionModal
        showModal={showModalInstantRedemption}
        onClose={toggleActiveModalInstantRedemption}
      />
    </>
  );
};

export default ReksaDanaAverageYieldCard;
