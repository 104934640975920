import React from 'react';
import {
  DataInfo,
  DataSort,
  SortWrapper,
  TextWrapper,
} from '../StockTable.styled';
import { SortBy, TableDefinitionConfig } from '../StockTable.type';
import { ChevronUp, ChevronDown } from 'react-feather';
import useColumnConfiguration from '../utils/useColumnConfiguration';

interface Props {
  id?: string | number;
  config: TableDefinitionConfig;
  label?: string;
  onSort?: (
    sort: SortBy,
    sortBy: string,
    label: string,
    isForceClientSide?: boolean
  ) => void;
  isActiveSort?: boolean;
  sortBy?: SortBy;
  sortColumn?: number | string;
  isLoading?: boolean;
  forceClientSort?: boolean;
  setIsForceClientSort: (isForce: boolean) => void;
}

const TableHeadSort: React.FC<Props> = ({
  id = '',
  config,
  label = '',
  onSort,
  isActiveSort = false,
  sortBy = 'DESC',
  sortColumn,
  isLoading = false,
  forceClientSort = false,
  setIsForceClientSort,
}) => {
  const { generateTextAlign, generateTextSize } = useColumnConfiguration(
    config,
    'header'
  );

  const showSortIcon = config?.showSortIcon ?? true;

  const dataSortAlign = generateTextAlign(config as TableDefinitionConfig);

  const textSize = generateTextSize(
    label,
    Number(config?.fontSize?.substring(0, 2))
  );

  const handleSort = () => {
    /** prevent sort when loading */
    if (isLoading) return;
    const isSameField = String(sortColumn) === String(id);

    const sortReturn = isSameField
      ? sortBy === 'DESC'
        ? 'ASC'
        : 'DESC'
      : 'DESC';

    setIsForceClientSort(forceClientSort);
    onSort?.(sortReturn, String(id), label, forceClientSort);
  };

  return (
    <DataInfo
      disabled={isLoading}
      onClick={handleSort}
      {...config}
      fontSize={textSize}
      isActive={isActiveSort}
    >
      <DataSort textAlign={dataSortAlign}>
        <TextWrapper isSeparated>{label}</TextWrapper>

        {showSortIcon && (
          <SortWrapper isVisible={isActiveSort}>
            <ChevronUp
              size={18}
              color={
                sortBy === 'ASC' ? 'var(--green-default)' : 'var(--inactive)'
              }
            />
            <ChevronDown
              size={18}
              color={
                sortBy === 'DESC' ? 'var(--green-default)' : 'var(--inactive)'
              }
            />
          </SortWrapper>
        )}
      </DataSort>
    </DataInfo>
  );
};

export default TableHeadSort;
