import {
  format as fnsFormat,
  formatISO as fnsFormatISO,
  formatDistanceToNow as fnsFormatDistanceToNow,
  isValid,
  parse,
} from 'date-fns';
import { id, enUS } from 'date-fns/locale';

type FormatISOOptions = {
  format?: 'extended' | 'basic' | undefined;
  representation?: 'complete' | 'date' | 'time' | undefined;
};

type FormatDistanceToNowOptions = {
  includeSeconds?: boolean | undefined;
  addSuffix?: boolean | undefined;
  locale?: Locale | undefined;
};

type FormatOptions = {
  locale?: Locale | undefined;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
  firstWeekContainsDate?: number | undefined;
  useAdditionalWeekYearTokens?: boolean | undefined;
  useAdditionalDayOfYearTokens?: boolean | undefined;
};

// Locales supported by our app
const locales = { id, enUS };

/**
 * Format date object into desired time format output
 * @param {Date|number} date The original time, can be Date object or unix timestamp in ms
 * @param {string} formatString String contains format recognizable by date-fns
 * @param {FormatOptions=} options date-fns option, if you want to override default config from our app
 * @returns {string} Formatted date string
 * @see https://date-fns.org/v2.14.0/docs/format
 */
export function format(
  date: Date | number,
  formatString: string,
  options?: FormatOptions
): string {
  if (isValid(date)) {
    return fnsFormat(date, formatString, {
      // @ts-ignore
      locale: locales[window.__localeId__ || 'id'],
      ...options,
    });
  }

  return '';
}

/**
 * Format date into ISO 8601 standard format
 * @param {Date|number} date The original time
 * @param {FormatISOOptions=} options date-fns option, if you want to override default config from our app
 * @returns {string} Formatted date string
 * @see https://date-fns.org/v2.15.0/docs/formatISO
 */
export function formatISO(
  date: Date | number,
  options?: FormatISOOptions
): string {
  return fnsFormatISO(date, {
    // @ts-ignore
    locale: locales[window.__localeId__ || 'id'],
    ...options,
  });
}

/**
 * Return the distance between the given date and now in words.
 * @param {Date|number} date The original time
 * @param {FormatDistanceToNowOptions=} options date-fns option, if you want to override default config from our app
 * @returns {string} Time relative to now, ex: less than a minute, about 1 hour
 * @see https://date-fns.org/v2.15.0/docs/formatISO
 */
export function formatDistanceToNow(
  date: Date | number,
  options?: FormatDistanceToNowOptions
) {
  return fnsFormatDistanceToNow(date, {
    // @ts-ignore
    locale: locales[window.__localeId__ || 'id'],
    ...options,
  });
}

/**
 * Return month in a format based on parameter
 * @constant {string} locale use locale setting date as Indonesian format
 * @param {Intl.DateTimeFormatOptions['month']} format paramter to set the value of month
 * @return {string[]} return consists of the month based on the format
 */
export function getMonths(format: Intl.DateTimeFormatOptions['month']) {
  const locale = 'id-ID';
  const formatDate = new Intl.DateTimeFormat(locale, {
    month: format,
  }).format;
  return [...Array(12).keys()].map((m) => {
    return formatDate(new Date(2021, m));
  });
}

export const utcToWIB = (date: string) => {
  const wib = new Date(date).toLocaleString('en-US', {
    timeZone: 'Asia/Jakarta',
  });

  return parse(wib, 'M/dd/yyyy, h:mm:ss a', new Date());
};

export { getUnixTime } from 'date-fns';

export default format;
