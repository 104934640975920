export const translation = {
  pasar_uang: 'Money Market',
  reset: 'Reset',
  stable_earn: 'Stable Earn',
  max_drowdown: 'Max Drawdown',
  obligasi_fr: 'FR Bonds',
  aum: 'AUM',
  all: 'All',
  filter: 'Filter',
  syariah: 'Syariah ',
  obligasi: 'Bonds',
  pencairan_instant: 'Instant Redemption',
  keuntungan: 'Profit',
  total_aum: 'Total AUM',
  watchlist: 'Watchlist',
  pembelian: 'Buying',
  beli: 'Buy',
  urutkan: 'Order',
  imbal_hasil: 'Return',
  tahun: 'Year',
  pilih_reksadana: 'Choose Mutual Funds',
  topup: 'Top Up',
  return: 'Return',
  reksadana: 'Mutual Funds',
  tambah: 'Add',
  lihat_semua: 'See All',
  year_to_date: 'Year to Date',
  top_reksadana: 'Top Mutual Funds',
  lainnya: 'Others',
  sbn_retail: 'SBN Retail',
  penjualan: 'Sell',
  portfolio: 'Portfolio',
  robo: 'Robo',
  konvensional: 'Conventional',
  hari: 'Day',
  ubah: 'Change',
  semua: 'All',
  explore: 'Explore',
  bibit_plus: 'Bibit Plus',
  expense_ratio: 'Expense Ratio',
  investasi: 'Investment',
  jual: 'Sell',
  average_yield: 'Average Yield',
  saham: 'Stocks',
  nav: 'NAV',
  bulan: 'Month',
  bandingkan: 'Compare',
  jenis_reksadana: 'Types of Mutual Funds',
  alokasi_dana: 'Fund Allocation',
  lanjutkan: 'Next',
  pilih: 'Select',
  baik_saya_mengerti: 'Okay, understood',
  simpan: 'Save',
  pilih_tanggal: 'Choose a date',
  batal: 'Cancel',
  mohon_tunggu: 'Please wait',
  konfirmasi: 'Confirm',
  cek_ulang: 'Double check',
  hapus: 'Delete',
  selesai: 'Done',
  atau: 'or',
  kirim: 'Send',
  cari: 'Search',
  bantuan: 'Support',
  rb: 'K',
  jt: 'M ',
  m: 'B',
  t: 'T',
  benchmark: 'Benchmark',
  index_fund: 'Index Fund',
  return_tertinggi: 'Highest Return',
  reksadana_usd: 'Mutual Fund USD',
  minimal_reksadana: 'Minimum 10K',
  filter_berdasarkan: 'Filter by',
  terapkan: 'Apply',
  urutkan_dari: 'Sort by',
  bahasa: 'Language',
};
