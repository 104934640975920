import { randomString } from 'utils/stringHelper';
import {
  LivePriceData,
  OrderBookLiveData,
  RandomStringFormat,
} from '../types/stocbitWSTypes';

interface ParserResponse {
  livePrice: LivePriceData | undefined;
  orderBook: OrderBookLiveData | undefined;
}

const parseLivePriceData = (messageData: string[]): LivePriceData => {
  const value: LivePriceData = {
    channel: 'C',
    companySymbol: '',
    lastUpdate: '',
    lastUpdateFormatted: '',
    adjClose: '',
    open: '',
    high: '',
    low: '',
    close: '',
    volume: '',
    lastPrice: '',
    change: '',
    percentageChange: '',
    dividend: '',
    foreignBuy: '',
    foreignFlow: '',
    foreignSell: '',
    frequency: '',
    marketCap: '',
    value: '',
    shareoutStanding: '',
    annEps: '',
    annPe: '',
    avgPrice: '',
    previous: '',
    frequencyAnalyzer: '',
    sysSendtime: '',
  };

  Object.keys(value).forEach((key, index) => {
    value[key] = messageData[index];
  });

  return value;
};

const parseOrderBookLiveData = (messageData: string[]): OrderBookLiveData => {
  const orderBookValidStatus = ['BID', 'OFFER'];

  const value: OrderBookLiveData = {
    channel: '#O',
    companySymbol: '',
    status: '',
    queue: Array(10).map(() => ({
      price: '',
      queue: '',
      volume: '',
    })),
    sysSendtime: '',
  };

  const arrQueue: { price: any; queue: any; volume: any }[] = [];
  const queueData = messageData.slice(3, messageData.length - 1);

  value.companySymbol = messageData[1];
  value.status = orderBookValidStatus.includes(messageData[2])
    ? messageData[2]
    : '';
  value.sysSendtime = messageData[messageData.length - 1];

  queueData.forEach((queueItem) => {
    const queueEntity = queueItem.split(';');

    arrQueue.push({
      price: queueEntity[0],
      queue: queueEntity[1],
      volume: queueEntity[2],
    });
  });

  value.queue = arrQueue;

  return value;
};

export const parserWSMessageData = (messeageData: string): ParserResponse => {
  const response: any = {
    livePrice: null,
    orderBook: null,
  };

  try {
    const splittedData: string[] = messeageData
      .replace(/[a#"[\]\\/]/g, '')
      .split('|');
    const channelKey = splittedData[0];

    const dataParserMap: any = {
      C: { key: 'livePrice', parser: parseLivePriceData },
      O: { key: 'orderBook', parser: parseOrderBookLiveData },
    };

    const dataParser = dataParserMap[channelKey]?.parser;

    if (typeof dataParser === 'function') {
      response[dataParserMap[channelKey].key] = dataParser(splittedData);
    }
  } catch (error) {
    throw error;
  }

  return response;
};

type RandomStringType = 'hex' | 'numeric' | undefined;

export const createRandomWSPath = (
  length: number,
  format: RandomStringFormat
): string => {
  const typesFormatMap = {
    mix: 'hex',
    num: 'numeric',
  };

  const type = typesFormatMap[format] as RandomStringType;

  return randomString(length, type);
};

export const isMessageDataType = (data: string): boolean => {
  const isNoHeartbeatResponse = data.includes('No response from heartbeat');
  const isUnAuthorizedMessage = data.includes('Unauthorized');
  const isServerCloseMessage = data.includes('primus::server::close');
  const isPingServerMessage = data.includes('primus::pong');
  const isClosureMessage = data.includes('Normal closure');
  const isAuthMessage = data.includes('Auth_OK');
  const isOpenMessage = data === 'o';

  return (
    !isNoHeartbeatResponse &&
    !isUnAuthorizedMessage &&
    !isServerCloseMessage &&
    !isPingServerMessage &&
    !isClosureMessage &&
    !isAuthMessage &&
    !isOpenMessage
  );
};
