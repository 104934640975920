import { useTheme } from 'hooks';
import React, { ImgHTMLAttributes, FC, useRef, useMemo } from 'react';
import { linkModify } from './image-link-modify';

interface Props extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> {
  link: string;
}

/**
 * Wrapper `img` for display link-image from Backend.
 *
 * `src` will be modified to respect user's current theme.
 * ex: https://assets.bibit.id/images/payment/rdn-wallet.svg --> https://assets.bibit.id/images/payment/rdn-wallet-dark.svg
 *
 */
export const ImageURL: FC<Props> = ({ link, ...other }) => {
  const { theme } = useTheme();
  const imgRef = useRef<HTMLImageElement>(null);

  const linkSrc = useMemo(() => linkModify(link, theme), [link, theme]);

  const handleError = () => {
    if (!imgRef.current) return;

    imgRef.current.src = link; // if failed make it to default link
  };

  return (
    <img
      ref={imgRef}
      onError={handleError}
      loading='lazy'
      decoding='async'
      src={linkSrc}
      alt={other.alt}
      {...other}
    />
  );
};
