export const institutionBlackListRoute = [
  '/recurring',
  '/referral',
  '/robo',
  '/event',
  '/academy',
  '/articles',
  '/gift',
  '/cart',
  '/notifications',
  '/community',
  '/tax-download',
  '/vouchers',
  '/glossary',
  '/community',
  '/bantuan',
  '/product/obligasi-negara/sbn',
  '/product/stock',
  '/categories/minimum',
  '/categories/instant',
  '/categories/usd',
  '/best/syariah',
  '/quiz',
  '/profiling-result',
  '/profiling',
  '/ahli-waris',
  '/tambah-ahli-waris',
  '/profit-review-2022',
];

export const INSTITUTION_MIN_BUY_TOP_UP = 1_000_000;

//Date format using yyyy-mm-dd
const FIRST_RELEASE_INSTITUTION = new Date('2023-01-25');

const INSTITUTION_CHANGE_DATE = FIRST_RELEASE_INSTITUTION.setMonth(
  FIRST_RELEASE_INSTITUTION.getMonth() + 1
);

export const PAST_INSTITUTION_CHANGE_DATE: boolean =
  Date.now() > INSTITUTION_CHANGE_DATE;

export const INSTITUTION_STORY_COUNTER = 'institutionStoryCounter';

export const INSTI_DEFAULT_DAILY_BUY_LIMIT = 10_000_000_000;
