export {
  isValid,
  isAfter,
  isSameDay,
  isSameMinute,
  isTomorrow,
  isWithinInterval,
} from 'date-fns';

export { isValid as default } from 'date-fns';

export const isExchangeHours = () => {
  const now = new Date();
  /**
   * convert locale date to GMT+7
   */
  const exchangeHours = now.getUTCHours() + 7;
  /**
   * exchange day is monday - friday
   */
  const exchangeDay = [1, 2, 3, 4, 5].includes(now.getDay());
  /**
   * exchange time is 09.00 - 15.00 (GMT+7)
   */
  const exchangeTime = exchangeHours >= 9 && exchangeHours < 15;

  return exchangeDay && exchangeTime;
};
