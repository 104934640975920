import React from 'react';
import { Modal } from '@bibitid/uikit-v1';

const ModalPlaceHolder: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Modal visible={true} showMask={false}>
      <Modal.Header showClose={false} showBackButton={false} />
      <Modal.Body>
        <div
          className='animated-background recomend-loader'
          style={{ width: '100%' }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ModalPlaceHolder;
