import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import { nextMonth } from '../utils';
import {
  StateProductBestOf,
  SelectedProduct,
  PortfolioTypeName,
} from '../types';
import { NativeContactList } from 'features/shared-portfolio/types';

export type OnboardingFlowStep =
  | 'investment_option'
  | 'syariah_option'
  | 'safest_product'
  | 'product_list_best_of'
  | 'emergency_option'
  | 'portfolio_option'
  | 'choose_icon'
  | 'create_portfolio'
  | 'choose_portfolio_type'
  | 'robo_configuration'
  | 'choose_single_product'
  | 'explore_product'
  | undefined;

type TriggeredFromPortfolioFlow =
  | 'default'
  | 'robo_create_pension'
  | 'robo_voucher'
  | 'quick_access'
  | 'tutorial'
  | 'robo_recommendation';

interface HomeOnboardingContextInterface {
  // States //

  /** Visibility flag */
  onboardingModalVisible: boolean;

  /** Onboarding Modal Step (ENUM) */
  onboardingModalStep: OnboardingFlowStep;

  /** Config to show best product */
  productBestOf: StateProductBestOf;

  /** Selected product to buy */
  selectedProduct?: SelectedProduct;

  /** User has emergency fund boolean */
  hasEmergencyFund: boolean;

  /** Portfolio option/type (ENUM) */
  portfolioTypeName: PortfolioTypeName | undefined;

  /** Portfolio Type Id (Ex: '1' for Bibit Darurat) */
  portfolioTypeId: string | undefined;

  /** Portfolio Id (Ex: 98504 as number) */
  onboardingPortfolioId: number | undefined;

  /** Portfolio Template ID (of icon template) */
  portfolioTemplateId: string | undefined;

  /** Portfolio Name */
  onboardingPortoName: string | undefined;

  /** Portfolio Target Amount */
  onboardingPortoTargetAmount: number | undefined;

  /** Portfolio Target Date */
  onboardingPortoDateTarget: Date;

  /** Portfolio Score */
  onboardingPortoScore: number | undefined;

  /** Toggle Portfolio Shared */
  onboardingPortoShared: boolean;

  /** List Contacts Portfolio Shared */
  onboardingPortoSharedContacts: Array<NativeContactList>;

  /** It is triggered from XXX flow */
  triggeredFromModalFlow: TriggeredFromPortfolioFlow;

  /** A flag to show Disarankan label */
  isFollowOnboardingRecommendation: boolean;

  /** A flag to use in PorfolioOptionOnBoardingModal
   * determine show or not back button
   */
  isPortoOptionStepFromEmergencyOption: boolean;

  // Function //

  /** Set visibility of onboarding modal flow */
  setOnboardingModalVisible: Dispatch<SetStateAction<boolean>>;

  /** Set Modal Step (ENUM) */
  setOnboardingModalStep: Dispatch<SetStateAction<OnboardingFlowStep>>;

  /** Set user has emergency fund boolean */
  setHasEmergencyFund: Dispatch<SetStateAction<boolean>>;

  /** Set Portfolio Option (ENUM) */
  setPortfolioTypeName: Dispatch<SetStateAction<PortfolioTypeName | undefined>>;

  /** Set Portfolio Type Id (Ex: '1' for Bibit Darurat) */
  setPortfolioTypeId: Dispatch<SetStateAction<PortfolioTypeName | undefined>>;

  /** Set Onboarding portfolio id (Ex: 98504 as number) */
  setOnboardingPortfolioId: Dispatch<SetStateAction<number | undefined>>;

  /** Set Portfolio Template ID (of icon template) */
  setPortfolioTemplateId: Dispatch<SetStateAction<string | undefined>>;

  /** Set Portfolio Name */
  setOnboardingPortoName: Dispatch<SetStateAction<string | undefined>>;

  /** Set Portfolio Target Amount */
  setOnboardingPortoTargetAmount: Dispatch<SetStateAction<number | undefined>>;

  /** Set Portfolio Date Target */
  setOnboardingPortoDateTarget: Dispatch<SetStateAction<Date>>;

  /** Set Portfolio Score */
  setOnboardingPortoScore: Dispatch<SetStateAction<number | undefined>>;

  /** Set Portfolio Shared */
  setOnboardingPortoShared: Dispatch<SetStateAction<boolean>>;

  /** Set Portfolio Shared Contacts */
  setOnboardingPortoSharedContacts: Dispatch<
    SetStateAction<Array<NativeContactList>>
  >;

  /**  Set config to show best product*/
  setProductBestOf: (data: StateProductBestOf) => void;

  /** Set selected product */
  /** @deprecated dont use this anymore. handle product in local component */
  setSelectedProduct: (product?: SelectedProduct) => void;

  /** Set triggered from modal flow in string */
  setTriggeredFromModalFlow: Dispatch<
    SetStateAction<TriggeredFromPortfolioFlow>
  >;

  /** Set is follow onboarding reocmmendation when select Disarankan */
  setIsFollowOnboardingRecommendation: Dispatch<SetStateAction<boolean>>;

  /** Set Portfolio Option step is triggered from Emergency Option] */
  setIsPortoOptionStepFromEmergencyOption: Dispatch<SetStateAction<boolean>>;

  /** Close Onboarding Modal and its flow */
  handleCloseHomeOnboardingModal: () => void;

  /** reset user input form */
  resetUserInput: () => void;
}

export const HomeOnboardingContext = createContext<
  HomeOnboardingContextInterface | undefined
>(undefined);

/**
 * Provider for home onboarding modal flow
 * TODO_ONBOARDING: Split HomeOnboardingContext into 2 Contexts:
 * HomeOnboardingStateContext
 * HhomeOnboardingFunctionContext
 */
const HomeOnboardingProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [onboardingModalVisible, setOnboardingModalVisible] =
    useState<boolean>(false);
  const [onboardingModalStep, setOnboardingModalStep] =
    useState<OnboardingFlowStep>(undefined);

  const [hasEmergencyFund, setHasEmergencyFund] = useState<boolean>(false);

  const [portfolioTypeName, setPortfolioTypeName] = useState<
    PortfolioTypeName | undefined
  >(undefined);

  const [portfolioTypeId, setPortfolioTypeId] = useState<
    PortfolioTypeName | undefined
  >(undefined);

  const [onboardingPortfolioId, setOnboardingPortfolioId] = useState<
    number | undefined
  >(undefined);

  const [productBestOf, setProductBestOf] = useState<StateProductBestOf>({
    typeBestOfProductList: 'moneymarket',
  });

  const [selectedProduct, setSelectedProduct] = useState<SelectedProduct>();

  const [portfolioTemplateId, setPortfolioTemplateId] = useState<
    string | undefined
  >(undefined);

  const [onboardingPortoName, setOnboardingPortoName] = useState<
    string | undefined
  >(undefined);

  const [onboardingPortoDateTarget, setOnboardingPortoDateTarget] =
    useState<Date>(nextMonth);

  const [onboardingPortoTargetAmount, setOnboardingPortoTargetAmount] =
    useState<number | undefined>(undefined);

  const [onboardingPortoScore, setOnboardingPortoScore] = useState<
    number | undefined
  >(undefined);

  const [onboardingPortoShared, setOnboardingPortoShared] =
    useState<boolean>(false);

  const [onboardingPortoSharedContacts, setOnboardingPortoSharedContacts] =
    useState<Array<NativeContactList>>([]);

  const [triggeredFromModalFlow, setTriggeredFromModalFlow] =
    useState<TriggeredFromPortfolioFlow>('default');

  const [
    isFollowOnboardingRecommendation,
    setIsFollowOnboardingRecommendation,
  ] = useState<boolean>(false);

  const [
    isPortoOptionStepFromEmergencyOption,
    setIsPortoOptionStepFromEmergencyOption,
  ] = useState<boolean>(false);

  const resetUserInput = () => {
    setPortfolioTemplateId(undefined);
    setOnboardingPortoName(undefined);
    setOnboardingPortoDateTarget(nextMonth);
    setOnboardingPortoTargetAmount(undefined);
    setOnboardingPortoShared(false);
    setOnboardingPortoSharedContacts([]);
  };

  const resetOnboardingState = () => {
    setHasEmergencyFund(false);
    setOnboardingPortfolioId(undefined);
    setIsFollowOnboardingRecommendation(false);
    setIsPortoOptionStepFromEmergencyOption(false);
    setPortfolioTypeName(undefined);
    setTriggeredFromModalFlow('default');

    resetUserInput();
  };

  const handleCloseHomeOnboardingModal = () => {
    setOnboardingModalVisible(false);
    setOnboardingModalStep(undefined);

    /**
     * reset modal state after modal colsed
     */
    setTimeout(() => {
      resetOnboardingState();
    }, 200);
  };

  return (
    <HomeOnboardingContext.Provider
      value={{
        // States
        onboardingModalVisible,
        onboardingModalStep,
        hasEmergencyFund,
        portfolioTypeName,
        portfolioTypeId,
        onboardingPortfolioId,
        portfolioTemplateId,
        onboardingPortoName,
        onboardingPortoTargetAmount,
        onboardingPortoDateTarget,
        onboardingPortoScore,
        onboardingPortoShared,
        onboardingPortoSharedContacts,
        productBestOf,
        selectedProduct,
        triggeredFromModalFlow,
        isFollowOnboardingRecommendation,
        isPortoOptionStepFromEmergencyOption,

        // Functions
        setOnboardingModalVisible,
        setOnboardingModalStep,
        handleCloseHomeOnboardingModal,
        setPortfolioTypeName,
        setPortfolioTypeId,
        setOnboardingPortfolioId,
        setHasEmergencyFund,
        setPortfolioTemplateId,
        setOnboardingPortoName,
        setOnboardingPortoTargetAmount,
        setOnboardingPortoDateTarget,
        setOnboardingPortoScore,
        setOnboardingPortoShared,
        setOnboardingPortoSharedContacts,
        setProductBestOf,
        setSelectedProduct,
        setTriggeredFromModalFlow,
        setIsFollowOnboardingRecommendation,
        setIsPortoOptionStepFromEmergencyOption,
        resetUserInput,
      }}
    >
      {children}
    </HomeOnboardingContext.Provider>
  );
};

export default HomeOnboardingProvider;
