import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--blue-low);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
`;

export const Image = styled.div`
  margin-right: 14px;
  position: relative;
  min-width: 36px;
  min-height: 36px;
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 36px;
    height: 36px;
  }
`;
