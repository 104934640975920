import type {
  StatusBarForegroundColor,
  StatusBarBackgroundColor,
} from 'core/statusbar/types';
import {
  useStatusBarFunction,
  useStatusBarState,
} from 'core/statusbar/context/StatusBarContext';
import { DEFAULT_STATUS_BAR_COLOR } from '../constants';
import { ThemeColor } from 'core/theme/mapped-color.type';
import { getThemeColor } from 'core/theme/utils/getThemeColor';
import { useTheme } from 'hooks';
import { ThemeTypes } from 'core/theme/ThemeProvider';

type InjectClass = {
  className: string;
  default: string;
  active: string;
  styles?: any;
};
interface ChangeColorProps {
  from: number;
  to: number;
  backgroundColor: StatusBarBackgroundColor;
  foregroundColor: StatusBarForegroundColor;
  injectClass?: InjectClass;
}

/**
 * Hook for listen scrolling page and change navbar and native statusbar color
 */
const useNavbarColorTransition = () => {
  const { statusBarColor } = useStatusBarState();
  const { onSetStatusBarColor } = useStatusBarFunction();
  const { theme } = useTheme();

  const handleInjectClassName = (
    isChanged: boolean,
    injectClass?: InjectClass
  ) => {
    if (!injectClass) return;

    const mainClass = document.querySelector('.' + injectClass.className);

    if (!mainClass) return;

    if (injectClass.styles) {
      if (isChanged) {
        //@ts-ignore
        mainClass.style.cssText = injectClass.styles;
      } else {
        //@ts-ignore
        mainClass.style.cssText = injectClass.defaultStyles;
      }
    }

    if (isChanged) {
      if (mainClass.classList.contains(injectClass.default)) return;
      mainClass.classList.remove(injectClass.active);
      mainClass.classList.add(injectClass.default);
      return;
    }

    if (mainClass.classList.contains(injectClass.active)) return;

    mainClass.classList.remove(injectClass.default);
    mainClass.classList.add(injectClass.active);
  };

  /**
   * handle scroll position and change the currentColor
   * when scrool beetween to and form
   */
  const changeColorByScroll = (
    values:
      | ChangeColorProps[]
      | ((color: ThemeColor, theme: ThemeTypes) => ChangeColorProps[]),
    event: any
  ) => {
    const scrollPosition = event.currentTarget.scrollTop || window.scrollY;

    const handleChangeStatusBar = (values: ChangeColorProps[]) => {
      /**
       * when scroll position is defined to change navbar color
       */
      const isChanged = values.some((value) => {
        const { from, to, backgroundColor, foregroundColor, injectClass } =
          value;

        if (scrollPosition >= from && scrollPosition <= to) {
          if (statusBarColor.backgroundColor !== backgroundColor) {
            onSetStatusBarColor({ backgroundColor, foregroundColor });
          }
          handleInjectClassName(true, injectClass);
          return true;
        }
        handleInjectClassName(false, injectClass);
        return false;
      });

      /**
       * if navbar color not change scroll position use default color
       */
      if (
        !isChanged &&
        statusBarColor.backgroundColor !==
          DEFAULT_STATUS_BAR_COLOR().backgroundColor
      ) {
        onSetStatusBarColor({
          backgroundColor: DEFAULT_STATUS_BAR_COLOR().backgroundColor,
          foregroundColor: DEFAULT_STATUS_BAR_COLOR().foregroundColor,
        });
      }
    };

    /**
     * when scroll less than ZERO dont change color, this for IOS behavior scroll
     */
    if (scrollPosition < 0) {
      return;
    }

    if (typeof values === 'function') {
      const themeColor = getThemeColor();
      const parseValues = values(themeColor, theme);
      handleChangeStatusBar(parseValues);
      return;
    }

    handleChangeStatusBar(values);
  };

  return {
    changeColorByScroll,
  };
};

export default useNavbarColorTransition;
