import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { DetailRecurring } from 'services/recurring.type.d';
import { getRecurringDetail } from 'features/recurring/networks/services';

export const useFetchDetailRecurring = (recurringID: string) => {
  const [loading, setLoading] = useState(true);
  const [dataRecurring, setDataRecurring] = useState<DetailRecurring | null>(
    null
  );

  useEffect(() => {
    if (!recurringID) return;
    setLoading(true);
    getRecurringDetail(recurringID)
      .then((res) => {
        const data = res.data.data;
        setDataRecurring(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [recurringID]);

  return { dataRecurring, loading };
};

/**
 * @description
 *
 * Fetching detail recurring using react-query
 */
export const useFetchQueryDetailRecurring = (
  recurringId: string | number,
  enabled = true
) => {
  return useQuery(
    ['Detail Recurring', recurringId?.toString()],
    () => getRecurringDetail(recurringId),
    {
      enabled: enabled,
    }
  );
};
