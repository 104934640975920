import React, { useCallback, useState } from 'react';
import { PortoRecurring, PromoRecurring } from 'services/recurring.type.d';
import { ReksaDana } from 'entities/reksadana.reducer';
import {
  ChoosenPayment,
  FlexibDetail,
  ReinvestBondsProduct,
} from 'features/recurring/types/recurring.type.d';
import { startOfTomorrow } from 'utils/date';
import { SelectType } from '../components/ModalChooseTypeSIPGlobal/ModalChooseTypeSIPGlobal';

interface RecurringPDState {
  chosenDayPD?: string;
  chosenDatePD: number[];
  expiredDatePD?: Date;
  frequencyRecurringPD: string;
  finishRecurringPD: string;
  investmentValuePD?: number;
}

export interface ModalChooseSIPProps {
  redirect: string;
  defaultSelected?: SelectType;
}

export interface RecurringShortFormState {
  investmentValue?: number;
  chosenPorto?: PortoRecurring;
  chosenReksaDana?: ReksaDana;
}

export interface RecurringEditFlexiSIPState {
  investmentValue?: number;
  chosenPorto?: PortoRecurring;
  chosenReksaDana?: ReksaDana;
  chosenPaymentMethod?: ChoosenPayment;
}

interface ErrorCreationForm {
  productError?: string;
  amountError?: string;
}

interface RecurringContextState {
  chosenPorto?: PortoRecurring;

  chosenReksaDana?: ReksaDana;

  investmentValue?: number;

  chosenDate: number[];

  chosenPaymentMethod: ChoosenPayment;

  frequencyRecurring?: string;

  chosenDay?: string;

  finishRecurring: string;

  expiredDate?: Date;

  stepPage: number;

  executeDate: string;

  loadingOverviewRecurring: boolean;

  promoRecurring?: PromoRecurring;

  createChallengeFailed: boolean;

  convenienceFee?: number;

  recurringErrorScheduler?: boolean;

  ifaTransaction: boolean;

  ifaCommissionFee: number;

  ifaCommissionPercentage: number;
  reinvestBondsProduct?: ReinvestBondsProduct;

  /**
   * determine the creation is flexible or autodebit
   */
  isFlexible: boolean;

  /**
   * new in auto rutin (SIP) to handle button next enable or disable
   */
  canNextStep: boolean;

  recurringPDState: RecurringPDState;

  recurringHomeState: RecurringShortFormState;

  recurringListState: RecurringShortFormState;

  flexiSIPModalVisible: boolean;

  flexiSIPModalRecurringId?: number;

  recurringOrderExpiredVisible?: {
    visible: boolean;
    recurringId?: number;
    onClose?: () => void;
  };

  remindSIPModalVisible: boolean;

  recurringEditFlexiSIPState: RecurringEditFlexiSIPState;

  recurringFlexiSIPState: RecurringEditFlexiSIPState;
  /**
   * state for show or not modal option autodebit / flexible  create sip
   */
  showModalChooseTypeSIP: boolean;

  /**
   * state option for modal option autodebit / flexible  create sip
   */
  modalChooseSIPOptions?: ModalChooseSIPProps;

  /**
   * state to hold recurring id.
   * this state also used for modal edit Flexible SIP open or not
   * when: value is exist we assume modal is open
   */
  editFlexibleId?: string;

  currentFlexibData?: FlexibDetail;

  /**
   * state for user that has free convenience fee quota
   * if true, then convenience fee should be 0,
   * if false, then convenience fee should set to amount from data
   */
  hasConvenienceFeeFreeQuota?: boolean;

  /**
   * covenience fee when it doesn't reach min purchase
   */
  convenienceFeeMinPurchase?: number;

  /**
   * Error message on creation flow
   */
  errorCreationForm?: ErrorCreationForm;
}

interface RecurringContextFunction {
  setChosenPorto: (data: PortoRecurring) => void;

  setChosenReksaDana: (data?: ReksaDana) => void;

  setInvestmentValue: (num?: number) => void;

  setChosenDate: (num: number[]) => void;

  setChosenPaymentMethod: (dataPayment: ChoosenPayment) => void;

  setFrequencyRecurring: (freq: string) => void;

  setChosenDay: (day: string) => void;

  setFinishRecurring: (val: string) => void;

  setExpiredDate: (date?: Date) => void;

  setStepPage: (step: number) => void;

  setExecuteDate: (exec: string) => void;

  setLoadingOverviewRecurring: (load: boolean) => void;

  handleResetRecurringContext: () => void;

  setPromoRecurring: (promoData: PromoRecurring) => void;

  clearPromoRecurring: () => void;

  setCreateChallengeFailed: (failed: boolean) => void;

  setConvenienceFee: (convenienceFee: number | undefined) => void;

  setRecurringErrorScheduler: (haveScheduler: boolean) => void;

  setIfaTransaction: React.Dispatch<React.SetStateAction<boolean>>;

  setIfaCommissionFee: React.Dispatch<React.SetStateAction<number>>;

  setIfaCommissionPercentage: React.Dispatch<React.SetStateAction<number>>;

  setReinvestBondsProduct: (data: ReinvestBondsProduct) => void;

  setIsFlexible: (value: boolean) => void;

  /**
   * new in auto rutin (SIP) to set next button enable or disable
   */
  setCanNextStep: (value: boolean) => void;

  setRecurringPDState: (data: RecurringPDState) => void;

  setRecurringHomeState: (data: RecurringShortFormState) => void;

  setRecurringListState: (data: RecurringShortFormState) => void;

  handleResetRecurringPDState: () => void;

  handleResetRecurringHomeState: () => void;

  handleResetRecurringListState: () => void;

  setFlexiSIPModalVisible: (visible: boolean, recurringId?: number) => void;

  setRecurringOrderExpiredModal: (
    props:
      | {
          visible: boolean;
          recurringId?: number;
          onClose?: () => void;
        }
      | undefined
  ) => void;

  setRemindSIPModalVisible: React.Dispatch<React.SetStateAction<boolean>>;

  setRecurringEditFlexiSIPState: (data: RecurringEditFlexiSIPState) => void;

  setRecurringFlexiSIPState: (data: RecurringEditFlexiSIPState) => void;
  /**
   * function for show or hide modal option autodebit / flexible  create sip
   * NOTE: please use this only with useModalChooseTypeSIP
   */
  setShowModalChooseTypeSIP: (show: boolean) => void;

  /**
   * function for set modalCreateSIPOptions
   * NOTE: please use this only with useModalChooseTypeSIP
   */
  setModalChooseSIPOptions: (options?: ModalChooseSIPProps) => void;

  /**
   * function for set modal edit Flexible SIP show or no
   * NOTE: please use this only with useEditFlexibleSip
   */
  setEditFlexibleId: (recurringId: string | undefined) => void;

  setCurrentFlexibData: React.Dispatch<
    React.SetStateAction<FlexibDetail | undefined>
  >;

  setHasConvenienceFeeFreeQuota: React.Dispatch<React.SetStateAction<boolean>>;

  setConvenienceFeeMinPurchase: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;

  setErrorCreationForm: (data: ErrorCreationForm) => void;
}

// Context State
export const State = React.createContext<RecurringContextState | undefined>(
  undefined
);

// Context Function
const Func = React.createContext<RecurringContextFunction | undefined>(
  undefined
);

/**
 * Provider Context
 */
const RecurringContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [chosenPorto, setChosenPorto] = useState<PortoRecurring | undefined>();
  const [chosenReksaDana, setChosenReksaDana] = useState<
    ReksaDana | undefined
  >();

  const [investmentValue, setInvestmentValue] = useState<number | undefined>();

  /** Chosen date Schedule Recurring if use choose Bulanan */
  const [chosenDate, setChosenDate] = useState([1]);

  /** Payment chosen by user */
  const [chosenPaymentMethod, setChosenPaymentMethod] =
    useState<ChoosenPayment>({ icon: '', key: '', name: '' }); //default payment method

  /** Scedule Type Recurring */
  const [frequencyRecurring, setFrequencyRecurring] = useState('Bulanan');

  /** Chosen Day for Schedule Recurring */
  const [chosenDay, setChosenDay] = useState('Senin');

  /** Determine when recurring Expired */
  const [finishRecurring, setFinishRecurring] = useState('Selamanya');

  /** Date Month of expired recurring */
  const [expiredDate, setExpiredDate] = useState<Date | undefined>();

  /** Step recurring */
  const [stepPage, setStepPage] = useState(1);

  /** Get result data for execute date */
  const [executeDate, setExecuteDate] = useState<string>('');

  /** Loading overview  */
  const [loadingOverviewRecurring, setLoadingOverviewRecurring] =
    useState<boolean>(false);

  /** Get promo recurring data / challenge */
  const [promoRecurring, setPromoRecurring] = useState<
    PromoRecurring | undefined
  >();

  /** Define of create recurring challenge status
   * if status failed, we need to show create challenge modal in step 3 recurring
   */
  const [createChallengeFailed, setCreateChallengeFailed] =
    useState<boolean>(false);

  const [convenienceFee, setConvenienceFee] = useState<number | undefined>(
    undefined
  );

  const [hasConvenienceFeeFreeQuota, setHasConvenienceFeeFreeQuota] =
    useState<boolean>(false);
  const [convenienceFeeMinPurchase, setConvenienceFeeMinPurchase] = useState<
    number | undefined
  >(undefined);

  /** IFA Transaction */
  const [ifaTransaction, setIfaTransaction] = useState<boolean>(false);

  /** IFA commission fee */
  const [ifaCommissionFee, setIfaCommissionFee] = useState<number>(0);

  /** IFA commission percentage */
  const [ifaCommissionPercentage, setIfaCommissionPercentage] =
    useState<number>(0);

  const [reinvestBondsProduct, setReinvestBondsProduct] = useState<
    ReinvestBondsProduct | undefined
  >();

  /**
   * new in auto rutin (SIP) determine next button enable or disable
   */
  const [canNextStep, setCanNextStep] = useState(false);

  const [isFlexible, setIsFlexible] = useState(false);

  const [currentFlexibData, setCurrentFlexibData] = useState<FlexibDetail>();

  /**
   * Define visibility of error modal recurring because portfolio already
   * have scheduler. Except for Jago (for Jago adding by query params)
   *
   * @see handleExecuteOrderBuy function in PaymentMethodModal.tsx
   */
  const [recurringErrorScheduler, setRecurringErrorScheduler] =
    useState<boolean>(false);

  /**
   * Flexi SIP Modal Visible
   */
  const [flexiSIPModalVisible, setFlexiSIPModalVisible] =
    useState<boolean>(false);
  const [flexiSIPModalRecurringId, setFlexiSIPModalRecurringId] =
    useState<number>();
  const [remindSIPModalVisible, setRemindSIPModalVisible] =
    useState<boolean>(false);

  const [recurringOrderExpiredVisible, setRecurringOrderExpiredVisible] =
    useState<{ visible: boolean; recurringId?: number }>();

  /**
   * Custom temporary states for auto rutin.
   * These states will only be persist on each respective page (home, auto rutin, product detail).
   * PD: stands for product detail, this means the state will only be used in product detail page only.
   */
  const [recurringPDState, setRecurringPDState] = useState<RecurringPDState>({
    chosenDayPD: 'Senin',
    chosenDatePD: [startOfTomorrow().getDate()],
    expiredDatePD: undefined,
    frequencyRecurringPD: 'Bulanan',
    finishRecurringPD: 'Selamanya',
    investmentValuePD: undefined,
  });

  const [recurringHomeState, setRecurringHomeState] =
    useState<RecurringShortFormState>({
      chosenPorto: undefined,
      chosenReksaDana: undefined,
      investmentValue: undefined,
    });

  const [recurringListState, setRecurringListState] =
    useState<RecurringShortFormState>({
      chosenPorto: undefined,
      chosenReksaDana: undefined,
      investmentValue: undefined,
    });

  /**
   * state for show or not modal option autodebit / flexible  create sip
   */
  const [showModalChooseTypeSIP, setShowModalChooseTypeSIP] = useState(false);

  /**
   * state option for modal option autodebit / flexible  create sip
   */
  const [modalChooseSIPOptions, setModalChooseSIPOptions] =
    useState<ModalChooseSIPProps>();

  /**
   * state for hold edit recurringId and Flexible SIP modal
   */
  const [editFlexibleId, setEditFlexibleId] = useState<string>();

  /** Reset all temp recurring states in PD page */
  const handleResetRecurringPDState = useCallback(() => {
    setRecurringPDState({
      chosenDayPD: 'Senin',
      chosenDatePD: [startOfTomorrow().getDate()],
      expiredDatePD: undefined,
      frequencyRecurringPD: 'Bulanan',
      finishRecurringPD: 'Selamanya',
      investmentValuePD: undefined,
    });
  }, []);

  const [recurringFlexiSIPState, setRecurringFlexiSIPState] =
    useState<RecurringEditFlexiSIPState>({
      chosenPorto: undefined,
      chosenReksaDana: undefined,
      investmentValue: undefined,
      chosenPaymentMethod: undefined,
    });

  const [recurringEditFlexiSIPState, setRecurringEditFlexiSIPState] =
    useState<RecurringEditFlexiSIPState>({
      chosenPorto: undefined,
      chosenReksaDana: undefined,
      investmentValue: undefined,
      chosenPaymentMethod: undefined,
    });

  // Creation SIP error form
  const [errorCreationForm, setErrorCreationForm] = useState<ErrorCreationForm>(
    {
      productError: undefined,
      amountError: undefined,
    }
  );

  /** Reset all temp recurring states in PD page */
  const handleResetRecurringHomeState = useCallback(() => {
    setRecurringHomeState({
      chosenPorto: undefined,
      chosenReksaDana: undefined,
      investmentValue: undefined,
    });
  }, []);

  /** Reset all temp recurring states in PD page */
  const handleResetRecurringListState = useCallback(() => {
    setRecurringListState({
      chosenPorto: undefined,
      chosenReksaDana: undefined,
      investmentValue: undefined,
    });
  }, []);

  const handleSetFlexiSIPModalVisible = useCallback(
    (visible: boolean, recurringId?: number) => {
      if (!visible) {
        setFlexiSIPModalVisible(false);
        /**
         * This code block is used for Ingatkan Lagi
         * But that feature has move to next phase
         */
        // await Storage.set(
        //   'last-flexib-modal',
        //   new Date().getDate()?.toString()
        // );
        // queryClient.invalidateQueries(['last-flexib-modal']);
        setCurrentFlexibData(undefined); // reset current flexib data
        setFlexiSIPModalRecurringId(undefined);
      } else {
        setFlexiSIPModalRecurringId(recurringId);
        setFlexiSIPModalVisible(true);
      }
    },
    []
  );

  const onSetRecurringOrderExpiredModal = useCallback(
    (props: { visible: boolean; recurringId?: number } | undefined) => {
      setRecurringOrderExpiredVisible(props);
    },
    []
  );

  /** Reset all states in Context */
  const handleResetRecurringContext = useCallback(() => {
    setChosenDate([1]);
    setChosenDay('Senin');
    setExpiredDate(undefined);
    setChosenPaymentMethod({ icon: '', key: '', name: '' });
    setChosenPorto(undefined);
    setChosenReksaDana(undefined);
    setFinishRecurring('Selamanya');
    setFrequencyRecurring('Bulanan');
    setInvestmentValue(undefined);
    setStepPage(1);
    setExecuteDate('');
    setLoadingOverviewRecurring(false);
    setPromoRecurring(undefined);
    setConvenienceFee(undefined);
    setRecurringErrorScheduler(false);
    setIfaTransaction(false);
    setIfaCommissionFee(0);
    setIfaCommissionPercentage(0);
    setReinvestBondsProduct(undefined);
    setErrorCreationForm({
      productError: undefined,
      amountError: undefined,
    });
  }, []);

  const clearPromoRecurring = useCallback(() => {
    setPromoRecurring(undefined);
  }, []);

  return (
    <State.Provider
      value={{
        chosenPorto,
        chosenReksaDana,
        investmentValue,
        chosenDate,
        chosenDay,
        expiredDate,
        finishRecurring,
        chosenPaymentMethod,
        frequencyRecurring,
        stepPage,
        executeDate,
        loadingOverviewRecurring,
        promoRecurring,
        createChallengeFailed,
        convenienceFee,
        recurringErrorScheduler,
        ifaTransaction,
        ifaCommissionFee,
        ifaCommissionPercentage,
        reinvestBondsProduct,
        canNextStep,
        recurringPDState,
        recurringHomeState,
        recurringListState,
        flexiSIPModalVisible,
        remindSIPModalVisible,
        recurringOrderExpiredVisible,
        recurringEditFlexiSIPState,
        isFlexible,
        recurringFlexiSIPState,
        showModalChooseTypeSIP,
        modalChooseSIPOptions,
        editFlexibleId,
        flexiSIPModalRecurringId,
        currentFlexibData,
        hasConvenienceFeeFreeQuota,
        convenienceFeeMinPurchase,
        errorCreationForm,
      }}
    >
      <Func.Provider
        value={{
          setChosenDate,
          setChosenDay,
          setExpiredDate,
          setChosenPaymentMethod,
          setChosenPorto,
          setChosenReksaDana,
          setFinishRecurring,
          setFrequencyRecurring,
          setInvestmentValue,
          setStepPage,
          setExecuteDate,
          setLoadingOverviewRecurring,
          handleResetRecurringContext,
          setPromoRecurring,
          clearPromoRecurring,
          setCreateChallengeFailed,
          setConvenienceFee,
          setRecurringErrorScheduler,
          setIfaTransaction,
          setIfaCommissionFee,
          setIfaCommissionPercentage,
          setReinvestBondsProduct,
          setCanNextStep,
          setRecurringPDState,
          setRecurringHomeState,
          setRecurringListState,
          handleResetRecurringPDState,
          handleResetRecurringHomeState,
          handleResetRecurringListState,
          setFlexiSIPModalVisible: handleSetFlexiSIPModalVisible,
          setRecurringOrderExpiredModal: onSetRecurringOrderExpiredModal,
          setRemindSIPModalVisible,
          setRecurringEditFlexiSIPState,
          setIsFlexible,
          setRecurringFlexiSIPState,
          setShowModalChooseTypeSIP,
          setModalChooseSIPOptions,
          setEditFlexibleId,
          setCurrentFlexibData,
          setHasConvenienceFeeFreeQuota,
          setConvenienceFeeMinPurchase,
          setErrorCreationForm,
        }}
      >
        {children}
      </Func.Provider>
    </State.Provider>
  );
};

export default RecurringContextProvider;

export const useRecurringContextState = () => {
  const ctx = React.useContext(State);
  if (!ctx) {
    throw new Error('useRecurringContextState only in page IndexRecurring');
  }
  return ctx;
};

export const useRecurringContextFunction = () => {
  const ctx = React.useContext(Func);
  if (!ctx) {
    throw new Error('useRecurringContextFunction only in page IndexRecurring');
  }
  return ctx;
};
