import React from 'react';
import { DataInfo } from '../StockTable.styled';
import { TableDefinitionConfig } from '../StockTable.type';

type Props = {
  config: TableDefinitionConfig;
  label: string;
};

const TableHeadText: React.FC<Props> = ({ config, label = '' }) => {
  return <DataInfo {...config}>{label}</DataInfo>;
};

export default TableHeadText;
