import { initializeApp, FirebaseOptions } from 'firebase/app';
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  getToken,
  AppCheckTokenResult,
  CustomProvider,
  AppCheckToken,
} from 'firebase/app-check';
import * as Sentry from '@sentry/react';
import type { Scope } from '@sentry/react';
import * as Parent from 'core/Parent';
import { isInsideNativeContainer, jwtIsValid } from 'utils/validator';
import { getEnv } from 'core/env';
import { getFlag } from 'utils/feature-flag';
import { checkFeatureVersion } from 'utils/feature-version';
import { logEventAction } from 'utils/Analytics';

type AppCheckAction = 'login' | 'register';

type AppcheckPayload = {
  phone?: string;
};

const {
  FirebaseApiKey,
  FirebaseAppId,
  FirebaseMeasurementId,
  FirebaseProjectId,
  RecaptchaV3,
  ForceAppCheckRecaptcha,
} = getEnv();

/**
 * firebase config
 */
const firebaseConfig: FirebaseOptions = {
  apiKey: FirebaseApiKey,
  projectId: FirebaseProjectId,
  appId: FirebaseAppId,
  measurementId: FirebaseMeasurementId,
};

/**
 * recaptcha key
 */
// const recaptchaKey = process.env.REACT_APP_RECAPTCHA_V3_TOKEN || '';

/**
 * Firebase instance
 */
export const firebaseApp = initializeApp(firebaseConfig);

/**
 * Get Custom App Check Provider
 * @todo this function is custom app check provider
 * that can create custom actions to separate traffic.
 * But for now there is a problem where the tokens sent are
 * not in the required format, wee need to find out more in detail.
 * @see https://firebase.google.com/docs/app-check/web/custom-provider
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getAppCheckProvider = (action?: AppCheckAction) => {
  const actionName = `app_check_${action || 'bibit'}`;
  return new CustomProvider({
    getToken: () => {
      return new Promise((resolve, _reject) => {
        const appCheckToken = {
          token: '',
          /** expired time: 1 day */
          expireTimeMillis: 86400 * 1000,
        };

        window.grecaptcha.ready(() => {
          (
            window.grecaptcha.execute(RecaptchaV3, {
              action: actionName,
            }) as Promise<string>
          )
            .then((token: AppCheckToken['token']) => {
              return resolve({
                ...appCheckToken,
                token,
              });
            })
            .catch((err) => {
              console.error(err);
              resolve(appCheckToken);
            });
        });
      });
    },
  });
};

/**
 * initialize app check
 */
export const initAppCheck = (action?: AppCheckAction) => {
  /**
   * appcheck instance
   */
  const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(RecaptchaV3 || ''),
  });

  /**
   * Firebase Appcheck enable based on feature flag
   */
  const appCheckEnable = getFlag('web_bibit_appcheck');

  /**
   * Get app check token from native / web
   * @see https://firebase.google.com/docs/app-check
   * @returns {AppCheckTokenResult | null}
   */
  const getAppCheckToken = (
    payload?: AppcheckPayload
  ): Promise<(AppCheckTokenResult & { provider?: string }) | null> => {
    return new Promise(async (resolve) => {
      if (!appCheckEnable) return resolve(null);
      if (ForceAppCheckRecaptcha)
        return resolve(await getAppCheckTokenFromRecaptcha(payload));

      // token from native when user access from webview
      if (isInsideNativeContainer()) {
        const newResponse = await checkFeatureVersion('Appcheck New Response');

        return Parent.postData(
          'getAppCheckToken',
          {
            fn: 'getAppCheckToken',
            timeout: 15000,
          },
          async (_, data) => {
            const provider = newResponse ? data?.provider : 'google';
            const token = newResponse ? data?.token : data;
            const error = newResponse ? data?.error : undefined;

            logEventAction({
              eventName: 'app_check_action',
              parameter: {
                context: 'appcheck',
                trigger: 'post_message',
                action: 'app_check_result_debug',
                data: {
                  ...(payload?.phone ? { phoneNumber: payload?.phone } : {}),
                  token: !!token ? `${token}`.slice(0, 5) + '***' : undefined,
                  provider,
                  error,
                },
              },
            });

            if (jwtIsValid(token)) {
              return resolve({ token });
            }

            if (provider === 'huawei') {
              const appCheckToken = (await getAppCheckTokenFromRecaptcha(
                payload
              )) as any;

              if (appCheckToken) {
                return resolve({ ...appCheckToken, provider });
              }
            }

            if (error) {
              Sentry.withScope((scope: Scope) => {
                scope.setLevel('debug');
                if (payload?.phone) {
                  scope.setTag('user.phoneNumber', payload?.phone);
                }
                scope.setTag('domain', 'appCheck');
                Sentry.captureMessage(error);
              });
            }

            return resolve(null);
          }
        );
      }

      return resolve(await getAppCheckTokenFromRecaptcha(payload));
    });
  };

  const getAppCheckTokenFromRecaptcha = async (payload?: AppcheckPayload) => {
    try {
      const appCheckTokenResponse = await getToken(
        appCheck,
        /* forceRefresh= */ false
      );

      const token = appCheckTokenResponse?.token || '';

      logEventAction({
        eventName: 'app_check_action',
        parameter: {
          context: 'appcheck',
          trigger: 'api',
          action: 'app_check_web_result_debug',
          data: {
            ...(payload?.phone ? { phoneNumber: payload?.phone } : {}),
            ...appCheckTokenResponse,
            token: !!token ? `${token}`.slice(0, 5) + '***' : undefined,
          },
        },
      });

      if (jwtIsValid(appCheckTokenResponse?.token)) {
        return appCheckTokenResponse;
      }
      return null;
    } catch (err) {
      // Send possible error to Sentry
      Sentry.withScope((scope: Scope) => {
        scope.setLevel('debug');
        if (payload?.phone) {
          scope.setTag('user.phoneNumber', payload?.phone);
        }
        scope.setTag('domain', 'appCheck');
        Sentry.captureMessage(err);
      });
      return null;
    }
  };

  return { firebaseApp, appCheck, getToken, getAppCheckToken };
};

export default initAppCheck;
