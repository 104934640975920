import React, { lazy } from 'react';
import LazyLoad from 'features/common/lazyload';
import tabBarHOC from 'withoutTabBar';

const StockNewsPageLazy = lazy(() => import('./StockNewsPage'));

const StockDetailPage: React.FC<React.PropsWithChildren<unknown>> = (
  props: any
) => <LazyLoad animationLoading component={StockNewsPageLazy} {...props} />;

export default tabBarHOC(StockDetailPage);
