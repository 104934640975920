import React, { ComponentProps } from 'react';
import { Redirect } from 'react-router';
import SentryRoute from './SentryRoute';
import useAuth from 'hooks/useAuth';

/**
 * `PublicRoute` Component is wrapper for `Route` Component which can be restrict route when user is logged in
 */
const PublicRoute: React.FC<
  React.PropsWithChildren<ComponentProps<typeof SentryRoute>>
> = (props) => {
  const { path, component: Component, restricted, ...defaultProps } = props;

  /**
   * Get User login status
   */
  const { isLogin } = useAuth();

  return (
    <SentryRoute
      {...defaultProps}
      render={(routerProps: any) => {
        return isLogin && restricted ? (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        ) : (
          <Component {...routerProps} {...defaultProps} />
        );
      }}
    />
  );
};

export default PublicRoute;
