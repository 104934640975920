import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Text, Flex, WhiteSpace, Card } from '@bibitid/uikit-v1';
import InvestmentCompanyLogo from '../../common/icons/InvestmentCompanyLogo';
import getSafely from '../../../utils/safely';
import { hasFundDataAdjusted } from 'utils/newRD';
import * as stringHelper from 'utils/stringHelper';
import Whitestar from 'assets/images/RecommendedIcon';
import { withFeatureFlag } from 'utils/feature-flag';
import PercentageFormat from 'features/common/reksadana/components/PercentageFormat';
import withTheme from 'hoc/withTheme.hoc';
import InstantLogoOriginal from 'assets/images/bank-jago/instant-redemption/instant-logo-original/InstantLogoOriginal';
import style from 'features/robo/RoboRecommendationAllItems/RecommendationCard.module.css';
import styled from 'styled-components';

const { Item: FlexItem } = Flex;

const CardRecommendation = styled(Card)`
  & .bit-uikit-card-divider {
    background-color: var(--modal-border);
  }
`;

class RecommendationCard extends React.PureComponent {
  /** Props from withRouter */
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  handleChangeUsedFund = (event) => {
    const { selected, fundData, onChange } = this.props;
    const { symbol, robocategory } = fundData;

    event.stopPropagation();
    if (selected !== symbol) {
      if (onChange && typeof onChange === 'function') {
        onChange(robocategory, symbol);
      }
    }
  };

  handleMutualFundDetailNavigation = (event) => {
    const { history, onCustomNavigateMutualFundDetail } = this.props;
    event.stopPropagation();

    const symbol = event.currentTarget?.dataset?.symbol;

    if (onCustomNavigateMutualFundDetail) {
      onCustomNavigateMutualFundDetail(symbol);
      return;
    }

    return history.push(`/reksadana/${symbol}`);
  };

  render() {
    const {
      selected,
      fundData,
      hideFundPageLink,
      featureFlag,
      classAdd,
      isInsideModal,
    } = this.props;
    const { handleMutualFundDetailNavigation } = this;
    /** @type boolean */
    const instantRedemptionActiveByFF =
      featureFlag?.web_jago_instant_redemption;

    const fundCode = getSafely(['investment_manager', 'ojkCode'], fundData);
    const { symbol, name } = fundData;
    const expenserto = getSafely(['expenseratio', 'percentage'], fundData);
    const totalaum = getSafely(['aum', 'value'], fundData);
    const year1 = hasFundDataAdjusted(fundData, 'simplereturn', '1y');
    const recommended = getSafely(['recommended'], fundData);
    const isInstantRedemption =
      instantRedemptionActiveByFF && fundData?.is_instant_redemption;

    return (
      <div
        key={symbol}
        className={classNames('profiling-card-fragment', {
          classAdd,
          'profiling-card-fragment-inside-modal': !!isInsideModal,
        })}
      >
        <CardRecommendation
          onClick={this.handleChangeUsedFund}
          className={classNames('am-card', `${style['recommendation-card']}`, {
            'profiling-card': true,
            'card-selected': selected,
          })}
          headerDivider='full'
          bodyStyle={{ padding: 0, flex: '1 1 50%' }}
          headerClassName={`${style['recommendation-card-header']}`}
          title={
            <Flex className='profiling-card-header' align='center'>
              <InvestmentCompanyLogo ojkCode={fundCode} />
              <div className='rc-upper-container'>
                <p>{name}</p>
                {!hideFundPageLink && (
                  <div
                    data-symbol={symbol}
                    onClick={handleMutualFundDetailNavigation}
                  >
                    <Text type='body1' htmlTag='a'>
                      Lihat Detail
                    </Text>
                  </div>
                )}
              </div>
              <div
                className={classNames('am-card-body', {
                  'whitestar-badge': recommended,
                })}
                style={{ padding: '10px 0', borderTop: 'none' }}
              >
                <Whitestar alt='recommeded' className='whitestar-alt' />
              </div>
            </Flex>
          }
          highlight={
            isInstantRedemption && (
              <Flex align='center'>
                <InstantLogoOriginal alt='instant' />
                <Text
                  className='greenval'
                  type='caption1'
                  style={{ marginLeft: '8px' }}
                >
                  Pencairan Instan
                </Text>
              </Flex>
            )
          }
        >
          <div
            className={classNames('am-card-body', {
              'whitestar-badge': recommended,
            })}
            style={{ padding: '10px 0', borderTop: 'none' }}
          >
            <Flex justify='between' style={{ flex: '1 1 0' }}>
              <FlexItem className='p-left'>
                <Text htmlTag='span' type='body12r' color='secondary'>
                  1 Year Return
                </Text>

                <p
                  className={classNames({
                    redval: year1 < 0,
                    greenval: year1 > 0,
                  })}
                >
                  <PercentageFormat value={year1} />
                </p>
              </FlexItem>
              <FlexItem className='p-center'>
                <Text htmlTag='span' type='body12r' color='secondary'>
                  Expense Ratio
                </Text>
                <Text type='body13r' htmlTag='p'>
                  {stringHelper.numberToPercentagePointChangeFormat(
                    expenserto,
                    {
                      forceSign: false,
                      mantissa: 2,
                    }
                  )}
                </Text>
              </FlexItem>
              <FlexItem className='p-right'>
                <Text htmlTag='span' type='body12r' color='secondary'>
                  Total AUM
                </Text>
                <Text htmlTag='p' type='body13r'>
                  {stringHelper.numberToAveragedLetter(totalaum, true, {
                    mantissa: 2,
                  })}
                </Text>
              </FlexItem>
            </Flex>
          </div>
        </CardRecommendation>
        {/* Instant Redemption Footer Card */}
        <WhiteSpace size='xl' />
      </div>
    );
  }
}

const composedWrapper = compose(withTheme, withRouter);

export default withFeatureFlag(['web_jago_instant_redemption'])(
  composedWrapper(RecommendationCard)
);
