import * as React from 'react';
import Light from './light.svg';
import Dark from './dark.svg';
import { ThemedImage } from '@bibitid/uikit-v1';

const IconHighReturn: React.FC<React.HTMLProps<HTMLPictureElement>> = (
  props
) => {
  const themeImageMapping = {
    light: Light,
    dark: Dark,
    default: Light,
  };

  return <ThemedImage imageMap={themeImageMapping} {...props} />;
};

export default IconHighReturn;
