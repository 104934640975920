import { useShariaProfile } from 'hooks';
import React, { FC, PropsWithChildren } from 'react';
import { Tabs as TabsUikit } from '@bibitid/uikit-v1';
import styled, { css } from 'styled-components';
import './BestTabs.css';

const { TabPane } = TabsUikit;

const WrapperChildren = styled.div`
  overflow: auto;
`;

// styled
const Tabs = styled(TabsUikit)<{ isInsideModal?: boolean }>`
  .common-best-tab & .bit-uikit-tabs-content > *:nth-child(1) {
    padding-top: 20px;
  }
  & {
    height: auto;
    ${(props) => () => {
      const bgColor = props.isInsideModal
        ? `var(--modal-surface)`
        : `var(--surface)`;
      const bgColorFinal = `background-color: ${bgColor}`;
      return css`
        ${bgColorFinal}
      `;
    }}
  }

  & .bit-uikit-tabs-list {
    flex-shrink: 0;
  }

  & .bit-uikit-tabs-title {
    padding: 12px 0;
  }

  & .bit-uikit-tabs-wrapper {
    ${(props) => () => {
      const borderColor = props.isInsideModal
        ? `var(--modal-border)`
        : `var(--border)`;
      const borderColorFinal = `border-bottom: 2px solid ${borderColor}`;
      return css`
        ${borderColorFinal}
      `;
    }}
  }

  @media only screen and (min-width: 1030px) {
    & .bit-uikit-tabs-list {
      position: relative;
    }
  }
`;

const tabsContent = [
  {
    id: '1',
    title: 'Pasar Uang',
    type: 'moneymarket',
  },
  {
    id: '2',
    title: 'Obligasi',
    type: 'debt',
  },
  {
    id: '3',
    title: 'Saham',
    type: 'equity',
  },
  {
    id: '4',
    title: 'Syariah',
    type: 'syariah',
  },
];

interface Props {
  onChangeTab?: (data: TabData) => void;
  height?: string;
  heightDesktop?: string;
  isInsideModal?: boolean;
}

/**
 * Handling custom List of product to make Tab like in `/categories/best/${category}`
 */
const CommonBestTab: FC<PropsWithChildren<Props>> = ({
  children,
  onChangeTab,
  isInsideModal,
}) => {
  const { isShariaPreferenceActive } = useShariaProfile();

  const filteredTabs = tabsContent.filter((d) => {
    // if user NON-syariah get all data
    if (!isShariaPreferenceActive) return true;

    // if syariah, then get data `type` non-syariah
    return d.type !== 'syariah';
  });

  const handleOnChange = (val: string) => {
    const theTabs = filteredTabs.filter((item) => {
      return item.id === val;
    })[0];
    onChangeTab?.(theTabs);
  };

  return (
    <div className={`common-best-tab ${isInsideModal && 'modal'}`}>
      <Tabs onChange={handleOnChange} isInsideModal={isInsideModal}>
        {filteredTabs.map((tab) => {
          const key = tab.id;
          const title = tab.title;
          return (
            <TabPane key={key} title={title}>
              <WrapperChildren>{children}</WrapperChildren>
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default CommonBestTab;
