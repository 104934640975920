import React from 'react';

import Crisp from 'core/Crisp/utils';
import SupportIcon from 'assets/images/tab-icon-asset/support-icon-common.svg';

import './IconSupport.css';
import IconHeadphone from 'assets/images/tab-icon-asset/IconHeadphone';
import { useTranslation } from 'react-i18next';

interface IconSupportProps {
  /**
   * Size of this icon in px
   */
  size: number;

  /**
   * Callback executed when user clicks this component
   */
  onClick?: Function;
  isHeadphoneIcon?: boolean;
}

/**
 * Support icon component, you can add more logic with onClick props when necessary
 */
const IconSupport: React.FC<React.PropsWithChildren<IconSupportProps>> = ({
  size,
  onClick,
  isHeadphoneIcon,
}) => {
  const { t } = useTranslation(['common']);
  const style = {
    width: size,
  };

  const showLiveSupport = () => {
    if (onClick) {
      onClick();
    }

    Crisp.showLiveSupport();
  };

  if (isHeadphoneIcon) {
    return (
      <div onClick={showLiveSupport}>
        <IconHeadphone width={18} height={18} color='var(--icon-default)' />
      </div>
    );
  }

  return (
    <div className='iconSupportWrap' onClick={showLiveSupport}>
      <img src={SupportIcon} alt='support-icon' style={style} /> {t('bantuan')}
    </div>
  );
};

export default IconSupport;
