import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import withStatusBar from 'core/statusbar/hoc/withStatusBar';

interface Props extends RouteProps {
  component?: any;
  restricted?: boolean;
  /** Props to determine whether to reset status bar on certain routes */
  defaultStatusBar?: boolean;
  /** Props to determine whether to reset bottom bar on certain routes */
  defaultBottomBar?: boolean;
  /**
   * @deprecated Use hooks withoutTabBar instead
   * Props to determine whether to show or hide tabbar
   */
  showTabBar?: boolean;
  hide?: boolean;
}

/**
 * Sentry HOC wrapper for React Router
 */
const SentryComponent = Sentry.withSentryRouting(Route);

/**
 * `SentryRoute` Component is a `Route` Component wrapped with Sentry HOC
 * This is to group any transactions route with parameter
 */
const SentryRoute: React.FC<React.PropsWithChildren<Props>> = ({
  ...props
}) => {
  const { component: Component, render, ...defaultProps } = props;
  let componentWithProps = render;

  if (!!Component) {
    componentWithProps = (routerProps: any) => {
      return <Component {...routerProps} {...defaultProps} />;
    };
  }

  return <SentryComponent {...defaultProps} render={componentWithProps} />;
};

export default withStatusBar<Props>(SentryRoute);
