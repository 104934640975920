import React, { FC, PropsWithChildren, useState } from 'react';
import { Tabs as TabsUikit } from '@bibitid/uikit-v1';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { BestType } from '../types';
import { onApplyParams } from 'features/product/reksadana/types';
import {
  useReksadanaProductFunctionContext,
  useReksadanaProductStateContext,
} from 'features/product/reksadana/context/ReksadanaProductContext';
import { useShariaProfile } from 'hooks';
import { bestShariahTabs, bestTabs, bestTabsInsti } from '../constants';
import SortAndCompareButton from 'features/product/reksadana/components/SortAndCompareButton';
import { randomString } from 'utils/stringHelper';
import './BestTabs.css';
import { useUserInInstitution } from 'features/institution/hooks';
import { useTranslation } from 'react-i18next';

const { TabPane } = TabsUikit;

interface Props {
  currentTab: BestType;
  isInsideModal?: boolean;
  onChangeTab: (data: TabData) => void;
  onConfirmCompare: () => void;
}

// styled
const Tabs = styled(TabsUikit)<{ isInsideModal?: boolean }>`
  & {
    height: auto;
    ${(props) => () => {
      const bgColor = props.isInsideModal
        ? `var(--modal-surface)`
        : `var(--surface)`;
      const bgColorFinal = `background-color: ${bgColor}`;
      return css`
        ${bgColorFinal}
      `;
    }}
  }

  & .bit-uikit-tabs-wrapper {
    ${(props) => () => {
      const bgColor = props.isInsideModal
        ? `var(--modal-surface)`
        : `var(--surface)`;
      const bgColorFinal = `background-color: ${bgColor}`;
      return css`
        ${bgColorFinal}
      `;
    }}
  }

  &
    .bit-uikit-tabs-content-wrapper
    .bit-uikit-tabs-content.bit-uikit-tabs-content-inactive {
    height: 0;
    overflow: visible;
  }

  & .bit-uikit-tabs-list {
    flex-shrink: 0;
  }

  & .bit-uikit-tabs-title {
    padding: 12px 0;
  }

  & .bit-uikit-tabs-wrapper {
    ${(props) => () => {
      const borderColor = props.isInsideModal
        ? `var(--modal-border)`
        : `var(--border)`;
      const borderColorFinal = `border-bottom: 2px solid ${borderColor}`;
      return css`
        ${borderColorFinal}
      `;
    }}
  }

  @media only screen and (min-width: 1030px) {
    & .bit-uikit-tabs-list {
      position: relative;
      ${({ isInsideModal }) =>
        isInsideModal
          ? css`
              width: 100%;
            `
          : ''}
    }
  }
`;

const SortAndCompareWrapper = styled.div`
  position: absolute;
  top: 43px;
  width: 100%;
  z-index: 1;
`;

const ReksadanaProductListWrapper = styled.div`
  .content-box {
    margin-top: 59px;
    min-height: calc(80vh - 200px);
  }

  .bit-puller-reksadana-products {
    height: 100%;
  }
`;

export const defaultTabBestRd = (type: string) => {
  switch (type) {
    case 'moneymarket':
      return '0';
    case 'debt':
      return '1';
    case 'equity':
      return '2';
    case 'syariah':
      return '3';
    default:
      return '0';
  }
};

/**
 * Handling best tabs mutual funds
 */
const CommonBestTabsMutualFunds: FC<PropsWithChildren<Props>> = ({
  children,
  currentTab,
  isInsideModal,
  onChangeTab,
  onConfirmCompare,
}) => {
  const { t } = useTranslation(['explore', 'common']);
  const history = useHistory();
  const { isShariaPreferenceActive } = useShariaProfile();

  const institution = useUserInInstitution();

  // Reksadana Product Context
  const {
    filterSortBy,
    filterSortPeriod,
    filterIsInstantRedemption,
    visibilityCompareModal,
  } = useReksadanaProductStateContext();
  const { buildQueryParams, setFilterSortOrder, setVisibilityCompareModal } =
    useReksadanaProductFunctionContext();

  /**
   * Key content for rerender component when tab changes
   */
  const [keyContentTab, setKeyContentTab] = useState(randomString(6));

  /**
   * - individual with preference sharia will be render bestShariaTabs
   * - individual without preference sharia will be render bestTabs
   */
  const bestTabsContent = !isShariaPreferenceActive
    ? institution
      ? bestTabsInsti
      : bestTabs
    : bestShariahTabs;

  /**
   * Sort and compare button handler
   */
  const sortAndCompareHandler = {
    /**
     * handle apply filter & sort and add query string to url
     * @param params selected filter parameters
     */
    handleApply: (params: onApplyParams) => {
      history.replace({
        search: buildQueryParams(params),
      });
    },

    /**
     * handle change sort direction
     * @param direction sort direction
     */
    handleDirectionChange: (direction: string) => {
      history.replace({
        search: buildQueryParams({
          is_instant_redemption: filterIsInstantRedemption,
          sort: direction,
          sort_by: String(filterSortBy),
          sort_period: filterSortPeriod,
        }),
      });
      setFilterSortOrder(direction);
    },

    handleConfirmCompare: () => {
      onConfirmCompare?.();
    },

    handleToggleCompareModal: (val?: boolean) => {
      if (typeof val !== 'undefined') {
        setVisibilityCompareModal(val);
        return;
      }
      setVisibilityCompareModal(!visibilityCompareModal);
    },
  };

  const handleChangeTab = (key: string) => {
    let tabToPass: TabData = {
      type: '',
      title: '',
    };

    /**
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
     */
    for (const [index, value] of Object.entries(bestTabsContent)) {
      if (index === key) tabToPass = value;
    }
    setKeyContentTab(randomString(6));
    onChangeTab?.(tabToPass);
  };

  return (
    <div style={{ position: 'relative' }}>
      <SortAndCompareWrapper>
        <SortAndCompareButton
          onApply={sortAndCompareHandler.handleApply}
          onSortDirectionChange={sortAndCompareHandler.handleDirectionChange}
          onConfirmCompare={sortAndCompareHandler.handleConfirmCompare}
          onToggleCompareModal={sortAndCompareHandler.handleToggleCompareModal}
          productListCategory={currentTab}
          showOnlyTopProduct={true}
          inModal={false}
          isFilterHidden={false}
        />
      </SortAndCompareWrapper>

      <div className={`common-best-tab ${isInsideModal && 'modal'}`}>
        <Tabs
          onChange={handleChangeTab}
          activeKey={defaultTabBestRd(currentTab)}
          animated={false}
          swipeable={false}
          isInsideModal={isInsideModal}
        >
          {bestTabsContent.map((item) => {
            const title =
              typeof item.title === 'string'
                ? t(item.title, { ns: 'common' })
                : item.title;
            return (
              <TabPane key={defaultTabBestRd(item.type)} title={title}>
                <ReksadanaProductListWrapper key={keyContentTab}>
                  {children}
                </ReksadanaProductListWrapper>
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default CommonBestTabsMutualFunds;
