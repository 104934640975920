import React from 'react';
import LazyLoad from 'features/common/lazyload';

const StockKeyStatsPageLazy = React.lazy(() => import('./StockKeyStatsPage'));

const StockKeyStatsPage = (props: any) => {
  return (
    <LazyLoad animationLoading component={StockKeyStatsPageLazy} {...props} />
  );
};

export default StockKeyStatsPage;
