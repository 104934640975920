import { useProfileData } from 'hooks/useProfileData';
import useAuth from 'hooks/useAuth';
import { useUserInInstitution } from 'features/institution/hooks';

/**
 * Wether user has done verified. Verified means that user :
 * - has KYC verified
 * - has email verified
 * - has status === 4
 *
 * @see https://www.notion.so/stockbit/user_status-e7d2e81d69284118aef874f7ce72a13d
 * @returns boolean
 */
export const useVerificationDoneStatus = () => {
  const { isLogin } = useAuth();
  const { data: dataProfile } = useProfileData(isLogin);
  const userInInstitution = useUserInInstitution();

  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;

  const isValidEmail = profileDetail?.validemail ?? 0;

  const isValidBank = profileDetail?.validbank ?? 0;

  const verificationStatus = profileDetail?.user?.status ?? 0;

  const isRegisteredAsInstiAccountOnly =
    !!profileDetail?.preference?.is_registered_as_institutional_account_only;

  /**
   * Individual verified when
   * - has KYC verified
   * - has email verified
   * - has valid bank
   */
  const isIndividualAccVerified =
    !userInInstitution &&
    Boolean(isValidEmail && isValidBank && verificationStatus === 4);

  /**
   * Insitution verified when
   * - user registered as institution account only without individual account
   * - or normal institution has valid email and verified status is 4
   */
  const isInstitutionAccVerified =
    userInInstitution &&
    !!isValidEmail &&
    (isRegisteredAsInstiAccountOnly || verificationStatus === 4);

  return userInInstitution ? isInstitutionAccVerified : isIndividualAccVerified;
};
