import React, { useEffect } from 'react';
import rqClient from 'network/queryClient';
import { postDataSetAppBottomBarColor } from 'core/statusbarbottom';
import {
  COLOR_BAR_ALL_SURFACE,
  COLOR_BAR_DEFAULT,
} from 'core/statusbar/constants';

/**
 * HOC for resetting status Bar
 * @param WrappedComponent - component that need to be wrapped
 */
const withoutTabBar = (WrappedComponent: any) => (props: any) => {
  const { defaultBottomBar = true } = props;
  useEffect(() => {
    /** set to default color bar */
    const defaultColor = COLOR_BAR_ALL_SURFACE();

    if (defaultBottomBar) {
      // @ set color bottom into surface color
      postDataSetAppBottomBarColor(
        defaultColor.bgColor,
        defaultColor.foregroundColor
      );
    }

    rqClient.setQueryData('showTabBar', {
      visible: false,
    });

    return () => {
      rqClient.setQueryData('showTabBar', {
        visible: true,
      });

      const surfaceColor = COLOR_BAR_DEFAULT();

      // @ set color bottom to default
      postDataSetAppBottomBarColor(
        surfaceColor.bottom.bgColor,
        surfaceColor.bottom.foregroundColor
      );
    };
  }, [defaultBottomBar]);

  return <WrappedComponent {...props} />;
};

export default withoutTabBar;
