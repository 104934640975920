import storage from 'core/Storage';
import { randomString } from 'utils/stringHelper';
import { clearRecordUser } from 'utils/Analytics';
import Crisp from 'core/Crisp/utils';
import rqClient from 'network/queryClient';
import { setAppStatusBarColor } from 'core/statusbar/utils/setAppStatusBarColor';
import { DEFAULT_STATUS_BAR_COLOR } from 'core/statusbar/constants';

/**
 * get refresh id to perform post-refresh-token to the Backend
 */
export const getRefreshId = async () => {
  // get refreshid from LocalStorage
  const refreshId = await storage.get('rrid');

  // if exist, just return the value
  if (refreshId) return refreshId;

  // @ handling when not found

  // generate new refresh id
  const newRefreshId = randomString(22, 'alphabet');

  // set refreshId to Local Storage
  storage.set('rrid', newRefreshId);
  return newRefreshId;
};

/**
 * Remove local storage `rrid`
 */
export const removeRefreshId = async () => {
  await storage.remove('rrid');
};

export const forceLogoutUser = async () => {
  // remove jago account data
  storage.removeObject('jagoAccount');

  // remove hiddenCompleteYourProfile data
  storage.removeObject('hiddenCompleteYourProfile');

  // Set Flag Manually Log out
  storage.setObject('hasManuallyLogout', true);

  // remove kyc data
  storage.removeObject('kyc');

  // remove registration finished step
  storage.removeObject('registration-step-finished');

  // remove registration active step
  storage.removeObject('registration-step-active');

  // remove profilings data
  //TODO: can probably improve to store profiling step and data in BE, -
  //TODO: so it doesn't pollute local storage
  storage.removeObject('form_profiling');
  storage.removeObject('form_profiling_step');

  await storage.removeTokenHandler();

  await storage.removeObject('rb');

  // clear record user analytics purpose
  clearRecordUser();

  Crisp.destroyCurrentSession();

  // reset status bar into default color
  setAppStatusBarColor({
    backgroundColor: DEFAULT_STATUS_BAR_COLOR().backgroundColor,
    foregroundColor: DEFAULT_STATUS_BAR_COLOR().foregroundColor,
  });

  // open modal auto logout
  rqClient.setQueryData('user_kickedout', true);
};

/**
 * helper to get access token from storage.get('actoken')
 */
export const getAccessToken = () => {
  return storage.get('actoken');
};

export const getAccesExpiredToken = () => {
  return storage.get('acexpire');
};

export const getRefreshExpiredToken = () => {
  return storage.get('rfexpire');
};
