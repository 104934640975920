import { Text } from '@bibitid/uikit-v1';
import React, { useState } from 'react';
import styled from 'styled-components';

const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Initial = styled(Text)<{ fontSize?: string }>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  font-weight: 700;
  color: var(--logo-text-color);
`;

const ImageWithFallback = ({
  src,
  alt,
  fallback,
  fontSize,
}: {
  src?: string;
  alt: string;
  fallback: string;
  fontSize?: string;
}) => {
  const [showFallback, setShowFallback] = useState(false);
  const initial = fallback[0];

  if (showFallback || !src) {
    return (
      <Placeholder>
        <Initial htmlTag='p' fontSize={fontSize}>
          {initial}
        </Initial>
      </Placeholder>
    );
  }

  return <img src={src} alt={alt} onError={() => setShowFallback(true)} />;
};

export default ImageWithFallback;
