import { useRecurringContextState } from 'features/recurring/contexts/RecurringContext';
import { useMemo } from 'react';

const useNextDisabled = () => {
  const {
    stepPage,
    chosenPorto,
    chosenReksaDana,
    investmentValue,
    chosenPaymentMethod,
    promoRecurring,
    convenienceFee,
  } = useRecurringContextState();

  const isPortoRobo = !!chosenPorto?.robo;

  const minPromoAmount = promoRecurring?.min_amount ?? 0;

  const disabled = useMemo(() => {
    // When you at step 1, do this things
    if (stepPage === 1) {
      // Dont have porto, disabled button
      if (!chosenPorto) return true;

      // Dont have porto robo, and RD Product, disabled button
      if (!isPortoRobo && !chosenReksaDana) return true;

      return false;
    }
    // When you at step 2, do this things
    if (stepPage === 2) {
      const isUSDMutualFund =
        chosenReksaDana?.currency_exchange?.currency === 'USD';

      const keyPaymentMethod = chosenPaymentMethod.key === '';
      const minPayment = chosenPaymentMethod.minAmount ?? 0;
      const maxPayment = chosenPaymentMethod.maxAmount ?? 0;
      // InvestmentValue undefined, or key payment method empty, disabled button
      if (!investmentValue || keyPaymentMethod) return true;

      let minBuy = 100000; //default robo

      // When porto is not robo, minbuy is equal with minbuy at RD Product
      if (!isPortoRobo) {
        minBuy = chosenReksaDana?.minbuy ?? 0;
      }

      const investmentValueWithConFee = investmentValue + (convenienceFee || 0);

      // Investment value less then minbuy, disabled button
      if (!minPromoAmount && investmentValue < minBuy) return true;

      // investment value should between minPayment and maxPayment
      // validate only if MutualFund is IDR currency
      if (
        !isUSDMutualFund &&
        (investmentValue < minPayment || investmentValueWithConFee > maxPayment)
      )
        return true;

      return false;
    }
  }, [
    chosenPaymentMethod,
    chosenPorto,
    chosenReksaDana,
    investmentValue,
    isPortoRobo,
    stepPage,
    minPromoAmount,
    convenienceFee,
  ]);

  return disabled;
};

export default useNextDisabled;
