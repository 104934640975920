import React, { SyntheticEvent } from 'react';
import { useAmountBlurred } from 'features/common/AmountBlurred/provider/AmountBlurredProvider';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';
import { ReactComponent as UnlockIcon } from '../../assets/unlock.svg';
import { ReactComponent as UnlockWhite } from '../../assets/unlock-white.svg';
import { ReactComponent as LockWhite } from '../../assets/lock-white.svg';
import lockAnimate from '../../assets/lock-animate.svg';
import styled from 'styled-components';
import Show from 'features/common/components/Show/Show';
import { TTooltipKey } from '../../constants/indes';
import { logEventAction } from 'utils/Analytics';

const Container = styled.div<{ showTooltip: boolean }>`
  margin-top: ${(props) => (props.showTooltip ? 32 : 0)}px;
  margin-left: 2px;
  color: var(--icon-default);
`;
const LockAnimate = styled.div`
  position: absolute;
  transform: translate(-3px, -31px);
`;
const ContainerTooltip = styled.div<{ tooltipKey: TTooltipKey }>`
  position: absolute;

  &::before {
    content: ' ';
    position: absolute;
    bottom: ${(props) => (props.tooltipKey === 'home' ? -1 : 4)}px;
    right: -16px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 7px solid var(--green-default);
  }
`;

interface IToogleAmountBlurred {
  showTooltip: boolean;
  tooltipKey: TTooltipKey;
  handleBlurredAmount: (event: SyntheticEvent<any>) => void;
  premium?: boolean;
}

const ToogleAmountBlurred: React.FC<IToogleAmountBlurred> = ({
  showTooltip,
  tooltipKey,
  handleBlurredAmount,
  premium,
}) => {
  const { isAmountBlurred } = useAmountBlurred();

  const handleClick = (event: React.SyntheticEvent<any, Event>) => {
    const context = tooltipKey === 'home' ? 'home.index' : 'portfolio.index';
    const screen = context.split('.')[0];

    logEventAction({
      eventName: 'home_action',
      parameter: {
        action: 'hide_amount',
        trigger: 'click',
        context,
        data: {
          screen,
          state: isAmountBlurred,
        },
      },
    });

    handleBlurredAmount(event);
  };

  const getIcon = () => {
    if (premium) return isAmountBlurred ? <LockWhite /> : <UnlockWhite />;

    return isAmountBlurred ? <LockIcon /> : <UnlockIcon />;
  };

  const renderIcon = () => {
    if (showTooltip) {
      return (
        <LockAnimate>
          <img
            style={{
              width: 27,
              height: 27,
              marginTop: tooltipKey === 'home' ? 0 : '-6px',
            }}
            src={lockAnimate}
            alt={'lockAnimate'}
          />
        </LockAnimate>
      );
    }

    return <>{getIcon()}</>;
  };

  return (
    <Container showTooltip={showTooltip} onClick={handleClick}>
      {renderIcon()}
      <Show when={showTooltip}>
        <ContainerTooltip tooltipKey={tooltipKey} />
      </Show>
    </Container>
  );
};

export default ToogleAmountBlurred;
