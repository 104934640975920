import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Text } from '@bibitid/uikit-v1';
import style from './StockOrderWarning.module.css';

interface StockOrderWarningProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  message: string;
}

const StockOrderWarning: React.FC<
  React.PropsWithChildren<StockOrderWarningProps>
> = ({ visible, setVisible, message }) => {
  useEffect(() => {
    if (visible) {
      setTimeout(() => setVisible(false), 3000);
    }
  }, [visible, setVisible]);

  return (
    <div
      className={classNames(style['stock-order-warning'], {
        [style['order-warning-show']]: visible,
      })}
    >
      <Text type='body1'>{message}</Text>
    </div>
  );
};

export default StockOrderWarning;
