import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ChevronLeft } from 'react-feather';
import { useSelector } from 'react-redux';

import { Props } from 'features/common/PinForm/types';
import IconSupport from 'features/common/IconSupport';
import { ContentBox, PaddingHorizontal } from 'features/common';

import { checkWindowBiometricType } from 'utils/biometric';
import { TRootReducers } from 'store/root';
import useProfileData from 'hooks/useProfileData';
import useAuth from 'hooks/useAuth';
import { useNativeIdleTimerToggle, useTheme, useWindowResize } from 'hooks';

import IconDeleteKey from 'assets/images/pin-screen-asset/IconDeleteKey/IconDeleteKey';
import PinLockIllustration from '../../assets/PinLockIllustration/PinLockIllustration';
import {
  touchBiometricDark,
  touchBiometricLight,
  faceBiometricDark,
  faceBiometricLight,
} from 'assets/images/biometric';

import { ActivityIndicator, Text, TopBar } from '@bibitid/uikit-v1';

/**
 * Render fake pin filled.
 *
 * this can be used when user filled PIN Form by Biometric
 */
const PinFake: React.FC<React.PropsWithChildren<{ maxPin: number }>> = ({
  maxPin,
}) => {
  return (
    <>
      {[...Array(maxPin)].map((_, i) => {
        return <div key={i} className='done' />;
      })}
    </>
  );
};

/**
 * Raw Component that handle PIN Screen
 */
const PinScreen: React.FC<React.PropsWithChildren<Props>> = ({
  title = 'Masukkan PIN',
  titlePosition = 'center',
  headerText = 'Masukkan PIN Kamu',
  renderOnBack,
  onClickFogetPin,
  descLabel,
  onBack,
  showSupport = true,
  onClickSupport,
  biometric,
  loginWithPin,
  showForgotPin,
  callbackForgotPin,
  onChallengeBiometric,
  errorMessage,
  onSubmitPin,
  onChangePin,
  onTapPin,
  loading,
  max,
  additionalContent,
}) => {
  const [pinValue, setPinValue] = useState<number[]>([]);
  const [pinMax, setPinMax] = useState(6);
  const { theme } = useTheme();

  // Get data profile user
  const { isLogin } = useAuth();
  const { data } = useProfileData(isLogin);
  const userDetail = data?.data?.data;

  const email = userDetail?.user.email;

  const location = useLocation();

  const idleHash = location.hash.includes('idle');

  /** tell us is biometric in loading or not*/
  const loadingBiometric = useSelector(
    (state: TRootReducers) => state?.entities?.common?.loadingBiometric
  );

  const disabledInput = pinValue.length === pinMax;

  // handle pin max user
  useEffect(() => {
    if (max) {
      setPinMax(max);
      return;
    }
    // get pin length user from userDetail
    // if not get it, fallback to 6
    const pinLengthUser = userDetail?.pin_length || 6;
    setPinMax(pinLengthUser);
  }, [userDetail, max]);

  // reset local state
  useEffect(() => {
    if (errorMessage) {
      // reset Local State
      const pinLengthUser = userDetail?.pin_length || 6;
      setPinMax(max || pinLengthUser);
      setPinValue([]);
    }
  }, [errorMessage, userDetail, max]);

  const handleClickForgetPin = () => {
    if (callbackForgotPin) {
      callbackForgotPin();
      return;
    }

    if (onClickFogetPin) {
      onClickFogetPin();
      return;
    }
  };

  const handleChangePinVal = (event: SyntheticEvent<HTMLDivElement>) => {
    if (disabledInput) return; // guard

    const valpin = event.currentTarget?.dataset?.valpin;
    if (!valpin) return; // guard

    // set value to local state
    const newVal = [...pinValue, Number(valpin)];
    setPinValue(newVal);

    onTapPin && onTapPin(valpin);

    // if user already filled all pin form
    if (pinMax === pinValue.length + 1) {
      const valStr = newVal.join('');
      // tell callback onChangePin
      onChangePin && onChangePin(valStr);
      onSubmitPin && onSubmitPin(valStr);
    }
  };

  const handleDeletePin = () => {
    if (disabledInput) return;

    let existPinVal = [...pinValue];
    existPinVal.pop();

    setPinValue(existPinVal);
  };

  const handleClickBiometric = () => {
    if (onChallengeBiometric) {
      onChallengeBiometric();
    }
  };

  /**
   * Decided which biometric image should show
   */
  const srcIconBiometric = (theme = 'light') => {
    const bioTypeIOS = checkWindowBiometricType(); // android always default touch;
    if (bioTypeIOS === 'face')
      return theme === 'light' ? faceBiometricLight : faceBiometricDark;
    return theme === 'light' ? touchBiometricLight : touchBiometricDark;
  };

  const { height } = useWindowResize();

  useNativeIdleTimerToggle();

  return (
    <>
      <div className='content-wrapper newPin' style={{ width: '100%' }}>
        <div className='content-header new-content-header'>
          <TopBar
            title={
              <span style={{ width: '100%', textAlign: titlePosition }}>
                {title}
              </span>
            }
            leftContent={
              renderOnBack && (
                <div
                  className='backBigger'
                  onClick={onBack}
                  data-testid='button-back'
                >
                  <ChevronLeft color='#949494' size={20} />
                </div>
              )
            }
            rightContent={
              showSupport && (
                <div className='icon-support-wrapper'>
                  <IconSupport size={14} onClick={onClickSupport} />
                </div>
              )
            }
          />
        </div>
        <ContentBox
          headerHeight={56}
          style={{ height: height - 56, padding: '0px' }}
        >
          <PaddingHorizontal>
            <div className='bit-link-new-pin'>
              <div className='login-register-icon'>
                <PinLockIllustration alt='icon secure' />
              </div>
              {additionalContent && additionalContent()}
              <div className='login-register-heading'>{headerText}</div>
              {idleHash && (
                <Text color='#b5b5b5' htmlTag='p'>
                  Sesi telah berakhir. Masukkan PIN untuk kembali menggunakan
                  Bibit.
                </Text>
              )}
              <div className='bit-new-pin'>
                {loadingBiometric ? (
                  <PinFake maxPin={pinMax} />
                ) : (
                  [...Array(pinMax)].map((_, i) => {
                    return (
                      <div
                        key={i}
                        className={`${pinValue.length > i ? 'done' : ''}`}
                      />
                    );
                  })
                )}
              </div>
              {errorMessage && <div className='errorspan'>{errorMessage}</div>}
              <div className='bit-pin-desc'>{descLabel}</div>
            </div>
            <div className='bit-custom-keyboard-box'>
              {showForgotPin && (loginWithPin || email) && (
                <div className='bit-forgot-pin' onClick={handleClickForgetPin}>
                  Lupa PIN?
                </div>
              )}

              {showForgotPin && !email && !loginWithPin && (
                <div className='bit-forgot-pin'>
                  Untuk ubah PIN, silakan masuk lewat aplikasi Bibit
                </div>
              )}

              <div className='bit-custom-keyboard'>
                <div
                  data-valpin='1'
                  data-testid='num-1'
                  onClick={handleChangePinVal}
                >
                  1
                </div>
                <div
                  data-valpin='2'
                  data-testid='num-2'
                  onClick={handleChangePinVal}
                >
                  2
                </div>
                <div
                  data-valpin='3'
                  data-testid='num-3'
                  onClick={handleChangePinVal}
                >
                  3
                </div>
                <div
                  data-valpin='4'
                  data-testid='num-4'
                  onClick={handleChangePinVal}
                >
                  4
                </div>
                <div
                  data-valpin='5'
                  data-testid='num-5'
                  onClick={handleChangePinVal}
                >
                  5
                </div>
                <div
                  data-valpin='6'
                  data-testid='num-6'
                  onClick={handleChangePinVal}
                >
                  6
                </div>
                <div
                  data-valpin='7'
                  data-testid='num-7'
                  onClick={handleChangePinVal}
                >
                  7
                </div>
                <div
                  data-valpin='8'
                  data-testid='num-8'
                  onClick={handleChangePinVal}
                >
                  8
                </div>
                <div
                  data-valpin='9'
                  data-testid='num-9'
                  onClick={handleChangePinVal}
                >
                  9
                </div>
                <div
                  onClick={handleClickBiometric}
                  data-testid='biometric-icon'
                >
                  {biometric && (
                    <img
                      style={{ height: 20 }}
                      src={srcIconBiometric(theme)}
                      alt='biometric-icon'
                    />
                  )}
                </div>
                <div
                  data-valpin='0'
                  data-testid='num-0'
                  onClick={handleChangePinVal}
                >
                  0
                </div>
                <div onClick={handleDeletePin} data-testid='delete-pin'>
                  <IconDeleteKey alt='delete' />
                </div>
              </div>
            </div>
          </PaddingHorizontal>
        </ContentBox>
      </div>
      <ActivityIndicator
        className='bit-uikit-w-full'
        text='loading...'
        loading={loading || loadingBiometric}
      />
    </>
  );
};

export default PinScreen;
