/** Endpoints api for Portfolio features */
const endpoints = {
  portfolioCategoryID: (categoryId: string) =>
    `/portfolios/category/${categoryId}`,
  portfolio: '/portfolios',
  portfolioAllocationSummary: `/portfolios/allocations/summary`,
  portfolioAllocation: (type: string) => `/portfolios/allocations/${type}`,
  portfolioAllocationDetail: (symbol: string) =>
    `/portfolios/allocations/${symbol}`,
  portfolioGoalOnTrack: (portfolioId: string) =>
    `/portfolios/goal/${portfolioId}/ontrack`,
  portfolioGoalDetail: (portfolioId: string) =>
    `/portfolios/goal/${portfolioId}/detail`,
  portfolioGoalChart: (portfolioId: string) =>
    `/portfolios/goal/${portfolioId}/chart`,
  recurringListByPorto: (portoId: string) => `/recurring/portfolio/${portoId}`,
  switchProductAvailable: `/portfolios/products/switch`,
  patchRearrangePortfolio: '/portfolios/sorts',
  portfolioCouponHistories: '/portfolios/coupon-histories',
  portfolioPerformance: '/portfolios/performances?v=2',
  portfolioAssetClasses: '/portfolios/performances/asset-classes',
  portfolioRealizedProfitStats:
    '/portfolios/performances/realized-profits/stats?v=2',
  portfolioRisk: '/portfolios/risks',
  calculateRebalanceAmount: (product_type: 'FR' | 'SE', symbol: string) =>
    `/portfolios/risks/recalculate?product_type=${product_type}&symbol=${symbol}`,
};

export default endpoints;
