import * as Parent from 'core/Parent';
import { BibitApiAxiosResponse } from 'services';
import { BankUser } from 'entities/bankAccounts.reducer';
import endpoints from './endpoints';
import list from 'services';

import {
  BankAmendStatus,
  BannerTimelineStatus,
  InstantRedemptionWithdrawalData,
  JagoStatus,
} from '../types';

import http from 'core/http';
import { DataParamPostDataType } from 'core/Parser/types';
import { randomString } from 'utils/stringHelper';
import { checkFeatureVersion } from 'utils/feature-version';
import { getFlag } from 'utils/feature-flag';

interface JagoStatusRes {
  data?: {
    data?: {
      status?: JagoStatus;
      timeline?: BannerTimelineStatus;
      main_bank_on_regis?: boolean;
      already_register?: boolean;
      customer_type?: 1 | 2; // 1: conven 2: sharia
      is_registration_blocked?: boolean;
      is_require_relinking?: boolean;
      verified_at?: string;
      is_account_active?: boolean;
    };
    message?: string;
  };
}

export interface BankJagoBalance {
  data?: {
    data?: {
      account_name?: string;
      account_no?: string;
      balance?: any;
      bank_amend_status?: BankAmendStatus;
    };
    message?: string;
  };
}

export interface BankJagoRegistrationPayload {
  source: /** entry point from jago home banner / payment method */
  | 'banner'
    /** entry point from success registration bibit / home banner when bibit account is unverified */
    | 'coregis'
    /** entry point from bibit plus home banner */
    | 'banner-plus'
    /** entry point from bibit plus when success registration bibit / home banner when bibit account is unverified */
    | 'coregis-plus'
    /** entry point from bibit plus home banner when already has stockbit account  */
    | 'banner-plus-jago-only';
  redirect_url: string;
}

export interface BankJagoRegistration {
  data?: {
    data?: {
      isProcessFinished?: boolean;
    };
    message?: string;
  };
}

export interface BankJagoDeviceInfo {
  data?: {
    data?: {
      device_info: string;
      signed_device_id: string;
      timestamp: string;
    };
    message: string;
  };
}

export interface BankJagoRegisterSubmit {
  data: {
    data: {
      webview_url: string;
    };
    message: string;
  };
}

export interface BankJagoBindAccount {
  data: {
    data: {
      webview_url: string;
    };
    message: string;
  };
}

/**
 * Generalize error from post message
 */
const handlePostMessageError = (
  error: any,
  data: any,
  reject: (reason?: any) => void
) => {
  if (error) {
    return reject(error);
  }

  if (data?.error) {
    /**
     * check error object type
     */
    const errorIsObject = typeof data?.error === 'object';

    /**
     * normalize error data to object
     */
    const errorData = errorIsObject ? data?.error : { message: data?.error };

    return reject({
      response: {
        data: errorData,
      },
    });
  }
};

/**
 * Get user's registration status at Bank Jago
 */
export const getBankJagoRegisterStatus = (): Promise<JagoStatusRes> => {
  return new Promise((resolve, reject) => {
    const processId = 'getJagoStatus_' + randomString(10);
    return Parent.postData(
      processId,
      {
        fn: 'getJagoStatus',
        timeout: 10000,
        check_expiration: true,
      },
      (error, accountStatus) => {
        if (error) {
          return reject(error);
        }

        if (accountStatus?.error) {
          return reject(new Error(accountStatus?.error));
        }

        return resolve({
          data: {
            data: accountStatus,
            message: 'Successfully retrieve jago status',
          },
        });
      }
    );
  });
};

/**
 * Get Jago Balance
 * @returns Promise<BankJagoBalance>
 */
export const getJagoBalance = (): Promise<BankJagoBalance> => {
  return new Promise((resolve, reject) => {
    const snapJagoFF = getFlag('web_jago_snap');

    // Hit new Jago Account Balance API if FF is ON
    if (snapJagoFF) {
      return http
        .post(endpoints.jagoAccountBalance)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    }

    const getJagoBalanceId = 'getJagoBalance_' + randomString(10);
    return Parent.postData(
      getJagoBalanceId,
      {
        fn: 'getJagoBalance',
        timeout: 10000,
        check_expiration: true,
      },
      (error, data: any) => {
        if (error) {
          return reject(error);
        }
        if (data?.error) {
          return reject(data?.error);
        }
        return resolve({
          data: {
            data: data,
            message: 'Successfully retrieve jago balance',
          },
        });
      }
    );
  });
};

export const postBankJagoResetStatus = (): BibitApiAxiosResponse<{}> => {
  return http.post(endpoints.jagoResetStatus);
};

export const postJagoUnlink = (): BibitApiAxiosResponse<{}> => {
  return http.post(endpoints.jagoUnlink);
};

export const getJagoInstantRedemptionStatus = () => {
  return http.get(endpoints.jagoInstantRedemptionStatus);
};

export const postJagoCheckCustomer = (
  token: string
): BibitApiAxiosResponse<{}> => {
  return http.post(endpoints.jagoCheckCustomer, { partner_token: token });
};

export const postBankJagoRegistration = (
  postMessageData?: BankJagoRegistrationPayload
): Promise<BankJagoRegistration> => {
  const data = {
    fn: 'registerBankJago',
    timeout: 0,
    check_expiration: true,
    ...(!!postMessageData ? { data: postMessageData } : {}),
  };

  return new Promise((resolve, reject) => {
    return Parent.postData('openJagoWebview', data, (error, data) => {
      handlePostMessageError(error, data, reject);

      return resolve({
        data: {
          data: data,
          message: 'Successfully Register Bank Jago',
        },
      });
    });
  });
};

/** To agree instant redemption with jago
 * by inputting password on jago webview
 * URL POST /jago/instant-redemption/pockets/request
 * */
export const postFirstTimeInstantRedemptionJago = async (
  userBankData: BankUser
) => {
  const useSlotPayload = await checkFeatureVersion('First Time Jago Payload');

  return new Promise((resolve, reject) => {
    return Parent.postData(
      'firstTimeInstantRedemptionJago',
      {
        fn: 'firstTimeInstantRedemptionJago',
        timeout: 0,
        check_expiration: true,
        data: {
          ...(useSlotPayload
            ? { user_bank_slot: userBankData?.bank_slot }
            : {
                user_bank_account_number:
                  userBankData?.user_bank_account_number,
              }),
        },
      },
      (error, data) => {
        if (error) {
          return reject(error);
        }

        if (data?.error) {
          return reject({
            response: {
              data: data?.error,
            },
          });
        }

        return resolve({
          data: {
            data: data,
            message: 'Successfully Linking to Jago',
          },
        });
      }
    );
  });
};

/**
 * To sell / withdraw instantly to jago
 * If instant redemption is unavailable,
 * reject with INSTANT_REDEMPTION_UNAVAILABLE key of error modal
 * */
export async function postOrderSellInstantRedemptionJago(
  data: InstantRedemptionWithdrawalData
) {
  const postMessageData: DataParamPostDataType = {
    fn: 'postOrderSellInstantRedemptionJago',
    timeout: 0,
    data,
    check_expiration: true,
  };

  return new Promise((resolve, reject) => {
    return Parent.postData(
      'postOrderSellInstantRedemptionJago',
      postMessageData,
      (error, data) => {
        if (error) {
          return reject(error);
        }

        const errorType: string = data?.error?.type;

        const isTimeoutFromJago: boolean =
          errorType === 'jago_timeout_exceeded';

        if (data?.error && !isTimeoutFromJago) {
          /**
           * check error object type
           */
          const errorIsObject = typeof data?.error === 'object';

          /**
           * normalize error data to object
           */
          const errorData = errorIsObject
            ? data?.error
            : { message: data?.error };

          return reject({
            response: {
              data: errorData,
            },
          });
        }

        // This will return success of detail data
        return resolve({
          data: {
            data,
            message: 'Successfully instant redemption withdrawal',
          },
        });
      }
    );
  });
}

/**
 * To sell / withdraw instantly to jago
 * If instant redemption is unavailable,
 * reject with INSTANT_REDEMPTION_UNAVAILABLE key of error modal
 * */
export async function postOrderSellInstantRedemptionJagoSNAP(
  data: InstantRedemptionWithdrawalData
) {
  const { secure_key = '', type, ...restData } = data;
  return new Promise((resolve, reject) => {
    return http
      .post(
        type === 'single' ? list.orderSell : list.roboSell + '?v=2',
        restData,
        {},
        {
          headers: {
            'x-secure-key': secure_key,
          },
        }
      )
      .then(async (res) => {
        const errorType: string = res?.data?.error?.type;
        const isTimeoutFromJago: boolean =
          errorType === 'jago_timeout_exceeded';

        if (res?.data?.error && !isTimeoutFromJago) {
          /**
           * check error object type
           */
          const errorIsObject = typeof res?.data?.error === 'object';

          /**
           * normalize error data to object
           */
          const errorData = errorIsObject
            ? res?.data?.error
            : { message: res?.data?.error };

          return reject({
            response: {
              data: errorData,
            },
          });
        }

        // This will return success of detail data
        return resolve({
          data: {
            data: res?.data?.data,
            message: 'Successfully instant redemption withdrawal',
          },
        });
      })
      .catch((err) => {
        if (err) {
          return reject(err);
        }
      });
  });
}

export const postBankJagoDeviceEnroll = (
  postMessageData?: any
): Promise<BankJagoRegistration> => {
  const data: DataParamPostDataType = {
    fn: 'enrollDeviceBankJago',
    timeout: 0,
    check_expiration: true,
    ...(!!postMessageData ? { data: postMessageData } : {}),
  };

  return new Promise((resolve, reject) => {
    return Parent.postData('openJagoWebview', data, (error, data) => {
      if (error) {
        return reject(error);
      }

      if (data?.error) {
        return reject(new Error(data?.error?.message));
      }

      return resolve({
        data: {
          data: data,
          message: 'Successfully Enroll Bank Jago',
        },
      });
    });
  });
};

/**
 * Get data device info for jago api payload
 */
export const getJagoDeviceInfo = () => {
  return new Promise<BankJagoDeviceInfo>((resolve, reject) => {
    return Parent.postData(
      'getJagoDeviceInfo',
      {
        fn: 'getJagoDeviceInfo',
      },
      (error, data) => {
        if (error) return reject(error);
        if (data?.error) {
          return reject({
            response: {
              data: data?.error,
            },
          });
        }
        return resolve({
          data: {
            data,
            message: 'Successfully Get Jago Device Info',
          },
        });
      }
    );
  });
};

/**
 * Fetch Occupation List for Jago registration
 */
export const getJagoRegistrationOccupationList = () => {
  return http.get(list.occupation, {
    is_snap: 1,
  });
};

/**
 * Post Jago register data
 */
export const postJagoRegisterData =
  (): BibitApiAxiosResponse<BankJagoRegisterSubmit> => {
    return http.post(endpoints.jagoRegister);
  };

/**
 * Fetch Jago user registration status
 */
export const getJagoRegistrationStatus = () => {
  return http.get(endpoints.jagoStats);
};

/**
 * Post Jago Bind Account
 */
export const postJagoBindAccount = (
  auth_code?: any
): BibitApiAxiosResponse<BankJagoBindAccount> => {
  return http.post(endpoints.jagoBind, {
    ...(!!auth_code ? { auth_code } : {}),
  });
};

/**
 * Post Jago Unbind Account
 */
export const postJagoUnbind = (): BibitApiAxiosResponse<{}> => {
  return http.post(endpoints.jagoUnbind);
};
