export const translation = {
  pilihan_reksadana: 'Pilihan Reksa Dana',
  konvensional: 'Konvensional',
  syariah: 'Syariah',
  bank_penampung: 'Bank Penampung',
  semua_bank: 'Semua Bank',
  pilih_bank_penampung: 'Pilih Bank Penampung',
  urutkan_berdasarkan: 'Urutkan Berdasarkan',
  minimum_pembelian: 'Minimum Pembelian',
  tertinggi: 'Tertinggi',
  terendah: 'Terendah',
  periode: 'Periode',
  pilih_bandingkan_produk: 'Pilih minimum 2 produk untuk di bandingkan',
  bandingkan_produk: 'Bandingkan Produk',
  pilih_reksadana: 'Pilih Reksa Dana',
  bandingkan: 'Bandingkan',
  tidak_ada_produk: 'Tidak Ada Produk',
  pencarian_tidak_ditemukan: 'Produk yang kamu cari tidak ditemukan.',
  tidak_ada_produk_kategori:
    'Tidak ada produk yang tersedia dalam kategori ini.',
  periode_return: 'Periode Return',
  urut_berdasarkan: 'Urut Berdasarkan',
  pilih_periode_return: 'Pilih Periode Return',
  terapkan: 'Terapkan',
  data_belum_tersedia: 'Data belum tersedia',
  hari_ini: 'Hari ini',
  investasi_saya: 'Investasi Saya',
  tingkat_risiko: 'Tingkat Risiko',
  informasi_investasi: 'Informasi Investasi',
  pencairan: 'Pencairan',
  bank_kustodian: 'Bank Kustodian',
  biaya_investasi: 'Biaya Investasi',
  gratis: 'Gratis',
  komisi_penjualan: 'Komisi Penjualan',
  komisi_pembelian: 'Komisi Pembelian',
  biaya_penjualan_bibit:
    'Biaya yang dikenakan oleh Bibit saat kamu melakukan penjualan.',
  investasi_rutin_produk_ini: 'Investasikan produk ini secara rutin.',
  nilai_investasi: 'Nilai Investasi',
  contoh_investasi: 'Contoh: {{amount}}',
  frekuensi: 'Frekuensi',
  bulanan: 'Bulanan',
  mingguan: 'Mingguan',
  harian: 'Harian',
  tiap_tanggal: 'Tiap Tanggal',
  berlaku_hingga: 'Berlaku Hingga',
  selamanya: 'Selamanya',
  pilih_bulan_berakhir: 'Pilih Bulan Berakhir',
  bandingkan_reksadana: 'Bandingkan Reksa Dana',
  mesin_waktu: 'Mesin Waktu',
  jika_berinvestasi: 'Jika kamu berinvestasi',
  rutin_perbulan: 'Rutin Per Bulan',
  nabung_sekali: 'Nabung Sekali',
  nilai_invest_bulan: 'Nilai Investasi /Bulan',
  mulai_investasi_sejak: 'Mulai Berinvestasi Sejak Berapa Tahun Lalu?',
  tahun: 'Tahun',
  nilai_investasi_hari_ini: 'Maka Nilai Estimasi Investasi Hari Ini',
  alokasi_asset: 'Alokasi Aset',
  sukuk_korp: 'Sukuk Korporasi',
  sukuk_pemerintah: 'Sukuk Pemerintah',
  kas_deposito_syariah: 'Kas & Deposito Syariah',
  daftar_switching: 'Daftar Switching',
  pembagian_dividen: 'Pembagian Dividen',
  tanggal: 'Tanggal',
  jumlah_rp_unit: 'Jumlah (Rp) / Unit',
  dokumen: 'Dokumen',
  sebulan_terakhir: '1 Bulan Terakhir',
  tiga_bulan_terakhir: '3 Bulan Terakhir',
  setahun_terakhir: '1 Tahun Terakhir',
  tiga_tahun_terakhir: '3 Tahun Terakhir',
  lima_tahun_terakhir: '5 Tahun Terakhir',
  sepuluh_tahun_terakhir: '10 Tahun Terakhir',
  biaya_transfer_penjualan: 'Biaya Transfer Penjualan',
  reksadana_baru: 'Reksa Dana Baru Terbit',
  lihat_selengkapnya: 'Lihat Selengkapnya',
  aktifkan_sip: 'Aktifkan SIP',
  keuntungan: 'Keuntungan',
  tinggi: 'Tinggi',
  sedang: 'Sedang',
  rendah: 'Rendah',
  instan: 'Instan',
  tiap_hari: 'Tiap Hari',
  senin: 'Senin',
  selasa: 'Selasa',
  rabu: 'Rabu',
  kamis: 'Kamis',
  jumat: 'Jumat',
  sabtu: 'Sabtu',
  minggu: 'Minggu',
  pilih_tanggal_berakhir: 'Pilih Tanggal Berakhir',
  expense_ratio_info:
    'Expense Ratio mengukur seberapa besar biaya yang dikeluarkan untuk mengelola reksa dana. Lebih kecil expense ratio tersebut mencerminkan kepiawaian Manajer Investasi dalam mengelola reksadana untuk investornya secara efisien.',
  average_yield_info_satu:
    'Weighted Average Yield to Maturity atau Average Yield adalah gambaran indikasi return asset holding reksadana apabila manajer investasi memegang asset holding hingga masa jatuh tempo. Semakin tinggi yield, semakin tinggi indikasi return.',
  average_yield_info_dua:
    'Perlu ditekankan Average Yield bukanlah kepastian return karena manajer investasi dapat menjual asset holding reksa dana untuk mengatur likuiditas dan merubah strategi investasi tergantung pergerakan pasar dan belum menghitung management fee dari manager investasi.',
  average_yield_info_tiga:
    'Average Yield perlu digunakan bersamaan dengan faktor lain ketika mengevaluasi reksa dana obligasi atau pasar uang, seperti durasi dan kualitas asset holdingnya.',
  total_aum_info: 'Jumlah dana yang dikelola dalam suatu produk Reksa Dana.',
  bank_kustodian_info:
    'Bank Kustodian adalah Bank yang mendapat persetujuan OJK untuk menyimpan dana investasi kamu termasuk menerima dividen dan mewakili kamu yang menjadi nasabahnya. Dana investasi kamu tidak disimpan di Bibit.',
  bank_penampung_info:
    'Bank Penampung adalah Bank yang digunakan Bank Kustodian untuk mengirimkan dana hasil penjualan ke rekening kamu. Kamu akan dikenakan biaya transfer oleh pihak bank (bukan Bibit) jika rekening Bank kamu berbeda dengan Bank Penampung.',
  biaya_transfer_penjualan_info:
    'Biaya yang dikenakan oleh Bank Kustodian (bukan Bibit) saat\nkamu melakukan penjualan.',
  exit_load_info:
    'Exit load adalah biaya yang dikenakan oleh manajer investasi\nuntuk penjualan dalam periode tertentu.',
  tingkat_risiko_tinggi_info:
    'Reksa Dana Saham memiliki tingkat fluktuasi pasar tertinggi di antara jenis reksa dana lainnya dengan potensi return yang juga tinggi.',
  tingkat_risiko_sedang_info:
    'Reksa Dana Obligasi memiliki tingkat fluktuasi pasar moderat dengan potensi keuntungan yang diharapkan dapat lebih tinggi dari tingkat inflasi dan bunga deposito.',
  tingkat_risiko_rendah_info:
    'Money market mutual funds have the lowest market fluctuation rate with the potential for returns to be consistently above the inflation rate.',
  daftar_switching_info:
    'Daftar produk yang tersedia untuk melakukan switching ke reksa dana lain. Switching hanya dapat dilakukan pada Manajer Investasi yang sama.',
  cari_reksa_dana: 'Cari reksa dana',
  tampilkan_produk: 'Tampilkan {{total}} Produk',
  return_yang_cukup_stabil_risiko:
    'Return yang cukup stabil dengan risiko yang moderat.',
  tipe_pengelolaan: 'Tipe Pengelolaan Dana',
  koleksi_reksa_dana: 'Koleksi Reksa Dana',
  target_harga_analis: 'Target Harga Analis',
  reksa_dana_syariah:
    'Reksa dana terbaik untuk yang ingin mengikuti prinsip syariah Islam.',
  tipe_pencairan: 'Tipe Pencairan',
  manajer_investasi: 'Manajer Investasi',
  filter_berdasarkan: 'Filter Berdsarkan',
  tidak_butuh_waktu_lama_pencairan:
    'Tidak butuh waktu lama, pencairan dana sekilat transfer uang.  ',
  cari_produk_investasi: 'Cari produk investasi',
  reksa_dana_portofolio_indeks:
    'Reksa dana yang portofolionya mengacu pada indeks tertentu.',
  produk_investasi: 'Produk Investasi',
  pilihan_reksa_dana_berkualitas:
    'Pilihan reksa dana berkualitas yang dikurasi oleh Bibit.',
  return_tinggi_untuk_risiko:
    'Return tinggi untuk yang berani ambil risiko lebih besar.',
  analisa_penilaian: '{{total_analyst}} Analisa Penilaian',
  semua: 'Semua',
  simpan_produk_favoritmu:
    'Simpan produk favoritmu untuk pantau pergerakan harga.',
  pilihan_reksa_dana: 'Pilihan Reksa Dana',
  saham_terkait: 'Saham Terkait',
  risiko_rendah_return:
    'Risiko rendah dengan return yang lebih tinggi dari deposito.',
  transaksi_reksa_dana_dengan:
    'Transaksi reksa dana dengan mata uang US Dollar.',
  reksa_dana_return_tinggi:
    'Reksa dana dengan return yang tinggi dalam setahun terakhir.',
  tambah_produk_ke_watchlist: 'Tambah Produk ke Watchlist',
  reksadana_campuran:
    'Reksa dana campuran dari beberapa efek sekaligus untuk investor yang mencari alternatif.',
  benchmark_reksadana:
    'Bandingkan performa reksa dana dengan performa instrumen keuangan/investasi lainnya.',
  koleksi_reksadana: 'Koleksi Reksa Dana',
  top_reksadana: 'Top Reksa Dana',
  investasi_minimum: 'Investasi dengan modal minimum Rp10,000.',
  cari_produk: 'Cari produk',
  terakhir_dilihat: 'Terakhir Dilihat',
  terakhir_dicari: 'Terakhir Dicari',
  tidak_ditemukan: 'Produk Tidak Ditemukan',
  coba_kata_kunci_lain:
    'Coba kata kunci lain atau cari produk lain yang terdaftar di KSEI.',
  reksadana_pasar_uang: 'Reksa Dana Pasar Uang',
  reksadana_obligasi: 'Reksa Dana Obligasi',
  reksadana_syariah: 'Reksa Dana Syariah',
  reksadana_saham: 'Reksa Dana Saham',
  pencairan_instan: 'Pencairan Instan',
  reksadana_lainnya: 'Reksa Dana Lainnya',
  index_fund: 'Index Fund',
  reksadana_usd: 'Reksa Dana USD',
  reksadana_minimal: 'Minimal 10rb',
  reksadana: 'Reksa Dana',
  saham_risiko_jangka_panjang:
    'Return tertinggi untuk yang berani ambil risiko investasi jangka panjang.',
  obligasi_risiko_moderat: 'Return yang stabil dengan risiko yang moderat.',
  performa_reksadana_lainnya:
    'Bandingkan performa reksa dana dengan performa instrumen investasi lainnya.',
  lima_tahun: '5 Year',
  tiga_tahun: '3 Year',
  satu_tahun: '1 Year',
  tiga_bulan: '3 Month',
  satu_bulan: '1 Month',
  satu_hari: '1 Day',
};
