import * as React from 'react';
import { Text, Skeleton } from '@bibitid/uikit-v1';
import style from './SectionTitle.module.css';

interface SectionTitleProps {
  /**
   * Icon element if provided
   */
  icon?: React.ReactNode;

  /**
   * Title text
   */
  title: string;

  /**
   * right content
   */
  rightContent?: React.ReactNode;
  /**
   * with skeleton loading
   */
  loading?: boolean;
}

/**
 * General title for sections at home page
 */
const SectionTitle: React.FC<React.PropsWithChildren<SectionTitleProps>> = ({
  icon,
  title,
  rightContent,
  loading,
}) => {
  if (loading) {
    return (
      <div className={style['bit-section-title']}>
        <div className={style['bit-title']}>
          <Skeleton width={60} height={12} />
        </div>
      </div>
    );
  }

  return (
    <div className={style['bit-section-title']}>
      <div className={style['bit-title']}>
        {icon}
        <Text type='body2'>{title}</Text>
      </div>
      {rightContent}
    </div>
  );
};

export default SectionTitle;
