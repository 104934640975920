import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { Props } from './BibitPlusUpgradeModal';

const BibitPlusUpgradeModalLazy = React.lazy(
  () => import('./BibitPlusUpgradeModal')
);

const BibitPlusUpgradeModal: React.FC<Props> = (props) => {
  return (
    <LazyModal
      visible={props.isOpen}
      component={BibitPlusUpgradeModalLazy}
      {...props}
    />
  );
};

export default BibitPlusUpgradeModal;
