import { mappedColors } from '../mapped-color';
import { getTheme } from 'utils/theme';

export const getThemeColor = () => {
  const theme = getTheme();
  // picked color in mapped colors
  const color = mappedColors[theme];

  return color;
};
