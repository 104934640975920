import * as React from 'react';
import light from './light.svg';
import dark from './dark.svg';
import { ThemedImage } from '@bibitid/uikit-v1';

const themeImageMapping = {
  light: light,
  dark: dark,
  default: light,
};

export const MinispotMaintenance: React.FC<
  React.HTMLProps<HTMLPictureElement>
> = (props) => {
  return (
    <ThemedImage
      imageMap={themeImageMapping}
      style={{ display: 'flex' }}
      {...props}
    />
  );
};

export default MinispotMaintenance;
