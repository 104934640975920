import { useQuery, useInfiniteQuery } from 'react-query';
import type { BibitApiSuccessResponse } from 'services';
import http from 'core/http';
import list from './endpoints';
import type {
  NotificationStats,
  NotificationList,
} from '../types/notification';
import queryClient from 'network/queryClient';

export const notifQueryKeys = {
  pushNotifStats: 'Push Notif Stats',
  pushNotifData: 'Push Notif Data',
};

export const getPushNotificationData = ({
  limit,
  cursor,
}: {
  limit: number;
  cursor?: number;
}) => {
  return http.get<BibitApiSuccessResponse<NotificationList>>(
    list.notifications,
    {
      type: 1,
      limit,
      ...(cursor ? { cursor } : {}),
    }
  );
};

export const getPushNotificationStats = () => {
  return http.get<BibitApiSuccessResponse<NotificationStats>>(
    list.notificationStats
  );
};

export const useFetchPushNotificationData = (
  enabled: boolean = true,
  limit = 25
) => {
  return useInfiniteQuery(
    [notifQueryKeys.pushNotifData],
    ({ pageParam = '' }) =>
      getPushNotificationData({
        limit,
        cursor: pageParam,
      }).then((res) => {
        return {
          ...res?.data?.data,
          meta: res?.data?.meta,
        };
      }),
    {
      enabled,
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true, // necessary to prevent refetching notifications that have been read.
      getNextPageParam: (res) => {
        // Should not get the next page if next cursor empty
        const nextCursor = res?.meta?.next_cursor || false;
        return nextCursor;
      },
      onSuccess: () => {
        queryClient.refetchQueries({
          queryKey: [notifQueryKeys.pushNotifStats],
        });
      },
    }
  );
};

export const useFetchPushNotificationStats = (enabled: boolean = true) => {
  return useQuery(
    [notifQueryKeys.pushNotifStats],
    () => getPushNotificationStats().then((payload) => payload?.data?.data),
    {
      enabled,
    }
  );
};
