import { openErrorModal } from 'features/errors/errorModal.reducer';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'utils/isEmpty';
import { usePostContactList } from '../hooks';
import {
  Member,
  SharedPortfolioInvitation,
  SharedPortfolioState,
  SharedPortfolioFunction,
  ActiveModal,
  NativeContactList,
  Category,
  SellType,
} from '../types';
import { genericLoadingDialog } from 'entities/common.reducer';

/** Initial state properties Context Shared portfolio  */
const SharedPortfolioContext = React.createContext<
  SharedPortfolioState | undefined
>(undefined);

/** Initial state function Context Shared portfolio */
export const SharedPortfolioFunctionContext = React.createContext<
  SharedPortfolioFunction | undefined
>(undefined);

const SharedPortfolioContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [activeModal, setActiveModal] = useState<ActiveModal>(undefined);

  const [categoryId, setCategoryId] = useState<number | undefined>(undefined);

  const [destinationCategory, setDestinationCategory] = useState<
    Category | undefined
  >(undefined);

  const [sharedPortfolioId, setSharedPortfolioId] = useState<
    number | undefined
  >(undefined);

  const [categoryTitle, setCategoryTitle] = useState<string | undefined>(
    undefined
  );

  const [member, setMember] = useState<Member | undefined>(undefined);

  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [nativeContactList, setNativeContactList] = useState<
    NativeContactList[]
  >([]);
  const [invitation, setInvitation] = useState<SharedPortfolioInvitation[]>([]);

  const [reason, setReason] = useState<string | undefined>(undefined);

  const [otherReason, setOtherReason] = useState<string | undefined>('');

  const [sellType, setSellType] = useState<SellType | undefined>(undefined);

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const [triggeredFromAchievedModal, setTriggeredFromAchievedModal] =
    useState<boolean>(false);

  /**
   * Should fetch contact list or not
   * - when user open contact list native, we will allow user to fetch api contact list
   * - when user remove member from chip, we don't need to refetch contact list
   */
  const [shouldFetchContactList, setShouldFetchContactList] = useState(true);

  /*
   * reset all shared portfolio context value
   */
  const resetSharedPortfolioModal = useCallback(() => {
    setActiveModal(undefined);
    setCategoryId(undefined);
    setSharedPortfolioId(undefined);
    setCategoryTitle(undefined);
    setMember(undefined);
    setIsAdmin(false);
    setNativeContactList([]);
    setMember(undefined);
    setInvitation([]);
    setReason(undefined);
    setOtherReason(undefined);
    setDestinationCategory(undefined);
    setTriggeredFromAchievedModal(false);
    setSellType(undefined);
    setIsDragging(false);
  }, []);

  const dispatch = useDispatch<any>();

  const {
    data: contactListData,
    isLoading: contactListLoading,
    mutateAsync: postContactList,
  } = usePostContactList();

  /**
   * Function to:
   * - Check if selected contact list to be invited is bibit user or not
   * - Open ContactConfirmationModal if success
   * - Open modal error if failed. (e.g: rate limit to check contact)
   */
  const checkContactList = useCallback(async () => {
    if (!shouldFetchContactList || isEmpty(nativeContactList)) return;

    try {
      dispatch(
        genericLoadingDialog({
          visible: true,
          message: '',
        })
      );

      await postContactList({
        phone_numbers: nativeContactList.map(
          ({ number }: { number: string }) => number
        ),
      });

      dispatch(
        genericLoadingDialog({
          visible: false,
          message: '',
        })
      );

      // Open modal contact confirmation if success
      setActiveModal('contact_confirmation');
    } catch (error) {
      dispatch(
        genericLoadingDialog({
          visible: false,
          message: '',
        })
      );

      // Reset provider state of shared porto modal
      resetSharedPortfolioModal();

      const errorMessage =
        error?.response?.data?.message || 'Gagal menampilkan daftar pengguna';
      const errorType = error?.response?.data?.type || '';

      // Open modal error
      dispatch(
        openErrorModal({
          message: errorMessage,
          type: errorType,
        })
      );
    }
  }, [
    dispatch,
    nativeContactList,
    postContactList,
    resetSharedPortfolioModal,
    shouldFetchContactList,
  ]);

  /**
   * Trigger ContactConfirmationModal open and check if selected contact is bibit user or not,
   * everytime user choose contact to be invited to shared porto.
   *
   * Triggered from native in components:
   * - PortfolioTopCardShared.tsx (click button 'Undang')
   * - ContactConfirmationModal.tsx
   * - MemberTab.tsx
   */
  useEffect(() => {
    if (!!sharedPortfolioId && !isEmpty(nativeContactList)) {
      checkContactList();
    }
  }, [sharedPortfolioId, nativeContactList, checkContactList]);

  return (
    <SharedPortfolioContext.Provider
      value={{
        activeModal,
        categoryId,
        sharedPortfolioId,
        nativeContactList,
        member,
        invitation,
        isAdmin,
        categoryTitle,
        reason,
        otherReason,
        destinationCategory,
        triggeredFromAchievedModal,
        sellType,
        isDragging,
        contactListData,
        contactListLoading,
        isLoading,
      }}
    >
      <SharedPortfolioFunctionContext.Provider
        value={{
          setActiveModal,
          setCategoryId,
          setSharedPortfolioId,
          setShouldFetchContactList,
          setNativeContactList,
          resetSharedPortfolioModal,
          setMember,
          setInvitation,
          setIsAdmin,
          setCategoryTitle,
          setReason,
          setOtherReason,
          setDestinationCategory,
          setTriggeredFromAchievedModal,
          setSellType,
          setIsDragging,
          setIsLoading,
        }}
      >
        {children}
      </SharedPortfolioFunctionContext.Provider>
    </SharedPortfolioContext.Provider>
  );
};

export default SharedPortfolioContextProvider;

export const useSharedPortfolioContext = () => {
  const context = React.useContext(SharedPortfolioContext);
  if (context === undefined) {
    throw new Error(
      'useSharedPortfolioContext must be used within a SharedPortfolioContext Provider'
    );
  }
  return context;
};

export const useSharedPortfolioFunctionContext = () => {
  const context = React.useContext(SharedPortfolioFunctionContext);

  if (context === undefined) {
    throw new Error(
      'useSharedPortfolioFunctionContext must be used within a SharedPortfolioFunctionContext Provider'
    );
  }

  return context;
};
