import React from 'react';

type Props = {
  /**
   * start date periode
   * @example Jan 31 2022
   */
  startDate: string;
  /**
   * end date periode
   * @example Jan 31 2022
   */
  endDate: string;
  /**
   * a fallback to show content before and after periode
   */
  fallback?: React.ReactNode;

  /**
   * determine use exact time today or not.
   * by default time today is 00:00
   * when exactTimeToday is true, time today will be current time. ex: 15:38
   */
  exactTimeToday?: boolean;

  /**
   * in periode content
   */
  children: React.ReactNode;
};

/**
 * Component to handle periodic content
 */
const PeriodicContent = (props: Props) => {
  const {
    startDate: startDateString,
    endDate: endDateString,
    fallback = undefined,
    children,
    exactTimeToday,
  } = props;

  /**
   * today without time
   */
  const today = exactTimeToday
    ? new Date()
    : new Date(new Date().toDateString());
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  /**
   * today is between start date and end date
   */
  const stillInPeriode = today >= startDate && today <= endDate;

  /**
   * render periodic content if still in periode or
   * render another content if out of periode
   */
  const result = stillInPeriode ? children : fallback;

  return (
    <>
      {result}
      {''}
    </>
  );
};

export default PeriodicContent;
