import {
  useTopModalFunctions,
  useTopUpGlobalContext,
} from 'features/topup-modal/TopupGlobalContext';
import { useAuth, useProfileData } from 'hooks';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  postRoboCalculateTopUp,
  fetchRoboRecommendationById,
  postRoboRecommendationUpdate,
  postRoboScoreUpdateById,
} from 'services/robo';

export interface CheckRoboParams {
  roboid: string;
  is_sharia?: number;
}

/**
 * calculate topup for robo transaction
 *
 */
export const usePostRoboCalculateTopUp = () => {
  const {
    setTotalAmount,
    setStampDutyFee,
    setIsIfaTransaction,
    setIfaCommissionFee,
    setIfaCommissionPercentage,
  } = useTopModalFunctions();
  const { giftcardCode, withStampDuty } = useTopUpGlobalContext();

  return useMutation(
    ({
      roboId,
      amount,
      withGiftCard,
      recurring_id,
    }: {
      roboId: string;
      amount: number;
      withGiftCard: boolean;
      /**
       * recurring_id userd for identify get robo from recurring
       */
      recurring_id?: number;
    }) =>
      postRoboCalculateTopUp(roboId, {
        amount,
        is_with_gift_card: withGiftCard,
        ...(withStampDuty ? { stamp_duty: withStampDuty } : {}),
        ...(recurring_id ? { recurring_id } : {}),
      }),
    {
      onSuccess: (data) => {
        setTotalAmount(data?.data?.data?.total_amount);

        const ifaTransaction = !!data?.data?.data?.is_ifa_transaction;
        if (ifaTransaction) {
          setIsIfaTransaction(ifaTransaction);
          setIfaCommissionFee(
            data?.data?.data?.transaction_fee?.commission?.amount || 0
          );
          setIfaCommissionPercentage(
            data?.data?.data?.transaction_fee?.commission?.value || 0
          );
        }

        if (!giftcardCode && withStampDuty) {
          setStampDutyFee(data?.data?.data?.stamp_duty_fee);
        }
      },
    }
  );
};

/**
 * fetch robo recommendation
 *
 * enable = true
 */

export const useFetchRoboRecommendation = (
  param: CheckRoboParams,
  enable: boolean = true
) => {
  const { isLogin } = useAuth();
  const { data: dataProfile } = useProfileData(isLogin);

  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;

  const isSyariah = profileDetail?.preference?.syariah === 1;

  return useQuery(
    ['Robo Recommendation', param.roboid, isSyariah],
    () => fetchRoboRecommendationById(param.roboid),
    {
      enabled: enable,
    }
  );
};

/**
 * fetch robo recommendation on profile page
 *
 * enable = true
 */

export const useFetchRoboRecomSharia = (
  param: CheckRoboParams,
  enable: boolean = true
) => {
  const { isLogin } = useAuth();
  const { data: dataProfile } = useProfileData(isLogin);

  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;

  const isSyariah = profileDetail?.preference?.syariah === 1;

  return useQuery(
    [
      'Robo Recommendation Profile Page',
      param.roboid,
      isSyariah,
      param.is_sharia,
    ],
    () => fetchRoboRecommendationById(param.roboid, param.is_sharia),
    {
      enabled: enable,
    }
  );
};

export const useUpdateRoboRecommendation = () => {
  const queryClient = useQueryClient();

  const { isLogin } = useAuth();
  const { data: dataProfile } = useProfileData(isLogin);

  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;

  const isSyariah = profileDetail?.preference?.syariah === 1;

  return useMutation(
    (item: { roboId: string; symbol: string; type: string }) =>
      postRoboRecommendationUpdate(item.roboId, { [item.type]: item.symbol }),
    {
      onSuccess: (data, item) => {
        queryClient.setQueryData(
          ['Robo Recommendation', item.roboId, isSyariah],
          data
        );
      },
    }
  );
};

export const useUpdateRoboScoreById = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (item: { roboId: string; updatedScore: number }) =>
      postRoboScoreUpdateById(item.roboId, { score: item.updatedScore }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['Portfolio Category Detail']);
        queryClient.invalidateQueries(['Portfolio Goal On Track']);
      },
    }
  );
};
