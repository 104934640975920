import LazyModal from 'features/common/LazyModal';
import { useRecurringContextState } from 'features/recurring/contexts/RecurringContext';
import React from 'react';

const ModalChooseTypeSIPGlobal = React.lazy(
  () => import('./ModalChooseTypeSIPGlobal')
);

const LazyModalChooseTypeSIPGlobal = (props: any) => {
  const { showModalChooseTypeSIP } = useRecurringContextState();

  return (
    <LazyModal
      component={ModalChooseTypeSIPGlobal}
      visible={showModalChooseTypeSIP}
      {...props}
    />
  );
};

export default LazyModalChooseTypeSIPGlobal;
