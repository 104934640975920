import { useQuery } from 'react-query';
import {
  getPaymentRecurring,
  ParamPaymentRecurring,
} from 'features/recurring/networks/services';
import { PortoRecurring } from 'services/recurring.type.d';
import { ReksaDana } from 'entities/reksadana.reducer';
import { isLinkAjaApplinkSupported } from 'features/linkAja/LinkAjaUtils';
import { useFlag } from 'utils/feature-flag';
import { useUserInInstitution } from 'features/institution/hooks';
import { useRecurringContextFunction } from '../contexts/RecurringContext';

/**
 * Fetching data payment recurring
 * @param chosenPorto
 * @param chosenProduct
 */
const useFetchPaymentRecurring = (
  chosenPorto?: PortoRecurring,
  chosenProduct?: ReksaDana,
  amount: number = 100000,
  promoId?: number,
  enabled = true,
  recurringId?: string,
  isFromFlexibleModal?: boolean
) => {
  const chosenRobo = !!chosenPorto?.roboid && chosenPorto?.roboid !== '0';
  const { supportedByVersion } = isLinkAjaApplinkSupported();
  const linkajaApplinkFF = useFlag('web_linkaja_applink');
  const institution = useUserInInstitution();
  const { setIfaTransaction, setIfaCommissionFee, setIfaCommissionPercentage } =
    useRecurringContextFunction();

  let symbol = chosenProduct?.symbol;
  if (chosenRobo) {
    symbol = chosenPorto?.roboid;
  }

  //if user choosen porto robo. the symbol will be roboID
  const param: ParamPaymentRecurring = {
    amount,
    ...(!isFromFlexibleModal ? { recurring: 1 } : {}),
    ...(!chosenRobo ? { product_symbol: symbol } : {}),
    ...(chosenRobo ? { robo_id: symbol } : {}),
    portfolio_id: chosenPorto?.id,
    ...(promoId ? { promo_id: promoId } : {}),
    ...(supportedByVersion && linkajaApplinkFF ? { v: 2 } : {}),
    ...(recurringId ? { recurring_id: recurringId } : {}),
  };

  return useQuery(
    ['Payment Recurring', param],
    () => getPaymentRecurring(param),
    {
      enabled: enabled && !institution,
      staleTime: 2 * 1000,
      onSuccess(data) {
        const ifaTransaction = !!data?.data?.meta?.is_ifa_transaction;
        if (ifaTransaction) {
          setIfaTransaction(ifaTransaction);
          setIfaCommissionFee(
            data?.data?.meta?.transaction_fee?.commission?.amount || 0
          );
          setIfaCommissionPercentage(
            data?.data?.meta?.transaction_fee?.commission?.value || 0
          );
        }
      },
    }
  );
};

export default useFetchPaymentRecurring;
