import { apiDelete, get, post, patch, put, apiInstance } from './http';

export { apiInstance };

const http = {
  delete: apiDelete,
  get,
  post,
  patch,
  put,
};

export default http;
