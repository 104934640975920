import { useState, useEffect } from 'react';

interface WindowSize {
  width: number | undefined;
  height: number | undefined;
}

type Orientation = 'landscape' | 'portrait';

// Hook
function useWindowSize(): WindowSize {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  const getOrientation = (): Orientation => {
    return window.matchMedia('(orientation: landscape)')?.matches
      ? 'landscape'
      : 'portrait';
  };

  const [, setOrientation] = useState<Orientation>(getOrientation());

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setOrientation((orientation) => {
        // swap height and width when the device rotates
        if (orientation !== getOrientation()) {
          setWindowSize((size) => {
            return {
              width: size.height,
              height: size.width,
            };
          });
        }

        if (orientation === getOrientation()) {
          // Set window width/height to state
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }

        return getOrientation();
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default useWindowSize;
