import { useEffect, useCallback, useState } from 'react';
import { useQueryParams } from 'utils/routeHelper';
import {
  useRecurringContextFunction,
  useRecurringContextState,
} from 'features/recurring/contexts/RecurringContext';
import { PortoRecurring } from 'services/recurring.type.d';
import { ReksaDana } from 'entities/reksadana.reducer';
import getSafely from 'utils/safely';
import { fetchProductById } from 'services/product';
import { useFetchQueryDetailRecurring } from 'features/recurring/hooks';
import { useHistory, useParams } from 'react-router-dom';
import {
  scheduleTypeDeserialize,
  daysWeekDeserialize,
  SearchParamCreation,
} from 'features/recurring/utils/utils';
import { parseISO } from 'utils/date';
import { isNumber } from 'utils/number';
import { add } from 'date-fns';
import { useFlag } from 'utils/feature-flag';
import { useFetchRdnDetail } from 'features/stock';

/** set invest value default if Porto is Goal Setting */
export const usePrefilledGoalSetting = () => {
  const autoRutinFF = useFlag('web_bibit_auto_rutin');

  /**
   * If mode === 'update' --> there recurringid will be attach to param url
   * if mode === 'create --> recurringid will NOT exist
   */
  const query = useQueryParams();

  const isreinvestBonds = query.get('action') === 'reinvestBonds';

  const paramUrl = useParams<{
    recurringid?: string;
    mode: 'create' | 'update';
  }>();

  const amountQueryParam = query.get('amount');
  const { chosenPorto, promoRecurring } = useRecurringContextState();

  const { setInvestmentValue } = useRecurringContextFunction();

  const isGoalSetting = chosenPorto?.is_goalsetting === 1;

  const minPromoAmount = promoRecurring?.min_amount ?? 0;

  useEffect(() => {
    // do nothing if mode update
    if (paramUrl.mode === 'update') return;

    // do nothink if amountQueryParam has value
    if (!!amountQueryParam) return;

    if (minPromoAmount) {
      return setInvestmentValue(minPromoAmount);
    }

    if ((!chosenPorto || !isGoalSetting) && !amountQueryParam) {
      return setInvestmentValue(undefined);
    }

    const investmentValueDefault = isreinvestBonds
      ? 0
      : chosenPorto?.recurring_amount;

    if (
      autoRutinFF &&
      !!investmentValueDefault &&
      investmentValueDefault < 100000
    ) {
      return;
    }

    if (!investmentValueDefault) return;
    setInvestmentValue(investmentValueDefault);
  }, [
    amountQueryParam,
    chosenPorto,
    isGoalSetting,
    paramUrl.mode,
    minPromoAmount,
    setInvestmentValue,
    isreinvestBonds,
    autoRutinFF,
  ]);
};

/** set default CHOSEN PORTO if PortoID given by query param */
export const usePrefilledChosenPorto = (availablePorto: PortoRecurring[]) => {
  const history = useHistory();
  const { chosenPorto, chosenReksaDana } = useRecurringContextState();
  const { setChosenPorto, setChosenReksaDana } = useRecurringContextFunction();
  const autoRutinFF = useFlag('web_bibit_auto_rutin');

  const query = useQueryParams();
  const portoIDQueryParam = query.get('chosenPorto');
  const productIdQueryParam = query.get('chosenPortoProduct');
  const sourceFrom = query.get('source');
  const promoId = query.get('promoId');
  const isReinvestBonds = query?.get('action') === 'reinvestBonds';

  /**
   * If there is portoId from query param
   * Then we should set porto chosen from query param
   */
  useEffect(() => {
    if (!portoIDQueryParam || isNaN(Number(portoIDQueryParam))) return;

    const portoSelector = availablePorto.find(
      (item) => item.id === Number(portoIDQueryParam)
    );

    if (!portoSelector || !!chosenPorto) return;
    setChosenPorto(portoSelector);
  }, [portoIDQueryParam, availablePorto, setChosenPorto, chosenPorto]);

  /**
   * Prefilled default porto if no porto is chosen
   * By default, first non robo porto on the list should be chosen
   */
  useEffect(() => {
    // If auto rutin FF not ON then skip this useEffect
    if (!autoRutinFF) return;

    /**
     * If entry from prduct detail or product query param is given
     * Then set chosen porto to filtered porto
     */
    if (
      [
        'short_form_product_detail',
        'button_SIP_product_detail',
        'button_top_rd',
        'product_detail_my_investment',
      ].includes(sourceFrom || '') &&
      !promoId &&
      (!!chosenReksaDana || !!productIdQueryParam) &&
      !portoIDQueryParam
    ) {
      if (!productIdQueryParam) return;

      fetchProductById(productIdQueryParam)
        .then((res) => {
          const data = getSafely(['data', 'data'], res) as
            | ReksaDana
            | undefined;
          if (!data) return;
          setChosenReksaDana(data);

          const isUSDCurrentcy = data?.currency_exchange?.currency === 'USD';
          const isMixedRDN = data?.type === 'Campuran';
          const isRDNNotMoneyMarket = data?.type !== 'Pasar Uang';

          const filteredDataPorto = availablePorto?.filter((item) => {
            //hide robo portfolio when reksadana type is Campuran
            if (isMixedRDN && item?.robo === 1) return false;

            // hide robo portfolio for reksadana with internationl currency
            if (isUSDCurrentcy && item?.robo === 1) return false;

            if (
              !!data &&
              isRDNNotMoneyMarket &&
              item?.type?.name === 'Bibit Darurat'
            ) {
              return false;
            }

            return true;
          });

          /**
           * porto type id 4 is "Bibit Tabungan"
           */
          const portoTabungan = filteredDataPorto.find(
            (val) => val?.type?.id === 4
          );

          /**
           * set default porto Bibit Tangungan if exist
           * or first porto
           */
          const portoToSet = portoTabungan || filteredDataPorto[0];

          const currentLocation = history.location;
          const queryParams = new URLSearchParams(currentLocation.search);
          queryParams.set('chosenPorto', `${portoToSet?.id}`);

          setChosenPorto(portoToSet);

          if (!!portoToSet?.id) {
            history.replace({
              pathname: currentLocation.pathname,
              search: queryParams.toString(),
            });
          }

          return;
        })
        .catch(() => {});
    }

    if (!chosenPorto && !portoIDQueryParam && !promoId) {
      const filteredPortoSingle = [...availablePorto]?.filter(
        (data) => data?.robo === 0
      );

      if (isReinvestBonds) return setChosenPorto(filteredPortoSingle?.[0]);

      /**
       * porto type id 4 is "Bibit Tabungan"
       */
      const portoTabungan = availablePorto.find((val) => val?.type?.id === 4);

      /**
       * set default porto Bibit Tangungan if exist
       * or first porto
       */
      setChosenPorto(portoTabungan || availablePorto[0]);
    }
  }, [
    autoRutinFF,
    availablePorto,
    chosenPorto,
    chosenReksaDana,
    history,
    isReinvestBonds,
    portoIDQueryParam,
    productIdQueryParam,
    promoId,
    setChosenPorto,
    setChosenReksaDana,
    sourceFrom,
  ]);
};

/**  set default CHOSEN PRODUCT if chosenPortoProduct given by query param */
export const usePrefilledProduct = () => {
  const { setChosenReksaDana, setChosenPaymentMethod } =
    useRecurringContextFunction();

  const query = useQueryParams();
  const symbolProductQueryParam = query.get('chosenPortoProduct');

  useEffect(() => {
    if (!symbolProductQueryParam) return;

    fetchProductById(symbolProductQueryParam)
      .then((res) => {
        const data = getSafely(['data', 'data'], res) as ReksaDana | undefined;
        if (!data) return;
        setChosenReksaDana(data);

        if (data.currency_exchange?.currency === 'USD') {
          setChosenPaymentMethod({
            icon: 'https://bibitdev-staging.s3.amazonaws.com/master-bank/icons/asset-20221020152328.svg',
            key: 'manual',
            name: 'Bank BCA',
            autodebet: false,
            // NOTES: if change maxAmount or minAmount, please also update in ModalEditBuyFlexibleSIP.tsx
            maxAmount: 100000000000000000000,
            minAmount: 1000000,
            paymentType: 'Transfer Manual',
            isPrefillFromUpdateRecurring: true,
          });
        }
      })
      .catch(() => {});
  }, [setChosenPaymentMethod, setChosenReksaDana, symbolProductQueryParam]);
};

/** Set default amount investment value given by query param */
export const usePrefilledAmount = () => {
  const { setInvestmentValue } = useRecurringContextFunction();

  const query = useQueryParams();
  const amountQueryParam = query.get('amount');

  useEffect(() => {
    if (!isNumber(amountQueryParam)) return;

    setInvestmentValue(Number(amountQueryParam));
  }, [amountQueryParam, setInvestmentValue]);
};

interface PrefilledDateParams {
  useNextDay?: boolean;
}
/** Date prefilled */
export const usePrefilledDate = (params?: PrefilledDateParams) => {
  const [isFilled, setIsFilled] = useState(false);
  const { setChosenDate } = useRecurringContextFunction();
  const { stepPage } = useRecurringContextState();

  const query = useQueryParams();
  const dateNumber: string | null = query.get('date');

  const paramUrl = useParams<{ mode: string }>();

  const { useNextDay } = params || {};

  useEffect(() => {
    // do nothing if mode 'update'
    // do nothing if current step is final step'
    if (paramUrl.mode === 'update' || stepPage === 3 || isFilled) return;

    const defaultDate: number = useNextDay
      ? add(new Date(), { days: 1 }).getDate()
      : new Date().getDate();
    // If there is query param -> set the date from date query
    // If NOT -> set today or next day date as default date
    // isNumeric is used to prevent misleading date
    // Ex: ?date=one -> false
    // Ex: ?date=1 -> true
    if (dateNumber && isNumber(dateNumber)) {
      setChosenDate([Number(dateNumber)]);
    } else {
      setChosenDate([defaultDate]);
    }

    setIsFilled(true);
  }, [dateNumber, paramUrl, stepPage, setChosenDate, useNextDay, isFilled]);
};

/**
 * reset expired month when user update IF want to Reactivate recurring
 */
export const useResetExpiredMonth = () => {
  const { setExpiredDate } = useRecurringContextFunction();
  const query = useQueryParams();
  const reactivate = query.get('reactivate');

  useEffect(() => {
    if (!reactivate) return;

    setExpiredDate(undefined);
  }, [reactivate, setExpiredDate]);
};

export const usePrefillFreq = () => {
  const [isFilled, setIsFilled] = useState(false);
  const { setFrequencyRecurring } = useRecurringContextFunction();

  const query = useQueryParams();
  const freq = query.get('freq') || 'Bulanan';
  const reactivate = query.get('reactivate');
  const isReinvestSBN = query.get('action') === 'reinvestSBN';
  const promoId = query.get('promoId');

  useEffect(() => {
    if (isFilled || reactivate || isReinvestSBN || promoId) return;

    setFrequencyRecurring(freq);
    setIsFilled(true);
  }, [
    isFilled,
    freq,
    reactivate,
    isReinvestSBN,
    promoId,
    setFrequencyRecurring,
  ]);
};

/**
 * Prefilled into Context State that satifying component needs
 */
export const usePrefilledUpdateRecurring = ({
  isAccountLocked = false,
}: {
  isAccountLocked?: boolean;
}) => {
  const history = useHistory();
  const paramUrl = useParams<{ recurringid?: string; mode: string }>();

  const [isDateFilled, setIsDateFilled] = useState(false);

  const { data: rdnDetailData } = useFetchRdnDetail();
  const tradingStatus = rdnDetailData?.data?.data?.trading_status;

  const recurringId = paramUrl.recurringid;
  const mode = paramUrl.mode;
  const query = useQueryParams();
  const reactivate = query.get('reactivate');
  const amount = query.get('amount');

  const { data } = useFetchQueryDetailRecurring(
    `${recurringId}`,
    !!recurringId && mode === 'update'
  );

  const dataRecurring = data?.data?.data;
  const currencyProduct =
    dataRecurring?.products[0]?.currency_exchange?.currency;

  const {
    setChosenDate,
    setChosenDay,
    setExpiredDate,
    setChosenPaymentMethod,
    setChosenPorto,
    setChosenReksaDana,
    setInvestmentValue,
    setFrequencyRecurring,
    setConvenienceFee,
    setIsFlexible,
  } = useRecurringContextFunction();

  /**
   * Helper preffilled
   *
   * - chosen Date
   * - Chosen Day
   */
  const preFilledRecurringType = useCallback(
    (freqType: 'Harian' | 'Bulanan' | 'Mingguan') => {
      if (!dataRecurring) return;

      switch (freqType) {
        case 'Bulanan':
          if (!isDateFilled) setChosenDate([dataRecurring.date]);
          break;
        case 'Harian':
          if (!isDateFilled) setChosenDate([1]);
          break;
        case 'Mingguan':
          setChosenDay(daysWeekDeserialize(dataRecurring.date) ?? '');
          break;
        default:
          break;
      }
      setIsDateFilled(true);
    },
    [dataRecurring, setChosenDate, setChosenDay, isDateFilled]
  );

  /**
   * Prefilled all data that component needs
   */
  useEffect(() => {
    if (mode === 'update' && !recurringId) {
      history.replace('/recurring/create');
    }

    if (mode === 'create' && amount) {
      setInvestmentValue(+amount);
    }

    if (!dataRecurring) return;
    const isPortoRobo = !!dataRecurring.portfolio.roboid;

    if (
      !isPortoRobo ||
      (isPortoRobo && dataRecurring?.products?.length === 1)
    ) {
      const products = getSafely(['products', 0], dataRecurring);
      setChosenReksaDana(products);
    }

    setChosenPorto({
      ...dataRecurring.portfolio,
      robo: dataRecurring.portfolio.roboid ? 1 : 0,
    });

    setIsFlexible(!dataRecurring.is_auto_debet);

    if (!amount) {
      setInvestmentValue(dataRecurring.amount);
    }

    const recurringType = scheduleTypeDeserialize(dataRecurring.recurring_type);

    const convenienceFee = dataRecurring?.convenience_fee ?? 0;

    const investedAmount = dataRecurring?.amount ?? 0;

    const maxAmount = dataRecurring?.payment_max_amount ?? 0;

    // Prefilled Frequency Recurring
    if (dataRecurring.payment_type_name !== 'Instant Payment') {
      // Force user to choose bulanan if payment type is not Instant Payment
      setFrequencyRecurring('Bulanan');
    } else {
      // Otherwise use based on recurringType
      setFrequencyRecurring(recurringType);
    }

    // Prefilled setChosenDay & setChosenDate
    preFilledRecurringType(recurringType);

    // filled chosenMonth when expired_date exist and `reactivate`-queryparam not exist
    if (dataRecurring.expired_date && !reactivate) {
      setExpiredDate(parseISO(dataRecurring.expired_date));
    }

    const isExceedMaxPaymentAmount =
      +(investedAmount + convenienceFee) <= +maxAmount;

    /** Guard to reset payment method, if payment type is Transfer Manual
     *  if payment type is Transfer manual will be reset the payment method
     */
    if (
      (dataRecurring.payment_type_name !== 'Transfer Manual' ||
        currencyProduct === 'USD') &&
      isExceedMaxPaymentAmount
    ) {
      if (dataRecurring.payment === 'jago' && isAccountLocked) {
        return;
      }
      // reset chosen payment method when trading status suspend
      if (tradingStatus && !tradingStatus?.details?.can_withdraw) {
        return;
      }
      setChosenPaymentMethod({
        icon: dataRecurring.payment_icon,
        name: dataRecurring.payment_name,
        key: dataRecurring.payment,
        autodebet: dataRecurring.is_auto_debet === 1,
        maxAmount: isNumber(dataRecurring.payment_max_amount)
          ? Number(dataRecurring.payment_max_amount)
          : 0,
        minAmount: isNumber(dataRecurring.payment_min_amount)
          ? Number(dataRecurring.payment_min_amount)
          : 0,
        paymentType: dataRecurring.payment_type_name,
        convenienceFeeOutcome:
          dataRecurring?.payment_config?.convenience_fee_outcome,
        isPrefillFromUpdateRecurring: true,
        convenienceFee: dataRecurring?.payment_config?.convenience_fee,
      });
      setConvenienceFee(convenienceFee);
    }
  }, [
    tradingStatus,
    dataRecurring,
    preFilledRecurringType,
    reactivate,
    setChosenDate,
    setExpiredDate,
    setChosenPaymentMethod,
    setChosenPorto,
    setChosenReksaDana,
    setFrequencyRecurring,
    setInvestmentValue,
    setConvenienceFee,
    recurringId,
    mode,
    amount,
    history,
    currencyProduct,
    isAccountLocked,
    setIsFlexible,
  ]);
};

export const useRecurringCreationSearchParams = (): SearchParamCreation => {
  const query = useQueryParams();
  const sAmount = query.get('amount');
  const sSerieId = query.get('serieId');
  const showSinglePortoOnly = query.get('showSinglePortoOnly');

  return {
    action: query.get('action') || undefined,
    amount: !!sAmount ? Number(sAmount) : undefined,
    autodebet: query.get('autodebet') || undefined,
    candidateDefaultPorto: query.get('candidateDefaultPorto') || undefined,
    chosenPorto: query.get('chosenPorto') || undefined,
    chosenPortoProduct: query.get('chosenPortoProduct') || undefined,
    date: query.get('date') || undefined,
    dueDate: query.get('dueDate') || undefined,
    dayDate: query.get('dayDate') || undefined,
    promoId: query.get('promoId') || undefined,
    reactivate: !!query.get('reactivate'),
    serieId: !!sSerieId ? Number(sSerieId) : undefined,
    serieType: query.get('serieType') || undefined,
    showSinglePortoOnly:
      showSinglePortoOnly && [0, 1].includes(Number(showSinglePortoOnly))
        ? (Number(showSinglePortoOnly) as 0 | 1)
        : undefined,
    source: query.get('source') || undefined,
    isFlexible: query.get('isFlexible') === 'true',
    isReinvestMaturity: query.get('isReinvestMaturity') || undefined,
  };
};
