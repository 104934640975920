import { useStorageCurrentInstutiton } from 'features/institution/hooks';
import { getRecurringListPorto } from 'features/recurring/networks/services';
import { useQuery } from 'react-query';

const useFetchListRecurringPorto = (
  portoID: string,
  enable: boolean = true
) => {
  const { data, isFetching } = useStorageCurrentInstutiton();
  const inInstitutionAccount = !!data?.institution_id;

  const enableFetching = enable && !isFetching && !inInstitutionAccount;

  return useQuery(
    ['Recurring Porto List', portoID],
    () => getRecurringListPorto(portoID),
    {
      enabled: enableFetching,
    }
  );
};

export default useFetchListRecurringPorto;
