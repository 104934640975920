import React, { useEffect, useState } from 'react';
import { PinChallengePage } from 'features/pin-protection';
import { useHistory } from 'react-router-dom';
import { checkTokenExpiration } from 'core/refresh-token';
import difference from 'lodash/difference';
import { apiInstance } from 'core/http';
import { findScopeByPathname } from 'core/token/UserAccessScope';
import { getTokenScope } from 'core/token/token';
import { useUserInInstitution } from 'features/institution/hooks';

interface Props {
  /** if its true, then we need to consider PIN */
  forceShowPin?: boolean;
}

/**
 * Component that give extra validation PIN for PrivateRoute.
 *
 */
const PinProtectionRoute: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  forceShowPin,
}) => {
  const institution = useUserInInstitution();
  // tell us is user has input pin by Local State
  const [hasInputPinLocalState, setHasInputPinLocalState] = useState(false);

  const history = useHistory<{ hasInputPin?: boolean; securekey?: string }>();

  // check `hasInputPin` in history location state
  // this hasInputPin passed by PinChallengeShouldShow
  const hasInputPin = history?.location?.state?.hasInputPin;

  /*
    1. Get token from API Instance.
    2. Check expiration token. If expired, then forceShowPin
    3. If token is not expired, check if the scope is correct with the mapping.
    4. If scope is correct, no need to show pin.
  */
  const validateUserScope = () => {
    const token = apiInstance.defaults.headers.common[
      'Authorization'
    ] as string;

    if (!token) {
      return false;
    }

    // check token expiration every request come out
    const tokenExp = checkTokenExpiration(token);
    if (tokenExp) {
      return false;
    }

    const scope = getTokenScope(token);
    if (!scope) {
      return false;
    }

    const scopeStrToArray = scope.split(' ');
    const userDefinedAccess = findScopeByPathname(
      history.location.pathname,
      institution
    );
    const isValidScope =
      userDefinedAccess &&
      difference(scopeStrToArray, userDefinedAccess.scope).length === 0;

    return !!isValidScope;
  };

  const hasValidScope = validateUserScope();

  /**
   * tell use should show pin in particular route
   *
   */
  const shouldShowPin =
    !hasInputPinLocalState && !hasInputPin && forceShowPin && !hasValidScope;

  const handleSuccessPin = (token?: string) => {
    history.replace({
      pathname: history.location.pathname,
      state: {
        ...history.location?.state,
        securekey: token,
      },
      search: history.location.search,
    });
    setHasInputPinLocalState(true);
  };

  const handlePinback = () => {
    history.goBack();
  };

  /**
   * handling every `pathname` is changes
   */
  useEffect(() => {
    // then we set hasinput tobe false
    setHasInputPinLocalState(false);
  }, [history.location.pathname]);

  if (shouldShowPin) {
    return (
      <PinChallengePage
        onSuccessPin={handleSuccessPin}
        showForgotPin
        showSupport
        renderOnBack
        onBack={handlePinback}
      />
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default PinProtectionRoute;
