import React from 'react';
import ImageWithFallback from 'features/common/ProductDetail/Image/ImageWithFallback';
import StockAvatarContainer from '../StockAvatarContainer/StockAvatarContainer';

interface StockIconProps {
  iconUrl?: string;
  symbol?: string;
  fontSize?: string;
  hideBackground?: boolean;
}

const StockIcon = ({ iconUrl, symbol = '', fontSize, hideBackground = false }: StockIconProps) => {
  return (
    <StockAvatarContainer hideBackground={hideBackground}>
      <ImageWithFallback
        src={iconUrl}
        alt={symbol}
        fallback={symbol}
        fontSize={fontSize}
      />
    </StockAvatarContainer>
  );
};

export default StockIcon;
