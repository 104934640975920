export const translation = {
  upload_gambar: 'Sedang mengupload Gambar',
  ambil_foto: 'Ambil Foto',
  diawasi_ojk:
    'Bibit telah berizin dan diawasi OJK (Otoritas Jasa Keuangan) sebagai APERD (Agen Penjual Reksa Dana) dengan nomor',
  lanjutkan_registrasi_kamu: 'Yuk Lanjutkan Registrasi Kamu',
  mengunggah: 'Mengunggah…',
  lanjutkan: 'Lanjutkan',
  foto_kamera: 'Foto dari Kamera',
  cek_keakuratkan_ktp: 'Cek Keakuratan Data KTP Kamu',
  nik: 'NIK',
  dokumen_foto: 'Dokumen Foto',
  foto_ktp: 'Foto KTP',
  rekening_bank_diperlukan: 'Rekening bank diperlukan untuk pencairan danamu',
  nama_bank: 'Nama Bank',
  nomor_rekening: 'Nomor Rekening',
  melihat_nomor_rekening: 'Dimana saya bisa melihat nomor rekening?',
  panduan_melihat_rekening: 'Panduan Melihat Nomor Rekening',
  pastikan_nomor_tertera:
    'Pastikan nomor yang kamu masukkan adalah nomor rekening yang tertera di bank, <strong>bukan nomor ATM.</strong>',
  nomor_kartu_atm: 'Nomor Kartu ATM',
  buku_tabungan: 'di Buku Tabungan',
  bank_digital: 'Aplikasi Bank Digital',
  mobile_banking: 'Aplikasi Mobile Banking',
  nama_pemilik_rekening: 'Nama Pemilik Rekening',
  pastikan_nama_rekening:
    'Pastikan rekening yang kamu gunakan atas nama kamu bukan orang lain.',
  belum_punya_rekening: 'Belum Punya Rekening Bank?',
  belum_memiliki_bank: 'Belum memiliki bank?',
  kenapa_harus_jago: 'Kenapa harus Bank Jago?',
  perlu_rekening_bank:
    'Kamu perlu rekening bank untuk bisa memulai investasi di Bibit berdasarkan ketentuan OJK.',
  kamu_setuju_buka_rekening:
    'Dengan klik Lanjutkan, kamu setuju untuk buka rekening <strong>Bank Jago</strong> di akhir registrasi Bibit.',
  bank_partner: 'Bank Partner Gojek dan Bibit',
  bank_digital_berkolaborasi:
    'Bank digital berkolaborasi bareng Gojek dan Bibit untuk membantu kamu atur keuangan',
  gratis_biaya_bulanan: 'Gratis Biaya Bulanan Bank Jago',
  buka_rekening: 'Hanya 4 Menit Untuk Buka Rekening ',
  buka_akun_bank_jago:
    'Buka akun bank Jago tanpa ribet hanya melalui ponsel kamu',
  banyak_keuntungan: 'Banyak Keuntungan di Bibit dengan Jago',
  transaksi_mudah_instan:
    'Transaksi mudah tanpa pindah aplikasi. Investasi disiplin dengan fitur autodebit dan pencairan instan',
  gratis_biaya_admin:
    'Gratis biaya admin dan gratis transfer antar bank hingga <1>25 transaksi/ bulan</1>',
  transaksi_mudah:
    'Transaksi mudah tanpa pindah aplikasi. Investasi disiplin dengan fitur autodebit',
  konfirmasi_bank: 'Konfirmasi Rekening Bank',
  nama_ktp: 'Nama di KTP',
  pastikan_data_benar:
    'Pastikan data yang kamu isi benar karena akan mempengaruhi proses pencairan dana.',
  tanda_tangan_digital: 'Berikan Tanda Tangan Digital',
  tanda_tangan_ktp:
    'Pastikan tanda tangan kamu sama dengan yang ada di KTP kamu.',
  tanda_tangan_disini: 'Silakan gambar tanda tangan digital di sini',
  syarat_ketentuan_reksadana:
    'Saya telah membaca dan menyetujui <1>Kebijakan Privasi</1> serta <4>syarat dan ketentuan</4> Pembukaan Rekening Efek Reksa Dana dan Transaksi Online Reksa Dana di PT Bibit Tumbuh Bersama',
  mengunggah_tanda_tangan: 'Mengunggah Tanda Tangan.…',
  memproses_pendaftaran: 'Memproses Pendaftaran…',
  daftar_sekarang: 'Daftar Sekarang',
  langkah_terakhir: 'Langkah Terakhir',
  buat_pin: 'Yuk Buat PIN Kamu',
  setup_pin: 'Setup PIN',
  konfirmasi_pin: 'Konfirmasi PIN',
  pendaftaran_berhasil: 'Pendaftaran Berhasil!',
  proses_verifikasi_data:
    'Mohon tunggu proses verifikasi data agar kamu bisa mulai berinvestasi. Estimasi selesai pada',
  segera_verifikasi_email: 'Segera Verifikasi Email Kamu',
  verifikasi_email_mulai_investasi:
    'Segera Verifikasi Email Kamu untuk Memulai Investasi',
  harap_cantumkan_email: 'Harap cantumkan email kamu sebelum verifikasi',
  kami_telah_mengirimkan: 'Kami telah mengirimkan email verifikasi ke',
  mulai_verifikasi: 'Mulai Verifikasi Email',
  cek_email: 'Cek Email',
  ganti_email: 'Ganti Alamat Email',
  belum_menerima_email: 'Belum menerima email?',
  kirim_ulang: 'Kirim Ulang',
  kirim_ulang_email: 'Kirim Ulang Email',
  verifikasi_mulai_investasi: 'Verifikasi Email untuk Memulai Investasi',
  verifikasi_email_kamu: 'Verifikasi Email oleh Kamu',
  verifikasi_jago: 'Verifikasi Bank Jago',
  verifikasi_bibit: 'Verifikasi oleh Bibit',
  verifikasi_ksei: 'Verifikasi oleh KSEI',
  dalam_proses_verifikasi:
    'Pendaftaran Bibit Kamu Sedang Dalam Proses Verifikasi. Estimasi Selesai:',
  masukkan_email_baru: 'Masukan Alamat Email Baru Anda',
  email_berhasil_dikirim:
    'Email Verifikasi Berhasil Dikirim. Silakan Cek Email Anda.',
  ubah_email: 'Ubah Email',
  sudah_mengirimkan_email: 'Bibit sudah mengirimkan email verifikasi ke:',
  cek_email_kamu: 'Silakan cek email kamu untuk melakukan verifikasi email.',
  data_diri: 'Isi Data Diri',
  isi_email: 'Isi Email',
  selfie_ktp: 'Foto Selfie dengan KTP',
  isi_data_ktp: 'Isi Data KTP',
  isi_data_bank: 'Isi Data Bank',
  tanda_tangan: 'Tanda Tangan Digital',
  selesai_registrasi: 'Selesai Registrasi',
  Lainnya: 'Lainnya',
  '- Pilih -': '- Pilih -',
  ambil_ulang: 'Ambil Ulang',
  peraturan_ojk:
    'Kami hanya meminta data sesuai peraturan OJK. Data kamu di enkripsi dan tidak akan diberikan pada pihak ketiga tanpa persetujuan kamu.',
  mohon_tunggu: 'Mohon Tunggu',
  dua_juta: 'Rp2 juta',
  lima_juta: 'Rp5 juta',
  tiga_juta: 'Rp3 juta',
  data_terenkripsi: 'Data Aman & Terenkripsi',
  uang_tersimpan_aman: 'Uangmu Tersimpan Aman di Bank Kustodian',
  pencairan_nama_rekening: 'Pencairan Hanya Atas Nama Rekeningmu',
  dana_disimpan_aman:
    'Dana yang kamu investasikan akan sepenuhnya disimpan secara aman di Bank Kustodian dan dapat dicairkan kapan saja.',
  dana_investasi_dicairkan:
    'Dana investasimu hanya bisa dicairkan ke rekening atas nama kamu yang tercatat di KTP.',
  pilih_bank: 'Pilih Bank',
  bank_atas_nama_sendiri:
    'Pastikan bank yang kamu pilih adalah rekening bank atas nama kamu sendiri 👌',
  melanjutkan: 'Lanjutkan',
  investasi_bulanan_error_msg: 'Investasi bulanan harus diisi lebih dari 0',
  investasi_awal_error_msg: 'Investasi awal harus diisi',
  'Jual sebagian': 'Jual sebagian',
  'Simpan semua': 'Simpan semua',
  'Beli lagi': 'Beli lagi',
  'Keputusan kamu di saat kondisi pasar naik turun sangat penting untuk membantu kami mempelajari toleransi kamu terhadap risiko.':
    'Keputusan kamu di saat kondisi pasar naik turun sangat penting untuk membantu kami mempelajari toleransi kamu terhadap risiko.',
  profil_risiko: 'Profil Risiko',
  simulasikan_investasimu:
    'Simulasikan investasimu di Bibit dan lihat seberapa besar uang kamu dapat bertumbuh',
  lanjut_registrasi: 'Lanjut Registrasi',
  ulangi_pertanyaan: 'Ulangi Pertanyaan',
  investor_moderat:
    'Tipe investor yang mempunyai toleransi terhadap risiko yang tidak terlalu tinggi dimana kamu mengharapkan return diatas suku bunga deposito dengan fluktuasi nilai pasar yang moderat.',
  investor_aggresif:
    'Tipe investor yang mempunyai toleransi terhadap risiko yang cenderung tinggi dimana kamu dapat menerima fluktuasi pasar yang tinggi untuk mencapai return tertinggi.',
  investor_conservatif:
    'Tipe investor yang mempunyai toleransi terhadap risiko yang cenderung rendah dimana kamu mengharapkan return yang minimal setara dengan suku bunga deposito dengan fluktuasi nilai pasar yang minimal.',
  konservatif: 'Konservatif',
  moderat: 'Moderat',
  agresif: 'Agresif',
  deskripsi_pasar_uang:
    'Alokasi investasi ditempatkan 100% ke instrumen Pasar Uang, seperti deposito bank dan Sertifikat Bank Indonesia (SBI).',
  deskripsi_obligasi:
    'Sebagian besar alokasi investasi ditempatkan pada efek, seperti utang dan obligasi.',
  deskripsi_saham:
    'Sebagian besar alokasi investasinya ditempatkan pada saham perusahaan yang terdaftar di Bursa Efek.',
  alokasi_dana_otomatis:
    'Teknologi kami secara otomatis akan membagi uang kamu ke 3 reksa dana yang berbeda tergantung jawaban kamu.',
  alokasi_dana_rekomendasi:
    'Jika rekomendasi kami adalah 20% Pasar Uang, 50% Obligasi, 30% Saham, maka jika kamu investasikan Rp10 juta, nilai investasi kamu akan dibagi menjadi :',
  isi_data_diri: 'Isi Data Dirimu Yuk!',
  pendidikan_terakhir: 'Pendidikan Terakhir',
  pilih_pendidikan_terakhir: 'Pilih Pendidikan Terakhir',
  SD: 'SD',
  SMP: 'SMP',
  SMA: 'SMA',
  Diploma: 'Diploma',
  S1: 'S1',
  S2: 'S2',
  S3: 'S3',
  lainnya: 'Lainnya',
  pendapatan_per_tahun: 'Pendapatan Per Tahun',
  '< Rp10 juta per tahun': '< Rp10 juta per tahun',
  'Rp10-50 juta per tahun': 'Rp10-50 juta per tahun',
  '> Rp50-100 juta per tahun': '> Rp50-100 juta per tahun',
  '> Rp100-500 juta per tahun': '> Rp100-500 juta per tahun',
  '> Rp500 juta-1 miliar per tahun': '> Rp500 juta-1 miliar per tahun',
  '> Rp1 miliar per tahun': '> Rp1 miliar per tahun',
  sumber_penghasilan: 'Sumber Penghasilan',
  Gaji: 'Gaji',
  'Keuntungan Bisnis': 'Keuntungan Bisnis',
  'Bunga Simpanan': 'Bunga Simpanan',
  Warisan: 'Warisan',
  'Orang Tua/Anak': 'Orang Tua/Anak',
  Undian: 'Undian',
  Pasangan: 'Pasangan',
  Tabungan: 'Tabungan',
  'Hasil Investasi': 'Hasil Investasi',
  nama_gadis_ibu: 'Nama Gadis Ibu Kandung',
  isi_email_kamu: 'Isi E-Mail Kamu Yuk!',
  alamat_email: 'Alamat Email',
  foto_ktp_kamu: 'Foto KTP Kamu',
  foto_jelas_terbaca:
    'Pastikan hasil foto kamu jelas terbaca untuk keperluan verifikasi identitas kamu.',
  salah: 'Salah',
  benar: 'Benar',
  foto_ktp_pembelian_reksadana:
    'Foto KTP dibutuhkan untuk pembelian reksadana sesuai dengan peraturan OJK.',
  ambil_dari_gallery: 'Ambil dari Gallery',
  ojk: 'OJK',
  keamanan: 'Keamanan',
  foto_buram: 'Foto Buram',
  pantulan_cahaya: 'Pantulan Cahaya',
  foto_terpotong: 'Foto Terpotong',
  foto_jelas: 'Foto Jelas',
  hasil_foto_memenuhi: 'Pastikan hasil foto memenuhi ketentuan:',
  ktp_sesuai_identitas: 'KTP sesuai dengan identitas kamu',
  ktp_tidak_silau: 'KTP tidak silau & tidak buram',
  ktp_terbaca_jelas: 'KTP terbaca jelas dan tidak terpotong ',
  tidak_ada_objek: 'Tidak ada objek lain selain KTP dalam foto',
  ulangi: 'Ulangi',
  harap_tunggu: 'Harap Tunggu',
  foto_selfie_ktp: 'Foto Selfie dengan KTP Kamu',
  pastikan_wajah_dan_ktp:
    'Pastikan wajah dan KTP kamu jelas terlihat untuk memastikan KTP kamu benar milik kamu.',
  selfie_dengan_ktp:
    'Selfie dengan KTP dibutuhkan untuk pembelian reksadana sesuai dengan peraturan OJK.',
  foto_selfie: 'Foto Selfie Tanpa KTP',
  foto_berbayang: 'Foto Berbayang Tidak Terlihat',
  ktp_dan_wajah: 'KTP dan Wajah Terlihat Jelas',
  hasil_foto_kamu:
    'Apakah hasil foto kamu sudah memenuhi ketentuan dibawah ini?',
  pencahayaan_yang_baik:
    'Pencahayaan yang baik, tidak gelap, dan tanpa pantulan',
  ktp_dibawah_dagu: 'KTP dibawah dagu dan tidak menutupi wajah',
  wajah_tidak_tertutup:
    'Wajah tidak tertutup rambut, masker, topi, atau kacamata',
  wajah_dan_ktp: 'Wajah dan KTP terlihat jelas dan tidak buram',
  isi_data_rekening: 'Isi Data Rekening Bank Kamu ',
  nama_lengkap: 'Nama Lengkap',
  tanggal_lahir: 'Tanggal Lahir',
  kode_refferal: 'Kode Referral (Opsional)',
  kode_whatsapp:
    'Masukkan kode yang dikirim melalui Whatsapp ke nomor handphone kamu (+{{code}}) {{phone}}',
  kode_default:
    'Masukkan kode yang dikirim melalui SMS ke nomor handphone kamu',
  data_saya_aman: 'Apakah Data Saya Aman?',
  data_kamu_dienkripsi:
    'Data kamu dienkripsi dan tidak akan diberikan kepada pihak ketiga\ntanpa persetujuan kamu',
  selanjutnya: 'Selanjutnya',
  simulasi_investasi: 'Simulasi Investasi',
  investasi_awal: 'Investasi Awal',
  investasi_bulanan: 'Investasi Bulanan',
  tipe_investor: 'Tipe Investor',
  nilai_investasi_kamu: 'Nilai Investasi kamu di tahun',
  investasi_bibit: 'Investasi di Bibit',
  tabungan_biasa: 'Tabungan Biasa',
  simulasi_deskripsi:
    'Simulasi dibuat berdasarkan kinerja masa lalu, dan tidak menjamin kinerja di masa depan.',
  simpan_hasil: 'Simpan Hasil',
  sumber_penghasilan_lain: 'Sumber Penghasilan Lain',
  pengecekan_email: 'Pengecekan Email…',
  masukkan_nomor_hp: 'Masukkan Nomor HP Kamu',
  registrasi: 'Registrasi',
  kode_email: 'Masukkan kode yang dikirim melalui email kamu',
  kode_verifikasi: 'Masukkan Kode Verifikasi',
  melalui_sms_ke_nomor_hp:
    'Masukkan kode yang dikirim melalui SMS ke nomor handphone kamu (+{{code}}) {{phone}}',
  kirim_ulang_kode: 'Kirim Ulang Kode',
  verifikasi: 'Verifikasi',
  metode_pengiriman_verifikasi: 'Pilih Metode Pengiriman Kode Verifikasi',
  kirim_melalui: 'Kirim melalui {{type}}',
  profil_risiko_kamu: 'Ketahui Profil Risiko Kamu',
  robo_advisor:
    'Robo Advisor akan memberikan rekomendasi sesuai dengan data diri dan toleransi risiko kamu.',
  data_aman_terenkripsi: 'Data kamu aman dan terenkripsi',
  mulai: 'Mulai',
  'Kapan ulang tahun kamu?': 'Kapan ulang tahun kamu?',
  total_pertanyaan: '{{total}} dari 6 pertanyaan',
  'Apakah kamu sudah menikah dan memiliki tanggungan keluarga?':
    'Apakah kamu sudah menikah dan memiliki tanggungan keluarga?',
  'Belum, masih single': 'Belum, masih single',
  'Sudah menikah, belum punya tanggungan':
    'Sudah menikah, belum punya tanggungan',
  'Sudah menikah, anak 1': 'Sudah menikah, anak 1',
  'Sudah menikah, anak 2 atau lebih': 'Sudah menikah, anak 2 atau lebih',
  'Sudah pensiun, atau sudah mapan keuangan':
    'Sudah pensiun, atau sudah mapan keuangan',
  'Berapa pendapatan bulanan dalam rumah tangga kamu?':
    'Berapa pendapatan bulanan dalam rumah tangga kamu?',
  'Termasuk penghasilan istri/suami, pendapatan sewa dll':
    'Termasuk penghasilan istri/suami, pendapatan sewa dll',
  'Berapa nilai total kekayaan kamu?': 'Berapa nilai total kekayaan kamu?',
  'Dapat berupa kas, deposito, properti, emas, saham atau reksa dana.':
    'Dapat berupa kas, deposito, properti, emas, saham atau reksa dana.',
  'Apa yang lebih kamu utamakan dalam berinvestasi?':
    'Apa yang lebih kamu utamakan dalam berinvestasi?',
  'Maksimalkan keuntungan': 'Maksimalkan keuntungan',
  'Menghindari kerugian': 'Menghindari kerugian',
  'Keduanya sama penting': 'Keduanya sama penting',
  'Jika nilai investasi kamu turun 15% dalam sebulan dengan keadaan pasar yang tidak menentu, apa yang akan kamu lakukan?':
    'Jika nilai investasi kamu turun 15% dalam sebulan dengan keadaan pasar yang tidak menentu, apa yang akan kamu lakukan?',
  'Jual semua': 'Jual semua',
};
