import React, {
  useState,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';

interface TooltipProps<T> {
  tooltipData?: T | T[] | null;
  tooltipTop: number | number[];
  tooltipLeft?: number | number[];
}

interface TooltipContextProps<T> {
  tooltip: TooltipProps<T>;
  setTooltip: Dispatch<SetStateAction<TooltipProps<T>>>;
}

const initialTooltip = {
  tooltipData: null,
  tooltipTop: 0,
  tooltipLeft: 0,
};

const TooltipContext = createContext<TooltipContextProps<any>>({
  tooltip: initialTooltip,
  setTooltip: () => null,
});

export const TooltipProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [tooltip, setTooltip] = useState<TooltipProps<any>>(initialTooltip);

  return (
    <TooltipContext.Provider value={{ tooltip, setTooltip }}>
      {children}
    </TooltipContext.Provider>
  );
};

function useTooltip<T>() {
  const { tooltip, setTooltip } = useContext(TooltipContext);

  const hideTooltip = () => {
    setTooltip(initialTooltip);
  };

  const showTooltip = (value: TooltipProps<T>) => {
    setTooltip(value);
  };

  return {
    hideTooltip,
    showTooltip,
    ...tooltip,
  };
}

export default useTooltip;
