import React from 'react';
import { TableDefinitionConfig } from '../StockTable.type';
import { ProfitLossContainer, ProfitLossWrapper } from '../StockTable.styled';
import { ArrowDownRight, ArrowUpRight } from 'react-feather';
import { Text } from '@bibitid/uikit-v1';
import { numberToGeneralValueFormat } from 'utils/stringHelper';

type Props = {
  config: TableDefinitionConfig;
  percentage?: number | string;
  value?: number | string;
};

const TableBodyProfitLoss: React.FC<Props> = ({
  config,
  percentage,
  value,
}) => {
  const isBalance = Number(percentage) === 0;
  const isProfit = Number(percentage) > 0;
  const Arrow = isProfit ? ArrowUpRight : ArrowDownRight;
  const textColor = isBalance
    ? 'primary'
    : isProfit
    ? 'greenDefault'
    : 'redDefault';

  return (
    <ProfitLossContainer {...config}>
      <Text type='body2'>
        Rp{numberToGeneralValueFormat(Number(value ?? 0))}
      </Text>
      <ProfitLossWrapper>
        {!isBalance && (
          <Arrow
            color={isProfit ? 'var(--green-default)' : 'var(--red-default)'}
            size={16}
          />
        )}
        <Text type='body14r' color={textColor}>
          {percentage}%
        </Text>
      </ProfitLossWrapper>
    </ProfitLossContainer>
  );
};

export default TableBodyProfitLoss;
