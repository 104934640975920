/**
 * Get Initial Name from Fullname.
 * Fullname just a string without emoji
 *
 * Example:
 * - Anna Hero > AH
 * - Anna > AN
 * - 628123456789 > 62
 */
export const getInitialName = (fullname: string) => {
  const firstTextInitial =
    fullname.split(' ')?.[0]?.substring(0, 1)?.toLocaleUpperCase() ?? '';

  const secondTextInitial =
    fullname.split(' ')?.[1]?.substring(0, 1)?.toLocaleUpperCase() ?? '';

  // return 2 char from first text when second text is empty
  if (!secondTextInitial) {
    return fullname.split(' ')?.[0]?.substring(0, 2)?.toLocaleUpperCase() ?? '';
  }

  return firstTextInitial + secondTextInitial;
};

export const getFullNameFormatted = (fullname: string) => {
  return fullname?.length > 20
    ? `${fullname.substring(0, 20)}...`
    : fullname ?? '-';
};
