import { generalLogInfo } from 'utils/Analytics';
import { getInstance } from '../CallbackMapper';

import type { Mapper } from '../CallbackMapper';
import type { MessagePayloadType } from '../types';
import { isJSON } from 'utils/validator';

interface CustomMessageEvent extends Event {
  detail?: string;
}

const callbackMapper: Mapper = getInstance();

/**
 * onMessage handler to execute callback in callback-mapper instance
 *
 * common steps:
 * - filter message response
 * - use key - callback mapper to retrieve target callback
 * - execute callback
 *
 * @param {CustomMessageEvent} resp object custom MessageEvent from Native postMessage
 */
export function onMessageReceiver(resp: CustomMessageEvent) {
  const { detail } = resp;

  // prevent parse when detail is not string
  if (typeof detail !== 'string') return detail;

  // if not JSON-Sring, do nothing
  if (!isJSON(detail)) return;

  let dataobj: MessagePayloadType = JSON.parse(detail);
  let { id, data } = dataobj;

  try {
    const cb: Function = callbackMapper.get(id);

    cb(null, data);
    callbackMapper.remove(id); // remove function from stackFunction
  } catch (error) {
    generalLogInfo('INFO: Catching error in onMessageReceiver', {
      file: 'Receiver.v3.js',
      function: 'onMessageReceiver',
      version: 'v3',
      data: detail,
      rawError: error,
      isJson: isJSON(detail),
      dataTag: {
        ...(id ? { postmessage_id: id } : {}),
      },
    });
  }
}

/**
 * IMPORTANT, EXECUTE IN APP BOOTING !
 * Start listener Native through 'Window' and 'Document'
 */
export function start() {
  /**
   * Attach listener
   */
  window.addEventListener('bibitnativebridge', onMessageReceiver);
}

export function removeStart() {
  /**
   * Remove listener
   */
  window.removeEventListener('bibitnativebridge', onMessageReceiver);
}
