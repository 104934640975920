import { useState, useEffect, useCallback } from 'react';
import useReactWebSocket from 'react-use-websocket';

import { getAuthWSKey } from '../networks/services';
import Storage from 'core/Storage';
import {
  createRandomWSPath,
  parserWSMessageData,
  isMessageDataType,
} from '../utils/stockbitWSHelper';
import {
  LivePriceData,
  OrderBookLiveData,
  RandomStringFormat,
} from 'features/stockbitWS/types/stocbitWSTypes';

import useAuth from '../../../hooks/useAuth';
import { getEnv } from 'core/env';
import useBibitPlusUpgradeStatus from 'features/bibitplus/hooks/useBibitPlusUpgradeStatus';

/**
 * WS Open Channel
 * - Live Price Feed (C)
 * - Order Book (O)
 */
enum SubscriptionChannel {
  livePrice = 'C',
  orderBook = 'O',
}

interface WebSocketHook {
  message: { [key: string]: any } | undefined;
  error: string | undefined;
  loading: boolean;
  connectionMessage: string | undefined;
}

export interface WSChannelProps {
  livePrice?: string[];
  orderBook?: string[];
}

interface MessageResponse {
  livePrice: LivePriceData | undefined;
  orderBook: OrderBookLiveData | undefined;
}

const { WsStockbitInterval, WsStockbitUrl } = getEnv();

const WS_INTERVAL: number = +WsStockbitInterval! || 7000;

const useStockbitWS = ({
  channels,
}: {
  channels: WSChannelProps;
}): WebSocketHook => {
  const { isLogin } = useAuth();

  const [wskeyState, setWskeyState] = useState<string>();
  const [wsError, setWsError] = useState<string | undefined>();
  const [wsLoading, setWsLoading] = useState<boolean>(true);
  const [wsMessage, setWsMessage] = useState<MessageResponse | undefined>();
  const [wsConnectionMessage, setWsConnectionMessage] = useState<
    string | undefined
  >();

  const { statusNumber } = useBibitPlusUpgradeStatus();

  const userUpgradeStatus = statusNumber || 0;
  const isBibitPlusUser: boolean = userUpgradeStatus === 4;
  const isChannelsEmpty =
    !channels.livePrice?.length && !channels.orderBook?.length;

  /**
   * Allowed user for connecting the Stockbit websocket.
   */
  const isEligibleUseWS: boolean =
    isLogin && isBibitPlusUser && !isChannelsEmpty;

  /**
   * Async function for generating ws URL and initiating wskey credential
   * Returning promise function to handling socket connection from react-use-websocket library
   */
  const generateWSURL = useCallback((): Promise<string> => {
    return new Promise(async (resolve) => {
      const accessToken = await Storage.getAccessToken();

      const firstPath = createRandomWSPath(3, RandomStringFormat.Num);
      const secondPath = createRandomWSPath(8, RandomStringFormat.Mix);

      /**
       * Fetch websocket credential from bibit API
       */
      getAuthWSKey(accessToken)
        .then((res) => {
          const wsUrlParsed = `${WsStockbitUrl}/${firstPath}/${secondPath}/websocket`;

          const wskeRes = res.data?.data?.wskey;

          setWskeyState(wskeRes);
          resolve(wsUrlParsed);
        })
        .catch((err) => {
          setWsError(JSON.stringify(err));
        });
    });
  }, []);

  const { sendJsonMessage, getWebSocket } = useReactWebSocket(
    generateWSURL,
    {
      onOpen: () => setWsConnectionMessage('WebSocket connection open'),
      onClose: () => setWsConnectionMessage('WebSocket connection closed.'),
      onError: () => setWsError('WebSocket connection failed.'),
      onMessage: (event: WebSocketEventMap['message']) =>
        processMessages(event),
    },
    isEligibleUseWS
  );

  /**
   * Set initial payload ws message
   * for streaming subscription data
   */
  const fetchInitMessage = useCallback((): void => {
    if (isEligibleUseWS) {
      const messagePayload = {
        wskey: wskeyState,
        loggedInUser: 'bibit',
        chname: {
          [SubscriptionChannel.livePrice]: channels.livePrice,
          [SubscriptionChannel.orderBook]: channels.orderBook,
        },
      };
      sendJsonMessage(messagePayload);
    }
  }, [channels, wskeyState, isEligibleUseWS, sendJsonMessage]);

  /**
   * Parsing process from data streaming event message.
   */
  const processMessages = (event: any) => {
    const messageData = event?.data;

    if (wsLoading) {
      setWsLoading(false);
    }

    if (isEligibleUseWS && isMessageDataType(messageData)) {
      const response: MessageResponse = parserWSMessageData(messageData);

      setWsMessage((currentMessage: MessageResponse | undefined) => ({
        livePrice: response?.livePrice || currentMessage?.livePrice,
        orderBook: response?.orderBook || currentMessage?.orderBook,
      }));
    }
  };

  /**
   * Kill connection
   * when user status is not eligible
   */
  useEffect(() => {
    if (!isEligibleUseWS && getWebSocket) {
      getWebSocket()?.close();
      setWsConnectionMessage(
        'Websocket connection is only for bibit plus user'
      );
    }
  }, [isEligibleUseWS, getWebSocket]);

  /**
   * Send initial message to socket server
   * and retrieve authorization response
   */
  useEffect(() => {
    if (isEligibleUseWS && !!wskeyState) {
      fetchInitMessage();
    }

    return () => {
      setWskeyState('');
    };
  }, [fetchInitMessage, isEligibleUseWS, wskeyState]);

  /**
   * Client heartbeat
   * to implement ping server method
   */
  useEffect(() => {
    if (isEligibleUseWS) {
      const wsHeartbeatInterval = setInterval(() => {
        if (wskeyState) {
          const wsPingText: string[] = [
            JSON.stringify(`primus::ping::${new Date().getTime()}`),
          ];

          sendJsonMessage(wsPingText);
        }
      }, WS_INTERVAL);

      return () => {
        clearInterval(wsHeartbeatInterval);
      };
    }
  }, [isEligibleUseWS, wskeyState, sendJsonMessage]);

  return {
    error: wsError,
    loading: wsLoading,
    message: wsMessage,
    connectionMessage: wsConnectionMessage,
  };
};

export default useStockbitWS;
