import { getDeviceInfo } from 'core/DeviceInfo/deviceInfo';
import queryClient from 'network/queryClient';
import { useQuery } from 'react-query';
import { isInsideNativeContainer } from 'utils/validator';

/**
 * Hooks to get device info and installed Bibit App info
 */
const useDeviceInfo = () => {
  const isNative = isInsideNativeContainer();

  return useQuery(['getDeviceInfo'], getDeviceInfo, {
    enabled: isNative,
    onSuccess: (data) => {
      if (!!data) {
        queryClient.setQueryDefaults(['getDeviceInfo'], {
          staleTime: Infinity,
          cacheTime: Infinity,
        });
      }

      return data;
    },
  });
};

export default useDeviceInfo;
