import { AxiosResponse } from 'axios';
import { PremiumTransactionFee } from 'features/premium/types';

interface Meta {
  is_maintenance: number;
  updated_at: number;

  /**
   * @deprecated use cursor.next_cursor
   * if api has response this. request  to move next_cursor to cursor.next_cursor
   */
  next_cursor: any;

  /**
   * @deprecated use cursor.prev_cursor
   * if api has response this. request  to move next_cursor to cursor.prev_cursor
   */
  prev_cursor: any;
  sort_by?: string;
  sort_column?: number;
  stable_earn_category_id?: number;
  is_ifa_transaction?: number;
  transaction_fee?: PremiumTransactionFee;
  cursor?: {
    next_cursor?: any;
    prev_cursor?: any;
  };
}
export interface BibitApiSuccessResponse<T> {
  data: T;
  message: string;
  meta?: Meta;
}

export type BibitApiAxiosResponse<T> = Promise<
  AxiosResponse<BibitApiSuccessResponse<T>>
>;

const endpoints = {
  analyticAdd: '/analytic/add',
  articlesFeedGet: '/blogs',
  articlesFeedCategory: '/blogs/categories',
  awsToken: '/tools/s3/token',
  awsTokenV2: '/tools/s3/tokenV2',
  awsTokenV2NoAuth: '/tools/s3/tokenV2/noauth',
  bankList: '/tools/list/bank',
  cashbackBalance: '/cashback/balance',
  cashbackHistory: '/cashback/history',
  cashbackNotificationList: `/notifications?type=2&is_read=0&page=1&limit=10`,
  cashbackReadNotification: (id: number) => `/notifications/${id}/read`,
  referralFriends: '/user/referrals',
  remindFriends: '/user/referrals/reminds',
  referralInvestedPending: '/referral/invested/pending',
  orderSell: '/order/sell',
  roboSell: '/robo/sell',
  catatanCuan: '/user/profile/flashback',
  catatanCuan2021Mock: '/catatancuan',
  catatanCuan2021: '/user/performances',
  changePhoneInSetting: '/auth/change/phone/update',
  checkEmail: `/auth/check/email`,
  checkNIK: `/auth/check/ktp`,
  checkOcr: (jobId: string) => `/tools/ocr/${jobId}`,
  checkProductHasTrx: (symbol: string) => `/products/${symbol}/history`,
  checkProductHasTrxInstitution: (symbol: string) =>
    `/institutions/products/${symbol}/history`,
  checkTrustedDevice: `/auth/login/devices`,
  cityListByCountry: (country: string) => `/tools/list/city/${country}`,
  occupationList: '/tools/occupations',
  occupationSectorList: '/tools/occupation-sectors',
  countryList: '/tools/list/country',
  provinceList: '/tools/provinces',
  cityList: '/tools/cities',
  deactiveBank: '/user/bank/deactivate',
  education: '/tools/list/education',
  emailChangeRequest: '/auth/change/email/request',
  phoneChangeUpdate: '/auth/forget/phone/update',
  phoneChangeVerify: '/auth/change/phone/verify',
  phoneChangeRequest: '/auth/change/phone/request',
  pinDirectChange: '/auth/change/pin',
  pinChangeUpdate: '/auth/change/pin/update',
  pinChangeVerify: '/auth/change/pin/verify',
  pinChangeRequest: '/auth/change/pin/request',
  roboScoring: '/robo/scoring',
  logout: '/auth/logout',
  otpRequest: '/auth/otp/change-phone',
  otpRequestChangePin: '/auth/otp/change-pin',
  userProfile: '/user/profile?v=2',
  userProfilePersonal: '/user/profile/personal',
  userProfileUpdate: '/user/profile/update',
  userChangeBankVerify: '/user/change/bank/verify',
  userChangeBankUpdate: '/user/change/bank/update',
  userChangeBankUpdateLiveness: '/user/change/bank/update/liveness',
  userLivenessQuotas: '/user/liveness/quotas',
  userChangeBankRequest: '/user/change/bank/request',
  userSearchRemove: '/user/search/remove',
  userSearchHistory: '/user/search/history',
  notificationList: '/notification/list',
  notificationStats: '/notifications/stats',
  voucherSubmit: '/voucher/enter/profile',
  roboCheck: '/robo/check',
  religionList: '/tools/list/religion',
  userEddUpload: '/user/edd/upload',
  pinSetup: '/auth/pin/setup',
  pinCheck: '/auth/check/pin',
  login: '/login',
  loginNumber: '/auth/login/phone',
  loginOtp: '/auth/login/otp',
  registerOtp: '/auth/register/otp',
  registerBank: '/auth/register/bank',
  registerPhone: '/auth/register/phone',
  registerEmailChange: '/auth/register/email/change',
  referralCheck: '/referral/check',
  orderHistory: '/orders/history',
  institutionOrderHistory: '/institutions/orders/history',
  institutionRdOrderHistory: '/institutions/mutual-funds/orders/histories',
  institutionBondsOrderHistory: '/institutions/bonds/orders/histories',
  orderSellPreview: '/order/sell/preview',
  orderList: '/orders',
  institutionOrderList: '/institutions/orders',
  institutionRdOrderList: '/institutions/mutual-funds/orders',
  institutionBondsOrderList: '/institutions/bonds/orders',
  roboTemplate: '/robo/template?v=2',
  roboRegister: '/robo/register?v=2',
  roboPreview: '/robo/preview',
  ktpValidate: '/auth/ktp/check',
  userKtpValidate: '/user/check/ktp',
  taxRequest: '/user/report/tax',
  taxDetailTrx: '/user/report/trans',
  taxYearData: '/tools/list/tax-period',
  portfolioGoalTemplate: '/portfolios/goal/template',
  portfolioGoalPreview: '/portfolios/goal/preview',
  portfolioGoalCreate: '/portfolios/goal/create',
  portfolioGoalPreviewByCategoryId: (categoryId: string) =>
    `/portfolios/goal/${categoryId}/preview`,
  portfolioGoalApplyByCategoryId: (categoryId: string) =>
    `/portfolios/goal/${categoryId}/apply`,
  portfolioGoalUpdate: '/portfolios/goal/update',
  portfolioCategoryDelete: '/portfolios/category/delete',

  sharedPortfolioSellAll: (sharedPortfolioId: string) =>
    `/portfolios/${sharedPortfolioId}/shared/sell-all`,
  sharedPortfolioSellAllPreview: (sharedPortfolioId: string) =>
    `/portfolios/${sharedPortfolioId}/shared/sell-all-preview`,
  sharedPortfolioRoboSellAll: (sharedPortfolioId: string) =>
    `/portfolios/${sharedPortfolioId}/shared/robo-sell-all`,
  sharedPortfolioRoboSellAllPreview: (sharedPortfolioId: string) =>
    `/portfolios/${sharedPortfolioId}/shared/robo-sell-all-preview`,

  roboList: '/robo/list',
  otpValidate: '/auth/validate_otp',
  getPaymentMethod: '/payment/channel',
  setDefaultBank: '/user/bank/default',
  emailChangeVerify: '/auth/change/email/verify',
  getBankListUser: (unmask = 0) => `/user/bank?unmask=${unmask}`,
  getManagerInvestment: '/products/im',
  giftCardValidatePaymentChannel: (channel: string) =>
    `/gift-card/channel/${channel}/validate`,
  giftcardClaim: '/gift-card/claim',
  giftcardDetail: (token: string) => `/gift-card/detail/${token}`,
  giftcardSendList: '/gift-card/list',
  giftcardSendReceived: '/gift-card/user',
  gojekConnect: '/payment/gopay/connect',
  gojekDisconnect: '/payment/gopay/disconnect',
  income: '/tools/list/income',
  incomeSource: '/tools/list/incomesource',
  linkajaTransactionVerification: '/linkaja',
  loginMethod: (page: string, phoneCode?: string) => {
    if (['register', 'register-resend'].includes(page)) {
      return `/tools/otp-methods/${page}?code=${phoneCode}`;
    } else {
      return `/tools/otp-methods/${page}`;
    }
  },

  portfolioGoalDetail: (portfolioId: string) =>
    `/portfolios/goal/${portfolioId}/detail`,
  portfolioGoalOnTrack: (portfolioId: string) =>
    `/portfolios/goal/${portfolioId}/ontrack`,
  portfolioGoalRecalculate: (portfolioId: string) =>
    `/portfolios/goal/${portfolioId}/recalculate`,
  portfolioGoalChart: (portfolioId: string) =>
    `/portfolios/goal/${portfolioId}/chart`,
  portfolioCategoryChangeIcon: (portfolioId: string) =>
    `/portfolios/category/${portfolioId}/changeicon`,
  products: (symbol: string) => `/products/${symbol}`,
  productsFFS: (symbol: string) => `/products/${symbol}/factsheets`,
  productsProspectus: (symbol: string) => `/products/${symbol}/prospectus`,
  productsChart: (symbol: string) => `/products/${symbol}/chart`,
  productsChartAum: (symbol: string) => `/products/${symbol}/chart/aum`,
  productsWatchlist: (symbol: string) => `/products/${symbol}/watchlist`,
  productsStats: (symbol: string) => `/products/${symbol}/stats`,
  productSwitchable: (symbol: string) => `/products/${symbol}/switchables`,
  productDividend: (symbol: string) => `/products/${symbol}/dividends`,
  notificationRead: (id: string) => `/notification/read/${id}`,
  notifications: '/notifications',
  occupations: '/tools/list/occupations',
  orderBonus: '/order/bonus',
  orderBuy: '/order/buy',
  orderBuyPreview: '/order/buy/preview',
  orderDetailByCode: (code: string) => `/orders/${code}`,
  institutionOrderDetailByCode: (code: string) =>
    `/institutions/orders/${code}`,
  institutionOrderDetailRdByCode: (code: string) =>
    `/institutions/mutual-funds/orders/${code}`,
  orderSubmitConfirmation: (code: string) => `/order/payment/${code}/confirm`,
  orderUploadImageByCode: (code: string) => `/order/payment/${code}/upload`,
  paymentGopayAccount: '/payment/gopay/account',
  paymentGopayCharge: (invoice: string) => `/payment/${invoice}/gopay/charge`,
  paymentRdnCharge: (invoice: string, paymentKey: string) =>
    `/payment/${invoice}/${paymentKey}/charge`,
  paymentShopeeCharge: (invoice: string) =>
    `/payment/${invoice}/shopeepay/charge`,
  paymentLinkAjaCharge: (invoice: string) =>
    `/payment/${invoice}/linkaja/charge`,
  paymentLinkAjaApplinkCharge: (invoice: string) =>
    `/payment/${invoice}/linkaja_applink/charge`,
  pengkinianFormData: '/user/renewal-requests',
  pinVerify: '/auth/login/pin?v=2',
  requestPermission: '/auth/pin/grant/scopes',
  requestSecureKey: '/auth/pin/grant/token',
  portfolio: '/portfolio',
  portfolioCategory: (category: string) => `/portfolios/category/${category}`,
  pengkinianSettingPref: '/setting/preference',
  portfolioCategory2: '/portfolios/category',
  productsFilter: '/products/filter',
  productsSearch: '/products/search',
  productTrxHistory: '/orders/products',
  productTrxHistoryInstitution: '/institutions/orders/products',
  portoTrxHistory: '/orders/portfolios',
  portoTrxHistoryInstitution: '/institutions/orders/portfolios',

  referralCreateCustom: '/referral/create/custom',
  relationshipList: '/tools/list/relationships',
  reqForgetPhone: '/auth/forget/phone/request',
  roboCalculateSellById: (roboId: string) => `/robo/calculate/sell/${roboId}`,
  roboCalculateTopup: (roboId: string) => `/robo/calculate/topup/${roboId}`,
  roboScoreUpdateById: (roboId: string) => `/robo/score/update/${roboId}`,
  roboTopup: '/robo/topup',
  roboUpdate: (id: string) => `/robo/recommendation/update/${id}`,
  switchProductAvailableBySymbol: (symbol: string) =>
    `/portfolios/products/switch/${symbol}`,
  simulationSymbol: (symbol: string) => `/products/${symbol}/simulations`,
  switchProduct: `/portfolios/products/switch`,
  switchProductAvailable: `/portfolios/products/switch`,
  userPreferenceUpdate: '/user/preference/update',
  userRequirementDocs: '/user/docs',
  verifyForgetPhone: '/auth/forget/phone/verify',
  pengkinianBOAllowRequest: '/user/beneficial-owners/quotas',
  pengkinianReadNotification: (id: number) => `/notifications/${id}/read`,
  pengkinianBOSet: '/user/beneficial-owners',
  pengkinianReadNotifBulk: '/notifications/read',
  occupation: '/tools/list/occupations',
  marital: '/tools/list/marital',
  getSignedUrl: '/user/image',
  cartOverview: '/carts/stats',
  cartItemList: '/carts/items',
  cartItemUpdate: (itemId: number) => `/carts/items/${itemId}`,
  cartUndo: (itemId: number) => `/carts/items/${itemId}/undo`,
  carts: '/carts',
  cartCheckoutOverview: '/carts/checkout/overview',
  cartCheckout: '/carts/checkout',
  cartVouchers: '/carts/vouchers?v=2',
  cartVoucherValue: '/carts/vouchers/value',
  cartPaymentChannel: '/carts/payments',
  cartPaymentChannelAppLink: '/carts/payments?v=2',
  realizedProfitStats: '/portfolios/performances/realized-profits/stats',
  realizedProfits: '/portfolios/performances/realized-profits',
  cashoutSurveyQuestions: (id: string) => `/surveys/cashout/${id}`,
  saveCashoutSurvey: '/surveys/cashout',
  closeCashoutSurvey: (id: string) => `/surveys/cashout/${id}/close`,
  realizedProfitOrderDetails: (code: string) =>
    `/portfolios/performances/realized-profits/${code}?v=2`,
};

export default endpoints;
