const endpoints = {
  jagoResetStatus: '/jago/register/reset',
  jagoUnlink: '/jago/unlink',
  jagoInstantRedemptionStatus: '/jago/instant-redemption/status',
  jagoCheckCustomer: '/jago/check/customer',
  jagoAccountBalance: 'jago/account?v=2',
  jagoRegister: '/jago/register?v=2',
  jagoStats: '/jago/stats',
  jagoBind: '/jago/bind',
  jagoUnbind: '/jago/unbind',
};

export default endpoints;
