import React from 'react';
import style from './ShareInvitationBanner.module.css';
import { Text, Banner, WhiteSpace } from '@bibitid/uikit-v1';
import {
  useSharedInvitationFunctionContext,
  useSharedInvitationStateContext,
} from '../../modals';
import HandsOnIllustration from 'assets/images/shared-portfolio/HandsOnIllustration/HandsOnIllustration';
import TopLeftBanner from 'assets/images/shared-portfolio/TopLeftBanner/TopLeftBanner';
import BottomRightBanner from 'assets/images/shared-portfolio/BottomRightBanner/BottomRightBanner';
import Analytics from 'utils/Analytics';

const SharedInvitationBanner = () => {
  const { invitation, invitationLoading } = useSharedInvitationStateContext();
  const { setSharedInvitationStep } = useSharedInvitationFunctionContext();

  const handleClickBanner = () => {
    Analytics.logEventAction({
      eventName: 'shared_action',
      parameter: {
        action: 'pending_invitation',
        context: 'portfolio.detail',
        trigger: 'click',
      },
    });
    setSharedInvitationStep('invitation');
  };

  if (invitation.length === 0) {
    return null;
  }

  if (invitationLoading) {
    return (
      <>
        <WhiteSpace size='xl' />
        <SharedInvitationBannerLoading />
      </>
    );
  }

  return (
    <div>
      <div className={style['banner-wrapper']}>
        <TopLeftBanner className={style['banner-top-left']} />
        <BottomRightBanner className={style['banner-bottom-right']} />
        <Banner
          variant='compact'
          title={`${invitation.length} Undangan Bibit Bareng`}
          icon={<HandsOnIllustration />}
          description={
            <Text>
              Klik banner ini untuk konfirmasi undangan Bibit Bareng kamu.
            </Text>
          }
          onCTAClick={handleClickBanner}
        />
      </div>
    </div>
  );
};

const SharedInvitationBannerLoading = () => {
  return (
    <div className={style['banner-container']}>
      <div className={style['banner-wrapper-loading']}>
        <TopLeftBanner className={style['banner-top-left-loading']} />
        <BottomRightBanner className={style['banner-bottom-right-loading']} />
        <div className={style['banner-icon']}>
          <div className={style['banner-animation']} />
        </div>
        <div className={style['banner-right-loader']}>
          <div className={style['banner-animation']} />
          <div className={style['banner-animation']} />
          <div className={style['banner-animation']} />
        </div>
      </div>
    </div>
  );
};

export default SharedInvitationBanner;
