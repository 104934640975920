import list from './index';
import * as Parent from 'core/Parent';
import Axios from 'axios';
import http from 'core/http';
import { getCurrentInstitution } from 'features/institution/utils';
import { endpoints as institutionEndpoints } from 'features/institution/networks/endpoints';
import { getEnv } from 'core/env';
import { openThirdPartyWebView } from 'utils/thirdPartyWebview';

const { PaymentWebhook } = getEnv();

/**
 * Submit order buy single
 * @param {Object} data
 * @param {string} data.payment_method Order payment method
 */
const postOrderBuy = (data) => {
  return new Promise((resolve, reject) => {
    return http
      .post(list.orderBuy, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

/**
 * Ask native container to request single mutual fund order to Bibit API
 * @param {Object} requestData User's order request data
 * @returns {Promise<any>}
 */
export const postOrderBuyNative = (requestData) => {
  return new Promise((resolve, reject) => {
    return Parent.postData(
      'postOrderBuyNative',
      {
        fn: 'postOrderBuyNative',
        data: requestData,
        timeout: 0,
        check_expiration: true,
      },
      (error, data) => {
        // This block below somehow not run due to our current postmessage
        // architecture. The alternative is asking our native devs to
        // return error object as key in returned data
        // TODO: restructure post message so this error argument is useful
        if (error) {
          return reject(error);
        }

        if (data?.error) {
          /**
           * check error object type
           */
          const errorIsObject = typeof data?.error === 'object';

          /**
           * normalize error data to object
           */
          const errorData = errorIsObject
            ? data?.error
            : { message: data?.error };

          return reject({
            response: {
              data: errorData,
            },
          });
        }

        // We're mapping this to unify service response data structure,
        // as if it is from axios
        return resolve({
          data: {
            data,
            message: 'Success',
          },
        });
      }
    );
  });
};

/**
 * Submit order buy jago snap
 * @param {Object} data
 * @returns {Promise<any>}
 */
export const postOrderBuyJagoSNAP = (data) => {
  return new Promise((resolve, reject) => {
    return http
      .post(list.orderBuy, data)
      .then(async (res) => {
        const orderData = res?.data?.data;
        const { invoice_number } = orderData;
        const { authentication_webview_url } = orderData?.payment_detail;

        const redirect_url = [`.*orders/${invoice_number}/detail.*`];

        const webviewRes = await openThirdPartyWebView({
          url: authentication_webview_url,
          exit_url: redirect_url,
        });

        // We're mapping this to unify service response data structure,
        // as if it is from axios
        return resolve({
          data: {
            data: {
              ...res?.data?.data,
              ...webviewRes,
            },
            message: 'Success',
          },
        });
      })
      .catch((err) => reject(err));
  });
};

/**
 * Submit order buy ROBO
 * @param {object} data
 */
const postOrderBuyRobo = (data) => {
  return new Promise((resolve, reject) => {
    return http
      .post(list.roboTopup, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

/**
 * Ask native container to request single mutual fund order to Bibit API
 * @param {Object} requestData User's order request data
 * @returns {Promise<any>}
 */
export const postOrderBuyRoboNative = (requestData) => {
  return new Promise((resolve, reject) => {
    return Parent.postData(
      'postOrderBuyRoboNative',
      {
        fn: 'postOrderBuyRoboNative',
        data: requestData,
        timeout: 0,
        check_expiration: true,
      },
      (error, data) => {
        // This block below somehow not run due to our current postmessage
        // architecture. The alternative is asking our native devs to
        // return error object as key in returned data
        // TODO: restructure post message so this error argument is useful
        if (error) {
          return reject(error);
        }

        if (data?.error) {
          /**
           * check error object type
           */
          const errorIsObject = typeof data?.error === 'object';

          /**
           * normalize error data to object
           */
          const errorData = errorIsObject
            ? data?.error
            : { message: data?.error };

          return reject({
            response: {
              data: errorData,
            },
          });
        }

        // We're mapping this to unify service response data structure,
        // as if it is from axios
        return resolve({
          data: {
            data,
            message: 'Success',
          },
        });
      }
    );
  });
};

/**
 * Submit order robo buy jago snap
 * @param {Object} data
 * @returns {Promise<any>}
 */
export const postOrderBuyRoboJagoSNAP = (data) => {
  return new Promise((resolve, reject) => {
    return http
      .post(list.roboTopup, data)
      .then(async (res) => {
        const orderData = res?.data?.data;
        const { authentication_webview_url } = orderData?.payment_detail;

        const { invoice_number } = orderData;
        const redirect_url = [`.*orders/${invoice_number}/detail.*`];

        const webviewRes = await openThirdPartyWebView({
          url: authentication_webview_url,
          exit_url: redirect_url,
        });

        // We're mapping this to unify service response data structure,
        // as if it is from axios
        return resolve({
          data: {
            data: {
              ...res?.data?.data,
              ...webviewRes,
            },
            message: 'Success',
          },
        });
      })
      .catch((err) => reject(err));
  });
};

/**
 * This will trigger flow for continuing order payment, useful if user
 * prematurely exit last order payment
 *
 * @param {ContinueOrderData} continueOrderData
 */
export const postContinueOrder = (continueOrderData) => {
  return new Promise((resolve, reject) => {
    return Parent.postData(
      'postContinueOrder',
      {
        fn: 'postContinueOrder',
        data: continueOrderData,
        timeout: 0,
        check_expiration: true,
      },
      (error, data) => {
        // Throw error if exist
        if (error) {
          return reject(error);
        }

        if (data?.error) {
          /**
           * check error object type
           */
          const errorIsObject = typeof data?.error === 'object';

          /**
           * normalize error data to object
           */
          const errorData = errorIsObject
            ? data?.error
            : { message: data?.error };

          return reject({
            response: {
              data: errorData,
            },
          });
        }

        // Continue this promise to next then, data returned doesn't matter
        return resolve();
      }
    );
  });
};

/**
 * POST: Submit sell single
 * @param {object} data
 */
const postOrderSell = (data, xsecurekey) => {
  return http.post(
    `${list.orderSell}?v=2`,
    data,
    {},
    {
      headers: {
        'x-secure-key': xsecurekey,
      },
    }
  );
};

/**
 * POST: Submit sell robo
 * @param {object} data
 */
const postOrderSellRobo = (data, securekey) => {
  return http.post(
    list.roboSell,
    data,
    {},
    {
      headers: {
        'x-secure-key': securekey,
      },
    }
  );
};

/**
 * POST: Submit sell-all robo
 * @param {object} data
 */
const postOrderSellAllRobo = (data, securekey) => {
  return http.post(
    `${list.sharedPortfolioRoboSellAll(data.shared_porto_id)}`,
    data,
    {},
    {
      headers: {
        'x-secure-key': securekey,
      },
    }
  );
};

/**
 * POST: Submit sell-all single porto
 * @param {object} data
 */
const postOrderSellAll = (data, securekey) => {
  return http.post(
    `${list.sharedPortfolioSellAll(data.shared_porto_id)}`,
    data,
    {},
    {
      headers: {
        'x-secure-key': securekey,
      },
    }
  );
};

/**
 * GET: Fetch available order bonus
 */
const fetchAvailableOrderBonus = () => {
  return http.get(list.orderBonus);
};

/**
 * POST: Verification LinkAja for transaction
 */
const postVerificationLinkAjaTransaction = (transactionData) => {
  return Axios.post(
    PaymentWebhook + list.linkajaTransactionVerification,
    transactionData
  );
};

/**
 * GET: List order history
 * @param {object} param
 */
const getListOrderHistory = (param) => {
  return http.get(list.orderHistory, param);
};

/**
 * POST: sell preview data
 * @param {object} data
 */
const postOrderSellPreview = (data) => {
  return http.post(list.orderSellPreview, data);
};

/**
 * GET: data list order
 */
const getListOrder = (param) => {
  return http.get(list.orderList, param);
};

/**
 * GET: get order detail by id
 * @param {string} code
 */
const getOrderDetailByID = (code) => {
  return http.get(list.orderDetailByCode(code));
};

/**
 * POST: upload image order by code
 * @param {string} code
 * @param {object} uploadData
 */
const postUploadImageOrderByCode = (code, uploadData) => {
  const currentInstitution = getCurrentInstitution();
  if (!!currentInstitution && !!currentInstitution.institution_id) {
    return http.post(
      institutionEndpoints.uploadPaymentReceipt(code),
      uploadData
    );
  }
  return http.post(list.orderUploadImageByCode(code), uploadData);
};

/**
 * POST: submit order confirmation
 * @param {string} wsTransCode
 * @param {string} code
 */
const postSubmitOrderConfirmation = (wsTransCode, code) => {
  const institution = getCurrentInstitution();
  if (!!institution?.institution_id) {
    return http.post(institutionEndpoints.confirmPaymentReceipt(wsTransCode));
  }

  return http.post(list.orderSubmitConfirmation(code));
};

/**
 *
 * @param {Object} params
 * @returns {Promise<import('axios').AxiosResponse<import('services').BibitApiSuccessResponse<import('./transaction.type').ProductHistoryTransaction[]>>}
 */
const getHistoryProductTransaction = (param) => {
  return http.get(list.productTrxHistory, param);
};

/**
 *
 * @param {number} page
 * @param {number} limit
 * @returns {Promise<import('axios').AxiosResponse<import('services').BibitApiSuccessResponse<import('./transaction.type').PortoHistoryTransaction[]>>}
 */
const getHistoryPortoTransaction = (page = 1, limit = 20, is_sbn = 0) => {
  return http.get(list.portoTrxHistory, { page, limit, is_sbn });
};

const getProductHasTransaction = async (symbol) => {
  const institution = getCurrentInstitution();
  if (!!institution?.institution_id) {
    return http.get(list.checkProductHasTrxInstitution(symbol));
  }

  return http.get(list.checkProductHasTrx(symbol));
};

/**
 * POST: Order Single Buy Preview
 * @param {number} amount
 * @param {string} symbol
 * @param {string|number} category_id
 * @param {boolean} is_with_gift_card
 * @param {boolean} [stamp_duty]
 */
export function postOrderSingleBuyPreview(
  amount = 0,
  symbol,
  category_id,
  is_with_gift_card = false,
  stamp_duty
) {
  return http.post(`${list.orderBuyPreview}?v=2`, {
    amount: amount,
    symbol: symbol,
    category_id: category_id,
    is_with_gift_card,
    ...(stamp_duty ? { stamp_duty: stamp_duty } : {}),
  });
}

/**
 * Bibit Institution API service
 */

/**
 * Get institution order list
 * @param {Object} params
 */
export const getInstitutionOrderList = (param) => {
  return http.get(list.institutionOrderList, param);
};

/**
 * Get institution RD order list
 * @param {Object} params
 */
export const getInstitutionRdOrderList = (param) => {
  return http.get(list.institutionRdOrderList, param);
};

/**
 * Get institution bonds order list
 * @param {Object} params
 */
export const getInstitutionBondsOrderList = (param) => {
  return http.get(list.institutionBondsOrderList, param);
};

/**
 * Get institution order detail by order id
 * @param {String} code
 */
export const getInstitutionOrderDetailById = (code) => {
  return http.get(list.institutionOrderDetailByCode(code));
};

/**
 * Get institution order detail rd by order id
 * @param {String} code
 */
export const getInstitutionOrderDetailRdById = (code) => {
  return http.get(list.institutionOrderDetailRdByCode(code));
};

/**
 * Get institution order history list
 * @param {Object} params
 */
export const getInstitutionOrderHistory = (param) => {
  return http.get(list.institutionOrderHistory, param);
};

/**
 * Get institution rd order history list
 * @param {Object} params
 */
export const getInstitutionRdOrderHistory = (param) => {
  return http.get(list.institutionRdOrderHistory, param);
};

/**
 * Get institution bonds order history list
 * @param {Object} params
 */
export const getInstitutionBondsOrderHistory = (param) => {
  return http.get(list.institutionBondsOrderHistory, param);
};

/**
 * @param {object} params
 * @param {number} page
 * @param {number} limit
 * @returns {Promise<import('axios').AxiosResponse<import('services').BibitApiSuccessResponse<import('./transaction.type').ProductHistoryTransaction[]>>}
 */
export const getHistoryProductTransactionInstitution = (
  params = {},
  page = 1,
  limit = 20
) => {
  return http.get(list.productTrxHistoryInstitution, {
    ...params,
    page,
    limit,
  });
};

/**
 *
 * @param {number} page
 * @param {number} limit
 * @returns {Promise<import('axios').AxiosResponse<import('services').BibitApiSuccessResponse<import('./transaction.type').PortoHistoryTransaction[]>>}
 */
export const getHistoryPortoTransactionInstitution = (page = 1, limit = 20) => {
  return http.get(list.portoTrxHistoryInstitution, { page, limit });
};

export {
  postOrderBuy,
  postOrderBuyRobo,
  postOrderSell,
  postOrderSellRobo,
  fetchAvailableOrderBonus,
  postVerificationLinkAjaTransaction,
  getListOrderHistory,
  postOrderSellPreview,
  getListOrder,
  getOrderDetailByID,
  postUploadImageOrderByCode,
  postSubmitOrderConfirmation,
  getHistoryProductTransaction,
  getProductHasTransaction,
  getHistoryPortoTransaction,
  postOrderSellAllRobo,
  postOrderSellAll,
};
