import React, { lazy } from 'react';
import LazyLoadWrapper from 'features/common/lazyload/LazyLoadWrapper';
import StockSellContextProvider from './contexts';

const StockSellPageLazy = lazy(() => import('./StockSellPage'));

const StockSellPage: React.FC<React.PropsWithChildren<unknown>> = () => (
  <LazyLoadWrapper>
    <StockSellContextProvider>
      <StockSellPageLazy />
    </StockSellContextProvider>
  </LazyLoadWrapper>
);

export default StockSellPage;
