import React, { useEffect } from 'react';
import { DEFAULT_STATUS_BAR_COLOR } from '../constants';
import { useStatusBarFunction } from '../context/StatusBarContext';

/**
 * HOC for resetting status Bar
 * @param WrappedComponent - component that need to be wrapped
 */
const withStatusBar =
  <T extends { defaultStatusBar?: boolean; defaultBottomBar?: boolean }>(
    WrappedComponent: any
  ) =>
  (props: T) => {
    const { defaultStatusBar = true, defaultBottomBar = true } = props;
    const { onSetStatusBarColor } = useStatusBarFunction();

    useEffect(() => {
      if (defaultStatusBar) {
        onSetStatusBarColor({
          backgroundColor: DEFAULT_STATUS_BAR_COLOR().backgroundColor,
          foregroundColor: DEFAULT_STATUS_BAR_COLOR().foregroundColor,
        });
      }
    }, [onSetStatusBarColor, defaultStatusBar]);

    return <WrappedComponent {...props} defaultBottomBar={defaultBottomBar} />;
  };

export default withStatusBar;
