import gopayAutodebit from 'assets/images/recurring/gopay-autodebit.svg';
import gopay from 'assets/images/recurring/gopay.svg';
import gopayDark from 'assets/images/recurring/gopay-dark.svg';
import jagoAutodebit from 'assets/images/recurring/jago-autodebit.svg';
import jago from 'assets/images/recurring/jago.svg';
import jagoDark from 'assets/images/recurring/jago-dark.svg';
import linkaja from 'assets/images/recurring/linkaja.svg';
import virtualAccoutBCA from 'assets/images/recurring/va-bca.svg';
import virtualAccoutBNI from 'assets/images/recurring/va-bni.svg';
import virtualAccoutBRI from 'assets/images/recurring/va-bri.svg';
import virtualAccoutCIMB from 'assets/images/recurring/va-cimb.svg';
import virtualAccoutMandiri from 'assets/images/recurring/va-mandiri.svg';
import virtualAccoutPermata from 'assets/images/recurring/va-permata.svg';
import virtualAccoutBCADark from 'assets/images/recurring/dark/va-bca-dark.svg';
import virtualAccoutBNIDark from 'assets/images/recurring/dark/va-bni-dark.svg';
import virtualAccoutBRIDark from 'assets/images/recurring/dark/va-bri-dark.svg';
import virtualAccoutCIMBDark from 'assets/images/recurring/dark/va-cimb-dark.svg';
import virtualAccoutMandiriDark from 'assets/images/recurring/dark/va-mandiri-dark.svg';
import virtualAccoutPermataDark from 'assets/images/recurring/dark/va-permata-dark.svg';
import manualBca from 'assets/images/recurring/manual-bca.svg';
import manualBcaDark from 'assets/images/recurring/manual-bca-dark.svg';
import shopeePay from 'assets/images/recurring/shopee-pay/light.svg';
import shopeePayDark from 'assets/images/recurring/shopee-pay/dark.svg';
import rdnAutodebit from 'assets/images/recurring/rdn-autodebit.svg';
import rdnAutodebitDark from 'assets/images/recurring/rdn-autodebit-dark.svg';

// AutoPay/Autodebet logo
import GopayAutopay from 'assets/images/topup-modal-asset/gopay-autopay-logo.svg';
import JagoAutopay from 'assets/images/bank-jago/jago-autodebet-logo.svg';
import RdnAutoDebetLogo from 'assets/images/topup-modal-asset/rdn-autodebet-logo.svg';
import GopayAutopayDark from 'assets/images/recurring/dark/gopay-autodebit-dark.svg';
import jagoAutopayDark from 'assets/images/recurring/dark/jago-autodebit-dark.svg';
import linkajaDark from 'assets/images/recurring/dark/linkaja-dark.svg';

import { useTheme } from 'hooks';
import { useFlag } from 'utils/feature-flag';

interface PaymentIcon {
  [key: string]: string;
}

export const paymentIcon: PaymentIcon = {
  gopay: gopay,
  jago: jago,
  linkaja: linkaja,
  linkaja_applink: linkaja,
  gopayAutodebit: gopayAutodebit,
  jagoAutodebit: jagoAutodebit,
  vabca: virtualAccoutBCA,
  vabni: virtualAccoutBNI,
  oy_bri: virtualAccoutBRI,
  oy_cimb: virtualAccoutCIMB,
  oy_mandiri: virtualAccoutMandiri,
  oy_permata: virtualAccoutPermata,
  vapermata: virtualAccoutPermata,
  manual: manualBca,
  shopeepay: shopeePay,
  bill_mandiri: virtualAccoutMandiri,
  rdn_bca: rdnAutodebit,
  rdn_jago: rdnAutodebit,
};

export const paymentIconDark: PaymentIcon = {
  gopay: gopayDark,
  jago: jagoDark,
  linkaja: linkajaDark,
  linkaja_applink: linkajaDark,
  gopayAutodebit: GopayAutopayDark,
  jagoAutodebit: jagoAutopayDark,
  vabca: virtualAccoutBCADark,
  vabni: virtualAccoutBNIDark,
  oy_bri: virtualAccoutBRIDark,
  oy_cimb: virtualAccoutCIMBDark,
  oy_mandiri: virtualAccoutMandiriDark,
  oy_permata: virtualAccoutPermataDark,
  vapermata: virtualAccoutPermataDark,
  manual: manualBcaDark,
  shopeepay: shopeePayDark,
  bill_mandiri: virtualAccoutMandiriDark,
  rdn_bca: rdnAutodebitDark,
  rdn_jago: rdnAutodebit,
};

export const autoPayLogo: PaymentIcon = {
  jago: JagoAutopay,
  gopay: GopayAutopay,
  rdn_bca: RdnAutoDebetLogo,
  rdn_jago: RdnAutoDebetLogo,
};

export const autoPayLogoDark: PaymentIcon = {
  jago: jagoAutopayDark,
  gopay: GopayAutopayDark,
  rdn_bca: RdnAutoDebetLogo,
  rdn_jago: RdnAutoDebetLogo,
};

// dyt: TODO - handle other payment

/** List Frequency for AutoDebet Payment */
export const listFrequency = ['Harian', 'Mingguan', 'Bulanan'];

/** List Frequency for NON-AutoDebet Payment  */
export const listFrequencyManual = ['Mingguan', 'Bulanan'];

export const listFinishRecurringDaily = ['Selamanya', 'Pilih Tanggal Berakhir'];

export const listFinishRecurringAt = ['Selamanya', 'Pilih Bulan Berakhir'];

export const listDays = [
  'Senin',
  'Selasa',
  'Rabu',
  'Kamis',
  'Jumat',
  'Sabtu',
  'Minggu',
];

/** List Key of Instant Payment */
export const listInstantPayment = ['linkaja', 'gopay', 'jago'];

/** Description of update version to use autodebet challenge */
export const updateReminderDescription =
  'Untuk bisa menggunakan fitur ini, kamu harus update aplikasi Bibit kamu ke versi terbaru.';

export const usePaymentIcon = () => {
  const { theme } = useTheme();

  if (theme === 'dark') {
    return paymentIconDark;
  }

  return paymentIcon;
};

export const useAutoPayLogo = () => {
  const { theme } = useTheme();
  const autoRutinFF = useFlag('web_bibit_auto_rutin');

  if (theme === 'dark') {
    return autoRutinFF
      ? { ...autoPayLogoDark, gopay: GopayAutopay, jago: JagoAutopay }
      : autoPayLogoDark;
  }

  return autoPayLogo;
};

export const frequencyDropdownList = [
  {
    value: 'Harian',
    label: 'harian',
  },
  {
    value: 'Mingguan',
    label: 'mingguan',
  },
  {
    value: 'Bulanan',
    label: 'bulanan',
  },
];

export const daysDropdownList = [
  {
    value: 'Senin',
    label: 'senin',
  },
  {
    value: 'Selasa',
    label: 'selasa',
  },
  {
    value: 'Rabu',
    label: 'rabu',
  },
  {
    value: 'Kamis',
    label: 'kamis',
  },
  {
    value: 'Jumat',
    label: 'jumat',
  },
  {
    value: 'Sabtu',
    label: 'sabtu',
  },
  {
    value: 'Minggu',
    label: 'minggu',
  },
];

export const finishRecurringDailyList = [
  {
    value: 'Selamanya',
    label: 'selamanya',
  },
  {
    value: 'Pilih Tanggal Berakhir',
    label: 'pilih_tanggal_berakhir',
  },
];

export const finishRecurringWeeklyMonthlyList = [
  {
    value: 'Selamanya',
    label: 'selamanya',
  },
  {
    value: 'Pilih Bulan Berakhir',
    label: 'pilih_bulan_berakhir',
  },
];

const generateDateDropdownList = () => {
  return Array.from({ length: 31 }, (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  }));
};

export const dateDropdownList = generateDateDropdownList();

export const idrAmounts: number[] = [
  100_000, 500_000, 1_000_000, 5_000_000, 10_000_000, 20_000_000, 50_000_000,
  100_000_000, 250_000_000, 500_000_000, 1000_000_000,
];

// Choices for minBuy USD 100
export const usdAmounts: number[] = [
  100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 5000, 10_000, 15_000,
  20_000, 25_000, 30_000, 35_000, 40_000, 45_000, 50_000, 100_000,
];
