import { requestInfinitePagination } from 'network/middleware';
import { useInfiniteQuery } from 'react-query';
import { filterProduct } from 'services/product';
import { ReksaDana } from 'entities/reksadana.reducer';
import { useUserInInstitution } from 'features/institution/hooks';

export interface FilterProductReturn {
  pages: any[];
  pageParams: any[];
  allData: ReksaDana[];
}

interface useFilterProductParams {
  type: string;
  tradable: number;
  limit: number;
  sort: string;
  currency: string;
  syariah?: number;
  sort_by?: number;
  sort_period?: string;
  is_instant_redemption?: number;
  meta?: string;
  im?: string;
  max_buy?: number;
  is_top_product?: number;
  is_highest_return?: number;
}

export const GET_INFINITE_PRODUCT_KEY = 'Reksadana Product Infinite Query';

export const useFilterProduct = (
  param: useFilterProductParams,
  enabled: boolean = true
) => {
  const institution = useUserInInstitution();

  if (institution) {
    // change currency param to idr if in institution
    param.currency = 'idr';
  }

  const fetch = ({ pageParam = 1 }) => {
    const params = {
      ...param,
      page: pageParam,
    };
    return requestInfinitePagination(pageParam, () =>
      filterProduct(params, undefined, true)
    );
  };

  const {
    data,
    error,
    isFetching,
    isFetched,
    isLoading,
    isRefetchError,
    isFetchingNextPage,
    hasNextPage,
    refetch,
    fetchNextPage,
  } = useInfiniteQuery([GET_INFINITE_PRODUCT_KEY, param], fetch, {
    enabled,
    getNextPageParam: (lastPage) => {
      const dontHaveNextPage =
        !Array.isArray(lastPage?.data?.data) ||
        lastPage?.data?.data?.length === 0;

      if (dontHaveNextPage) return undefined;

      const nextPageNumber = lastPage?.next_page;
      return nextPageNumber;
    },
    select: (data) => ({
      pages: [...data.pages],
      pageParams: [...data.pageParams],
      allData: data.pages.flatMap((item) => item.data.data),
    }),
  });

  return {
    data: data as FilterProductReturn,
    error,
    isLoading,
    isFetching,
    isFetched,
    isRefetchError,
    isFetchingNextPage,
    hasNextPage,
    refetch,
    fetchNextPage,
  };
};

export default useFilterProduct;
