import React, { PropsWithChildren, useEffect, useState } from 'react';
import storage from 'core/Storage';

export const MultiLanguageContext = React.createContext<{
  lang?: Lang;
  setLang: React.Dispatch<React.SetStateAction<Lang | undefined>>;
}>({
  lang: undefined,
  setLang: () => null,
});

export type Lang = 'en' | 'id';

const MultiLanguangeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [lang, setLang] = useState<Lang>();

  useEffect(() => {
    const init = async () => {
      let lang = (await storage.get('language')) as Lang;
      if (lang !== 'en' && lang !== 'id') {
        localStorage.setItem('language', 'id');
        await storage.set('language', 'id');
        setLang('id');
        return;
      }

      localStorage.setItem('language', lang);
      await storage.set('language', lang);
      setLang(lang);
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MultiLanguageContext.Provider value={{ lang, setLang }}>
      {children}
    </MultiLanguageContext.Provider>
  );
};

export default MultiLanguangeProvider;
