import React, { lazy, Suspense, useState, useEffect, useCallback } from 'react';
import {
  useTopUpGlobalContext,
  useTopModalFunctions,
} from 'features/topup-modal/TopupGlobalContext';
import './top-up-modal.css';
import { useLocation } from 'react-router-dom';
import { removeLatestDataPersistProperties } from 'utils/helpers/persistProperties';
/**
 * not restore topup modal data on this pages
 */
const blacklistPaths = [
  '/jago-tnc',
  '/articles',
  '/articles/detail',
  '/jago-resume',
  '/bibitplus-coregist',
  '/bibitplus-coregist/stockbit',
  '/bibitplus-coregist/jago',
  '/bibitplus-list',
  '/stockbit-coregist',
  '/stockbit/deposit',
  '/stockbit/deposit/bca',
  '/stockbit/deposit/jago',
  '/stockbit/deposit/bri',
  '/stockbit/deposit/bni',
  '/stockbit/deposit/mandiri',
  '/stockbit/deposit/other',
  '/stockbit/deposit/gopay',
  '/stockbit/deposit/ovo',
  '/stockbit/deposit/dana',
  '/stockbit/deposit/linkaja',
  '/sbn/registration/tnc',
  '/sbn/sync/stockbit/exit',
  '/bibitplus-submitted',
  '/bibitplus-jago-syariah',
  '/bibitplus-sbn-failed',
];
/**
 * Lazily load whole topup modal flow
 */
const TopUpModalComponent = lazy(() => import('./TopUpModalFlow'));

const TopupModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [firstLoad, setFirstLoad] = useState(false);

  const location = useLocation();

  const { topUpVisible, redirectSaveTopUp } = useTopUpGlobalContext();

  const { restoreOpenTopUp } = useTopModalFunctions();

  useEffect(() => {
    if (!firstLoad && topUpVisible) {
      setFirstLoad(true);
    }
  }, [topUpVisible, firstLoad]);

  const shouldShowTopup =
    !blacklistPaths.includes(location.pathname) && !!redirectSaveTopUp;

  const decideVisibleTopup = useCallback(async () => {
    if (shouldShowTopup) {
      setTimeout(() => {
        return restoreOpenTopUp();
      }, 200);
    }

    if (!redirectSaveTopUp) {
      return removeLatestDataPersistProperties('persist_top_up');
    }

    return;
  }, [restoreOpenTopUp, shouldShowTopup, redirectSaveTopUp]);

  useEffect(() => {
    decideVisibleTopup();
  }, [decideVisibleTopup]);

  if (firstLoad) {
    return (
      <Suspense fallback={null}>
        <TopUpModalComponent />
      </Suspense>
    );
  }

  return null;
};

export default TopupModal;
