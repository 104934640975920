/*
 * @Author: stockbit6
 * @Date:   2016-08-01 11:59:06
 * @Last Modified by:   stockbit6
 * @Last Modified time: 2018-09-06 11:17:54
 */

import axios from 'axios';
import compareVersion from 'compare-versions';
import * as Sentry from '@sentry/react';
import { getMimeTypeFromFile } from 'utils/fileReader';

import { generalLogError, logEventDebug } from 'utils/Analytics';
import getSafely from 'utils/safely';
import Storage from 'core/Storage';
import list from 'services';
import { formatISO } from 'utils/date/format';
import http from 'core/http';
import { randomString as generateRandomString } from 'utils/stringHelper';
import { default as Compress } from 'compress.js';
import { getEnv } from './env';
import { getDeviceInfo } from './DeviceInfo/deviceInfo';

const { ApiUrl, AwsBibitBucket } = getEnv();

/**
 * Compress the image
 *
 * @param {*} file
 * @returns
 */
function compressImage2(file) {
  const compress = new Compress();
  const options = {
    size: 0.7, // the max size in MB, defaults to 2MB
    quality: 0.75, // the quality of the image, max is 1,
    maxWidth: 1920, // the max width of the output image, defaults to 1920px
    maxHeight: 1920, // the max height of the output image, defaults to 1920px
    // resize: true, // defaults to true, set false if you do not want to resize the image width and height
  };
  return compress.compress([file], options).then(([compressed]) => {
    let output = '';
    if (compressed) {
      const { data, prefix } = compressed;
      output = prefix + data;
    }
    return output;
  });
}

/**
 * Convert URI to BLOB
 *
 * @param {string} dataURI
 * @returns {blob}
 */
function dataURItoBlob(dataURI) {
  let content = dataURI.split(',')[1];
  let binary = atob(content);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}

/**
 * Record duration time as second
 *
 * @returns
 */
function createRecordTime() {
  let startTime = new Date();
  return function stopTimer() {
    const stopTime = new Date();
    const diffInMs = stopTime.getTime() - startTime.getTime();

    const diffInSec = diffInMs / 1000;
    const durationInSec = Math.abs(diffInSec);
    return durationInSec;
  };
}

/**
 * Convert bytes to human readable
 *
 * @param {*} fileSizeInBytes
 * @returns
 */
function getReadableFileSizeString(fileSizeInBytes) {
  var i = -1;
  var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

/**
 * Compress base64 Image
 *
 * @export base64ImageCompress
 * @param {string} base64Image
 * @returns
 */
export async function base64ImageCompress(base64Image) {
  let _base64Compressed = '';
  const systemName = window?.systemName || window?.document?.systemName;
  /**
   * Skip compress on android
   */
  if (systemName === 'android') return base64Image;

  try {
    let _blob = dataURItoBlob(base64Image);
    _base64Compressed = await compressImage2(_blob);
    return _base64Compressed;
  } catch (error) {
    logEventDebug({
      eventName: 'error_base64ImageCompress',
      parameter: {
        trigger: 'call',
        context: 'javascript',
        data: {
          description: error,
        },
      },
    });
    return '';
  }
}

/**
 *
 * @param {string} base64 :: data:image/jpeg;........
 * @returns {string} contentType
 */
function getContentTypeFromBase64(base64) {
  try {
    if (typeof base64 !== 'string') {
      throw new Error('param_not_string:: base64');
    }
    if (base64.substr(0, 4) !== 'data') {
      throw new Error('param_not_data_uri:: base64');
    }
    const split1 = base64.split(':');
    const split2 = split1[1].split(';');
    return split2[0];
  } catch (error) {
    Sentry.configureScope(function (scope) {
      scope.setExtra('service_error', 'getContentTypeFromBase64');
      scope.setExtra('the_error', error);
    });
    return 'image/jpeg';
  }
}

/**
 *
 * @param {string} base64 :: data:image/jpeg;........
 */
export function getExtFromBase64(base64) {
  let contentType = getContentTypeFromBase64(base64);
  let extSplit = contentType.split('/');
  return '.' + extSplit[1];
}

/**
 * Convert base64 string representation into image object
 * @param  {File}            file - The file to be converted
 * @return {Promise<String>}      - Base 64 string representation
 */
export async function base64ToImage(base64, needCompress = false) {
  const ext = getExtFromBase64(base64);

  let file_name = Date.now() + ext;
  let _blob = dataURItoBlob(base64);
  if (needCompress) {
    logEventDebug({
      eventName: 'base64_start_compress',
      parameter: {
        trigger: 'call',
        context: 'aws_upload',
      },
    });

    try {
      let _base64Compressed = await compressImage2(_blob);
      _blob = dataURItoBlob(_base64Compressed);

      logEventDebug({
        eventName: 'base64_done_compress',
        parameter: {
          trigger: 'call',
          context: 'aws_upload',
        },
      });
    } catch (e) {
      console.error('error ', e);
      logEventDebug({
        eventName: 'error_base64ToImage',
        parameter: {
          trigger: 'call',
          context: 'javascript',
          data: {
            description: e,
          },
        },
      });
    }
  }
  let file = new File([_blob], file_name);
  return file;
}

/**
 * Convert File object to Base64 string representation
 * @param  {File}            file - The file to be converted
 * @return {Promise<String>}      - Base 64 string representation
 */
export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

/**
 * Generate image name
 *
 * @export
 * @param {string} formattype
 * @param {string} [prefix='stockbit']
 * @returns {string}
 */
export function formatUploadImageName(formattype, prefix = 'stockbit') {
  let randomString = generateRandomString(10);
  let now = formatISO(new Date());
  now = now.replace(/-/g, '');
  now = now.replace(/:/g, '');
  now = now.substr(0, 15);
  return prefix + randomString + now + 'web.' + formattype;
}

/**
 * Convert string to slug
 *
 * @export
 * @param {string} text
 * @returns
 */
export function convertToSlug(text) {
  if (!text) return text;
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

/**
 * Generate file name
 *
 * @export
 * @param {string} file
 * @returns
 */
export function formatUploadFileName(file) {
  try {
    let extension = file.slice(((file.lastIndexOf('.') - 1) >>> 0) + 2); // Returning only file extension, safeguarding from 'filename' or '.filename'
    let filename = file.substr(0, file.lastIndexOf('.'));
    let randomString = generateRandomString(10);
    let d = new Date();
    let currDate = String('00' + d.getDate()).slice(-2); // Get today's date with leading zero
    let currMonth = String('00' + (d.getMonth() + 1)).slice(-2); // Get today's month with leading zero
    let currYear = d.getFullYear();
    return (
      convertToSlug(
        filename + ' ' + randomString + currYear + currMonth + currDate
      ) +
      '.' +
      extension
    );
  } catch (error) {
    logEventDebug({
      eventName: 'error_formatUploadFileName',
      parameter: {
        trigger: 'call',
        context: 'javascript',
        data: {
          description: error,
        },
      },
    });
  }
}

async function __seekCredential() {
  const token = await Storage.getAccessToken();
  const islogin = token ? true : false;
  if (!islogin) return null;
  return token;
}

/**
 * definition paramsImageFallback
 * @typedef {Object} ParamsImageFallback
 * @property {string} file - Base64 String
 * @property {string} key - Key code path s3
 * @property {string} item - Purpose upload enum
 * @property {string} item_id - Unique code upload target
 */

/**
 * Fallback upload Image to API
 *
 * @param {ParamsImageFallback} paramsImageFallback params image
 * @param {Object} options upload image fallback option
 * @param {Boolean} options.needCompress decide if image need to be compressed
 * @returns
 */
async function uploadImageFallback(paramsImageFallback, opt) {
  let needCompress = false;

  // If need compress variable is not passed, default to true
  if (!opt || opt.needCompress === null || opt.needCompress === undefined) {
    needCompress = true;
  }

  const stopRecorder = createRecordTime();

  let ac = await __seekCredential();
  const options = {
    headers: {
      'content-type': 'application/json',
    },
  };
  if (ac) ac = 'Bearer ' + ac;
  if (ac) options.headers['Authorization'] = ac;

  const { file, key, item, item_id, isUpdate } = paramsImageFallback;

  let _blob = dataURItoBlob(file);
  let file_compressed = file;
  if (needCompress) {
    file_compressed = await compressImage2(_blob);
  }

  let body = {
    file: file_compressed,
    key,
    item,
    item_id,
    ...(!!isUpdate ? { is_update: true } : {}),
  };

  return axios
    .post(ApiUrl + '/tools/s3/upload', body, options)
    .catch((error) => {
      let _message = '';
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        _message = [
          'Data: ' + error.response.data,
          'Status: ' + error.response.status,
          'Headers: ' + error.response.headers,
          'Error object: ' + JSON.stringify(error),
        ].join(' - ');
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        _message = 'Error.request : ' + JSON.stringify(error);
      } else {
        // Something happened in setting up the request that triggered an Error
        _message = 'Message : ' + error.message;
      }

      logEventDebug({
        eventName: 'error_after_apiupload_network',
        parameter: {
          trigger: 'call',
          context: 'javascript',
          data: {
            description: _message,
            timeInSecond: stopRecorder(),
          },
        },
      });
      return Promise.reject(error);
    });
}

export async function createBase64UploadPromiseFallback(
  filename = null,
  file = '',
  needCompress = false,
  options = null
) {
  let generateFilename = false;

  let thefile = file;
  if (!file) {
    thefile = filename;
    // file = filename;
    generateFilename = true;
  }

  if (!thefile) {
    return '';
  }

  let newFileName = '';

  if (generateFilename) {
    // Generate unique filename
    let fileName = 'identity.jpeg';
    newFileName = formatUploadFileName(fileName);
  } else {
    // not generate
    newFileName = filename;
  }

  let _resolve = null;
  let _reject = null;

  const PromiseOutput = new Promise((resolve, reject) => {
    _resolve = resolve;
    _reject = reject;
  });

  logEventDebug({
    eventName: 'start_upload_fallback',
    parameter: {
      trigger: 'call',
      context: 'javascript',
      data: {
        description: 'fallback',
      },
    },
  });

  if (options && options.uploadfor && options.code) {
    const PuploadImageFallback = uploadImageFallback(
      {
        file: file,
        key: newFileName,
        item: options.uploadfor,
        item_id: options.code,
        isUpdate: options.isUpdate,
      },
      { needCompress }
    );
    PuploadImageFallback.then((result) => {
      if (!result) {
        logEventDebug({
          eventName: 'error500_afterfallback_network',
          parameter: {
            trigger: 'call',
            context: 'javascript',
            data: {
              description:
                'result from api server is empty, forced to fallback',
            },
          },
        });
        throw new Error('Something is wrong, Please try again later.');
      }
      _resolve(result);
    }).catch((err) => {
      logEventDebug({
        eventName: 'error_after_fallback_network',
        parameter: {
          trigger: 'call',
          context: 'javascript',
          data: {
            description: err,
          },
        },
      });
      _reject(err);
    });
  } else {
    _reject('cannot found uploadfor');
  }

  return PromiseOutput;
}

/**
 *
 * @param {*} type :: type: path_in_aws
 * 					  ===========================================
 * 					      ktp: 'registrations',
             		  signature: 'registrations',
             		  edd: 'edd',
                  kk: 'kk',
             		  bank_selfie: 'bankaccount',
             		  bank_account: 'bankaccount',
             		  payment_receipt: 'orders/confirmations'
                  document: 'document',
 * @param {*} accessToken
 */
function generateTokenUploadV2(type, accessToken = null) {
  return new Promise((resolve, reject) => {
    const stopRecorder = createRecordTime();
    let availableType = [
      'ktp',
      'edd',
      'signature',
      'bank_selfie',
      'bank_account',
      'payment_receipt',
      'document',
    ];

    if (!availableType.includes(type)) {
      return reject('Please check your Type Upload file');
    }

    let generateURL;
    if (accessToken === null) {
      generateURL = list.awsTokenV2 + `?type=${type}`;
    } else {
      /**
       * Dipakai jika Bearer Token Tidak ada, dan access token ini kita dapat dari Auth URL seperti dari Email:
       *
       * Saat ini dipakai ketika:
       * - Change/Add Bank
       *
       * */
      generateURL =
        list.awsTokenV2NoAuth +
        `?type=${type}&verification_token=${accessToken}`;
    }

    return http
      .get(generateURL)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        Sentry.configureScope(function (scope) {
          scope.setExtra('service_error', 'AWS TOKEN');
          scope.setExtra('duration_service', stopRecorder());
          scope.setExtra('the_error', err);
        });
        logEventDebug({
          eventName: 'error_after_tokenaws_network',
          parameter: {
            trigger: 'call',
            context: 'javascript',
            data: {
              err: err,
            },
          },
        });
        return reject(err);
      });
  });
}

const getFileEvent = (fileObj) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsArrayBuffer(fileObj);

    reader.onloadend = (fileEvent) => {
      return resolve(fileEvent);
    };
  });
};

/**
 * Upload image avatar
 *
 * @export
 * @param {*} file
 * @param {*} [newfilename=null]
 * @param options ex: { uploadfor: 'edd', code: id_user }
 * @returns
 */
export async function uploadImageAvatar(
  file,
  newfilename = null,
  options = null
) {
  const typeUpload = getSafely(['uploadfor'], options, '');
  const tokenCode = getSafely(['token'], options, null);

  return generateTokenUploadV2(typeUpload, tokenCode).then(async (resp) => {
    logEventDebug({
      eventName: 'token_retrieved',
      parameter: {
        trigger: 'call',
        context: 'aws_upload',
      },
    });

    let token = null;

    if (resp) {
      const {
        data: { data: _data },
      } = resp;
      token = _data;
    }

    let fd = new FormData();
    let former_file = null;

    if (file._file) former_file = file._file;

    let _acl = '';
    let _bucket = AwsBibitBucket;

    /* dynamic read from api */
    try {
      let temp = null;
      temp = atob(token['policy']);
      temp = JSON.parse(temp);
      if (
        temp.conditions &&
        typeof temp.conditions.map === 'function' &&
        temp.conditions.length > 0
      )
        temp.conditions.map((item) => {
          if (item['acl']) {
            _acl = item['acl'];
          }
          if (item['bucket']) {
            _acl = item['bucket'];
          }
          return item;
        });
    } catch (error) {
      console.error(error);
      const extraData = {
        file: 'Aws.js',
        function: 'uploadImageAvatar',
        token_policy: token,
        error: error,
      };
      generalLogError('JSON.parse catch', extraData);
      logEventDebug({
        eventName: 'error_dynamicread_policyaws',
        parameter: {
          trigger: 'call',
          context: 'javascript',
        },
      });
    }

    if (!_acl) _acl = 'public-read';

    logEventDebug({
      eventName: 'acl_bucket_done_read',
      parameter: {
        trigger: 'call',
        context: 'aws_upload',
      },
    });

    const fileEvent = await getFileEvent(file);

    const contentType = getMimeTypeFromFile(fileEvent, file);

    fd.append('key', newfilename);
    fd.append('AWSAccessKeyId', token['AWSAccessKeyId']);
    fd.append('acl', _acl);
    fd.append('success_action_redirect', token['success_action_redirect']);

    fd.append('policy', token['policy']);
    fd.append('signature', token['signature']);
    fd.append('Content-Type', contentType);

    logEventDebug({
      eventName: 'form_data_assigned',
      parameter: {
        trigger: 'call',
        context: 'aws_upload',
      },
    });

    let name = 'none';
    let size = '0 kB';
    if (!former_file) {
      name = file ? file.name : '';
      size = file ? getReadableFileSizeString(file.size) : '';
      fd.append('file', file);
    } else {
      name = former_file ? former_file.name : '';
      size = former_file ? getReadableFileSizeString(former_file.size) : '0 kB';
      fd.append('file', former_file);
    }

    logEventDebug({
      eventName: 'size_upload_image',
      parameter: {
        trigger: 'call',
        context: 'javascript',
        data: {
          name: name,
          size: size,
        },
      },
    });

    let awsBucket = _bucket;

    const stopRecorder = createRecordTime();
    return axios.post(awsBucket, fd).catch((error) => {
      let _message = '';
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        _message = [
          'Data: ' + error.response.data,
          'Status: ' + error.response.status,
          'Headers: ' + error.response.headers,
          'Error object: ' + JSON.stringify(error),
        ].join(' - ');
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        _message = 'Error.request : ' + JSON.stringify(error);
      } else {
        // Something happened in setting up the request that triggered an Error
        _message = 'Message : ' + error.message;
      }
      Sentry.configureScope(function (scope) {
        scope.setExtra('service_error', 'AWS BUCKET UPLOAD');
        scope.setExtra('file_upload_size', size);
        scope.setExtra('duration_service', stopRecorder());
        scope.setExtra('the_error', error);
      });
      logEventDebug({
        eventName: 'error_after_network',
        parameter: {
          trigger: 'call',
          context: 'javascript',
          data: {
            description: _message,
            timeInSecond: stopRecorder(),
          },
        },
      });
    });
  });
}

/**
 * Upload base64 to s3
 *
 * @export
 * @param {*} [filename=null]
 * @param {string} [file='']
 * @param {boolean} [needCompress=false]
 * @param {Option} [options=null]
 * @returns
 */
export async function createBase64UploadPromise(
  filename = null,
  file = '',
  needCompress = false,
  options = null
) {
  logEventDebug({
    eventName: 'image_upload_start',
    parameter: {
      trigger: 'call',
      context: 'aws_upload',
    },
  });

  const deviceInfo = await getDeviceInfo();
  const version = getSafely(['SYSTEM_VERSION'], deviceInfo);
  const systemName = getSafely(['SYSTEM_NAME'], deviceInfo);

  if (
    !!version &&
    !!systemName &&
    systemName.toLowerCase() === 'ios' &&
    compareVersion.compare(version, '12.0.0', '<')
  ) {
    return await createBase64UploadPromiseFallback(
      filename,
      file,
      false,
      options
    );
  }

  let generateFilename = false;

  let thefile = file;
  if (!file) {
    thefile = filename;
    // file = filename;
    generateFilename = true;
  }

  if (!thefile) {
    return '';
  }

  let _file = null;
  let newFileName = '';

  if (generateFilename) {
    // Generate unique filename
    let fileName = 'identity.jpeg';
    newFileName = formatUploadFileName(fileName);
  } else {
    // not generate
    newFileName = filename;
  }

  let _resolve = null;
  let _reject = null;

  const PromiseOutput = new Promise((resolve, reject) => {
    _resolve = resolve;
    _reject = reject;
  });

  _file = await base64ToImage(thefile, needCompress);

  const PuploadImageAvatar = uploadImageAvatar(_file, newFileName, options);

  PuploadImageAvatar.then((result) => {
    if (!result) {
      logEventDebug({
        eventName: 'errorforbidden_after_network',
        parameter: {
          trigger: 'call',
          context: 'javascript',
          data: {
            description: 'result from aws is empty, forced to fallback',
          },
        },
      });
      throw new Error('Something is wrong, Please try again later.');
    }

    logEventDebug({
      eventName: 'done_uploading',
      parameter: {
        trigger: 'call',
        context: 'aws_upload',
      },
    });

    _resolve(result);
  }).catch((error) => {
    logEventDebug({
      eventName: 'error_after_network',
      parameter: {
        trigger: 'call',
        context: 'javascript',
        data: {
          description: error,
          options,
        },
      },
    });
    if (options && options.uploadfor && options.code) {
      logEventDebug({
        eventName: 'start_fallback_after_network',
        parameter: {
          trigger: 'call',
          context: 'javascript',
        },
      });

      const PuploadImageFallback = uploadImageFallback(
        {
          file: file,
          key: newFileName,
          item: options.uploadfor,
          item_id: options.code,
          isUpdate: options.isUpdate,
        },
        { needCompress }
      );
      PuploadImageFallback.then((result) => {
        if (!result) {
          logEventDebug({
            eventName: 'error500_afterfallback_network',
            parameter: {
              trigger: 'call',
              context: 'javascript',
              data: {
                description:
                  'result from api server is empty, forced to fallback',
              },
            },
          });
          throw new Error('Something is wrong, Please try again later.');
        }
        _resolve(result);
      }).catch((err) => {
        logEventDebug({
          eventName: 'error_after_fallback_network',
          parameter: {
            trigger: 'call',
            context: 'javascript',
            data: {
              description: err,
            },
          },
        });
        _reject(err);
      });
    } else {
      logEventDebug({
        eventName: 'missing_options_fallback',
        parameter: {
          trigger: 'call',
          context: 'javascript',
          data: {
            options,
          },
        },
      });
    }
  });

  return PromiseOutput;
}
