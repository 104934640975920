import { isInsideNativeContainer } from 'utils/validator';
import {
  listFrequency,
  listFrequencyManual,
} from 'features/recurring/constants/constant';
import { isMethodVA } from 'utils';
import { DetailRecurring, PromoRecurring } from 'services/recurring.type';
import type * as H from 'history';
import { FlexibDetail } from '../types/recurring.type';

/**
 * Serialize frequency value to suitable with API body
 * @param scheduleType
 */
export const scheduleTypeSerialize = (scheduleType?: string) => {
  scheduleType = (scheduleType ?? '').toLocaleLowerCase();
  switch (scheduleType) {
    case 'bulanan':
      return 'per_month';
    case 'mingguan':
      return 'per_week';
    case 'harian':
      return 'per_day';
    default:
      return 'per_month';
  }
};

/**
 * Deserialize from API to suitable with View
 * @param type
 */
export const scheduleTypeDeserialize = (
  type: 'per_month' | 'per_week' | 'per_day'
) => {
  switch (type) {
    case 'per_day':
      return 'Harian';
    case 'per_month':
      return 'Bulanan';
    case 'per_week':
      return 'Mingguan';
  }
};

/**
 * Serialize Days of Week to suitable with API body
 * @param days
 */
export const daysWeekSerialize = (days?: string) => {
  days = (days ?? '').toLocaleLowerCase();

  switch (days) {
    case 'minggu':
      return 0;
    case 'senin':
      return 1;
    case 'selasa':
      return 2;
    case 'rabu':
      return 3;
    case 'kamis':
      return 4;
    case 'jumat':
      return 5;
    case 'sabtu':
      return 6;
    default:
      break;
  }
};

/**
 * Deserialize days of number to suitable with View
 * @param daysNum
 */
export const daysWeekDeserialize = (daysNum: number) => {
  switch (daysNum) {
    case 0:
      return 'Minggu';
    case 1:
      return 'Senin';
    case 2:
      return 'Selasa';
    case 3:
      return 'Rabu';
    case 4:
      return 'Kamis';
    case 5:
      return 'Jumat';
    case 6:
      return 'Sabtu';
    default:
      break;
  }
};

export const validatePaymentRestrict = (
  isJagoPending: boolean,
  investmentValue: number,
  maxAmount: number,
  minAmount: number,
  keyPayMethod?: string,
  isGopayConnected?: boolean,
  typePayment?: 'autodebet' | 'instant_payment',
  convenienceFee: number = 0
) => {
  const insideNativeApp = isInsideNativeContainer();
  const investmentAmount = investmentValue
    ? investmentValue + convenienceFee
    : 0;

  const meetMinRestrict = investmentAmount < minAmount || false;

  const meetMaxRestrict = investmentAmount > maxAmount || false;

  const meetAmountRestrict = meetMinRestrict || meetMaxRestrict;

  const meetMinRestricWithoutConvenienceFee =
    (investmentValue ? investmentValue : 0) < minAmount || false;

  const meetAmountRestrictWithoutConvenienceFee =
    meetMinRestricWithoutConvenienceFee || meetMaxRestrict;

  /** disabled checkbox when status jago pending OR meet amount restrict */
  const disabled = meetAmountRestrict;
  const disabledWithoutConvenienceFee = meetAmountRestrictWithoutConvenienceFee;

  /**
   * determine gopay only available at App Native if Gopay Not Connected.
   * Only validate if typePayment === 'autodebet'
   */
  const gopayServiceAppOnly =
    typePayment === 'autodebet' &&
    keyPayMethod === 'gopay' &&
    !isGopayConnected;

  const jagoServiceAppOnly = keyPayMethod === 'jago';

  /**
   * Determine service in App Native only if
   *
   * - key pay === jago
   * - key pay === gopay and isGopayConnected === false
   */
  const onlyOnAppService =
    !insideNativeApp && (gopayServiceAppOnly || jagoServiceAppOnly);

  return {
    disabled,
    meetAmountRestrict,
    meetMinRestrict,
    meetMaxRestrict,
    meetMinRestricWithoutConvenienceFee,
    meetAmountRestrictWithoutConvenienceFee,
    disabledWithoutConvenienceFee,
    onlyOnAppService,
  };
};

export const listFrequencyDropDown = (
  chosenPaymentKey: string,
  autoDebet?: boolean
) => {
  if (autoDebet) return listFrequency;
  if (isMethodVA(chosenPaymentKey) || chosenPaymentKey === 'manual')
    return ['Bulanan'];
  return listFrequencyManual;
};

export const renderPaymentMethodName = (
  paymentKey: string,
  paymentName: string,
  paymentType?: string
) => {
  if (paymentKey === 'manual') {
    return 'Transfer Manual';
  }
  if (paymentType === 'Virtual Account') {
    return `Virtual Account`;
  }

  if (paymentKey === 'linkaja_applink') {
    return 'LinkAja';
  }

  return paymentName;
};

/**
 * Render frequency challenge
 * @param frequency
 */
export const renderFrequencyChallenge = (
  frequency: string,
  withSuffix?: boolean
) => {
  switch (frequency) {
    case 'weekly':
      return `minggu${withSuffix ? 'an' : ''}`;
    case 'daily':
      return `hari${withSuffix ? 'an' : ''}`;
    case 'monthly':
    default:
      return `bulan${withSuffix ? 'an' : ''}`;
  }
};

/**
 * Check Autodebet Challenge State for Analytics
 * - default => 'autodebet_trace' object is empty
 * - start => success_count === 0 && status === 1
 * - ongoing_first => success_count === 1
 * - ongoing_second => success_count === 2
 * - done => status === 4
 * - reschedule_first => status === 2 && success_count === (0)
 * - reschedule_second => status === 2 && success_count === (1)
 * - reschedule_third => status === 2 && success_count === (2)
 * - restart_first => status === 3 && success_count === (0)
 * - restart_second => status === 3 && success_count === (1)
 * - restart_third => status === 3 && success_count === (2)
 * @param {PromoRecurring} promo
 */
export const checkAutodebetChallengeState = (promo: PromoRecurring) => {
  if (!promo) return;

  const autodebetTrace = promo?.autodebet_trace;
  const status = autodebetTrace?.status;
  const successCount = autodebetTrace?.success_count;

  // Analytics State
  let state = '';

  // State: default
  if (!!autodebetTrace) state = 'default';

  // State: done
  if (status === 4) state = 'done';

  // ongoing state
  if (status === 1) {
    if (successCount === 0) state = 'start';
    if (successCount === 1) state = 'ongoing_first';
    if (successCount === 2) state = 'ongoing_second';
  }

  // reschedule state
  if (status === 2) {
    if (status === 2 && successCount === 0) state = 'reschedule_first';
    if (status === 2 && successCount === 1) state = 'reschedule_second';
    if (status === 2 && successCount === 2) state = 'reschedule_third';
  }

  // restart state
  if (status === 3) {
    if (successCount === 0) state = 'restart_first';
    if (successCount === 1) state = 'restart_second';
    if (successCount === 2) state = 'restart_third';
  }

  return {
    id: promo?.id,
    state: state,
  };
};

/**
 * all known creation search param please add here.
 */
export interface SearchParamCreation {
  action?: 'reinvestBonds' | string;
  autodebet?: string;
  amount?: string | number;
  chosenPorto?: string | number | undefined;
  chosenPortoProduct?: string | number | undefined;
  date?: string;
  reactivate?: boolean;
  promoId?: string;
  serieId?: number;
  serieType?: string;
  dueDate?: string;
  dayDate?: string;
  showSinglePortoOnly?: 0 | 1;
  candidateDefaultPorto?: string;
  source?: string;
  isFlexible?: boolean;
  freq?: 'Bulanan' | 'Mingguan' | 'Harian' | string;
  isReinvestMaturity?: string;
}

export const navigateToRecurringCreation = (
  history: H.History<H.LocationState>,
  params?: SearchParamCreation
) => {
  const searchParams = new URLSearchParams();

  Object.entries(params || {}).forEach(([key, value]) => {
    if (![undefined, null].includes(value)) {
      searchParams.set(key, String(value));
    }
  });

  history.push(`/recurring/create?${searchParams.toString()}`);
};

export const createRecurringUrlCreation = (params?: SearchParamCreation) => {
  const searchParams = new URLSearchParams();
  Object.entries(params || {}).forEach(([key, value]) => {
    if (![undefined, null].includes(value)) {
      searchParams.set(key, String(value));
    }
  });

  return `/recurring/create?${searchParams.toString()}`;
};

export const normalizeFlexibleSIPDetail = (
  recurring?: DetailRecurring
): FlexibDetail | undefined => {
  if (!recurring) {
    return undefined;
  }

  return {
    recurringId: recurring.id,
    name: recurring?.name,
    payChannel: recurring?.payment,
    amount: recurring?.amount,
    paymentMaxAmount: recurring?.payment_max_amount
      ? Number(recurring?.payment_max_amount)
      : undefined,
    paymentMinAmount: recurring?.payment_min_amount
      ? Number(recurring?.payment_min_amount)
      : undefined,
    reminderStatus: undefined,
    products: recurring?.products,
    paymentName: recurring?.payment_name,
    paymentIcon: recurring?.payment_icon,
    portfolio: recurring?.portfolio,
    paymentTypeName: recurring?.payment_type_name,
    product:
      recurring?.products?.length === 1
        ? recurring?.products?.[0]?.symbol
        : undefined,
    convenienceFee: recurring?.convenience_fee,
    isAutoDebet: recurring?.is_auto_debet,
    reminderDate: undefined,
    paymentChannelStatus: recurring?.payment_channel_status,
    frequencyRecurring: scheduleTypeDeserialize(recurring?.recurring_type),
    hasConvenienceFeeFreeQuota: recurring?.hasConvenienceFeeFreeQuota || false,
    ifaCommissionFee:
      recurring?.is_ifa_transaction && !!recurring?.transaction_fee
        ? recurring?.transaction_fee?.commission?.amount
        : 0,
    errorLog: recurring?.error_log,
    payment_config: recurring?.payment_config,
  };
};
