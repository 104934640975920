import { postData } from './../Parent';
import { randomString } from 'utils/stringHelper';

/**
 * Remove this constant only after refactor code biometric timeout module tobe dependent to postData timeout.
 * This code ignoring timeout from postData.
 */
const IGNORE_POSTDATA_TIMEOUT = 0;

export function removeKeyStorageNative(key) {
  const id = 'remove_' + key + '_' + randomString(10);
  const dataToSend = {
    key: key,
  };
  return new Promise((resolve, reject) => {
    postData(
      id,
      {
        fn: 'removeData',
        data: dataToSend,
        timeout: IGNORE_POSTDATA_TIMEOUT,
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }
        return resolve(data);
      }
    );
  });
}

export function getKeyStorageNative(key) {
  const dataToSend = {
    key: key,
  };
  const id = 'get_' + key + '_' + randomString(10);
  return new Promise((resolve, _) => {
    postData(
      id,
      { fn: 'getData', data: dataToSend, timeout: IGNORE_POSTDATA_TIMEOUT },
      (err, data) => {
        if (err) {
          return resolve('');
        }
        return resolve(data);
      }
    );
  });
}

export function setStorageNative(key, value) {
  const dataToSend = {
    key: key,
    value: value,
  };
  const id = 'store_' + key + '_' + randomString(10);
  return new Promise((resolve, reject) => {
    postData(
      id,
      { fn: 'storeData', data: dataToSend, timeout: IGNORE_POSTDATA_TIMEOUT },
      (err, data) => {
        if (err) {
          return reject(err);
        }
        return resolve(data);
      }
    );
  });
}
