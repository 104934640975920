import React, { MouseEvent, useEffect, useMemo } from 'react';
import { Text, Collapse } from '@bibitid/uikit-v1';

import { useFetchOrderBook } from 'features/stock/networks';
import { useStockbitWSContext } from 'features/stockbitWS';
import {
  numberToGeneralValueFormat,
  numberToAveragedLetter,
} from 'utils/stringHelper';

import style from './StockOrderBook.module.css';
import Analytics from 'utils/Analytics';
import { useTheme } from 'hooks';
import classNames from 'classnames';

interface StockOrderBookProps {
  companyId?: number;
  companySymbol: string;
  setUnitPrice: (price: number) => void;
  // just for analytics
  isSell?: boolean;
}

interface OrderBookLotProps {
  getOrderBookQueueData: {
    bid: any[];
    offer: any[];
  };
  handleClick: (event: MouseEvent<HTMLDivElement>) => void;
  getValueColor: (
    value: string
  ) => 'redDefault' | '#969696' | 'greenDefault' | undefined;
  totalLotBid: number;
  isDesktopView: boolean;
}

/**
 * adjust length order book
 * if order book less than 10 fill the rest with {}
 * @returns
 */
const adjustLengthOrderBook = (value: any[]) => {
  /*  fix sentry issue https://stockbitgroup.slack.com/archives/CV48PGE2Y/p1705370457614679 */
  const values = typeof value !== 'object' ? [] : value;

  const valueLength = values?.length || 0;
  const lessThan10 = valueLength < 10;
  const left = new Array(valueLength > 10 ? 10 : 10 - valueLength).fill({});

  return lessThan10 ? [...values, ...left] : value;
};

const OrderBookLot: React.FC<OrderBookLotProps> = React.memo(
  ({
    getOrderBookQueueData,
    handleClick,
    getValueColor,
    totalLotBid,
    isDesktopView,
  }) => {
    return (
      <div className={style['orderbook-bid']}>
        <div>
          <Text type='body2'>Lot</Text>
          <Text type='body2'>Bid</Text>
        </div>
        {adjustLengthOrderBook(getOrderBookQueueData.bid).map(
          (bids: any, idx: number) => (
            <div key={idx}>
              <Text type='body1'>
                {parseInt(bids.lot)
                  ? numberToGeneralValueFormat(bids?.lot)
                  : ''}
              </Text>

              <div onClick={handleClick} data-price={bids?.price}>
                <Text type='body1' color={getValueColor(bids?.price)}>
                  {parseInt(bids.price)
                    ? numberToGeneralValueFormat(bids?.price)
                    : ''}
                </Text>
              </div>
            </div>
          )
        )}
        <div>
          <Text type='body2'>
            {numberToGeneralValueFormat(totalLotBid, {
              average: !isDesktopView,
              totalLength: isDesktopView ? 0 : 4,
              optionalMantissa: true,
            }).toUpperCase()}
          </Text>
          <div />
        </div>
      </div>
    );
  },
  (_oldProp, newProp) => {
    return typeof newProp.getOrderBookQueueData.bid !== 'object';
  }
);

interface OrderBookOfferProps {
  getOrderBookQueueData: {
    bid: any[];
    offer: any[];
  };
  handleClick: (event: MouseEvent<HTMLDivElement>) => void;
  getValueColor: (
    value: string
  ) => 'redDefault' | '#969696' | 'greenDefault' | undefined;
  totalLotAsk: number;
  isDesktopView: boolean;
}

const OrderBookOffer: React.FC<OrderBookOfferProps> = React.memo(
  ({
    getOrderBookQueueData,
    handleClick,
    getValueColor,
    totalLotAsk,
    isDesktopView,
  }) => {
    return (
      <div className={style['orderbook-ask']}>
        <div>
          <Text type='body2'>Ask</Text>
          <Text type='body2'>Lot</Text>
        </div>
        {adjustLengthOrderBook(getOrderBookQueueData.offer).map(
          (offers: any, idx: number) => (
            <div key={idx}>
              <div onClick={handleClick} data-price={offers.price}>
                <Text
                  type='body1'
                  color={getValueColor(offers.price)}
                  data-price={offers.price}
                >
                  {parseInt(offers.price)
                    ? numberToGeneralValueFormat(offers.price)
                    : ''}
                </Text>
              </div>
              <Text type='body1'>
                {parseInt(offers.lot)
                  ? numberToGeneralValueFormat(offers.lot)
                  : ''}
              </Text>
            </div>
          )
        )}
        <div>
          <div />
          <Text type='body2'>
            {numberToGeneralValueFormat(totalLotAsk, {
              average: !isDesktopView,
              totalLength: isDesktopView ? 0 : 4,
              optionalMantissa: true,
            }).toUpperCase()}
          </Text>
        </div>
      </div>
    );
  },
  (_oldProp, newProp) => {
    return typeof newProp.getOrderBookQueueData.offer !== 'object';
  }
);

const StockOrderBook: React.FC<StockOrderBookProps> = ({
  companyId,
  companySymbol,
  setUnitPrice,
  isSell,
}) => {
  const { message: stockbitWS, setChannels } = useStockbitWSContext();

  useEffect(() => {
    setChannels({
      livePrice: [companySymbol],
      orderBook: [companySymbol],
    });
  }, [companySymbol, setChannels]);

  const { data: orderBookDataAPI } = useFetchOrderBook(companySymbol);

  const bibitAPIResponseData = orderBookDataAPI?.data?.data;
  const stockbitWSLivePriceData = stockbitWS?.livePrice[companySymbol];
  const stockbitWSOrderBookData = stockbitWS?.orderBook[companySymbol];

  const getOrderBookSummaryDetail = () => {
    const parseLotData = (volume: number | undefined) =>
      volume ? volume / 100 : 0;

    const isForeignbsExist = bibitAPIResponseData?.is_foreignbs_exist;

    const lot = stockbitWSLivePriceData?.volume
      ? parseLotData(stockbitWSLivePriceData.volume)
      : parseLotData(bibitAPIResponseData?.volume);

    const open = stockbitWSLivePriceData?.open
      ? stockbitWSLivePriceData.open
      : bibitAPIResponseData?.open || 0;

    const high = stockbitWSLivePriceData?.high
      ? stockbitWSLivePriceData.high
      : bibitAPIResponseData?.high || 0;

    const low = stockbitWSLivePriceData?.low
      ? stockbitWSLivePriceData.low
      : bibitAPIResponseData?.low || 0;

    const value = stockbitWSLivePriceData?.value
      ? stockbitWSLivePriceData.value
      : bibitAPIResponseData?.value || 0;

    const average = stockbitWSLivePriceData?.avgPrice
      ? stockbitWSLivePriceData.avgPrice
      : bibitAPIResponseData?.average || 0;

    const previous = stockbitWSLivePriceData?.previous
      ? stockbitWSLivePriceData.previous
      : bibitAPIResponseData?.previous || 0;

    const lastprice = stockbitWSLivePriceData?.lastPrice
      ? stockbitWSLivePriceData.lastPrice
      : bibitAPIResponseData?.lastprice || 0;

    const fBuy = stockbitWSLivePriceData?.foreignBuy
      ? stockbitWSLivePriceData.foreignBuy
      : bibitAPIResponseData?.fbuy || 0;

    const fSell = stockbitWSLivePriceData?.foreignSell
      ? stockbitWSLivePriceData?.foreignSell
      : bibitAPIResponseData?.fsell || 0;

    return {
      lot,
      low,
      high,
      open,
      average,
      previous,
      lastprice,
      value,
      fBuy,
      fSell,
      isForeignbsExist,
    };
  };

  const getOrderBookQueueData = () => {
    const bidWSQueue =
      stockbitWSOrderBookData?.status === 'BID' &&
      (stockbitWSOrderBookData?.queue || []).map(
        (queue: { price: string; volume: string }) => ({
          price: queue.price,
          lot: queue.volume ? +queue.volume / 100 : 0,
        })
      );
    const offerWSQueue =
      stockbitWSOrderBookData?.status === 'OFFER' &&
      (stockbitWSOrderBookData?.queue || []).map(
        (queue: { price: string; volume: string }) => ({
          price: queue.price,
          lot: queue.volume ? +queue.volume / 100 : 0,
        })
      );

    const bibitAPIQueue = (status: string) => {
      return [...Array(10)].map((_, idx) => {
        const priceValue: any =
          bibitAPIResponseData?.[status]?.[`price${idx + 1}`];
        const lotValue: any =
          bibitAPIResponseData?.[status]?.[`volume${idx + 1}`];

        return {
          price: priceValue,
          lot: lotValue ? +lotValue / 100 : 0,
        };
      });
    };

    return {
      bid: stockbitWSOrderBookData?.status ? bidWSQueue : bibitAPIQueue('bid'),
      offer: stockbitWSOrderBookData?.status
        ? offerWSQueue
        : bibitAPIQueue('offer'),
    };
  };

  const getValueColor = (value: string) => {
    if (!value) return '#969696';

    const previousPrice = getOrderBookSummaryDetail().previous;

    const below = +value < +previousPrice;
    const equal = +value === +previousPrice;
    const above = +value > +previousPrice;

    if (below) return 'redDefault';
    if (equal) return '#969696';
    if (above) return 'greenDefault';
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    const price = event.currentTarget.dataset['price'];
    setUnitPrice(+(price || '') || 0);
  };

  const renderFbuyAndFsell = () => {
    const { fBuy, fSell, isForeignbsExist } = getOrderBookSummaryDetail();

    if (isForeignbsExist) {
      return (
        <>
          <div>
            <Text type='body1'>F Buy</Text>
            <Text type='body1'>
              {numberToAveragedLetter(fBuy, true, {
                mantissa: 2,
              })}
            </Text>
          </div>
          <div>
            <Text type='body1'>F Sell</Text>
            <Text type='body1'>
              {numberToAveragedLetter(fSell, true, {
                mantissa: 2,
              })}
            </Text>
          </div>
        </>
      );
    }
  };

  const totalLotBid =
    getOrderBookQueueData().bid?.length &&
    getOrderBookQueueData()
      .bid.map((bids: any) => bids?.lot || 0)
      .reduce((a: any, b: any) => {
        return a + b;
      });
  const totalLotAsk =
    getOrderBookQueueData().offer?.length &&
    getOrderBookQueueData()
      .offer.map((offers: any) => offers?.lot || 0)
      .reduce((a: any, b: any) => {
        return a + b;
      });

  const isDesktopView = useMemo(() => {
    return window.innerWidth >= 1100;
  }, []);

  const handleChangeAccordion = () => {
    Analytics.logEventAction({
      eventName: isSell ? 'sell_stock_action' : 'buy_stock_action',
      parameter: {
        action: isSell ? 'sell_order_book' : 'buy_order_book',
        trigger: 'click',
        context: isSell ? 'sell_stock.sell_form' : 'buy_stock.buy_stock_form',
        data: {
          product_id: companyId,
          symbol: companySymbol,
        },
      },
    });
  };

  const { theme } = useTheme();

  return (
    <div
      className={classNames(style['stock-orderbook'], {
        [style['stock-orderbook--dark']]: theme === 'dark',
      })}
    >
      <Collapse
        className={style['orderbook-wrapper']}
        activeKey={['1']}
        accordion
      >
        <Collapse.Panel
          key={'1'}
          title={<Text type='body2'>Order Book</Text>}
          onClick={handleChangeAccordion}
          className={style['orderbook-panel']}
        >
          <div className={style['orderbook-details']}>
            <div>
              <Text type='body1'>Open</Text>
              <Text
                type='body1'
                color={getValueColor(getOrderBookSummaryDetail().open)}
              >
                {numberToGeneralValueFormat(getOrderBookSummaryDetail().open)}
              </Text>
            </div>
            <div>
              <Text type='body1'>Lot</Text>
              <Text
                type='body1'
                color={getValueColor(getOrderBookSummaryDetail().lastprice)}
              >
                {numberToGeneralValueFormat(getOrderBookSummaryDetail().lot)}
              </Text>
            </div>
            <div>
              <Text type='body1'>High</Text>
              <Text
                type='body1'
                color={getValueColor(getOrderBookSummaryDetail().high)}
              >
                {numberToGeneralValueFormat(getOrderBookSummaryDetail().high)}
              </Text>
            </div>
            <div>
              <Text type='body1'>Val</Text>
              <Text
                type='body1'
                color={getValueColor(getOrderBookSummaryDetail().lastprice)}
              >
                {numberToGeneralValueFormat(getOrderBookSummaryDetail().value, {
                  average: !isDesktopView,
                  totalLength: isDesktopView ? 0 : 4,
                }).toUpperCase()}
              </Text>
            </div>
            <div>
              <Text type='body1'>Low</Text>
              <Text
                type='body1'
                color={getValueColor(getOrderBookSummaryDetail().low)}
              >
                {numberToGeneralValueFormat(getOrderBookSummaryDetail().low)}
              </Text>
            </div>
            <div>
              <Text type='body1'>Avg</Text>
              <Text
                type='body1'
                color={getValueColor(getOrderBookSummaryDetail().lastprice)}
              >
                {numberToGeneralValueFormat(
                  getOrderBookSummaryDetail().average
                )}
              </Text>
            </div>
            {renderFbuyAndFsell()}
          </div>
          <div className={style['orderbook-table']}>
            <OrderBookLot
              getOrderBookQueueData={getOrderBookQueueData()}
              getValueColor={getValueColor}
              handleClick={handleClick}
              isDesktopView={isDesktopView}
              totalLotBid={totalLotBid}
            />
            <OrderBookOffer
              getOrderBookQueueData={getOrderBookQueueData()}
              getValueColor={getValueColor}
              handleClick={handleClick}
              isDesktopView={isDesktopView}
              totalLotAsk={totalLotAsk}
            />
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default StockOrderBook;
