import { useMutation, useQuery, useInfiniteQuery } from 'react-query';
import {
  getMemberList,
  postContactList,
  postInviteMember,
  postCancelInvitation,
  postDeleteMember,
  getInvitation,
  postRejectInvitation,
  postAcceptInvitation,
  getMemberContribution,
  getSharedPortfolioHistoryV2,
  getSharedPortfolioReason,
  postLeaveSharedProtfolio,
  sharedPortfolioSwitchAll,
  getAvailableSwitchAllPorto,
} from '../networks';
import { BodyFindContact, BodySharedPortfolioHistory } from '../types';

/**
 * Post to get bibit contact list
 */
export const usePostContactList = () => {
  return useMutation((body: BodyFindContact) => postContactList(body));
};

/**
 * Fetching shared portfolio member list
 * enable = true
 */
export const useFetchMemberList = (
  portfolioId: string,
  enable: boolean = true
) => {
  return useQuery(
    ['Member List', portfolioId],
    () => getMemberList(portfolioId),
    {
      enabled: enable,
    }
  );
};

/**
 * Fetching shared portfolio invitation
 * enable = true
 */
export const useFetchInvitation = (enable: boolean = true) => {
  return useQuery(['Shared Portfolio Invitation'], () => getInvitation(), {
    enabled: enable,
  });
};

/**
 * Fetching shared portfolio member contribution
 * enable = true
 */
export const useFetchMemberContribution = (
  sharedPortfolioId: string,
  enable: boolean = true
) => {
  return useQuery(
    ['Shared Portfolio Contribution', sharedPortfolioId],
    () => getMemberContribution(sharedPortfolioId),
    {
      enabled: enable,
    }
  );
};

/**
 * Fetching shared portfolio member history
 * enable = true
 */
export const useFetchMemberHistory = (
  sharedPortfolioId: string,
  bodySharedPortfolioHistory: BodySharedPortfolioHistory,
  enable: boolean = true
) => {
  const PAGE_LIMIT = 10;

  const fetcher = ({ pageParam = '' }) => {
    const queryParams = {
      limit: PAGE_LIMIT,
      ...(!!pageParam ? { cursor: pageParam } : {}),
    };

    return getSharedPortfolioHistoryV2(
      sharedPortfolioId,
      bodySharedPortfolioHistory,
      queryParams
    );
  };

  return useInfiniteQuery(
    ['Shared Portfolio History', sharedPortfolioId, bodySharedPortfolioHistory],
    fetcher,
    {
      enabled: enable,
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage?.data?.meta?.next_cursor;
        if (!nextPage) return false;

        return nextPage;
      },
    }
  );
};

/**
 * Fetching shared kick or leave portfolio reason
 * enable = true
 */
export const useFetchReason = (
  type: 'kick' | 'leave',
  enable: boolean = true
) => {
  return useQuery(
    ['Shared Portfolio Reason'],
    () => getSharedPortfolioReason(type),
    {
      enabled: enable,
    }
  );
};

/**
 * invite shared portfolio
 */
export const useSendInvitation = () => {
  return useMutation(postInviteMember);
};

/**
 * cancel shared portfolio invitation
 */
export const useCancelInvitation = () => {
  return useMutation(
    (item: { sharedPortfolioId: number; invitationId: number }) =>
      postCancelInvitation(item.sharedPortfolioId, item.invitationId)
  );
};

/**
 * accept shared portfolio invitation
 */
export const useAcceptInvitation = () => {
  return useMutation(
    (item: { sharedPortfolioId: number; invitationId: number }) =>
      postAcceptInvitation(item.sharedPortfolioId, item.invitationId)
  );
};

/**
 * reject shared portfolio invitation
 */
export const useRejectInvitation = () => {
  return useMutation(
    (item: { sharedPortfolioId: number; invitationId: number }) =>
      postRejectInvitation(item.sharedPortfolioId, item.invitationId)
  );
};

/**
 * delete shared portfolio member
 */
export const useDeleteMember = () => {
  return useMutation(postDeleteMember);
};

/**
 * leave shared portfolio
 */
export const useLeaveSharedPortfolio = () => {
  return useMutation(postLeaveSharedProtfolio);
};

/**
 * switch all shared portfolio
 */
export const useSharedPortfolioSwitchAll = () => {
  return useMutation(sharedPortfolioSwitchAll);
};

/**
 * Fetching available switch-all Portfolio
 * @param portfolioId
 * @param enabled
 */
export const useFetchAvailableSwitchAll = (
  portfolioId: string,
  enabled: boolean = true
) => {
  return useQuery(
    ['List Available Switch All', portfolioId],
    () => getAvailableSwitchAllPorto(portfolioId),
    {
      enabled,
    }
  );
};
