import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import getSafely from 'utils/safely';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Analytics from 'utils/Analytics';
import {
  useQueryParams,
  useParsedQueryParams,
  serializeQueryParams,
} from 'utils/routeHelper';
import ModalCompareReksaDana from 'features/common/reksadana/components/modals/ModalCompareReksaDana';
import ModalSortReksaDana from 'features/common/reksadana/components/modals/ModalSortReksaDana';
import {
  useReksadanaProductStateContext,
  useReksadanaProductFunctionContext,
} from '../context/ReksadanaProductContext';
import FilterReksaDanaModal from 'features/common/reksadana/components/modals/FilterReksaDanaModal';
import { GetProductsFilterParams } from 'features/common/reksadana/types';
import styles from './SortAndCompareButton.module.css';
import { useUserInInstitution } from 'features/institution/hooks';
import CompareIcon from 'assets/images/portofolio-asset/CompareIcon';
import SortIcon from 'assets/images/portofolio-asset/SortIcon';
import FilterIcon from 'assets/images/portofolio-asset/FilterIcon';
import CompareIconModal from 'assets/images/portofolio-asset/compare-modal.svg';
import SortIconModal from 'assets/images/portofolio-asset/sort-modal.svg';
import FilterIconModal from 'assets/images/portofolio-asset/filter-modal.svg';
import { useTheme } from 'hooks';
import Show from 'features/common/components/Show/Show';
import { getAnalyticData } from 'features/common/reksadana/utils/filterAnalytic';
import { SortByEnum } from 'features/common/reksadana/constants';
import { FLAG_RETURN_PERIOD } from '../constants';
import { useTranslation } from 'react-i18next';

interface Props {
  onFilterReset?: Function;
  onSortReset?: Function;
  onConfirmCompare?: () => void;
  onToggleCompareModal?: (val?: boolean) => void;
  isFilterHidden?: boolean;
  isWatchlistPage?: boolean;
  showInstantTooltip?: boolean;
  onApply?: (params: onApplyParams) => void;
  onSortDirectionChange?: (direction: string) => void;
  productListCategory?: string;
  inModal?: boolean;
  showOnlyTopProduct?: boolean;
  showCompareButton?: boolean;
}
interface SortAndCompareButtonParams {
  type: string;
  im?: string;
  category?: string;
}

export interface onApplyParams {
  is_instant_redemption?: boolean;
  sort?: string;
  sort_period?: string;
  sort_by?: string;
  is_syariah?: boolean;
  bank_id?: number;
}

const SortAndCompareButton: React.FC<React.PropsWithChildren<Props>> = ({
  onFilterReset,
  onSortReset,
  onConfirmCompare,
  onToggleCompareModal,
  isFilterHidden = false,
  isWatchlistPage = false,
  onApply,
  onSortDirectionChange,
  productListCategory,
  inModal,
  showOnlyTopProduct = false,
  showCompareButton = true,
}) => {
  const { t } = useTranslation(['explore', 'common']);
  // for anaytic purpose
  const urlPath = window.location.pathname;
  const isRoboPage = urlPath.includes('/profiling-select/');

  /**
   *
   * USE STATES
   *
   */

  // Since we don't want to search funds right away after selecting,
  // we save the selected method and period in state, and change url and filter sort context
  // after user has click Terapkan
  const [showSortModal, setShowSortModal] = React.useState<boolean>(false);
  const [periodVisible, setPeriodVisible] = React.useState<boolean>(false);

  /**
   *
   * HOOKS
   *
   */

  const {
    filterSortOrder,
    filterIsSorting,
    filterSortBy,
    filterSortPeriod,
    filterIsInstantRedemption,
    filterIsSyariah,
    filterBankId,
    filterIsTopCompany,
    visibilityCompareModal,
    comparedProductsData,
    filterIsFiltering,
    numberOfActiveFilter,
    numberOfActiveSort,
    filterCurrency,
    filterIm,
    filterMaxBuy,
    filterMeta,
    filterType,
    isUserSyariah,
    SORT_BY_DEFAULT,
    SORT_PERIOD_DEFAULT,
    SORT_ORDER_DEFAULT,
    extraParams,
    extraParamsShownOnURL,
    filterFixedParams,
    filterFixedParamsShown,
  } = useReksadanaProductStateContext();

  const { setFlagReturnPeriod } = useReksadanaProductFunctionContext();

  const [selectedSortBy, setSelectedSortBy] = React.useState<
    string | undefined
  >(String(SORT_BY_DEFAULT));
  const [selectedAscendOrDescend, setSelectedAscendOrDescend] = React.useState<
    string | undefined
  >(String(SORT_ORDER_DEFAULT));
  const [selectedSortPeriod, setSelectedSortPeriod] = React.useState<
    string | undefined
  >(String(SORT_PERIOD_DEFAULT));

  const [flagReturn, setFlagReturn] = React.useState<string | undefined>(
    String(FLAG_RETURN_PERIOD)
  );

  const [showFilterModal, setShowFilterModal] = React.useState(false);

  const [selectedBankName, setSelectedBankName] = useState('Semua Bank');

  const { theme } = useTheme();

  const isDarkMode = theme === 'dark';

  const { setVisibilityCompareModal, setComparedProductsData } =
    useReksadanaProductFunctionContext();

  const history = useHistory();

  /** We need to know if the component on in high return pages
   * For sorting sort_period to handle show product
   */
  const isHighReturn = history.location.pathname.includes('highreturn');

  const { url } = useRouteMatch();

  const { type, category: profilingCategory } =
    useParams<SortAndCompareButtonParams>();

  const query = useQueryParams();
  const parsedQuery = useParsedQueryParams();

  const actionQuery = query.get('action');
  const amountQuery = query.get('amount');
  const serieIdQuery = query.get('serieId');
  const serieTypeQuery = query.get('serieType');
  const dueDateQuery = query.get('dueDate');
  const dayDateQuery = query.get('dayDate');
  const isReinvestMaturity = !!query.get('isReinvestMaturity');

  const isInstitution = useUserInInstitution();

  const sortByQuery = query.get('sort_by');
  const sortOrderQuery = query.get('sort_order');
  const sortPeriodQuery = query.get('sort_period');

  const flagReturnPeriodQuery = query.get('flag_return_period');

  const defaultSortBy =
    SORT_BY_DEFAULT !== undefined ? String(SORT_BY_DEFAULT) : SORT_BY_DEFAULT;

  useEffect(() => {
    setSelectedSortBy(sortByQuery || defaultSortBy);
  }, [defaultSortBy, sortByQuery]);

  useEffect(() => {
    setSelectedAscendOrDescend(sortOrderQuery || SORT_ORDER_DEFAULT);
  }, [SORT_ORDER_DEFAULT, sortOrderQuery]);

  useEffect(() => {
    setSelectedSortPeriod(sortPeriodQuery || SORT_PERIOD_DEFAULT);
  }, [SORT_PERIOD_DEFAULT, sortPeriodQuery]);

  useEffect(() => {
    setFlagReturn(flagReturnPeriodQuery || FLAG_RETURN_PERIOD);
  }, [flagReturnPeriodQuery]);

  /**
   *
   * CONSTS
   *
   */

  const {
    location: { state },
  }: any = history;

  const categoryType = type || profilingCategory || productListCategory;
  const dynamicCategory =
    categoryType === 'im' ? query.get('im') : categoryType;

  const analyticData: any = getAnalyticData(isRoboPage, categoryType, filterIm);

  /** Determine Terapkan button is disabled or not */
  const isTerapkanButtonDisabled: boolean =
    (!selectedSortBy && !selectedSortPeriod) || !selectedSortBy;

  const isResetButtonDisabled: boolean =
    selectedSortBy === String(SORT_BY_DEFAULT) &&
    selectedAscendOrDescend === SORT_ORDER_DEFAULT &&
    selectedSortPeriod === SORT_PERIOD_DEFAULT;

  const filterExtraParams = {
    ...extraParams,
    ...extraParamsShownOnURL,
    ...filterFixedParams,
    ...filterFixedParamsShown,
  };

  /**
   *
   * FUNCTIONS
   *
   */

  const handleOpenSortModal = () => {
    if (visibilityCompareModal) {
      return;
    }

    const stringSortBy =
      filterSortBy !== undefined ? String(filterSortBy) : filterSortBy;
    if (selectedSortBy !== stringSortBy) setSelectedSortBy(stringSortBy);

    const periodVisibility = !!(
      stringSortBy === '5' ||
      stringSortBy === '4' ||
      stringSortBy === '10'
    );
    setPeriodVisible(periodVisibility);

    if (selectedAscendOrDescend !== filterSortOrder)
      setSelectedAscendOrDescend(filterSortOrder);

    if (selectedSortPeriod !== filterSortPeriod)
      setSelectedSortPeriod(filterSortPeriod);

    if (!!filterIsSorting) {
      Analytics.logEventAction({
        eventName: 'search_action',
        parameter: {
          action: 'sort_change',
          trigger: 'click',
          data: {
            ...analyticData,
            bank: selectedBankName,
            fund:
              filterIsSyariah === undefined
                ? 'all'
                : filterIsSyariah
                ? 'syariah'
                : 'conventional',
            instant: filterIsInstantRedemption ? 'instant' : 'all',
            top: filterIsTopCompany ? 'top' : 'all',
            order: filterSortOrder,
            active: filterIsSorting && numberOfActiveSort > 0,
            sort_period: filterSortPeriod,
            ...(!!filterSortBy ? { sort_by: SortByEnum?.[filterSortBy] } : {}), // Default sort
          },
          context: 'search.filter_result',
        },
      });
    } else {
      Analytics.logEventAction({
        eventName: 'search_action',
        parameter: {
          action: 'filter',
          trigger: 'click',
          data: {
            ...analyticData,
            bank: selectedBankName,
            fund:
              filterIsSyariah === undefined
                ? 'all'
                : filterIsSyariah
                ? 'syariah'
                : 'conventional',
            instant: filterIsInstantRedemption ? 'instant' : 'all',
            top: filterIsTopCompany ? 'top' : 'all',
            order: filterSortOrder,
            active: filterIsSorting && numberOfActiveSort > 0,
            sort_period: filterSortPeriod,
            ...(!!filterSortBy ? { sort_by: SortByEnum?.[filterSortBy] } : {}), // Default sort
          },
          context: 'search.filter',
        },
      });
    }
    return setShowSortModal(true);
  };

  const handleCloseSortModal = () => setShowSortModal(false);

  const handleToggleCompareModal = () => {
    setVisibilityCompareModal(!visibilityCompareModal);

    if (onToggleCompareModal) {
      Analytics.logEventAction({
        eventName: 'search_action',
        parameter: {
          action: 'compare_initiate',
          trigger: 'click',
          data: {
            group: state,
            [state]: dynamicCategory,
          },
          context: 'search.group',
        },
      });
      onToggleCompareModal();
    }
  };

  /** Handle Sort by / Urutkan berdasarkan */
  const handleSelectSortMethod = (event: React.MouseEvent<HTMLDivElement>) => {
    const method = event.currentTarget.dataset.id;
    const sortBy = event.currentTarget.dataset.defaultsortby;
    const periodVisibility = !!(method === '5' || method === '4');
    const defaultSortPeriod = '1y';

    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'sort_by',
        trigger: 'click',
        data: {
          ...analyticData,
          sort_by: method,
        },
        context: 'search.result',
      },
    });

    if (selectedSortBy === method) return;

    setPeriodVisible(periodVisibility);
    setSelectedSortBy(method);

    // When click the sort method, set the sort ascending or descending from `sortBy`
    setSelectedAscendOrDescend(sortBy);
    setSelectedSortPeriod(defaultSortPeriod);
  };

  /** Select Period Filter */
  const handleSelectPeriod = (event: React.MouseEvent<HTMLDivElement>) => {
    const period = event.currentTarget.dataset.id;

    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'sort_period',
        trigger: 'click',
        data: {
          ...analyticData,
          period,
        },
        context: 'search.result',
      },
    });

    setSelectedSortPeriod(period);
  };

  /** Select Sort Order Filter */
  const handleSelectOrder = (event: React.MouseEvent<HTMLDivElement>) => {
    const order = event.currentTarget.dataset.id;

    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'sort_order',
        trigger: 'click',
        data: {
          ...analyticData,
          order,
        },
        context: 'search.filter_result',
      },
    });
    setSelectedAscendOrDescend(order);
  };

  /** Terapkan Button Function */
  const handleImplementSort = () => {
    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'sort',
        trigger: 'click',
        data: {
          ...analyticData,
          sort_by: SortByEnum?.[selectedSortBy || ''],
          order: selectedAscendOrDescend,
          period: selectedSortPeriod,
          top: filterIsTopCompany ? 'top' : 'all',
        },
        context: 'search.filter_result',
      },
    });

    // onApply callback with selected params
    if (!!onApply) {
      const onApplyParams = {
        ...(flagReturn ? { flag_return_period: 'true' } : {}),
        ...(selectedSortBy ? { sort_by: selectedSortBy } : {}),
        ...(selectedSortPeriod ? { sort_period: selectedSortPeriod } : {}),
        ...(selectedAscendOrDescend ? { sort: selectedAscendOrDescend } : {}),
        ...(filterIsSyariah !== undefined
          ? { is_syariah: filterIsSyariah }
          : {}),
        ...(filterBankId ? { bank_id: filterBankId } : {}),
        is_instant_redemption: filterIsInstantRedemption,
        ...(filterIsTopCompany ? { is_top_product: 1 } : {}),
        ...(actionQuery === 'reinvestBonds' && {
          action: actionQuery,
          amount: amountQuery,
          serieId: serieIdQuery,
          serieType: serieTypeQuery,
          dueDate: dueDateQuery,
          dayDate: dayDateQuery,
          ...(isReinvestMaturity ? { isReinvestMaturity } : {}),
        }),
      };
      setFlagReturnPeriod(true);
      onApply(onApplyParams);
    }

    handleCloseSortModal();
  };

  // handle reset sort
  const handleResetSort = () => {
    const disabled = isResetButtonDisabled;
    const defaultPeriodVisible = !!defaultSortBy;

    if (disabled) return;

    /**
     * Reset all sort query params
     */
    const newParamsObj = {
      ...parsedQuery,
      sort: null,
      sort_by: null,
      sort_period: null,
      sort_order: null,
    };

    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'sort_cancel',
        trigger: 'click',
        data: {
          ...analyticData,
          sort_by: SortByEnum?.[selectedSortBy || ''],
          order: selectedAscendOrDescend,
          period: selectedSortPeriod,
        },
        context: 'search.filter_result',
      },
    });

    setFlagReturnPeriod(false);
    setSelectedAscendOrDescend(SORT_ORDER_DEFAULT);
    setSelectedSortBy(defaultSortBy);
    setSelectedSortPeriod(SORT_PERIOD_DEFAULT);
    setPeriodVisible(defaultPeriodVisible);

    history.replace({
      search: serializeQueryParams(newParamsObj),
    });
  };

  const handleCancelCompare = () => {
    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'compare_cancel',
        trigger: 'click',
        data: {
          group: state,
          [state]: dynamicCategory,
        },
        context: 'search.group',
      },
    });

    setComparedProductsData([]);

    handleToggleCompareModal();
  };

  const handleRemoveComparedProduct = (
    event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>
  ) => {
    const symbol = getSafely(['currentTarget', 'dataset', 'symbol'], event);
    const id = getSafely(['currentTarget', 'dataset', 'id'], event);

    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'compare_remove',
        trigger: 'click',
        data: {
          company_id: id,
          group: state,
          [state]: dynamicCategory,
        },
        context: 'search.group',
      },
    });

    setComparedProductsData(
      comparedProductsData.filter((item) => item?.symbol !== symbol)
    );
  };

  const handleConfirmCompare = () => {
    const symbolsCompared = comparedProductsData
      .map((fund) => fund?.symbol)
      .join(',');
    const idsCompared = comparedProductsData.map((fund) => fund?.id).join(',');

    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'compare_result',
        trigger: 'click',
        data: {
          company_id: idsCompared,
          group: state,
          [state]: dynamicCategory,
        },
        context: 'search.group',
      },
    });

    const historyObj = {
      pathname: '/compare',
      search: `symbols=${symbolsCompared}`,
      state: {
        lastUrl: url,
      },
    };

    // prevent user back to compare page again when create new comparation product from select product flow
    if (history.location.pathname === '/compare') {
      history.replace(historyObj);
    } else {
      history.push(historyObj);
    }

    // reset compare
    setComparedProductsData([]);
    setVisibilityCompareModal(false);

    // callback function when user click "Bandingkan"
    onConfirmCompare && onConfirmCompare();
  };

  const handleOpenFilterModal = () => {
    if (visibilityCompareModal) return;

    Analytics.logEventAction({
      eventName: 'filter_sort_action',
      parameter: {
        action: 'filter',
        context: 'search.filter_result',
        trigger: 'click',
        data: {
          ...analyticData,
          bank: selectedBankName,
          fund:
            filterIsSyariah === undefined
              ? 'all'
              : filterIsSyariah
              ? 'syariah'
              : 'conventional',
          instant: filterIsInstantRedemption ? 'instant' : 'all',
          top: filterIsTopCompany ? 'top' : 'all',
          sort: filterSortOrder,
          period: filterSortPeriod,
          ...(!!filterSortBy ? { sort_by: SortByEnum?.[filterSortBy] } : {}), // Default sort
        },
      },
    });

    setShowFilterModal(true);
  };

  const handleFilterApply = (data: GetProductsFilterParams) => {
    const {
      bank_id,
      is_instant_redemption,
      syariah,
      is_top_product,
      action,
      amount,
      dueDate,
      serieId,
      serieType,
    } = data;

    const isInstantRedemption = is_instant_redemption !== undefined;
    const isSyariah = syariah === 1 ? true : syariah === 0 ? false : undefined;

    if (!!onApply) {
      const onApplyParams = {
        ...(flagReturnPeriodQuery ? { flag_return_period: 'true' } : {}),
        ...(filterSortBy ? { sort_by: String(filterSortBy) } : {}),
        ...(filterSortPeriod ? { sort_period: filterSortPeriod } : {}),
        ...(filterSortOrder ? { sort: filterSortOrder } : {}),
        ...(isInstantRedemption
          ? { is_instant_redemption: isInstantRedemption }
          : {}),
        ...(isSyariah !== undefined ? { is_syariah: isSyariah } : {}),
        ...(bank_id !== undefined ? { bank_id } : {}),
        ...(is_top_product !== undefined
          ? { is_top_product: String(is_top_product) }
          : {}),
        ...(action === 'reinvestBonds' && {
          action: action,
          amount: amount,
          serieId: serieId,
          serieType: serieType,
          dueDate: dueDate,
        }),
      };
      onApply(onApplyParams);
    }

    setShowFilterModal(false);
  };

  const handleFilterClose = () => {
    setShowFilterModal(false);
  };

  /**
   *
   * USE EFFECTS
   *
   */
  /** Comparing product analytics */
  useEffect(() => {
    if (visibilityCompareModal) {
      Analytics.logEventNavigation({
        eventName: 'navigate',
        parameter: {
          page: 'search',
          view: 'compare',
          data: {
            group: state,
            [state]: dynamicCategory,
          },
        },
      });
    }
  }, [state, dynamicCategory, visibilityCompareModal]);

  const filterIcon =
    inModal && isDarkMode ? (
      <img src={FilterIconModal} alt='filter' />
    ) : (
      <FilterIcon />
    );
  const compareIcon =
    inModal && isDarkMode ? (
      <img src={CompareIconModal} alt='compare' />
    ) : (
      <CompareIcon />
    );
  const sortIcon =
    inModal && isDarkMode ? (
      <img src={SortIconModal} alt='sort' />
    ) : (
      <SortIcon />
    );

  useEffect(() => {
    if (!showSortModal && !showFilterModal && !visibilityCompareModal) {
      const data: any = getAnalyticData(isRoboPage, categoryType, filterIm);

      Analytics.logEventNavigation({
        eventName: 'navigate',
        parameter: {
          page: 'filter_sort',
          view: 'result',
          data: {
            ...data,
            bank: selectedBankName,
            fund:
              filterIsSyariah === undefined
                ? 'all'
                : filterIsSyariah
                ? 'syariah'
                : 'conventional',
            instant: filterIsInstantRedemption ? 'instant' : 'all',
            top: filterIsTopCompany ? 'top' : 'all',
            sort: selectedAscendOrDescend,
            period: selectedSortPeriod,
            sort_by: SortByEnum?.[selectedSortBy || ''],
          },
        },
      });
    }
  }, [
    showSortModal,
    showFilterModal,
    visibilityCompareModal,
    isRoboPage,
    categoryType,
    filterIm,
    selectedBankName,
    filterIsSyariah,
    filterIsInstantRedemption,
    filterIsTopCompany,
    selectedAscendOrDescend,
    selectedSortPeriod,
    selectedSortBy,
  ]);

  return (
    <>
      <div
        data-testid='filter-container'
        className={classNames(styles['bit-compare-menus'], {
          [styles['visibility-hidden']]: isFilterHidden,
          [styles['bit-compare-menus-dark']]: isDarkMode,
          [styles['bit-compare-menus-dark-inmodal']]: isDarkMode && inModal,
        })}
      >
        {/* Filter button */}
        <div
          className={classNames(styles['bit-compare-menus-opt'], {
            [styles['selected']]:
              showFilterModal ||
              (filterIsFiltering && numberOfActiveFilter > 0),
          })}
          onClick={handleOpenFilterModal}
        >
          <div>
            {numberOfActiveFilter > 0 && <>{numberOfActiveFilter}</>}
            {numberOfActiveFilter === 0 && filterIcon}
          </div>
          <span>{t('filter', { ns: 'common' })}</span>
        </div>

        {/* Sort button */}
        <div
          className={classNames(styles['bit-compare-menus-opt'], {
            [styles['selected']]:
              showSortModal ||
              (filterIsSorting &&
                numberOfActiveSort > 0 &&
                flagReturnPeriodQuery),
          })}
          onClick={handleOpenSortModal}
        >
          <div>{sortIcon}</div>
          <span>{t('urutkan', { ns: 'common' })}</span>
        </div>

        {/* Compare button */}
        <Show when={showCompareButton}>
          <div
            className={classNames(styles['bit-compare-menus-opt'], {
              [styles['selected']]: visibilityCompareModal,
            })}
            onClick={handleToggleCompareModal}
          >
            <div>{compareIcon}</div>
            <span>{t('bandingkan', { ns: 'common' })}</span>
          </div>
        </Show>
      </div>

      <ModalCompareReksaDana
        compareSelected={visibilityCompareModal}
        cancelCompare={handleCancelCompare}
        comparedProducts={comparedProductsData}
        removeCompareProduct={handleRemoveComparedProduct}
        confirmCompare={handleConfirmCompare}
      />
      <FilterReksaDanaModal
        visibility={showFilterModal}
        onClickFilter={handleFilterApply}
        onClose={handleFilterClose}
        currency={filterCurrency}
        im={filterIm}
        maxBuy={filterMaxBuy}
        meta={filterMeta}
        type={filterType.join('')}
        showSyariah={!isUserSyariah || isInstitution}
        showTopProductSection={!showOnlyTopProduct}
        extraParams={filterExtraParams}
        categoryType={categoryType}
        sortBy={String(filterSortBy)}
        sort={String(filterSortOrder)}
        period={String(filterSortPeriod)}
        setSelectedBankName={setSelectedBankName}
        highReturnProduct={isHighReturn}
      />
      <ModalSortReksaDana
        sortSelected={showSortModal}
        periodVisible={periodVisible}
        isTerapkanButtonDisabled={isTerapkanButtonDisabled}
        isResetButtonDisabled={isResetButtonDisabled}
        categoryType={categoryType}
        im={filterIm}
        selectedSortBy={selectedSortBy}
        selectedSortPeriod={selectedSortPeriod}
        selectedSortOrder={selectedAscendOrDescend}
        closeSortModal={handleCloseSortModal}
        selectSortMethod={handleSelectSortMethod}
        selectPeriod={handleSelectPeriod}
        implementSort={handleImplementSort}
        selectOrder={handleSelectOrder}
        resetFilter={handleResetSort}
      />
    </>
  );
};

export default SortAndCompareButton;
