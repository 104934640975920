import * as React from 'react';
import IllustrationLight from './light/maintance.svg';
import IllustrationDark from './dark/maintance.svg';
import { ThemedImage } from '@bibitid/uikit-v1';

const themeImageMapping = {
  light: IllustrationLight,
  dark: IllustrationDark,
  default: IllustrationLight,
};

export const MaintanceIllustration: React.FC<
  React.HTMLProps<HTMLPictureElement>
> = (props) => {
  return <ThemedImage imageMap={themeImageMapping} {...props} />;
};

export default MaintanceIllustration;
