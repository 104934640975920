interface MapErrorMessageType {
  [key: string]: any;
}

/** Minimum age for validate input birth date */
export const MIN_AGE_USER = 17;

export const mapErrorMsg: MapErrorMessageType = {
  education: 'Harap pilih pendidikan terakhir kamu.',
  email: 'Harap masukkan email aktif kamu.',
  income: 'Harap pilih kisaran pendapatan kamu dalam setahun.',
  income_source: 'Harap pilih sumber penghasilan utama kamu.',
  income_source_other: 'Harap masukkan sumber pendapatan anda',
  bank: 'Harap Pilih Bank aktif yang akan kamu pergunakan untuk bertransaksi Reksa Dana.',
  bank_owner:
    'Harap masukkan nama rekening sesuai dengan yang di rekening bank kamu.',
  bank_number: 'Harap masukkan nomor rekening dari Bank kamu.',
  nik: 'Harap isi dengan NIK KTP kamu yang masih berlaku',
  nik_url: 'Harap upload KTP kamu yang masih berlaku',
  edd_url: 'Harap Upload Foto Selfie kamu sambil memegang KTP',
  signature_url:
    'Harap isi tanda tangan anda secara elektronik sesuai dengan KTP',
  full_name: 'Harap isi nama lengkap kamu sesuai dengan KTP',
  birth_date: 'Harap isi tanggal lahir kamu sesuai dengan KTP',
  mother_maiden_name: 'Isi dengan nama gadis ibu kandung kamu',
};

export const mapMinimumLengthMsg: MapErrorMessageType = {
  nik: (length: string) =>
    `Panjang NIK KTP yang kamu masukkan kurang dari ${length}`,
  full_name: (length: string) =>
    `Panjang Nama Lengkap kamu yang kamu masukkan kurang dari ${length}`,
  bank_number: (length: string) =>
    `Panjang Nomor Rekening yang kamu masukkan kurang dari ${length}`,
  bank_owner: (length: string) =>
    `Panjang Nama Pemilik Rekening yang kamu masukkan kurang dari ${length}`,
};

export const mapInvalidErrorMsg = {
  birth_date_min_age: (minAge: number | string) =>
    `Maaf umur di bawah ${minAge} tahun belum bisa mendaftar di Bibit`,
};
