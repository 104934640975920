import { getPortoRecurring } from 'features/recurring/networks/services';
import { useQuery } from 'react-query';

/**
 * Fetching list recurring data
 */
export const useFetchListRecurring = (enable: boolean = true) => {
  return useQuery(['Recurring List'], () => getPortoRecurring(), {
    enabled: enable,
  });
};
export default useFetchListRecurring;
