import React, { useCallback, useEffect } from 'react';
import crispUtils, { premiumUserTagSegment } from './utils';
import { useAuth, useProfileData } from 'hooks';
import { useFetchPremiumStats } from 'features/premium/networks/resolver';
import { getEnv } from 'core/env';

const { WebCrispId } = getEnv();

/**
 * Component for binding initiate Crisp Chat Support
 */
const ChatSupport: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { isLogin } = useAuth();

  const { data, isLoading: isLoadingProfile } = useProfileData(isLogin);
  const dataProfile = data?.data?.data;

  const { data: dataPremiumStats } = useFetchPremiumStats(isLogin);
  const beenAPremiumUser = !!dataPremiumStats?.data?.data?.premium_qualified_at;

  const appenScriptCrisp = useCallback((doneLoaded?: () => void) => {
    let d = document;
    window.s = d.createElement('script');
    window.s.src = 'https://client.crisp.chat/l.js';
    window.s.async = 1;
    d.getElementsByTagName('head')[0].appendChild(window.s);

    window.s.onload = () => {
      if (doneLoaded) {
        doneLoaded();
      }
    };
  }, []);

  useEffect(() => {
    if (isLoadingProfile) return;

    const crispToken = dataProfile?.crisp?.sessionid;
    const { email, fullname, phone } = dataProfile?.user ?? {};

    // attach data to window
    window.CRISP_TOKEN_ID = crispToken;
    window.CRISP_WEBSITE_ID = WebCrispId;
    window.CRISP_RUNTIME_CONFIG = {
      session_merge: true,
    };

    if (!!dataProfile) {
      // Create New Session to crisp
      crispUtils.createNewSession({
        email: email,
        fullname: fullname,
        phone: phone,
        CRISP_TOKEN_ID: crispToken,
      });

      // Create new crisp-segment for been a premium user
      if (beenAPremiumUser) {
        premiumUserTagSegment();
      }
    }

    setTimeout(() => {
      appenScriptCrisp();
    }, 500);
  }, [
    isLogin,
    dataProfile,
    beenAPremiumUser,
    appenScriptCrisp,
    isLoadingProfile,
  ]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default ChatSupport;
