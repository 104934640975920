export type SellOrderStatusType =
  | 'wait_payment'
  | 'conf_payment'
  | 'process'
  | 'waiting'
  | 'waiting_unit'
  | 'accept'
  | 'reject_sinvest'
  | 'reject_cs'
  | 'expire';

export const orderStatus = {
  wait_payment: 'Menunggu Pembayaran',
  conf_payment: 'Pembayaran Sedang Diverifikasi',
  process: 'Pembayaran Telah Diterima',
  waiting: 'Menunggu Verifikasi Bank Kustodian',
  waiting_unit: 'Menunggu Verifikasi Bank Kustodian',
  accept: 'Pembelian Berhasil',
  reject_user: 'Pembelian Dibatalkan',
  reject_sinvest: 'Menunggu Verifikasi Bank Kustodian',
  reject_cs: 'Pesanan Ditolak',
  expire: 'Expired',
};

/** Reguler Sell Status */
export const orderStatusSell = {
  process: 'Order Jual Diterima',
  waiting: 'Menunggu Verifikasi Bank Kustodian',
  waiting_unit: 'Menunggu Verifikasi Bank Kustodian',
  accept: 'Order Jual Berhasil',
  reject_sinvest: 'Menunggu Verifikasi Bank Kustodian',
  reject_cs: 'Penjualan Ditolak',
  expire: 'Expired',
  reject_user: 'Penjualan Dibatalkan',
  wait_payment: '-',
  conf_payment: '-',
};

/** Instant Redemption Sell Status */
export const orderInstantRedemptionSellStatus = {
  process: 'Pencairan Dana Berhasil',
  waiting: 'Pencairan Dana Berhasil',
  waiting_unit: 'Pencairan Dana Berhasil',
  accept: 'Pencairan Dana Berhasil',
  reject_sinvest: 'Menunggu Verifikasi Bank Kustodian', // note: won't be triggered
  reject_cs: 'Pencairan Gagal',
  expire: 'Pencairan Gagal',
  wait_payment: 'Pencairan Dalam Proses',
  conf_payment: '-',
};

export const orderStatusRedemptionSBN = {
  accept: 'Redeem Berhasil',
  expire: 'Expired',
  process: 'Order Redeem Diterima',
  waiting: '-',
  waiting_unit: '-',
  reject_sinvest: '-',
  reject_cs: '-',
  wait_payment: '-',
  conf_payment: '-',
};

export const orderStatusSecondaryMarketSBN = {
  accept: 'Penjualan Berhasil',
  expire: 'Expired',
  process: 'Order Penjualan Diterima',
  waiting: '-',
  waiting_unit: '-',
  reject_sinvest: '-',
  reject_cs: 'Pembeli Tidak Ditemukan',
  wait_payment: '-',
  conf_payment: '-',
};

/** Reguler Buy Status for Institution */
export const orderStatusInsti = {
  wait_payment: 'Menunggu Pembayaran',
  conf_payment: 'Pembayaran Sedang Diverifikasi',
  process: 'Pembayaran Telah Diterima',
  waiting: 'Menunggu Verifikasi Bank Kustodian',
  waiting_unit: 'Menunggu Verifikasi Bank Kustodian',
  accept: 'Pembelian Berhasil',
  reject_user: 'Pembelian Dibatalkan',
  reject_sinvest: 'Pesanan Ditolak',
  reject_cs: 'Pesanan Ditolak',
  expire: 'Expired',
};

/** Reguler Sell Status for Institution */
export const orderStatusSellInsti = {
  process: 'Order Jual Diterima',
  waiting: 'Menunggu Verifikasi Bank Kustodian',
  waiting_unit: 'Menunggu Verifikasi Bank Kustodian',
  accept: 'Order Jual Berhasil',
  reject_sinvest: 'Menunggu Verifikasi Bank Kustodian',
  reject_cs: 'Order Jual Ditolak',
  expire: 'Expired',
  reject_user: 'Penjualan Dibatalkan',
  wait_payment: '-',
  conf_payment: '-',
};
