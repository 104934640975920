import React from 'react';
import { DataValue } from '../StockTable.styled';
import { TableDefinitionConfig } from '../StockTable.type';

type Props = {
  config: TableDefinitionConfig;
  value?: string | number;
  raw?: string | number;
};

const TableBodyText: React.FC<Props> = ({ config, value = '', raw = '' }) => {
  const dataSource = config?.dataSource === 'raw' ? raw : value;

  return <DataValue {...config}>{dataSource}</DataValue>;
};

export default TableBodyText;
