import { AxiosResponse } from 'axios';
import http from 'core/http';
import endpoints from './endpoints';

/**
 * GET: auth wskey
 * @param accessToken
 */
export function getAuthWSKey(accessToken: string): Promise<AxiosResponse> {
  return http.get(endpoints.authWSKey, null, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}
