import React from 'react';
import InvestmentCompanyLogo from 'features/common/icons/InvestmentCompanyLogo';
import getSafely from 'utils/safely';
import { Text } from '@bibitid/uikit-v1';
import classNames from 'classnames';
import { ReksaDana } from 'entities/reksadana.reducer';
import { numberToPercentagePointChangeFormat } from 'utils/stringHelper';
import { decideTextColor } from 'features/reksadana/utils/decideTextColor';
import { hasFundDataAdjusted } from 'utils/newRD';
import TopIcon from './assets/top-icon.svg';
import IconWrapper from 'utils/IconWrapper/IconWrapper';
import InstantLogo from 'assets/images/bank-jago/instant-redemption/instant-logo/InstantLogo';
import { IntroInstantRedemptionModal } from 'features/bankjago/components/modals';

interface CardProps {
  fundData: ReksaDana;
  isComparing?: boolean;
  sortPeriod?: string;
  selected?: boolean;
  onClick: (roboCategory: string, symbol: string) => void;
  showModalInstantRedemption: boolean;
  toggleActiveModalInstantRedemption: () => void;
  isInsti?: boolean;
}

const ReksaDanaMaxDrawdownCard: React.FC<
  React.PropsWithChildren<CardProps>
> = ({
  fundData,
  isComparing,
  selected,
  sortPeriod,
  onClick,
  showModalInstantRedemption,
  toggleActiveModalInstantRedemption,
  isInsti,
}) => {
  if (!sortPeriod) return null;
  const { name, investment_manager, is_instant_redemption, is_top_product } =
    fundData;

  let maxDrawdownNumber: number = hasFundDataAdjusted(
    fundData,
    'maxdrawdown',
    sortPeriod
  );

  const decideColor = decideTextColor(
    Number(
      numberToPercentagePointChangeFormat(maxDrawdownNumber).replace(/%/g, '')
    )
  );

  const handleClick = () => {
    const { robocategory, symbol } = fundData;
    onClick(robocategory, symbol);
  };

  return (
    <>
      <div
        className={classNames({
          'bit-filter-result-row': true,
          'bit-filter-drawdown': true,
          'card-selected': selected,
          'card-reksa-shake': isComparing,
        })}
      >
        <div>
          <div className='bit-filter-reksa' onClick={handleClick}>
            <InvestmentCompanyLogo
              type='investment-manager'
              size='square'
              ojkCode={getSafely(['ojkCode'], investment_manager, '')}
            />
            <div>
              <h3>{name}</h3>
            </div>
          </div>
          <div className='wrapper-return-icon'>
            <div className='wrapper-images'>
              {is_top_product === 1 && (
                <IconWrapper
                  backgroundColor={{
                    default: 'var(--green-low)',
                    inModal: 'var(--modal-green-low)',
                  }}
                  $size='20px'
                  className='instant-redemption-top-mutual-fund'
                  $borderRadius='50%'
                >
                  <img
                    src={TopIcon}
                    alt=''
                    style={{ width: '12px', height: '12px' }}
                  />
                </IconWrapper>
              )}
              {is_instant_redemption && !isInsti && (
                <IconWrapper
                  backgroundColor={{
                    default: 'var(--green-low)',
                    inModal: 'var(--modal-green-low)',
                  }}
                  $size='20px'
                  className='instant-redemption-top-mutual-fund'
                  $borderRadius='50%'
                >
                  <InstantLogo
                    alt='instant'
                    style={{ display: 'flex' }}
                    onClick={toggleActiveModalInstantRedemption}
                  />
                </IconWrapper>
              )}
            </div>
            <div
              className={classNames('bit-filter-right-result minus-drawdown')}
            >
              <Text htmlTag='p' color={decideColor}>
                {numberToPercentagePointChangeFormat(maxDrawdownNumber)}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <IntroInstantRedemptionModal
        showModal={showModalInstantRedemption}
        onClose={toggleActiveModalInstantRedemption}
      />
    </>
  );
};

export default ReksaDanaMaxDrawdownCard;
