import * as Sentry from '@sentry/react';
import type { Scope } from '@sentry/react';
import { postData } from 'core/Parent';
import {
  GeneralLogSentryDataTypes,
  RecordUserTypes,
  AnalyticsLogActionTypes,
  AnalyticsLogNavigationTypes,
  AnalyticsLogDebugTypes,
  AnalyticsLogEventThirdPartyTypes,
  ThirtParty,
} from 'utils/Analytics/types';
import { isInsideNativeContainer } from 'utils/validator';
import { hasAnalyticDebug } from './utils';
import {
  getCurrentInstitution,
  getCurrentRoleInstitution,
} from 'features/institution/utils';
import { getEnv } from 'core/env';
import {
  firebaseRecordUser,
  firebaseTrackEvent,
} from 'core/firebase/analytics';
import { initClevertap } from 'core/clevertap';
import {
  clevertapRecordUser,
  clevertapTrackEvent,
} from 'core/clevertap/analytics';
import { randomString } from 'utils/stringHelper';

const { ShowAnalyticsLog } = getEnv();
initClevertap();

export function generalLogError(
  title: string,
  data: GeneralLogSentryDataTypes,
  userid?: string
) {
  Sentry.withScope(function (scope: Scope) {
    scope.setLevel('error');
    if (data) Object.keys(data).map((name) => scope.setExtra(name, data[name]));
    if (userid) {
      Sentry.setUser({
        id: userid,
      });
    }
    if (data.dataTag) {
      scope.setTags(data.dataTag);
    }
    Sentry.captureMessage(title);
  });
}

export function generalLogInfo(title: string, data: GeneralLogSentryDataTypes) {
  Sentry.withScope(function (scope: Scope) {
    scope.setLevel('info');
    if (data) {
      Object.keys(data).map((name) => scope.setExtra(name, data[name]));
    }

    if (data.dataTag) {
      scope.setTags(data.dataTag);
    }

    Sentry.captureMessage(title);
  });
}

/**
 * Record analytics user id
 */
function logUser(id: string) {
  return postData(`analyticsSetUserId_${randomString()}`, {
    fn: 'analyticsSetUserId',
    data: { id },
    timeout: 0,
  });
}
/**
 * Record analytics user props
 */
function logUserProps(props: Record<string, any>) {
  return postData(`analyticsSetUserProps_${randomString()}`, {
    fn: 'analyticsSetUserProps',
    data: props,
    timeout: 0,
  });
}

/**
 * Record clear analytics user
 */
function logClearUser() {
  return postData(`analyticsClearRecordUser_${randomString()}`, {
    fn: 'analyticsClearRecordUser',
    data: {},
    timeout: 0,
  });
}

/**
 * Record User
 */
export function recordUser({ id, ...props }: RecordUserTypes) {
  const isNative = isInsideNativeContainer();
  /** Get Availability Status from Native */
  const isAnalyticsNative =
    window.document.isAnalyticsNative || window.isAnalyticsNative;

  const supportNativeAnalytics = isNative && isAnalyticsNative;

  const Identity = `user_${id}`;

  const isAnalyticsUserProps =
    window.document.isAnalyticsUserProps || window.isAnalyticsUserProps;

  if (supportNativeAnalytics && isAnalyticsUserProps) {
    logUser(id);
    if (!!props) logUserProps({ Identity, ...props });
  }

  if (!isNative) {
    firebaseRecordUser({ id, Identity, ...props });
    clevertapRecordUser({ Identity, ...props });
  }
}

/**
 * Clear User
 */
export function clearRecordUser() {
  const isNative = isInsideNativeContainer();
  /** Get Availability Status from Native */
  const isAnalyticsNative =
    window.document.isAnalyticsNative || window.isAnalyticsNative;

  const supportNativeAnalytics = isNative && isAnalyticsNative;

  const isAnalyticsUserProps =
    window.document.isAnalyticsUserProps || window.isAnalyticsUserProps;
  if (supportNativeAnalytics && isAnalyticsUserProps) {
    return logClearUser();
  }
}

/**
 * Record an analytics event
 * @description There is 3 event type of analytics (navigate, action, debug). You should choose one of _eventKey first to know keys of the parameter
 * */
function logEvent(
  _eventKey: 'navigate' | 'action' | 'debug',
  event:
    | AnalyticsLogNavigationTypes
    | AnalyticsLogActionTypes
    | AnalyticsLogDebugTypes
) {
  const isNative = isInsideNativeContainer();
  /** Get Availability Status from Native */
  const isAnalyticsNative =
    window.document.isAnalyticsNative || window.isAnalyticsNative;
  let postName: string = '';
  const { parameter, eventName } = event;
  const url = parameter.url || window.location.href;
  if (!eventName || !parameter) {
    return console.warn(
      'To record analytics _eventKey and parameter must be defined'
    );
  }

  if (_eventKey === 'navigate') {
    postName = `analyticsLogNavigation`;
  }

  if (_eventKey === 'action') {
    postName = `analyticsLogAction`;
  }

  if (_eventKey === 'debug') {
    postName = `analyticsLogDebug`;
  }

  const institutionId = getCurrentInstitution()?.institution_id;
  const roleInstitution = getCurrentRoleInstitution();

  const eventData = {
    eventName,
    parameter: {
      ...parameter,
      url,
      institution_env: institutionId ? 'institution' : 'individual',
      ...(!!institutionId ? { institution_id: institutionId } : {}),
      ...(!!roleInstitution ? { role: roleInstitution } : {}),
    },
  } as
    | AnalyticsLogNavigationTypes
    | AnalyticsLogActionTypes
    | AnalyticsLogDebugTypes;

  if (ShowAnalyticsLog) {
    // eslint-disable-next-line no-console
    console.log('%cNEW EVENT', 'background: #00ab6b; color: white', eventData);
  }

  if (isNative && isAnalyticsNative) {
    return postData(`${postName}_${randomString()}`, {
      fn: postName,
      data: {
        event_name: eventData.eventName,
        parameter: eventData.parameter,
      },
    });
  }

  if (!isNative) {
    firebaseTrackEvent(eventData);
    clevertapTrackEvent(_eventKey, eventData);
  }
}
/**
 * Record an analytics event navigation
 * */
export function logEventNavigation(event: AnalyticsLogNavigationTypes) {
  return logEvent('navigate', event);
}
/**
 * Record an analytics event action
 * */
export function logEventAction(event: AnalyticsLogActionTypes) {
  return logEvent('action', event);
}

/**
 * Record an analytics event debug
 * */
export function logEventDebug(event: AnalyticsLogDebugTypes) {
  const additionalData =
    typeof event.parameter.data === 'object'
      ? event.parameter.data
      : { data: event.parameter.data };
  const debugEvent = {
    eventName: event.eventName,
    parameter: {
      ...event.parameter,
      data: {
        ...additionalData,
      },
    },
  };
  if (hasAnalyticDebug()) return logEvent('debug', debugEvent);
}

/**
 * @description Record an analytics event for Third Party (for now Branch and Facebook)
 * */
export function logEventThirdParty(event: AnalyticsLogEventThirdPartyTypes) {
  let { eventName, data, sendTo = ['appsflyer'] } = event;
  const postName = 'analyticsLogThirdParty';
  /** Get Availability Status from Native */
  const isThirdPartyNative =
    window.document.isThirdPartyNative || window.isThirdPartyNative;
  const isNative = isInsideNativeContainer();

  const postMessageData: {
    event_name: string;
    data?: Record<string, any>;
    send_to: Array<ThirtParty>;
  } = {
    event_name: eventName,
    send_to: sendTo,
  };

  if (data) {
    postMessageData.data = data;
  }

  if (ShowAnalyticsLog) {
    // eslint-disable-next-line no-console
    console.log('%c3RD PARTY', 'background: #2a79b0; color: white', {
      ...postMessageData,
    });
  }
  if (isNative && isThirdPartyNative) {
    return postData(`${postName}_${randomString()}`, {
      fn: postName,
      data: postMessageData,
    });
  }
}
export default Object.assign(
  {},
  {
    clearRecordUser,
    recordUser,
    logEventNavigation,
    logEventAction,
    logEventDebug,
    logEventThirdParty,
  }
);
