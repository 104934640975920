import React, { FC } from 'react';
import { Plus } from 'react-feather';
import { useCreatePortoModal } from 'features/portfolio/modals/CreatePortfolioRoboModal/context/CreatePortfolioModalProvider';
import DontHavePortoRoboIcon from './assets/dontHavePortoRoboIcon/DontHavePortoRoboIcon';
import styles from './InformationCreatingRoboModal.module.css';

import { Text, Flex, Modal, ButtonNew } from '@bibitid/uikit-v1';

const InformationCreatingRoboModal: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const {
    createPortfolioRoboModalVisible,
    step,
    closeModal,
    setStep,
    resetCreatePortfolioRoboModal,
  } = useCreatePortoModal();
  const visible = createPortfolioRoboModalVisible && step === 'information';

  const handleNextStep = () => {
    setStep('choose');
  };

  const handleCloseModal = () => {
    closeModal();
    resetCreatePortfolioRoboModal();
  };

  return (
    <Modal visible={visible} onClose={handleCloseModal}>
      <Modal.Header centerTitle showBackButton={false} showClose={true} />
      <Modal.Body>
        <Flex direction='column' justify='center'>
          <div className={styles['spot-hero']}>
            <DontHavePortoRoboIcon alt='dont have porto robo' />
          </div>
          <Text type='subtitle2' className={styles['content-text']}>
            Kamu Belum Punya Portofolio Robo
          </Text>
          <Text type='subtitle1' className={styles['content-text']}>
            Voucher ini hanya dapat digunakan untuk pembelian di portofolio
            robo.
          </Text>
        </Flex>
      </Modal.Body>
      <Modal.Footer
        primaryButton={
          <ButtonNew
            icon={<Plus size={16} style={{ marginRight: 4 }} />}
            content='Buat Portofolio Robo'
            onClick={handleNextStep}
          />
        }
      />
    </Modal>
  );
};

export default InformationCreatingRoboModal;
