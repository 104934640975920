import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translation as commonId } from './locales/id/common';
import { translation as commonEN } from './locales/enUS/common';
import { translation as exploreEN } from './locales/enUS/explore';
import { translation as exploreId } from './locales/id/explore';
import { translation as authenticationId } from './locales/id/authentication';
import { translation as authenticationEN } from './locales/enUS/authentication';

const resources = {
  en: {
    common: commonEN,
    explore: exploreEN,
    authentication: authenticationEN,
  },
  id: {
    common: commonId,
    explore: exploreId,
    authentication: authenticationId,
  },
};

class LanguangeDetector {
  static type: string;
  type: 'languageDetector';

  constructor(services: any, options = {}) {
    this.type = 'languageDetector';
  }

  detect() {
    let lang = localStorage.getItem('language') || 'id';
    if (lang !== 'en' && lang !== 'id') {
      lang = 'id';
    }
    window.__localeId__ = lang;
    document.documentElement.lang = lang;
    return lang;
  }
}

LanguangeDetector.type = 'languageDetector';

i18n
  .use(LanguangeDetector)
  .use(initReactI18next)
  .init({
    resources,
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', (val) => {
  window.__localeId__ = { en: 'enUS', id: 'id' }[val];
});

export default i18n;
