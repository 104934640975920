import React, { useState } from 'react';

export interface SIPStockDetail {
  iconUrl?: string;
  name?: string;
  symbol?: string;
  isSharia?: 0 | 1;
}

export interface SIPSahamShortFormState {
  chosenStock?: SIPStockDetail;
  buyType: 'lot' | 'price';
  buyLot: number;
  buyUnitPrice?: number;
}

export interface SIPSahamCreationFormState extends SIPSahamShortFormState {}

interface SIPSahamContextState {
  SIPSahamShortFormHomeState: SIPSahamShortFormState;
  SIPSahamShortFormMainPageState: SIPSahamShortFormState;
}

interface SIPSahamContextFunction {
  setSIPSahamShortFormHomeState: (data: SIPSahamShortFormState) => void;
  setSIPSahamShortFormMainPageState: (data: SIPSahamShortFormState) => void;
}

// Context State
export const State = React.createContext<SIPSahamContextState | undefined>(
  undefined
);

// Context Function
const Func = React.createContext<SIPSahamContextFunction | undefined>(
  undefined
);

/**
 * Provider Context
 */
const SIPSahamContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [SIPSahamShortFormHomeState, setSIPSahamShortFormHomeState] = useState<SIPSahamShortFormState>({
    chosenStock: undefined,
    buyType: 'lot',
    buyLot: 0,
    buyUnitPrice: undefined,
  });

  const [SIPSahamShortFormMainPageState, setSIPSahamShortFormMainPageState] = useState<SIPSahamShortFormState>({
    chosenStock: undefined,
    buyType: 'lot',
    buyLot: 0,
    buyUnitPrice: undefined,
  });

  return (
    <State.Provider
      value={{
        SIPSahamShortFormHomeState,
        SIPSahamShortFormMainPageState
      }}
    >
      <Func.Provider
        value={{
          setSIPSahamShortFormHomeState,
          setSIPSahamShortFormMainPageState
        }}
      >
        {children}
      </Func.Provider>
    </State.Provider>
  );
};

export default SIPSahamContextProvider;

export const useSIPSahamContextState = () => {
  const ctx = React.useContext(State);
  if (!ctx) {
    throw new Error('useSIPSahamContextState only in page IndexRecurring');
  }
  return ctx;
};

export const useSIPSahamContextFunction = () => {
  const ctx = React.useContext(Func);
  if (!ctx) {
    throw new Error('useSIPSahamContextFunction only in page IndexRecurring');
  }
  return ctx;
};
