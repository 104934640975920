import { AxiosResponse } from 'axios';
import { useQuery, useMutation } from 'react-query';
import http from 'core/http';
import {
  PortfolioCategoryDetailResponse,
  PortfolioResponse,
} from 'features/portfolio/types';
import {
  CompanyPickerData,
  CreateInstitutionDataBody,
  formUpdateEndpoint,
  PatchInstitutionDataBody,
  InstitutionStats,
  InstitutionData,
  InsitutionWealthSpecialist,
} from './institutionRegister.type.d';
import { BibitApiSuccessResponse } from 'services';
import { ParamGetPayment } from 'services/payment';
import { PaymentMethod } from 'services/payment.type';
import { ParamOrderBuy } from 'services/transaction.type';
import { endpoints } from './endpoints';
import { getListBank } from 'services/tools';
import { PortfolioInstitutionDetail } from './porto-institution.type.d';
import { RestAPICore } from 'core/http/http.type.d';
import {
  EarlyAccess,
  EarlyAccessPostAPIResponse,
  EarlyAccessQuotaAPIResponse,
} from './early-access.type';
import {
  InstitutionPendingOrders,
  InstitutionProfileDetail,
  ProfileInstitution,
} from '../types/institution.type';

export interface ApiSuccessResponse<T> {
  data: T;
  message: string;
}
export type ApiResponse<T> = Promise<AxiosResponse<ApiSuccessResponse<T>>>;

type InstitutionProfileResponse = ApiResponse<ProfileInstitution>;
type InstitutionProfileDetailResponse = ApiResponse<InstitutionProfileDetail>;

export const getInstitutionProfile = (): InstitutionProfileResponse => {
  return http.get(endpoints.profile);
};

export const getInstitutionProfileDetail = (
  securekey: string
): InstitutionProfileDetailResponse => {
  return http.get(
    endpoints.profileDetail,
    {},
    {
      headers: {
        'x-secure-key': securekey,
      },
    }
  );
};

export const getInstitutionPortfolios = (): PortfolioResponse => {
  return http.get(endpoints.portfolio);
};

export const getInstitutionPortfolioById = (categoryId: string) => {
  return http.get<RestAPICore<PortfolioInstitutionDetail>>(
    endpoints.portfolioCategoryID(categoryId)
  );
};

export const updateInstitutionPortfolioById = ({
  categoryId,
  name,
}: {
  categoryId: string;
  name: string;
}): PortfolioCategoryDetailResponse => {
  return http.patch(endpoints.portfolioCategoryID(categoryId), { name });
};

export const updateDotProfileInstitutionNotifStats = (payload: {
  dot_notifications: {
    profile: number;
  };
}) => {
  return http.patch(endpoints.notification, payload);
};

export const updateTooltipsInstitutionNotifStats = (payload: {
  tooltips: {
    switch_institution_account: number;
  };
}) => {
  return http.patch(endpoints.notification, payload);
};

export const fetchInstitutionAllPaymentMethod = (
  payload: ParamGetPayment
): Promise<AxiosResponse<BibitApiSuccessResponse<PaymentMethod[]>>> => {
  return http.get(endpoints.paymentMethod, payload);
};

export const postInstitutionBuy = (payload: ParamOrderBuy) => {
  return http.post(endpoints.buy, payload);
};

export const getPendingOrderStats = (): Promise<
  AxiosResponse<BibitApiSuccessResponse<InstitutionPendingOrders[]>>
> => {
  return http.get(endpoints.pendingOrder);
};

export const getInstitutionRealizedProfitOrderDetails = (id: string) => {
  return http.get(endpoints.realizedProfitOrderDetails(id));
};

/**
 * Create institution leads registration
 */
export const postInstitutionLeads = (
  payload: EarlyAccess
): Promise<AxiosResponse<EarlyAccessPostAPIResponse>> => {
  return http.post(endpoints.institutionLeads, payload);
};

/**
 * Update institution leads registration
 */
export const patchInstitutionLeads = (payload: {
  source: number;
}): Promise<AxiosResponse<EarlyAccessPostAPIResponse>> => {
  return http.patch(endpoints.institutionLeads, payload);
};

/**
 * Get early access registration quota
 */
export const getEarlyAccessStats = (): Promise<
  AxiosResponse<EarlyAccessQuotaAPIResponse>
> => {
  return http.get(endpoints.earlyAccessStats);
};

/**
 * Institution Register Step (1-2)
 * Fetch picker data, submit and update form API
 */
// Step 1 - Company Data
export const useFetchCompanyTypes = (enabled: boolean = true) => {
  return useQuery(
    ['Get Company Types'],
    () => http.get<RestAPICore<CompanyPickerData>>(endpoints.companyTypes),
    { enabled: enabled }
  );
};

export const useFetchCompanyCharacteristics = (enabled: boolean = true) => {
  return useQuery(
    ['Get Company Characteristics'],
    () =>
      http.get<RestAPICore<CompanyPickerData>>(
        endpoints.companyCharacteristics
      ),
    { enabled: enabled }
  );
};

// Step 2 - Company Financial
export const useFetchCompanyRiskProfiles = (enabled: boolean = true) => {
  return useQuery(
    ['Get Company Risk Profiles'],
    () =>
      http.get<RestAPICore<CompanyPickerData>>(endpoints.companyRiskProfiles),
    { enabled: enabled }
  );
};

export const useFetchCompanyIncomeLevels = (enabled: boolean = true) => {
  return useQuery(
    ['Get Company Income Levels'],
    () =>
      http.get<RestAPICore<CompanyPickerData>>(endpoints.companyIncomeLevels),
    { enabled: enabled }
  );
};

export const useFetchCompanyGoals = (enabled: boolean = true) => {
  return useQuery(
    ['Get Company Risk Goals'],
    () => http.get<RestAPICore<CompanyPickerData>>(endpoints.companyGoals),
    { enabled: enabled }
  );
};

export const useFetchCompanySourceIncomes = (enabled: boolean = true) => {
  return useQuery(
    ['Get Company Source Incomes'],
    () =>
      http.get<RestAPICore<CompanyPickerData>>(endpoints.companySourceIncomes),
    { enabled: enabled }
  );
};

export const useFetchCompanyAssets = (enabled: boolean = true) => {
  return useQuery(
    ['Get Company Assets'],
    () => http.get<RestAPICore<CompanyPickerData>>(endpoints.companyAssets),
    { enabled: enabled }
  );
};

export const useFetchCompanyProfits = (enabled: boolean = true) => {
  return useQuery(
    ['Get Company Profits'],
    () => http.get<RestAPICore<CompanyPickerData>>(endpoints.companyProfits),
    { enabled: enabled }
  );
};

export const useFetchCompanyIndustries = (enabled: boolean = true) => {
  return useQuery(
    ['Get Company Industries'],
    () => http.get<RestAPICore<CompanyPickerData>>(endpoints.companyIndustries),
    { enabled: enabled }
  );
};

export const useFetchBankList = (enabled: boolean = true) => {
  return useQuery(['Get Bank List'], getListBank, { enabled: enabled });
};

export const useFetchInstitutionStats = (enabled: boolean = true) => {
  return useQuery(
    ['Get Insitution Stats'],
    () => http.get<RestAPICore<InstitutionStats>>(endpoints.institutionStats),
    { enabled: enabled }
  );
};

export const useFetchRejectedReasons = (enabled: boolean = true) => {
  return useQuery(
    ['Get Rejected Reasons'],
    () => http.get<RestAPICore<string[]>>(endpoints.rejectedList),
    { enabled: enabled }
  );
};

export const useFetchWealthSpecialist = (enabled: boolean = true) => {
  return useQuery(
    ['Get Wealth Specialist'],
    () =>
      http.get<RestAPICore<InsitutionWealthSpecialist>>(
        endpoints.wealthSpecialist
      ),
    { enabled: enabled }
  );
};

// Create Institution Data
export const usePostInstitutionData = () => {
  const postCreateInstitution = (body: CreateInstitutionDataBody) =>
    http.post(endpoints.createInstitutionData, body);
  return useMutation(postCreateInstitution);
};

export const usePatchInstitutionData = (formEndpoint: formUpdateEndpoint) => {
  const postCreateInstitution = (body?: PatchInstitutionDataBody) =>
    http.patch(endpoints.updateInstitutionData(formEndpoint), body);
  return useMutation(postCreateInstitution);
};

export const useFetchInstitutionData = (
  enabled: boolean = true,
  onSuccess?: (data: AxiosResponse<RestAPICore<InstitutionData>, any>) => void,
  onError?: (error: unknown) => void
) => {
  return useQuery(
    ['Get Insitution Data'],
    () =>
      http.get<RestAPICore<InstitutionData>>(endpoints.createInstitutionData),
    {
      enabled: enabled,
      onSuccess: onSuccess,
      onError: onError,
      refetchOnMount: 'always',
    }
  );
};

/**
 * Check if company email already exist
 */
export const postCheckCompanyEmail = (payload: { email: string }) => {
  return http.post(endpoints.checkCompanyEmail, payload);
};

/**
 * Check if company email already exist
 */
export const patchCancelRegistration = () => {
  return http.patch(endpoints.cancelRegistration);
};

/**
 * Close RDN Banner
 */
export const patchCloseRDNBanner = (payload: {
  hide_register_institution_rdn_wallet_banner: number;
}) => {
  return http.patch(endpoints.institutionSettings, payload);
};
