/** List of route that make home tabbar active */
export const activeHome = ['/', 'home'];
export const bibitPlusActiveHome = ['/', 'home', '/event'];

/** List of route that make portfolio tabbar active */
export const activePortofolio = [
  '/portfolio',
  '/portfolio/:categoryId/details',
  '/portfolio/performance-chart',
];

/** List of route that make transaction tabbar active */
export const activeTransaction = [
  '/orders',
  '/orders/history',
  '/orders/:orderId/detail',
  '/transactions',
  '/transactions/order/reksadana',
  '/transactions/order/stock',
  '/transactions/order/sbn',
  '/transactions/order/stable_earn',
  '/transactions/order/all',
  '/transactions/history/reksadana',
  '/transactions/history/stock',
  '/transactions/history/sbn',
  '/transactions/history/stable_earn',
];

/** List of route that make Profile tabbar active */
export const activeProfile = [
  '/profile',
  '/profile/settings',
  '/profile/glossary',
  '/profile/user/detail',
];

/** List of route that make Explore tabbar active */
export const activeExplore = [
  '/explore',
  '/explore/mutualfund',
  '/explore/stock',
  '/explore/stable-earn',
  '/explore/bonds/:type',
  '/categories/:type',
  '/categories/best/:type',
  '/categories/highreturn/:type',
];
