import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import useOnClickOutside from 'hooks/useOnClickOutside';
import InvestmentCompanyLogo from 'features/common/icons/InvestmentCompanyLogo';
import getSafely from 'utils/safely';
import { hasFundDataAdjusted } from 'utils/newRD';
import stringHelper from 'utils/stringHelper';
import { useHistory } from 'react-router-dom';
import { ReksaDana } from 'entities/reksadana.reducer';
import './reksadana-cards.css';
import { Text, WhiteSpace } from '@bibitid/uikit-v1';
import PercentageFormat from '../components/PercentageFormat';
import { useReksadanaProductStateContext } from 'features/product/reksadana/context/ReksadanaProductContext';
import { useQueryParams } from 'utils/routeHelper';

interface ReksaDanaCardProps {
  fundData: ReksaDana;
  category?: string;
  /**
   * Comparing Product
   */
  isComparing?: boolean;
  /**
   * Show Lihat Detail Link
   */
  showLink?: boolean;
  /**
   * Show RD type (used in /watchlist)
   */
  showProductType?: boolean;
  /**
   * selected: To show green border when comparing (Bandingkan) and selected state when filtering (Filter)
   */
  selected?: boolean;
  /**
   * selectedIcon:  To show Checkmark icon
   */
  selectedIcon?: boolean;
  /**
   * Render custom dropdown element
   */
  dropdownElement?: keyof JSX.IntrinsicElements;
  /**
   * Config kebabicon / menu icon
   */
  kebabIconConfig?: { show: boolean; onClick: (fundsymbol: string) => void };
  /**
   * Card function
   */
  onClick: (roboCategory: string, symbol: string) => void;
  /**
   * Render custom right content element
   */
  rightContent?: React.ReactNode;
  /**
   * Render custom footer (under RD Card) content element
   */
  footerContent?: React.ReactNode;
  /**
   * Custom container classname
   */
  containerClassname?: string;
  /**
   * Handle custom navigate mutual fund detail function (Lihat Detail)
   * Used in BestProductListModal to show modal
   * instead of redirecting to a product detail page
   */
  onCustomNavigateMutualFundDetail?: (symbol: string) => void;
}

const ReksaDanaGenericCard: React.FC<
  React.PropsWithChildren<ReksaDanaCardProps>
> = ({
  fundData,
  isComparing,
  showLink = false,
  kebabIconConfig = { show: false, onClick: () => {} },
  showProductType = false,
  selected,
  selectedIcon = false,
  onClick,
  dropdownElement,
  rightContent,
  footerContent,
  containerClassname = '',
  onCustomNavigateMutualFundDetail,
}) => {
  const query = useQueryParams();
  const ref = useRef(null);
  const history = useHistory();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const { filterSortPeriod, filterSortBy } = useReksadanaProductStateContext();

  const getQueryFlagReturnPeriod = query.get('flag_return_period');

  const navClassName = classNames({
    redval:
      (hasFundDataAdjusted(fundData, 'simplereturn', filterSortPeriod) ?? 0) <
      0,
    greenval:
      (hasFundDataAdjusted(fundData, 'simplereturn', filterSortPeriod) ?? 0) >
      0,
    black:
      hasFundDataAdjusted(fundData, 'simplereturn', filterSortPeriod) === 0 ||
      hasFundDataAdjusted(fundData, 'simplereturn', filterSortPeriod) ===
        undefined,
  });

  const handleClick = () => {
    const { robocategory, symbol } = fundData;
    onClick(robocategory, symbol);
  };

  const handleKebabIcon = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();

    const fundsymbol = event.currentTarget.dataset.fundsymbol;

    setShowDropdown(true);

    if (fundsymbol) {
      kebabIconConfig?.onClick(fundsymbol);
    }
  };

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  const handleMutualFundDetailNavigation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();

    const symbol = event.currentTarget?.dataset?.symbol;

    if (onCustomNavigateMutualFundDetail && !!symbol) {
      onCustomNavigateMutualFundDetail(symbol);
      return;
    }

    return history.push(`/reksadana/${symbol}`);
  };

  useOnClickOutside(ref, handleCloseDropdown);

  const decideTitleReturnPeriod = () => {
    switch (filterSortPeriod) {
      case '1y':
        return '1 Year Return';
      case '1d':
        return '1 Day Return';
      case '1m':
        return '1 Month Return';
      case '3m':
        return '3 Month Return';
      case 'ytd':
        return 'YTD Return';
      case '3y':
        return '3 Year Return';
      case '5y':
        return '5 Year Return';
      default:
        return '1 Year Return';
    }
  };

  return (
    <>
      <div
        data-symbol={fundData.symbol}
        data-testid={`reksadana-card-${fundData.symbol}`}
        onClick={handleClick}
        className={classNames('am-card', {
          'card-selected': selected || selectedIcon,
          'card-reksa-shake': isComparing,
          [containerClassname]: containerClassname,
        })}
      >
        <div className='porto-card bit-reksa-generic-card'>
          <div className='bit-porto-card-top'>
            {/* Logo */}
            <div className='porto-card-logo'>
              <InvestmentCompanyLogo
                type='investment-manager'
                size='square'
                ojkCode={getSafely(
                  ['investment_manager', 'ojkCode'],
                  fundData,
                  ''
                )}
              />
            </div>
            {/* Reksadana Name */}
            <div className='porto-card-name'>
              <div className='top-title'>
                <div>{fundData.name}</div>
                <div className='show-in-1030'>{rightContent}</div>
              </div>
              {showProductType && <div>{fundData.type}</div>}
              {!!showLink && (
                <div
                  data-symbol={fundData.symbol}
                  onClick={handleMutualFundDetailNavigation}
                  className='porto-card-detail-text-wrapper'
                >
                  <Text type='body1' htmlTag='a'>
                    Lihat Detail
                  </Text>
                </div>
              )}
            </div>

            {/* Checkmark icon or custom right content (ex: instant redemption logo) */}
            {<div className='hide-in-1030'>{rightContent}</div>}
          </div>
          {/* Bottom side */}
          <div className='porto-card-btm'>
            <div className='porto-card-btm-child'>
              <div>{decideTitleReturnPeriod()}</div>
              <div
                className={classNames(navClassName, {
                  bold:
                    getQueryFlagReturnPeriod === 'true' &&
                    (filterSortBy === 5 || filterSortBy === 10),
                })}
              >
                <PercentageFormat
                  value={hasFundDataAdjusted(
                    fundData,
                    'simplereturn',
                    filterSortPeriod
                  )}
                />
              </div>
            </div>
            <div className='porto-card-btm-child'>
              <div>Expense Ratio</div>
              <div
                className={
                  getQueryFlagReturnPeriod === 'true' && filterSortBy === 3
                    ? 'bold'
                    : ''
                }
              >
                {stringHelper.numberToPercentagePointChangeFormat(
                  fundData?.expenseratio?.percentage === 0
                    ? undefined
                    : fundData?.expenseratio?.percentage,
                  {
                    forceSign: false,
                    mantissa: 2,
                  }
                )}
              </div>
            </div>
            <div className='porto-card-btm-child'>
              <div>Total AUM</div>
              <div
                className={
                  getQueryFlagReturnPeriod === 'true' && filterSortBy === 2
                    ? 'bold'
                    : ''
                }
              >
                {stringHelper.numberToAveragedLetter(
                  fundData?.aum?.value,
                  true,
                  { mantissa: 2 }
                )}
              </div>
            </div>
          </div>
          {/* Menu or icon on the right side */}
          {/* --- Kebab icon = 3 vertical dots --- */}
          {kebabIconConfig.show && (
            <div
              ref={ref}
              className={classNames('moreBullet', {
                'show-popup': showDropdown,
              })}
              onClick={handleKebabIcon}
              data-fundsymbol={fundData?.symbol}
              data-testid={`kebab-icon-${fundData?.symbol}`}
            >
              <b />
              <b />
              <b />
              <div className='bit-more-dropdown-popup'>{dropdownElement}</div>
            </div>
          )}
        </div>
      </div>
      {/* Render optional footer content under RD card */}
      {footerContent && footerContent}
      <WhiteSpace size='xl' />
    </>
  );
};

export default ReksaDanaGenericCard;
