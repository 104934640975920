/* istanbul ignore file */
import * as Sentry from '@sentry/react';
import history from 'utils/History';
import { getEnv } from 'core/env';

declare global {
  interface Window {
    VERSION: string;
  }
}

const ignorePatterns = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  'ChunkLoadError',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  /^No error$/,
  /recaptcha\/releases\//, // ignore recaptcha
  /__show__deepen/,
  /Access is denied/,
  /anonymous function: captureException/,
  /Cannot read property '(getReadModeConfig|getReadModeExtract|getReadModeRender)' of undefined/,
  /Blocked a frame with origin/,
  /console is not defined/,
  /DataCloneError/,
  /event is not defined/,
  /feedConf/,
  /MyIPhoneApp/,
  /snapchat.com/,
  /vid_mate_check is not defined/,
  /win\.document\.body/,
  /window\._sharedData\.entry_data/,
  /ztePageScrollModule/,
  /AxiosError/,
  /Java object is gone/,
  'TypeError: Failed to fetch',
  'TypeError: NetworkError when attempting to fetch resource.',
  /.getRestrictions/,
];

function containsIgnoredPattern(stack: string): boolean {
  const ignorePatternsRegex: RegExp[] = [
    /^No error$/,
    /recaptcha\/releases\//, // ignore recaptcha
    /__show__deepen/,
    /Access is denied/,
    /anonymous function: captureException/,
    /Cannot read property '(getReadModeConfig|getReadModeExtract|getReadModeRender)' of undefined/,
    /Blocked a frame with origin/,
    /console is not defined/,
    /DataCloneError/,
    /event is not defined/,
    /feedConf/,
    /MyIPhoneApp/,
    /snapchat.com/,
    /vid_mate_check is not defined/,
    /win\.document\.body/,
    /window\._sharedData\.entry_data/,
    /ztePageScrollModule/,
    /AxiosError/,
    /Java object is gone/,
    /Failed to execute 'replaceState' on 'History'/,
    /EncodingError/,
    /Loading CSS chunk/,
    /Non-Error promise rejection captured with keys/,
    /wt\(gtm\)/,
    /Unexpected end of script/,
    /Unexpected EOF/,
    /getRestrictions/, // ignore gtm error
  ];
  return !!stack && ignorePatternsRegex.some((pattern) => pattern.test(stack));
}

const { SentryDsn, AppVersion, SentryEnv } = getEnv();

// Initialize sentry for tracking JS Error
Sentry.init({
  dsn: SentryDsn,
  release: AppVersion,
  environment: SentryEnv,
  tracesSampleRate: 0.001,
  beforeSend: (event, hint) => {
    if (hint) {
      const error = hint.originalException;
      // We will filter ignored error pattern here, until our Sentry plan is upgraded to business plan
      if (
        error instanceof Error &&
        error.stack &&
        containsIgnoredPattern(error.stack)
      ) {
        // Don't send if stack contains ignored pattern
        return null;
      }

      // ignoring exact error Hint "Timeout" due uncertainty Error
      // https://sentry.lab.bibit.id/organizations/bibit/issues/7976/?environment=production&project=4&query=is%3Aunresolved
      // https://github.com/getsentry/sentry-javascript/issues/2514
      if (error === 'Timeout') {
        return null;
      }
    }
    return event;
  },
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/api\.bibit\.id/,
    /^https:\/\/api\.lab\.bibit\.id/,
  ],
  ignoreErrors: ignorePatterns,
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

Sentry.setTag('mobileVersion', window.VERSION ? window.VERSION : 'unknown');
