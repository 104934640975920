export const translation = {
  pasar_uang: 'Pasar Uang',
  reset: 'Reset',
  stable_earn: 'Stable Earn',
  max_drowdown: 'Max Drawdown',
  obligasi_fr: 'Obligasi FR',
  aum: 'AUM',
  all: 'All',
  filter: 'Filter',
  syariah: 'Syariah',
  obligasi: 'Obligasi',
  pencairan_instant: 'Pencairan Instan',
  keuntungan: 'Keuntungan',
  total_aum: 'Total AUM',
  watchlist: 'Watchlist',
  pembelian: 'Pembelian',
  beli: 'Beli',
  urutkan: 'Urutkan',
  imbal_hasil: 'Imbal Hasil',
  tahun: 'Tahun',
  pilih_reksadana: 'Pilih Reksadana',
  topup: 'Top Up',
  return: 'Return',
  reksadana: 'Reksa Dana',
  tambah: 'Tambah',
  lihat_semua: 'Lihat Semua',
  minimum_pembelian: 'Minimum Pembelian',
  year_to_date: 'Year to Date',
  top_reksadana: 'Top Reksa Dana',
  lainnya: 'Lainnya',
  sbn_retail: 'SBN Retail',
  penjualan: 'Penjualan',
  portfolio: 'Portfolio',
  robo: 'Robo',
  konvensional: 'Konvensional',
  hari: 'Hari',
  ubah: 'Ubah',
  semua: 'Semua',
  explore: 'Explore',
  bibit_plus: 'BIbit Plus',
  expense_ratio: 'Expense Ratio',
  investasi: 'Investasi',
  jual: 'Jual',
  average_yield: 'Average Yield',
  saham: 'Saham',
  nav: 'NAV',
  bulan: 'Bulan',
  bandingkan: 'Bandingkan',
  jenis_reksadana: 'Jenis Reksa Dana',
  alokasi_dana: 'Alokasi Dana',
  lanjutkan: 'Lanjutkan',
  pilih: 'Pilih',
  baik_saya_mengerti: 'Baik, Saya Mengerti',
  simpan: 'Simpan',
  pilih_tanggal: 'Pilih Tanggal',
  batal: 'Batal',
  mohon_tunggu: 'Mohon Tunggu',
  konfirmasi: 'Konfirmasi',
  cek_ulang: 'Cek Ulang',
  hapus: 'Hapus',
  selesai: 'Selesai',
  atau: 'atau',
  kirim: 'Kirim',
  cari: 'Cari',
  bantuan: 'Bantuan',
  rb: 'rb',
  jt: 'jt',
  m: 'M',
  t: 'T',
  benchmark: 'Benchmark',
  index_fund: 'Index Fund',
  return_tertinggi: 'Return Tertinggi',
  reksadana_usd: 'Reksa Dana USD',
  minimal_reksadana: 'Minimal 10rb',
  filter_berdasarkan: 'Filter Berdasarkan',
  terapkan: 'Terapkan',
  urutkan_dari: 'Urutkan dari',
  bahasa: 'Bahasa',
};
