/* eslint-disable no-useless-escape */
import React from 'react';
import { Toast } from '@bibitid/uikit-v1';

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

// TO CHECK IS METHOD IS PART OF VIRTUAL ACCOUNT
export function isMethodVA(paymentKey = '') {
  const vaKeyCollection = [
    'vabni',
    'oy_bri',
    'oy_permata',
    'vapermata',
    'oy_cimb',
    'oy_mandiri',
    'vabca',
    'bill_mandiri',
    'other_vabni',
  ];
  return vaKeyCollection.some((e) => e === paymentKey);
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function notifClick() {
  if (typeof window.document.notifClick === 'function')
    window.document.notifClick();
}

export function _showErrorToast(err = '', styles) {
  const theStyle = styles
    ? styles
    : {
        position: 'absolute',
        top: 0,
        bottom: 0,
        paddingBottom: 10,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
      };
  if (err)
    Toast.show({
      content: (
        <div style={theStyle} onClick={() => Toast.clear()}>
          {err}
        </div>
      ),
      duration: 3000,
      icon: 'fail',
    });
}

/**
 * Get user bank data
 * @param {Object} profile - profile object
 */
export const getUserBankData = (profile) => {
  return {
    bank_name: profile ? profile.bank_name : '',
    bank_account_number: profile ? profile.bank_account_number : '',
    bank_account_name: profile ? profile.bank_account_name : '',
  };
};

export function getPreferenceValueFromString(preferenceData, string) {
  if (preferenceData.length > 0) {
    const filterData = preferenceData?.filter((item) => {
      return Object.keys(item)[0] === string;
    });

    const getData = filterData?.[0]?.[string] || false;

    return getData;
  } else {
    return false;
  }
}
