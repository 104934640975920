/** Endpoints api for shared-portfolio */
const endpoints = {
  sharedPortfolioCreate: (category_id: string) =>
    `/portfolios/${category_id}/shared/create`,
  sharedPortfolioReverse: (sharedPortfolioId: string) =>
    `/portfolios/${sharedPortfolioId}/shared/reverse`,
  sharedPortfolioInvite: (sharedPortfolioId: string) =>
    `/portfolios/${sharedPortfolioId}/shared/invitations`,
  sharedPortfolioGetInvitation: '/portfolios/shared/invitations',
  sharedPortfolioCancelInvitation: (
    sharedPortfolioId: string,
    invitationId: string
  ) =>
    `/portfolios/${sharedPortfolioId}/shared/invitations/${invitationId}/cancel`,
  sharedPortfolioAcceptInvitation: (
    sharedPortfolioId: string,
    invitationId: string
  ) =>
    `/portfolios/${sharedPortfolioId}/shared/invitations/${invitationId}/accept`,
  sharedPortfolioRejectInvitation: (
    sharedPortfolioId: string,
    invitationId: string
  ) =>
    `/portfolios/${sharedPortfolioId}/shared/invitations/${invitationId}/reject`,
  sharedPortfolioDeleteMember: (sharedPortfolioId: string, memberId: string) =>
    `/portfolios/${sharedPortfolioId}/shared/members/${memberId}/remove`,
  sharedPortfolioLeave: (sharedPortfolioId: string) =>
    `/portfolios/${sharedPortfolioId}/shared/members/leave`,
  sharedPortfolioSwitchAll: (sharedPortfolioId: string) =>
    `/portfolios/${sharedPortfolioId}/shared/switch-all`,
  sharedPortfolioAvailablePorto: (sharedPortfolioId: string) =>
    `/portfolios/${sharedPortfolioId}/shared/available-portfolio-destinations/`,
  sharedPortfolioMember: (sharedPortfolioId: string) =>
    `/portfolios/${sharedPortfolioId}/shared/members`,
  sharedPortfolioReason: (type: string) =>
    `/portfolios/shared/exit-reasons?type=${type}`,
  sharedPortfolioContribution: (sharedPortfolioId: string) =>
    `/portfolios/${sharedPortfolioId}/shared`,
  sharedPortfolioHistory: (sharedPortfolioId: string) =>
    `/portfolios/${sharedPortfolioId}/shared/activities`,

  findContact: '/user/friends',
};

export default endpoints;
