import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import {
  handleResponseErrorCase,
  handleResponseGeneral,
} from 'utils/http-response';
import Analytics from 'utils/Analytics';
import {
  addOrderEntry,
  orderSetNewPaymentReceipt,
} from '../../../entities/orders.reducer';
import { openErrorModal } from '../../errors/errorModal.reducer';
import { setDataOpenGopay } from 'features/gopay/gopay.reducer';

import getSafely from '../../../utils/safely';
import {
  postUploadImageOrderByCode,
  postSubmitOrderConfirmation,
  postContinueOrder,
} from 'services/transaction';
import { postGopayCharge } from 'services/payment';
import { setToggleModalGopayPaymentSuccess } from 'features/gopay/gopay.reducer';
import {
  retryGetOrderDetail,
  retryInstitutionGetOrderDetailRd,
} from '../network/resolver';
import {
  postInstitutionSubmitOrderConfirmation,
  postPremiumSubmitOrderConfirmation,
} from 'features/orders/network/mutation';
import { uploadToS3 } from 'utils/s3/upload';
import { uploadLogInfo } from 'utils/s3/analytics';
import history from 'utils/History';
import { showUploadLimitModal } from 'utils/s3/utils';
import { getCurrentInstitution } from 'features/institution/utils';
import queryClient from 'network/queryClient';

const defaultState = {
  loading: false,
  id: null,
  uploadingImage: false,
  submittingConfirmation: false,
  gopayIsSubmitting: false,
};

// Action creators
const [
  setOrderDetailPageId,
  orderDetailLoading,
  orderDetailLoaded,
  orderDetailUploadImage,
  orderDetailSubmittingConfirmation,
  orderDetailGopaySubmitting,
] = [
  'SET_ORDER_DETAIL_PAGE_ID',
  'ORDER_DETAIL_LOADING',
  'ORDER_DETAIL_LOADED',
  'ORDER_DETAIL_UPLOAD_IMAGE',
  'ORDER_DETAIL_SUBMITTING_CONFIRMATION',
  'ORDER_DETAIL_GOPAY_SUBMITTING',
].map(createAction);

export { setOrderDetailPageId, orderDetailLoading, orderDetailLoaded };

// Selectors
export const getOrderDetailDataById = (state) =>
  state.entities.orders.byId[state.orderDetail.id];

// Reducers
const orderDetail = createReducer(
  {
    [setOrderDetailPageId]: (state, payload) => {
      return update(state, {
        id: { $set: payload },
      });
    },
    [orderDetailLoading]: (state) => {
      return update(state, {
        loading: { $set: true },
      });
    },
    [orderDetailLoaded]: (state, payload) => {
      return update(state, {
        loading: { $set: false },
        id: { $set: payload },
      });
    },
    [orderDetailUploadImage]: (state, payload) => {
      return update(state, {
        uploadingImage: { $set: payload },
      });
    },
    [orderDetailSubmittingConfirmation]: (state, payload) => {
      return update(state, {
        submittingConfirmation: { $set: payload },
      });
    },
    [orderDetailGopaySubmitting]: (state, payload) => {
      return update(state, {
        gopayIsSubmitting: { $set: payload },
      });
    },
  },
  defaultState
);

// Thunks
/**
 * Get order detail data based on Order Code given
 * @param {String} code - Order code
 */
export function getOrderDetailData(code) {
  return (dispatch) => {
    dispatch(orderDetailLoading());

    const req = retryGetOrderDetail(code).then((response) => {
      const responseData = response.data.data;
      dispatch(
        addOrderEntry({
          ...responseData,
          code,
          orderCode: responseData.code,
          trans_code: responseData.code,
        })
      );

      return dispatch(
        orderDetailLoaded(responseData.payment?.invoice_number ?? code)
      );
    });
    handleResponseErrorCase(req, (err) => {
      const errorModalObj = {
        type: err.raw ? err.raw.type : err.message,
        message: err.err || err.message,
      };

      /**
       * When user landing into order detail page from deeplink, this condition will be guard for infinity loading
       */
      if (errorModalObj?.type === 'failed_get_by_code') {
        return history.replace('/orders');
      }

      return dispatch(openErrorModal(errorModalObj));
    });
  };
}

/**
 * Institution Get Order Detail
 * @param {String} code - Order code
 */
export const institutionGetOrderDetail = (code) => {
  const reqInstitutionGetOrderDetail = retryInstitutionGetOrderDetailRd;

  return (dispatch) => {
    dispatch(orderDetailLoading());
    const req = reqInstitutionGetOrderDetail(code).then((response) => {
      const responseData = response.data.data;
      dispatch(
        addOrderEntry({ ...responseData, code, trans_code: responseData.code })
      );

      return dispatch(
        orderDetailLoaded(responseData.payment?.invoice_number ?? code)
      );
    });
    handleResponseErrorCase(req, (err) => {
      const errorModalObj = {
        type: err.raw ? err.raw.type : err.message,
        message: err.err || err.message,
      };

      /**
       * When user landing into order detail page from deeplink, this condition will be guard for infinity loading
       */
      if (errorModalObj?.type === 'order_not_found') {
        return history.replace('/orders');
      }

      return dispatch(openErrorModal(errorModalObj));
    });
  };
};

function trackOrderActionUploadFileClick({ keyFile, premiumStatus = 0 }) {
  let analyticState = 'normal';
  if (premiumStatus === 1) analyticState = 'almost eligible';
  if (premiumStatus > 1) analyticState = 'eligible';

  Analytics.logEventAction({
    eventName: 'order_action',
    parameter: {
      action: 'upload_file',
      trigger: 'click',
      data: {
        keyFile,
        state: analyticState,
      },
      context: 'order.detail',
    },
  });
}

/**
 * Submit payment receipt image to backoffice
 * @param {File} imageFile - Payment receipt image
 */
export function uploadImageReceipt({
  code,
  imageFile,
  premiumStatus = 0,
  userId,
}) {
  return async (dispatch, getState) => {
    if (getState().orderDetail.uploadingImage) {
      return Promise.resolve({});
    }

    dispatch(orderDetailUploadImage(true));

    const fileName = imageFile.name;

    trackOrderActionUploadFileClick({ keyFile: fileName, premiumStatus });

    try {
      const req = await uploadToS3(imageFile, {
        uploadType: 'payment_receipt',
        fileName,
      });

      postUploadImageOrderByCode(code, { receipt: req.data?.data?.file_url })
        .then(() => {
          const orderData = getOrderDetailDataById(getState());

          const portfolioFilteredByOrderCode = orderData?.portfolios?.filter(
            (porto) => {
              const itemFilteredByOrderCode = porto?.items?.filter(
                (item) => item.code === code
              );

              return itemFilteredByOrderCode?.length > 0;
            }
          );

          dispatch(
            orderSetNewPaymentReceipt({
              invoice_number: orderData.payment.invoice_number,
              portfolio_id: portfolioFilteredByOrderCode?.[0]?.portfolio_id,
              code,
              url: req.data?.data?.signed_url,
            })
          );
          dispatch(orderDetailUploadImage(false));
        })
        .catch((err) => {
          // Dismiss all loading
          dispatch(orderDetailUploadImage(false));

          const errorModalObj = {
            type: err.response?.data?.type,
            message: err.response?.data?.message,
          };
          return dispatch(openErrorModal(errorModalObj));
        });
    } catch (err) {
      dispatch(orderDetailUploadImage(false));

      uploadLogInfo({
        name: 'error_upload_image_receipt',
        context: 'uploadImageReceipt.OrderDetail_reducer',
        data: err,
        userId: userId,
      });

      const errType = err?.response?.data?.type || 'unknown_error';

      if (errType === 'upload_request_exceeds') {
        showUploadLimitModal();
      } else {
        const errorModalObj = {
          type: err.response?.data?.type,
          message: err.response?.data?.message,
        };

        dispatch(openErrorModal(errorModalObj));
      }
    }
  };
}

/**
 * Submit order confirmation
 * @param {File} orderData - Payment receipt image
 * @param {requestCallback} callback - Callback function
 */
export function submitOrderConfirmation(orderData, callback) {
  return (dispatch, getState) => {
    const institution = getCurrentInstitution();

    const isInstitutionTrans = !!institution;
    const isIndividualTrans = !institution;
    const isPremiumTrans = isIndividualTrans && !!orderData?.wsTransaction;
    const wsTransCode = orderData?.wsTransaction?.code ?? '';

    if (getState().orderDetail.submittingConfirmation) {
      return Promise.resolve({});
    }

    dispatch(orderDetailSubmittingConfirmation(true));

    let req = null;

    // default submit order confirmation
    if (isIndividualTrans && !isPremiumTrans) {
      req = postSubmitOrderConfirmation(wsTransCode, orderData.code).then(
        () => {
          dispatch(getOrderDetailData(orderData.orderId));
          dispatch(orderDetailSubmittingConfirmation(false));
          callback();
        }
      );
    }

    // handle submit order confirmation for permium transaction
    if (isIndividualTrans && isPremiumTrans) {
      req = postPremiumSubmitOrderConfirmation(
        wsTransCode,
        orderData.code
      ).then(() => {
        dispatch(getOrderDetailData(orderData.orderId));
        dispatch(orderDetailSubmittingConfirmation(false));
        callback();
      });
    }

    // handle submit order confirmation for institution transaction
    if (isInstitutionTrans) {
      req = postInstitutionSubmitOrderConfirmation(wsTransCode).then(() => {
        dispatch(institutionGetOrderDetail(orderData.orderId));
        dispatch(orderDetailSubmittingConfirmation(false));
        callback();

        queryClient.refetchQueries(['Institution Orders All']);
        queryClient.refetchQueries(['Institution Orders RD']);
        queryClient.refetchQueries(['Institution Orders Bonds']);
      });
    }

    handleResponseErrorCase(req, (err) => {
      const errorModalObj = {
        type: err.raw ? err.raw.type : err.message,
        message: err.err || err.message,
      };
      return dispatch(openErrorModal(errorModalObj));
    });
  };
}

export function payWithGopay(invoiceNumber, data) {
  return (dispatch, getState) => {
    const gopayIsSubmitting = getState().orderDetail.gopayIsSubmitting;
    const boughtOrderId = getSafely(['orderDetail', 'id'], getState());

    if (gopayIsSubmitting) {
      Promise.resolve({});
    }

    dispatch(orderDetailGopaySubmitting(true));

    const req = postGopayCharge(invoiceNumber, data).then((response) => {
      const chargeData = handleResponseGeneral(response);
      const { actions } = chargeData.data;

      if (actions && Array.isArray(actions)) {
        const orderData = getOrderDetailDataById(getState());

        // Search for gopay url
        const usedGopayURLObject = actions.find((item) => {
          return (
            item.name === 'deeplink-redirect' ||
            item.name === 'verification-link-app'
          );
        });
        const gopayMobileURL = getSafely(['url'], usedGopayURLObject, '');
        const isSuccessTokenizerwIthoutPin =
          getSafely(['payment_detail', 'transaction_status'], orderData, '') ===
          'settlement';

        dispatch(orderDetailGopaySubmitting(false));

        // If automatically settled, trigger gopay success modal, then reload the order detail
        // else just use usual open gopay prompt
        if (!!isSuccessTokenizerwIthoutPin) {
          dispatch(setToggleModalGopayPaymentSuccess(true));
          dispatch(getOrderDetailData(boughtOrderId));
        } else {
          dispatch(setDataOpenGopay({ url: gopayMobileURL, invoice: '' }));
        }

        return;
      }

      /**
       * value will be:
       *
       * - settlement
       * - pending
       * - deny
       */
      const transactionStatus = getSafely(
        ['data', 'transaction_status'],
        chargeData
      );

      dispatch(orderDetailGopaySubmitting(false));
      if (transactionStatus === 'deny') {
        return dispatch(
          openErrorModal({
            type: 'TRANSACTION_EXPIRED',
            message: 'Metode pembayaran GoPay tidak dapat digunakan',
          })
        );
      }

      if (transactionStatus === 'settlement') {
        return dispatch(getOrderDetailData(boughtOrderId));
      }
    });

    return handleResponseErrorCase(req, (err) => {
      const errorModalObj = {
        type: err.raw ? err.raw.type : err.message,
        message: err.err || err.message,
      };
      dispatch(orderDetailGopaySubmitting(false));
      return dispatch(openErrorModal(errorModalObj));
    });
  };
}

/**
 * Continue order payment, if user used jago payment channel
 * @param {string} invoiceNum - Order id, as written in url
 * @param {Function} handleGetBalance - Get jago balance function
 */
export function payWithJago(invoiceNum, handleGetBalance) {
  return (dispatch, getState) => {
    const orderId = getSafely(['orderDetail', 'id'], getState());

    return postContinueOrder({
      invoiceId: invoiceNum,
      channel: 'jago',
    })
      .then(() => {
        dispatch(getOrderDetailData(orderId));

        queryClient.refetchQueries(['Order List All']);
        queryClient.refetchQueries(['Order List Mutual Fund']);
        queryClient.refetchQueries(['Order List SBN']);
        queryClient.refetchQueries(['Order List Stock']);

        // Refetch Jago balance
        if (!!handleGetBalance) {
          handleGetBalance();
        }
      })
      .catch((error) => {
        const type = getSafely(['response', 'data', 'type'], error, '');
        const message = getSafely(
          ['response', 'data', 'message'],
          error,
          'Upss! Pembelian gagal dibuat. Mohon coba kembali'
        );

        const objError = {
          type,
          message,
        };
        dispatch(openErrorModal(objError));
      });
  };
}

export default orderDetail;
