import { useQuery } from 'react-query';
import queryClient from 'network/queryClient';
import { postData } from 'core/Parent';
import { isInsideNativeContainer } from 'utils/validator';
import { useCallback } from 'react';

const useTranslucent = () => {
  const isNative = isInsideNativeContainer();

  const { data } = useQuery(
    ['translucent'],
    () => {
      return queryClient.getQueryData<'active' | 'notActive'>(['translucent'], {
        exact: true,
      });
    },
    { staleTime: Infinity }
  );

  const setTranslucent = useCallback(
    ({
      state,
      saveState = true,
    }: {
      state: 'active' | 'notActive';
      saveState?: boolean;
    }) => {
      if (!isNative) return;

      postData(
        'setTranslucent',
        {
          fn: 'setTranslucent',
          data: { enabled: state === 'active' },
          timeout: 0,
        },
        (err, data) => {}
      );
      if (saveState) {
        queryClient.setQueryData(['translucent'], state);
      }
    },
    [isNative]
  );

  return {
    state: data || 'notActive',
    setTranslucent,
  };
};

export default useTranslucent;
