import compareVersion from 'compare-versions';
import { DeviceInfo } from 'core/DeviceInfo/deviceInfo';
import { isInsideNativeContainer } from 'utils/validator';

const acceptValue: Array<'dark' | 'light'> = ['dark', 'light'];

/**
 * Get value theme from localStorage `bibit-theme`
 */
export const getTheme = (): 'dark' | 'light' => {
  const htmlTag = document.querySelector('html');
  const themeStorage = htmlTag?.getAttribute('data-theme') as
    | null
    | 'dark'
    | 'light';

  if (!themeStorage) return 'light';

  if (acceptValue.includes(themeStorage)) return themeStorage;

  return 'light';
};

const MIN_IOS_VERSION = '4.55.1' as const;
const MIN_ANDROID_VERSION = '3.70.0' as const;
const IOS_LINK = 'itms-apps://itunes.apple.com/app/id1445856964' as const;
const ANDROID_LINK =
  'https://play.google.com/store/apps/details?id=com.bibit.bibitid' as const;
const HUAWEI_LINK = 'https://appgallery.huawei.com/#/app/C103011423' as const;

/**
 * Retrive link updater based on DeviceInfo
 *
 * if null you dont need to update App User
 */
export const linkUpdateDarkmode = (deviceInfo?: DeviceInfo | null) => {
  const webview = isInsideNativeContainer();

  if (!webview) return null;

  const SYSTEM_NAME = deviceInfo?.SYSTEM_NAME || '';
  const VERSION = deviceInfo?.VERSION || '';

  const userDevice = SYSTEM_NAME.toLowerCase();
  const userVersion = VERSION.toLowerCase();

  switch (userDevice) {
    case 'ios':
      const iosNotValid = compareVersion.compare(
        userVersion,
        MIN_IOS_VERSION,
        '<'
      );
      if (iosNotValid) return IOS_LINK;
      return null;
    case 'android':
      const androNotValid = compareVersion.compare(
        userVersion,
        MIN_ANDROID_VERSION,
        '<'
      );

      if (androNotValid) return ANDROID_LINK;
      return null;
    case 'huawei':
      const huaweiNotValid = compareVersion.compare(
        userVersion,
        MIN_ANDROID_VERSION,
        '<'
      );
      if (huaweiNotValid) return HUAWEI_LINK;
      return null;
    default:
      return null;
  }
};

const MIN_IOS_SYSTEM_SETTING_VERSION = '4.58.0' as const;

export const isNotSupportSystemSettingVersion = (
  deviceInfo?: DeviceInfo | null
) => {
  const webview = isInsideNativeContainer();

  if (!webview) return false;

  const SYSTEM_NAME = deviceInfo?.SYSTEM_NAME || '';
  const VERSION = deviceInfo?.VERSION || '';

  const userDevice = SYSTEM_NAME.toLowerCase();
  const userVersion = VERSION.toLowerCase();

  switch (userDevice) {
    case 'ios':
      return compareVersion.compare(
        userVersion,
        MIN_IOS_SYSTEM_SETTING_VERSION,
        '<'
      );
    case 'huawei':
    case 'android':
      if (!window.isDarkModeSystemSupported) return true;
      return false;
    default:
      return null;
  }
};
