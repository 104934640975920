import * as React from 'react';
import Light from './light.svg';
import Dark from './dark.svg';
import { ThemedImage } from '@bibitid/uikit-v1';

const themeImageMapping = {
  light: Light,
  dark: Dark,
  default: Light,
};

export const Announcement: React.FC<React.HTMLProps<HTMLPictureElement>> = (
  props
) => {
  return (
    <ThemedImage
      style={{ display: 'flex', justifyContent: 'center' }}
      imageMap={themeImageMapping}
      {...props}
    />
  );
};

export default Announcement;
