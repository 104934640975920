import axios, { AxiosRequestConfig } from 'axios';
import { BASE_URL, serializeParam } from './utils';
import { API_TIMEOUT } from './constant';
import requestInterceptors from './request.interceptors';
import { AxiosHTTPError } from './http.type';
import responseErrorInterceptors, {
  responseInterceptors,
} from './response.interceptors';
import { injectApiInterceptors } from './injectApi.interceptors';
import { getEnv } from 'core/env';

const { DevTools } = getEnv();

/**
 * Create Axios Instance with default
 */
export const apiInstance = axios.create({
  timeout: API_TIMEOUT,
  baseURL: BASE_URL,
});

// request interceptors
apiInstance.interceptors.request.use(async (config) => {
  // custom request interceptors
  await requestInterceptors(config);
  return config;
});

// response interceptors
apiInstance.interceptors.response.use(
  async (res) => {
    await responseInterceptors(res);
    if (DevTools) {
      return injectApiInterceptors(res);
    }
    return res;
  },
  async (err: AxiosHTTPError) => {
    await responseErrorInterceptors(err);

    return Promise.reject(err);
  }
);

/**
 * handle HTTP GET to RestAPI
 */
export const get = <T = any>(
  endpoint: string,
  queryParam: any = {},
  config?: AxiosRequestConfig
) => {
  /**
   * Url endpoint
   */
  let url = endpoint;
  /**
   * Add query param when `queryParam` is given
   */
  if (queryParam && Object.keys(queryParam).length > 0) {
    url = url + '?' + serializeParam(queryParam);
  }

  const newConfig: AxiosRequestConfig = {
    // attach config from param function
    ...config,
    headers: {
      ...config?.headers,
      'content-type': 'application/json',
    },
  };

  return apiInstance.get<T>(url, newConfig);
};

/**
 * handle HTTP POST to RestAPI
 */
export const post = <T = any>(
  endpoint: string,
  body?: Record<string, any>,
  queryParam: any = {},
  config?: AxiosRequestConfig
) => {
  /**
   * Url endpoint
   */
  let url = endpoint;
  /**
   * Add query param when `queryParam` is given
   */
  if (queryParam && Object.keys(queryParam).length > 0) {
    url = url + '?' + serializeParam(queryParam);
  }

  return apiInstance.post<T>(url, body, config);
};

/**
 * handle HTTP DELETE to RestAPI
 *
 * Notes: we not allowed to use `delete` as declaration any good name is welcome
 */
export const apiDelete = (
  endpoint: string,
  bodyparam?: any,
  queryParam: any = {},
  config?: AxiosRequestConfig
) => {
  /**
   * Url endpoint
   */
  let url = endpoint;
  /**
   * Add query param when `queryParam` is given
   */
  if (queryParam && Object.keys(queryParam).length > 0) {
    url = url + '?' + serializeParam(queryParam);
  }

  const newConfig: AxiosRequestConfig = {
    // attach config from param function
    ...config,
    data: bodyparam,
  };

  return apiInstance.delete(url, newConfig);
};

/**
 * handle HTTP PATCH to RestAPI
 */
export const patch = (
  endpoint: string,
  bodyparam?: any,
  queryParam: any = {},
  config?: AxiosRequestConfig
) => {
  /**
   * Url endpoint
   */
  let url = endpoint;
  /**
   * Add query param when `queryParam` is given
   */
  if (queryParam && Object.keys(queryParam).length > 0) {
    url = url + '?' + serializeParam(queryParam);
  }

  return apiInstance.patch(url, bodyparam, config);
};

/**
 * handle HTTP PUT to RestAPI
 */
export const put = (
  endpoint: string,
  bodyparam?: any,
  queryParam: any = {},
  config?: AxiosRequestConfig
) => {
  /**
   * Url endpoint
   */
  let url = endpoint;
  /**
   * Add query param when `queryParam` is given
   */
  if (queryParam && Object.keys(queryParam).length > 0) {
    url = url + '?' + serializeParam(queryParam);
  }

  return apiInstance.put(url, bodyparam, config);
};
