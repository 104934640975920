import styled, { css } from 'styled-components';
import { TableDefinitionConfig } from './StockTable.type';
import { Text } from '@bibitid/uikit-v1';

export const Container = styled.div`
  display: flex;
`;

export const ExtraWhiteSpace = styled.div<{ paddingLeft?: string }>`
  position: absolute;
  top: 60px;
  bottom: 20px;
  left: 0px;
  width: 20px;
  padding-left: ${({ paddingLeft }) => paddingLeft};
  background-color: var(--surface);
  z-index: 101 !important;

  @media only screen and (min-width: 1030px) {
    top: 0px !important;
  }
`;

export const TableContainer = styled.div<{ isLoading?: boolean }>`
  background-color: var(--surface);
  margin-left: 2px;

  @media only screen and (min-width: 1030px) {
    min-height: calc(100vh - 200px);
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.5;
    `}
`;

export const TableWrapper = styled.div<{
  removeScrollBar?: boolean;
  scrollXOnWeb?: boolean;
}>`
  padding: 0px 0px 0px 20px;
  overflow-x: scroll;
  overscroll-behavior-x: none;
  background-color: var(--surface);
`;

export const Table = styled.table<{
  isLoading?: boolean;
  paddingLeft?: string;
}>`
  border-collapse: collapse;
  white-space: nowrap;
  margin-bottom: 80px;
  margin-right: 10px;
  -webkit-overflow-scrolling: touch;
  padding-left: ${({ paddingLeft }) => paddingLeft} !important;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.5;
    `}
`;

export const TableHead = styled.thead<{
  stickyHeader?: boolean;
  paddingLeft?: string;
}>`
  ${({ stickyHeader, paddingLeft }) =>
    stickyHeader &&
    css`
      position: sticky;
      top: -1px;
      background-color: var(--surface);
      z-index: 100;
      left: ${paddingLeft};
    `}
`;

export const TableBody = styled.tbody`
  position: relative;
  top: 0;
`;

export const TableRow = styled.tr<{
  showBorder?: boolean;
}>`
  background-color: var(--surface);
  z-index: 99;
  border-bottom: ${({ showBorder }) => showBorder && '1px solid var(--border)'};
`;

export const TableHeader = styled.th<TableDefinitionConfig>`
  display: inline-block;
  position: sticky;
  cursor: pointer;
  background-color: var(--surface);
  border-bottom: ${({ hideBorder }) =>
    hideBorder ? 'unset' : '1px solid var(--border)'};

  ${({ columnSticky, leftPos }) =>
    columnSticky &&
    css`
      position: sticky;
      left: ${leftPos};
      z-index: 99;
    `};
`;

export const TableDefinition = styled.td<TableDefinitionConfig>`
  display: inline-block;

  ${({ columnSticky, leftPos }) =>
    columnSticky &&
    css`
      position: sticky;
      top: auto;
      left: ${leftPos};
      z-index: 99;
      padding: 8px 0;
    `}
`;

type DataInfoProps = {
  isActive?: boolean;
  disabled?: boolean;
  marginLeft?: string;
} & TableDefinitionConfig;

export const DataInfo = styled.div<DataInfoProps>`
  height: 39px;
  white-space: normal;
  font-size: ${({ fontSize }) => fontSize ?? '12px'};
  color: ${({ color }) => color ?? 'var(--text-primary)'};
  text-align: ${({ textAlign }) => textAlign ?? 'left'};
  font-weight: ${({ fontWeight }) => fontWeight ?? 'normal'};
  width: ${({ width }) => width ?? '100px'};
  background-color: ${({ bgColor }) => bgColor ?? 'var(--surface)'};
  margin-left: ${({ leftPos }) => leftPos ?? 'unset'};
  z-index: 99;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  @media only screen and (max-width: 320px) {
    font-size: 10px !important;
  }
`;

export const SkeletonWrapper = styled.div<{
  height?: string;
  width?: string;
  position?: 'flex-start' | 'center' | 'flex-end';
  verticalPosition?: 'flex-start' | 'center' | 'flex-end';
  showBorder?: boolean;
}>`
  display: flex;
  justify-content: ${({ position }) => position ?? 'flex-end'};
  align-items: ${({ verticalPosition }) => verticalPosition ?? 'flex-start'};
  height: ${({ height }) => height ?? '40px'};
  white-space: normal;
  width: ${({ width }) => width ?? '100px'};
  border-bottom: 1px solid var(--divider-thin);

  div:first-child {
    margin-right: 12px;
  }
`;

export const DataValue = styled.div<TableDefinitionConfig>`
  display: flex;
  align-items: center;
  justify-content: ${({ textAlign }) => textAlign ?? 'flex-start'};
  white-space: normal;
  font-size: ${({ fontSize }) => fontSize ?? '13px'};
  color: ${({ color }) => color ?? 'var(--text-primary)'};
  font-weight: ${({ fontWeight }) => fontWeight ?? 'normal'};
  width: ${({ width }) => width ?? '100px'};
  background-color: ${({ bgColor }) => bgColor ?? 'var(--surface)'};
  margin-left: ${({ leftPos }) => leftPos ?? 'unset'};
  padding-right: 10px;
  word-break: break-all;

  @media only screen and (max-width: 320px) {
    font-size: 10px !important;
  }
`;

export const DataSort = styled.div<{ textAlign?: string }>`
  display: flex;
  align-items: center;
  position: relative;
  right: ${({ textAlign }) => (textAlign !== 'flex-start' ? '-5px' : 'unset')};
  height: 22px;
  justify-content: ${({ textAlign }) => textAlign ?? 'flex-start'};
  margin-top: 16px;
`;

export const EmittenWrapper = styled.div<TableDefinitionConfig>`
  display: flex;
  height: 52px;
  align-items: center;
  white-space: normal;
  font-size: ${({ fontSize }) => fontSize ?? '12px'};
  color: ${({ color }) => color ?? 'var(--text-primary)'};
  font-weight: ${({ fontWeight }) => fontWeight ?? 'normal'};
  width: ${({ width }) => width ?? '100px'};
  background-color: ${({ bgColor }) => bgColor ?? 'var(--surface)'};

  @media only screen and (max-width: 320px) {
    font-size: 10px !important;
  }
`;

export const EmittenIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 12px;
`;

export const EmittenData = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 4px;
`;

export const EmittenTitle = styled(Text)`
  padding-bottom: 20px;
`;

export const EmittenDescription = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const TextWrapper = styled.div<{ isSeparated?: boolean }>`
  width: ${({ isSeparated }) => (isSeparated ? '100%' : 'unset')};
`;

export const SortWrapper = styled.div<{ isVisible?: boolean }>`
  display: flex;
  height: 20px;
  flex-direction: column;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

  svg {
    position: relative;
    top: -1px;
  }

  svg:last-child {
    position: relative;
    top: -3px;
  }

  @media only screen and (max-width: 320px) {
    height: 16px;

    svg {
      height: 16px;
      width: 16px;
      position: relative;
      top: -1px;
    }

    svg:last-child {
      position: relative;
      top: -3px;
    }
  }
`;

export const ProfitLossContainer = styled.div<TableDefinitionConfig>`
  display: flex;
  column-gap: 12px;
  justify-content: center;
  flex-direction: column;
  align-items: ${({ textAlign }) => textAlign ?? 'flex-start'};
  white-space: normal;
  font-size: ${({ fontSize }) => fontSize ?? '12px'};
  color: ${({ color }) => color ?? 'var(--text-primary)'};
  font-weight: ${({ fontWeight }) => fontWeight ?? 'normal'};
  width: ${({ width }) => width ?? '100px'};
  background-color: ${({ bgColor }) => bgColor ?? 'var(--surface)'};
`;

export const ProfitLossWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    position: relative;
    top: -1px;
    margin-right: 2px;
  }
`;
