import * as React from 'react';
import { ThemedImage } from '@bibitid/uikit-v1';
import Light from '../../assets/close-filter/light.svg';
import Dark from '../../assets/close-filter/dark.svg';

const themeImageMapping = {
  light: Light,
  dark: Dark,
  default: Light,
};

const CloseFilterIcon: React.FC<React.HTMLProps<HTMLPictureElement>> = (
  props
) => {
  return <ThemedImage imageMap={themeImageMapping} {...props} />;
};

export default CloseFilterIcon;
