import React from 'react';
import { useLocation } from 'react-router-dom';
import * as Parent from 'core/Parent';
import { isInsideNativeContainer } from './validator';

/**
 * Get query url:
 * ex: ?hello=world
 */
export function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Get query url in object:
 * input: ?hello=world
 * output: { hello: world }
 */
export function useParsedQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => {
    if (search) {
      const URI = search.substring(1);

      const result = (/^[?#]/.test(URI) ? URI.slice(1) : URI)
        .split('&')
        .reduce((params: any, param: any) => {
          let [key, value] = param.split('=');
          if (key === '') {
            return params;
          }
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {});

      return result;
    }
    return {};
  }, [search]);
}

/**
 * Serialize object to query url:
 * input: { hello: world }
 * output: ?hello=world
 */
export function serializeQueryParams(obj: {
  [key: string]: null | undefined | string;
}) {
  return Object.entries(obj)
    .filter(([_, val]) => val !== null && val !== undefined)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
}

/**
 * Handle Open link to browser
 */
export function openLink(href: string, target?: string, features?: string) {
  const isWebview = isInsideNativeContainer();

  if (isWebview) {
    return Parent.postData('openLink', { fn: 'openLink', data: href });
  }

  return window.open(href, target, features);
}
