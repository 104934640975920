import { TableTextAlignType, TableDefinitionConfig } from '../StockTable.type';
import { useMemo } from 'react';

const useColumnConfiguration = (
  config: TableDefinitionConfig,
  type: 'header' | 'body'
) => {
  const generateTextAlign = (
    configData: TableDefinitionConfig
  ): TableTextAlignType => {
    if (configData?.textAlign === 'right') return 'flex-end';
    if (configData?.textAlign === 'center') return 'center';

    return 'flex-start';
  };

  const generateTextSize = (text: string, currentSize: number) => {
    const MAX_LENGTH_UNTIL_DROP_SIZE = 40;
    const dropSize = Math.round(text?.length / MAX_LENGTH_UNTIL_DROP_SIZE);

    return `${currentSize - dropSize}px`;
  };

  const configurationData = useMemo(() => {
    if (type === 'body') {
      return {
        ...config,
        textAlign: generateTextAlign(config as TableDefinitionConfig),
      };
    }

    return config;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return {
    configurationData,
    generateTextAlign,
    generateTextSize,
  };
};

export default useColumnConfiguration;
