import endpoints from './endpoints';
import {
  CompanyDetailResponse,
  CompanyStatsDetailResponse,
  StockPortfolioBySymbolResponse,
  WatchlistsParams,
  StockFeeResponse,
  StockBuyPreviewResponse,
  StockBuyBody,
  StockBuyCancelBody,
  StockSellBody,
  StockSellPreviewResponse,
  StockSellCancelBody,
  RdnDetailReponse,
  CorpActionResponse,
  CompanyChartParameter,
  CompanyChartResponse,
  CompaniesRelatedResponse,
  actionType,
  CompanyExerciseableResponse,
  StockExerciseBody,
  StockExercisePreviewResponse,
  RdnCashOnHandReponse,
  OrderBookResponse,
  CompanyKeyStatsResponse,
  ReportType,
  PeriodType,
  FinancialStatementResponse,
  FinancialStatementChartResponse,
} from '../types';
import http from 'core/http';
import {
  ParamsStockCategorization,
  StockCategorizationDetailResponse,
} from '../types/stockCategorization';

/**
 * get company detail by symbol
 * @param symbol
 */
export const getCompanyDetail = (symbol: string): CompanyDetailResponse => {
  return http.get(endpoints.companyDetail(symbol)).then((data) => {
    return data;
  });
};

export const getCompanyStats = (symbol: string): CompanyStatsDetailResponse => {
  return http.get(endpoints.stockCompaniesDetailStats(symbol));
};

export const addWatchlist = (props: WatchlistsParams) => {
  return http.post(endpoints.watchlists, props);
};

export const getStockPortfolioBySymbol = (
  symbol: string
): StockPortfolioBySymbolResponse => {
  return http.get(endpoints.stockPortfoliosSymbol(symbol));
};

/**
 * get user rdn detail
 */
export const getRdnDetail = (): RdnDetailReponse => {
  return http.get(endpoints.rdnDetail);
};

/**
 * get user rdn cash on hand
 */
export const getRdnCashOnHand = (): RdnCashOnHandReponse => {
  return http.get(endpoints.rdnCashOnHand);
};

/**
 * get stock fee
 */
export const getStockFee = (): StockFeeResponse => {
  return http.get(endpoints.stockFee);
};

/**
 * get trending company stock
 */
export const getCompaniesRelated = (
  symbol: string
): CompaniesRelatedResponse => {
  return http.get(endpoints.stockCompaniesRelated(symbol));
};

/**
 * get company stock chart
 */
export const getStockCompanyChart = ({
  symbol,
  ...props
}: CompanyChartParameter): CompanyChartResponse => {
  return http.get(endpoints.stockCompaniesChart(symbol), props);
};

/**
 * create stock buy order
 * @param body
 */
export const postStockBuy = (body: StockBuyBody) => {
  return http.post(endpoints.stockBuy, body);
};

/**
 * post stock buy preview
 * @param body
 */
export const postStockBuyPreview = (
  body: StockBuyBody
): StockBuyPreviewResponse => {
  return http.post(endpoints.stockBuyPreview, body);
};

/**
 * create stock buy amend order
 * @param body
 */
export const postStockBuyAmend = (body: StockBuyBody) => {
  return http.patch(endpoints.stockBuy, body);
};

/**
 * get stock buy amend preview
 * @param body
 */
export const postStockBuyAmendPreview = (body: StockBuyBody) => {
  return http.patch(endpoints.stockBuyPreview, body);
};

/**
 * cancel stock buy order
 * @param body
 */
export const postCancelStockBuy = (body: StockBuyCancelBody) => {
  return http.delete(endpoints.stockBuy, body);
};

/**
 * create stock sell order
 * @param body
 */
export const postStockSell = (body: StockSellBody) => {
  return http.post(endpoints.stockSell, body);
};

/**
 * post stock sell preview
 * @param body
 */

export const postStockSellPreview = (
  body: StockSellBody
): StockSellPreviewResponse => {
  return http.post(endpoints.stockSellPreview, body);
};

/**
 * post stock sell amend order
 * @param body
 */
export const postStockSellAmend = (body: StockSellBody) => {
  return http.patch(endpoints.stockSell, body);
};

/**
 * post stock sell amend preview
 * @param body
 */
export const postStockSellAmendPreview = (body: StockSellBody) => {
  return http.patch(endpoints.stockSellPreview, body);
};

/**
 * cancel stock sell order
 * @param body
 */
export const postCancelStockSell = (body: StockSellCancelBody) => {
  return http.delete(endpoints.stockSell, body);
};

/**
 * create stock exercise order
 * @param body
 */
export const postStockExercise = (body: StockExerciseBody) => {
  return http.post(endpoints.stockExercise, body);
};

/**
 * post stock exercise preview
 * @param body
 */

export const postStockExercisePreview = (
  body: StockExerciseBody
): StockExercisePreviewResponse => {
  return http.post(endpoints.stockExercisePreview, body);
};

export const getStockCompaniesSymbolCorpAction = (
  symbol: string,
  body: {
    type?: string;
    page: string;
  }
): CorpActionResponse => {
  return http.get(endpoints.stockCompaniesSymbolCorpActions(symbol), {
    ...(body.type === 'all' ? undefined : { type: body.type }),
    page: body.page,
  });
};

/**
 * get order book by symbol
 * @param symbol
 */
export const getOrderBookBySymbol = (symbol: string): OrderBookResponse => {
  return http.get(endpoints.stockOrderBook(symbol));
};

export const getCompanyExerciseable = (
  symbol: string,
  body: {
    type: actionType;
  }
): CompanyExerciseableResponse => {
  return http.get(endpoints.stocksCompaniesExerciseable(symbol), body);
};

/**
 * get company key stats
 * @param symbol
 */
export const getCompanyKeyStats = (symbol: string): CompanyKeyStatsResponse => {
  return http.get(endpoints.stockCompanyKeyStats(symbol));
};

/**
 * get stock financial statement
 * @param symbol = company symbol
 * @param report_type = income_statement | balance_sheet | cash_flow
 * @param period = https://www.notion.so/stockbit/Bibit-Plus-Stocks-in-Bibit-cfb0e86bb7f44989896ad37dcf6606ff#3f23df640b9144dfb529d2ce8c3c24a5
 */
export const getFinancialStatement = (
  symbol: string,
  report_type: ReportType,
  period: PeriodType,
  lang: 'id' | 'en'
): FinancialStatementResponse => {
  return http.get(endpoints.stockFinancialStatement(symbol), {
    report_type,
    period,
    lang,
  });
};

/**
 * get stock financial statement Chart
 * @param symbol = company symbol
 * @param report_type = income_statement | balance_sheet | cash_flow
 * @param period = https://www.notion.so/stockbit/Bibit-Plus-Stocks-in-Bibit-cfb0e86bb7f44989896ad37dcf6606ff#3f23df640b9144dfb529d2ce8c3c24a5
 */
export const getFinancialStatementChart = (
  symbol: string,
  report_type: ReportType,
  period: PeriodType
): FinancialStatementChartResponse => {
  return http.get(endpoints.stockFinancialStatementChart(symbol), {
    report_type,
    period,
  });
};

export const getStockCategorizationDetailById = (
  id: number,
  params: ParamsStockCategorization
): StockCategorizationDetailResponse => {
  return http.get(endpoints.stockCategorizationDetailById(id), { ...params });
};
