import React, { FC, CSSProperties, useRef } from 'react';
import noImage from 'assets/images/topup-modal-asset/no-image.svg';
import { getEnv } from 'core/env';
import { Skeleton } from '@bibitid/uikit-v1';

const { PublicUrl } = getEnv();

interface Props {
  /**
   * default 'square'
   */
  size?: 'square' | 'orig';
  /**
   * default: 'investment-manager'
   */
  type?: 'investment-manager' | 'benchmark';
  ojkCode?: string;
  className?: string;
  style?: CSSProperties;
  isLoading?: boolean;
}

const MILogo: FC<Props> = ({
  size = 'square',
  type = 'investment-manager',
  ojkCode,
  style,
  className,
  isLoading,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);

  const handleErrorImage = () => {
    if (!imgRef) return;

    if (!imgRef.current) return;

    imgRef.current.src = noImage;
    imgRef.current.onerror = null;
  };

  if (isLoading) {
    return <Skeleton width={32} height={32} variant='circle' margin='0' />;
  }

  if (!ojkCode) {
    return <img src={noImage} alt='Company' className={className} />;
  }

  if (size === 'orig') {
    const origImageUrl = `${PublicUrl}/assets/logos/${type}/${size}/${ojkCode}.png`;
    return (
      <img
        src={origImageUrl}
        className={className}
        style={style}
        alt={ojkCode}
        ref={imgRef}
        onError={handleErrorImage}
      />
    );
  }

  const squareImage = `${PublicUrl}/assets/logos/${type}/${size}/${ojkCode}.svg`;

  return (
    <img
      className={className}
      src={squareImage}
      alt={ojkCode}
      onError={handleErrorImage}
      style={style}
      ref={imgRef}
    />
  );
};

export default MILogo;
