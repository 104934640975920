import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ContentBox from 'features/common/ContentBox';
import tabBarHoc from 'withoutTabBar';
import { useParams, useHistory } from 'react-router-dom';
import './IndexReksadanaProduct.css';
import { goBack } from 'utils/History';
import { useFlag } from 'utils/feature-flag';
import { useQueryParams } from 'utils/routeHelper';
import useNavbarColorTransition from 'core/statusbar/hooks/useNavbarColorTransition';
import { StatusBarApp } from 'core/statusbar/types';
import {
  useStatusBarFunction,
  useStatusBarState,
} from 'core/statusbar/context/StatusBarContext';
import {
  DEFAULT_STATUS_BAR_COLOR,
  PORTFOLIO_PAGE_STATUS_BAR_COLOR,
} from 'core/statusbar/constants';
import { ReksaDanaCardList } from 'features/common/reksadana/lists/CategorizedReksaDanaList';
import Analytics from 'utils/Analytics';
import classNames from 'classnames';
import { useTopUpGlobalContext } from 'features/topup-modal/TopupGlobalContext';
import { ReksaDana } from 'entities/reksadana.reducer';
import SortAndCompareButton from './components/SortAndCompareButton';
import { IntroInstantRedemptionModal } from 'features/bankjago/components/modals';
import {
  useReksadanaProductFunctionContext,
  useReksadanaProductStateContext,
} from './context/ReksadanaProductContext';
import { Waypoint } from 'react-waypoint';
import { onApplyParams } from './types';
import { AxiosHTTPError } from 'core/http/http.type';
import Show from 'features/common/components/Show/Show';
import ErrorCard from 'features/common/ErrorCard';
import NotFound404 from 'features/common/NotFound404/NotFound404';
import BibitPlusCategoryBanner, {
  CategoryBannerProps,
} from 'features/category/component/banners/BibitPlusCategoryBanner';
import { useTheme, useToggle } from 'hooks';
import TopCompanyInfoModal from 'features/common/modals/TopCompanyInfoModal/TopCompanyInfoModal';
import PullerReksadaProductList from './components/PullerReksadaProductList';
import { Text, TopBar } from '@bibitid/uikit-v1';
import ArrowDown from './assets/arrow-down.svg';
import ModalReturnByPeriod from './components/ModalReturnByPeriod';
import CommonBestTab from 'features/explore/pages/ExploreBestPage/Tabs/CommonBestTab';
import CloseFilterIcon from './components/CloseFilter/CloseFilterIcon';
import { getAnalyticData } from 'features/common/reksadana/utils/filterAnalytic';
import { useTranslation } from 'react-i18next';
interface SortAndCompareButtonParams {
  type: string;
  im?: string;
  category?: string;
}

interface IndexReksadanaProductProps extends PropsWithChildren<unknown> {
  /** probably dont need */
  pageType: string;
  showHeader?: boolean;
  showBanner?: boolean;
  renderPuller?: boolean;
  showPullToRefresh?: boolean;
  removePullerBox?: boolean;
  showSortAndCompare?: boolean;
  showCompareButton?: boolean;
  onCardClick?: (roboCategory: string, symbol: string, additional: any) => void;
  onConfirmCompare?: () => void;
  category?: string;
  currency?: string;
  showLink?: boolean;
  listWrapperClassName?: string;
  classNamesPuller?: string;
  selected?: string | string[];
  fixedParamsFromProps?: Record<string, string | number | undefined>;
  /**
   * If this true, instant modal will be shown by query
   *
   * If false, it will be shown by local state
   *
   * Default value = true
   */
  enableInstantModalByQuery?: boolean;

  /**
   * Enabled to set statusbar or not when rendering this component
   */
  enabledSetStatusBarColor?: boolean;

  ableToSelectProduct?: boolean;
  onCustomNavigateMutualFundDetail?: (symbol: string) => void;

  // Clean query params on mount
  cleanQueryParams?: boolean;
  bottomHeight?: number;
  /**
   * If this is true:
   * - network will call is_top_product = true
   * - hide 'top product' filter section
   * - hide 'top' ribbon in product
   */
  showOnlyTopProduct?: boolean;

  showHighestReturn?: boolean;

  inModal?: boolean;

  classNameBox?: string;

  /**
   * props to hide / show mutual fund with type 'campuran'
   */
  showMixedProducts?: boolean;

  /**
   * props to hide / show mutual fund with usd currency
   */
  showUSDProducts?: boolean;
  removeDevider?: boolean;
  showQuickPeriod?: boolean;

  disableApplyParams?: boolean;
}

const truncateText = (txt: string) => {
  if (!txt) return '';

  const sp = txt.split(' ');
  if (sp.length <= 1) return ''; // guard

  if (txt.toUpperCase().startsWith('PT')) {
    return sp[1];
  }

  return sp[0];
};

/**
 * Decides what title will be rendered
 */
const decideTitle = (category: string, imName: string | null) => {
  switch (category) {
    case 'equity':
      return 'reksadana_saham';
    case 'debt':
      return 'reksadana_obligasi';
    case 'syariah':
      return 'reksadana_syariah';
    case 'moneymarket':
      return 'reksadana_pasar_uang';
    case 'instant':
      return 'pencairan_instan';
    case 'others':
      return 'reksadana_lainnya';
    case 'indexfund':
      return 'index_fund';
    case 'usd':
      return 'reksadana_usd';
    case 'minimum':
      return 'reksadana_minimal';
    case 'im':
      return !!imName ? truncateText(imName || '') : 'reksadana';
    // For custom category collections
    default:
      return 'reksadana';
  }
};

/**
 * Display mutual fund to user, based on category
 */
const ReksadanaProductList: React.FC<IndexReksadanaProductProps> = ({
  pageType,
  showHeader = true,
  showBanner = true,
  onCardClick,
  onConfirmCompare,
  showSortAndCompare = true,
  category = '',
  currency,
  showLink,
  listWrapperClassName,
  classNamesPuller,
  selected,
  fixedParamsFromProps,
  enableInstantModalByQuery = true,
  showOnlyTopProduct = false,
  showHighestReturn = false,
  enabledSetStatusBarColor = true,
  ableToSelectProduct,
  onCustomNavigateMutualFundDetail,
  cleanQueryParams = false,
  bottomHeight,
  inModal,
  renderPuller = true,
  showPullToRefresh = true,
  removePullerBox,
  classNameBox,
  showCompareButton = true,
  showMixedProducts = true,
  showUSDProducts = true,
  removeDevider = false,
  showQuickPeriod = true,
  disableApplyParams = false,
}) => {
  const { t } = useTranslation(['explore']);
  /**
   *
   * USE STATES
   *
   */
  const { statusBarColor } = useStatusBarState();
  const { onSetStatusBarColor } = useStatusBarFunction();
  const { changeColorByScroll } = useNavbarColorTransition();
  const { theme } = useTheme();
  const { active: showIRModal, toggleActive: toggleIRModal } = useToggle();
  const { active: showTopCompanyModal, toggleActive: toggleTopCompanyModal } =
    useToggle();

  const {
    active: showReturnPeriodModal,
    toggleActive: toggleModalReturnPeriod,
  } = useToggle();

  const [currentCategoryTab, setCurrentCategoryTab] = useState('moneymarket');

  const instantRedemptionActiveByFF: boolean = useFlag(
    'web_jago_instant_redemption'
  );

  const history = useHistory();

  const query = useQueryParams();

  const isReinvestBonds = query.get('action') === 'reinvestBonds';

  const { maxBuy } = useTopUpGlobalContext();

  const { type, category: profilingCategory } =
    useParams<SortAndCompareButtonParams>();

  const {
    filterSortBy,
    filterSortPeriod,
    filterIsSorting,
    productQuery,
    filterIsInstantRedemption,
    visibilityCompareModal,
    comparedProductsData,
    numberOfActiveSort,
    flagReturnPeriod,
    filterIsTopCompany,
    filterIsSyariah,
    filterBankId,
    activeChipsReturnPeriod,
    filterIm,
  } = useReksadanaProductStateContext();

  const comparedProductsSymbol = comparedProductsData?.map(
    (item) => item?.symbol
  );

  const {
    setFilterType,
    setFilterCurrency,
    setFilterSortOrder,
    setVisibilityCompareModal,
    setComparedProductsData,
    setFilterFixedParams,
    buildQueryParams,
    resetAllState,
    setFilterFixedParamsShown,
    setFlagReturnPeriod,
    setActiveChipsReturnPeriod,
  } = useReksadanaProductFunctionContext();

  const renderTextSortPeriod = () => {
    switch (filterSortPeriod) {
      case '1y':
        return '1 Year';
      case '1d':
        return '1 Day';
      case '1m':
        return '1 Month';
      case '3m':
        return '3 Month';
      case 'ytd':
        return 'Year to Date';
      case '3y':
        return '3 Year';
      case '5y':
        return '5 Year';
      default:
        return '1 Year';
    }
  };

  const renderTextSortBy = () => {
    switch (filterSortBy) {
      case 5:
      case 10:
        return 'Return';
      case 3:
        return 'Expense Ratio';
      case 2:
        return 'Total AUM';
      case 4:
        return 'Max Drawdown';
      case 6:
        return 'Minimum Pembelian';
      case 9:
        return 'Average Yield';
    }
  };

  /**
   *
   * CONSTS
   *
   */

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    refetch,
    error: errorProduct,
  } = productQuery;

  const errMsg = (errorProduct as AxiosHTTPError)?.response?.data?.message;

  const {
    location: { state },
  }: any = history;

  const categoryType = type || profilingCategory;

  const imName = query.get('im');

  const dynamicCategory = categoryType === 'im' ? imName : categoryType;

  /** Is categories instant page */
  const isCategoriesInstantPage =
    history.location.pathname === '/categories/instant';

  /**
   * @TODO change string type to SortByType
   * services/reksadana.type
   */

  /**
   *
   * FUNCTIONS
   *
   */

  /**
   * Go to last visited page
   */
  const handleClickBack = () => {
    goBack(history);
  };

  /**
   * Listen when content box scrolled
   */
  const listenPageScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (!enabledSetStatusBarColor) return;

    const { backgroundColor, foregroundColor } = decideColor(category);

    changeColorByScroll(
      [
        {
          from: 0,
          to: 150,
          backgroundColor,
          foregroundColor,
        },
      ],
      event
    );
  };

  const handlePullToRefresh = () => {
    refetch();
  };

  const handleInitializeList = useCallback(() => {
    let fixedParams = {};

    const categoryInitialize =
      category === 'best' || category === 'highreturn'
        ? currentCategoryTab
        : category;

    if (isReinvestBonds || !showUSDProducts) setFilterCurrency('idr');
    switch (categoryInitialize) {
      case 'equity':
        setFilterType([1]);
        break;
      case 'debt':
      case 'fixincome':
        setFilterType([2]);
        break;
      case 'moneymarket':
        setFilterType([3]);
        break;
      case 'benchmark':
        setFilterType([8, 9]);
        break;
      case 'others':
        setFilterType([0]);
        break;
      case 'minimum':
        fixedParams = {
          meta: 'minimum',
        };
        break;
      case 'indexfund':
        fixedParams = {
          meta: 'indexfund',
        };
        break;
      case 'usd':
        setFilterCurrency('usd');
        break;
      case 'im':
        if (!!imName) {
          setFilterFixedParamsShown({
            im: imName,
          });
        }
        break;
      case 'instant':
        fixedParams = {
          is_instant_redemption: 1,
        };
        break;
      case 'highreturn':
        setFilterType([5]);
        fixedParams = {
          is_highest_return: 1,
        };
        break;
      case 'syariah':
        fixedParams = {
          syariah: 1,
        };
        break;
      case 'best':
        fixedParams = {
          is_top_product: 1,
        };
        break;
      default:
        setFilterType([]);
        break;
    }
    /**
     * set filter is_top_product
     */
    if (!!showOnlyTopProduct)
      fixedParams = { ...fixedParams, is_top_product: 1 };

    /** Set filter is_highest_return */
    if (!!showHighestReturn) {
      fixedParams = {
        ...fixedParams,
        is_highest_return: 1,
        limit: 5,
        sort_by: !flagReturnPeriod ? 5 : filterSortBy,
      };
    }

    /**
     * set filter max_buy
     */
    if (!!maxBuy) fixedParams = { ...fixedParams, max_buy: maxBuy };

    // set custom currency
    if (currency) setFilterCurrency(currency);

    // set extra params from props
    if (fixedParamsFromProps)
      fixedParams = { ...fixedParams, ...fixedParamsFromProps };

    setFilterFixedParams(fixedParams);
  }, [
    category,
    currency,
    currentCategoryTab,
    filterSortBy,
    fixedParamsFromProps,
    flagReturnPeriod,
    imName,
    isReinvestBonds,
    maxBuy,
    setFilterCurrency,
    setFilterFixedParams,
    setFilterFixedParamsShown,
    setFilterType,
    showHighestReturn,
    showOnlyTopProduct,
    showUSDProducts,
  ]);

  const handleToggleCompareModal = (val?: boolean) => {
    if (typeof val !== 'undefined') {
      setVisibilityCompareModal(val);
      return;
    }
    setVisibilityCompareModal(!visibilityCompareModal);
  };

  /** Show Introduction of Instant Redemption modal */
  const handleShowInstantModal = (
    event: React.SyntheticEvent<HTMLDivElement, Event>
  ) => {
    // Guard if instant redemption isn't active by feature flag
    if (!instantRedemptionActiveByFF) return;

    event.stopPropagation();
    toggleIRModal();
  };

  const handleCloseInstantModal = () => {
    toggleIRModal();
  };

  /** Show Top Company info modal */
  const handleShowTopCompanyModal = () => {
    toggleTopCompanyModal();
  };

  const handleCloseTopCompanyModal = () => {
    toggleTopCompanyModal();
  };

  /**
   * Handle reksadana card click
   * @param category
   * @param symbol
   * @returns
   */
  const handleCardClickInside = (category: string, symbol: string) => {
    const index = data?.allData.findIndex((item) => item.symbol === symbol);
    const fundObject: ReksaDana | null =
      index > -1 ? data?.allData[index] : null;

    // Is not comparing
    if (!visibilityCompareModal) {
      // For other pages than category
      if (onCardClick) {
        onCardClick(category, symbol, {
          fundObject,
          sortBy: String(filterSortBy),
        });
        return;
      }

      // Push to ReksadanaPage
      history.push(`/reksadana/${symbol}`);

      if (filterIsSorting) {
        Analytics.logEventAction({
          eventName: 'search_action',
          parameter: {
            action: 'sort_company',
            trigger: 'click',
            data: {
              company_id: fundObject?.id,
              group: state,
              [state]: dynamicCategory,
            },
            context: 'home.group',
          },
        });
      } else {
        Analytics.logEventAction({
          eventName: 'search_action',
          parameter: {
            action: 'company',
            trigger: 'click',
            data: {
              company_id: fundObject?.id,
              search: !!Number(filterSortBy),
              group: state,
              [state]: dynamicCategory,
            },
            context: 'home.group',
          },
        });
      }
      return;
    }

    // If is comparing
    // Remove from comparison if selected again, for toggling effect
    if (
      Array.isArray(comparedProductsData) &&
      comparedProductsData.find((item) => item?.symbol === symbol)
    ) {
      setComparedProductsData(
        comparedProductsData.filter((item) => item?.symbol !== symbol)
      );

      Analytics.logEventAction({
        eventName: 'search_action',
        parameter: {
          action: 'compare_company',
          trigger: 'click',
          data: {
            company_id: fundObject?.id,
            group: state,
            [state]: dynamicCategory,
            status: 'deselect',
          },
          context: 'search.group',
        },
      });
      return;
    }

    // Prevent addition if already 3 symbol selected
    if (
      Array.isArray(comparedProductsData) &&
      comparedProductsData.length >= 3
    ) {
      return;
    }

    Analytics.logEventAction({
      eventName: 'search_action',
      parameter: {
        action: 'compare_company',
        trigger: 'click',
        data: {
          company_id: fundObject?.id,
          group: state,
          [state]: dynamicCategory,
          status: 'select',
        },
        context: 'search.group',
      },
    });

    const comparedProduct = data?.allData?.find(
      (item) => item?.symbol === symbol
    );
    const arrayComparedProducts = [...comparedProductsData];
    if (comparedProduct) {
      arrayComparedProducts.push(comparedProduct);
    }

    return setComparedProductsData(arrayComparedProducts);
  };

  /**
   * handle change sort direction
   * @param direction sort direction
   */
  const handleDirectionChange = (direction: string) => {
    history.replace({
      search: buildQueryParams({
        is_instant_redemption: filterIsInstantRedemption,
        sort: direction,
        sort_by: String(filterSortBy),
        sort_period: filterSortPeriod,
      }),
    });
    setFilterSortOrder(direction);
  };

  /**
   *
   * USE EFFECTS
   *
   */

  const decideColor = (category: string) => {
    let statusBarColorDecided: StatusBarApp;

    switch (category) {
      case 'moneymarket':
        statusBarColorDecided = {
          backgroundColor:
            theme === 'light'
              ? PORTFOLIO_PAGE_STATUS_BAR_COLOR.backgroundColor
              : '#163228',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'debt':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#dceaf4' : '#1e2d39',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'equity':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#ecdff7' : '#2a2339',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'others':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#e1f0f9' : '#1c3236',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'syariah':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#ECF2DE' : '#212711',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'highreturn':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#FDECD7' : '#212711',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'indexfund':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#f4ede7' : '#382d1e',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'usd':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#e0e5f0' : '#1b2637',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'minimum':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#f1dfee' : '#351d31',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      case 'instant':
        statusBarColorDecided = {
          backgroundColor: theme === 'light' ? '#fff1d1' : '#352e1d',
          foregroundColor: theme === 'light' ? 'dark' : 'light',
        };

        return statusBarColorDecided;
      default:
        statusBarColorDecided = {
          backgroundColor: DEFAULT_STATUS_BAR_COLOR().backgroundColor,
          foregroundColor: DEFAULT_STATUS_BAR_COLOR().foregroundColor,
        };
        return statusBarColorDecided;
    }
  };

  useEffect(() => {
    if (!enabledSetStatusBarColor) return;

    const { backgroundColor, foregroundColor } = decideColor(category);

    onSetStatusBarColor({ backgroundColor, foregroundColor });

    return () => {
      document.body.style.removeProperty('overflow');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSetStatusBarColor, category, enabledSetStatusBarColor, theme]);

  useEffect(() => {
    // If instant redemption FF is being turned off, user cannot access /categories/instant page
    // This block will force user to other page
    if (!instantRedemptionActiveByFF && isCategoriesInstantPage) {
      history.replace('/categories/others');
    }
  }, [instantRedemptionActiveByFF, history, isCategoriesInstantPage]);

  /** Initalize state on page open */
  useEffect(() => {
    resetAllState();
    handleInitializeList();
  }, [handleInitializeList, resetAllState]);

  /** clean query params */
  useEffect(() => {
    const disableClean = query.get('disable-clean');

    if (Boolean(disableClean)) return;
    if (!cleanQueryParams) return;

    history.replace({
      search: '',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleanQueryParams]);

  /** Navigate analytics */
  useEffect(() => {
    if (filterIsSorting) {
      const data = {
        group: state,
        [state]: dynamicCategory,
        ...(!!filterSortBy ? { sort_by: filterSortBy } : {}),
        ...(!!filterSortPeriod ? { sort_periode: filterSortPeriod } : {}),
      };

      Analytics.logEventNavigation({
        eventName: 'navigate',
        parameter: {
          page: 'search',
          view: 'filter_result',
          data: data,
        },
      });
    }
  }, [state, dynamicCategory, filterSortBy, filterSortPeriod, filterIsSorting]);

  const dataEmpty = (data?.allData || []).length <= 0;

  if (!isLoading && !!errMsg) {
    return (
      <NotFound404
        title='Produk Tidak Ditemukan'
        desc='Maaf produk yang kamu cari tidak ditemukan di sistem kami'
      />
    );
  }

  const urlPath = window.location.pathname;
  const isRoboPage = urlPath.includes('/profiling-select/');

  const dataAnalytics = getAnalyticData(isRoboPage, categoryType, filterIm);

  const handleApply = (params: onApplyParams) => {
    if (disableApplyParams) {
      const pathname = window.location.href || '/';
      const split = pathname.split('?');
      const getQueryParams = split[1];
      const combinedQueryParams = `${getQueryParams}&${buildQueryParams(
        params
      )}`;

      return history.replace({
        search: combinedQueryParams,
      });
    }

    history.replace({
      search: buildQueryParams(params),
    });
  };

  const handlePeriodReturn = () => {
    const onApplyParams: onApplyParams = {
      ...(!!activeChipsReturnPeriod
        ? { sort_period: activeChipsReturnPeriod }
        : { sort_period: filterSortPeriod }),
      ...(filterIsSyariah !== undefined ? { is_syariah: filterIsSyariah } : {}),
      ...(filterIsInstantRedemption
        ? { is_instant_redemption: filterIsInstantRedemption }
        : {}),
      ...(filterIsTopCompany ? { is_top_product: filterIsTopCompany } : {}),
      ...(filterBankId ? { bank_id: filterBankId } : {}),
    };
    handleApply(onApplyParams);

    toggleModalReturnPeriod();
    setFlagReturnPeriod(false);
  };

  const handleCloseFilter = () => {
    if (history.location.search !== '') {
      history.replace(history.location.pathname);
    }

    Analytics.logEventAction({
      eventName: 'filter_sort_action',
      parameter: {
        action: 'close_sort',
        trigger: 'click',
        context: 'rd.index',
        data: {
          ...dataAnalytics,
          fund:
            filterIsSyariah === undefined
              ? 'all'
              : filterIsSyariah
              ? 'syariah'
              : 'conventional',
        },
      },
    });

    setFlagReturnPeriod(false);
  };

  const handleOpenModalReturnPeriod = () => {
    if (visibilityCompareModal) return;

    Analytics.logEventAction({
      eventName: 'filter_sort_action',
      parameter: {
        action: 'return_period',
        trigger: 'click',
        context: 'rdproduct.index',
      },
    });

    toggleModalReturnPeriod();
  };

  const statementRenderTextSortPeriod =
    filterSortBy === 4 || filterSortBy === 5 || filterSortBy === 10;

  return (
    <div
      className={classNames(
        'content-wrapper get-started-page get-started-page-reksadana',
        {
          'mutualfund-categories--dark': theme === 'dark',
          'reksadana-product-list-dark': theme === 'dark',
          [classNameBox ?? '']: !!classNameBox,
        }
      )}
    >
      {showHeader && (
        <div
          className='content-header categories-page new-content-header'
          style={{ backgroundColor: statusBarColor.backgroundColor }}
        >
          <TopBar
            style={{ backgroundColor: 'transparent' }}
            title={t(decideTitle(category, imName))}
            showBackButton
            onClickBack={handleClickBack}
          />
        </div>
      )}
      <ContentBox
        headerHeight={56}
        bottomHeight={bottomHeight}
        onScroll={listenPageScroll}
        className={classNames('reksadana-page-contentbox', {
          'content-box-inmodal': inModal,
        })}
        id='scrollable-container'
      >
        {/* Category Banner */}

        {showBanner && (
          <BibitPlusCategoryBanner
            category={category as CategoryBannerProps['category']}
            removeDivider={removeDevider}
          />
        )}

        {/* Filter and ReksaDana List With Pull To Refresh */}
        <PullerReksadaProductList
          renderPuller={renderPuller}
          showPullToRefresh={showPullToRefresh}
          removePullerBox={removePullerBox}
          classNamesPuller={classNamesPuller}
          isLoading={isLoading}
          dataEmpty={dataEmpty}
          handlePullToRefresh={handlePullToRefresh}
        >
          {showSortAndCompare && (
            <SortAndCompareButton
              onApply={handleApply}
              onSortDirectionChange={handleDirectionChange}
              onToggleCompareModal={handleToggleCompareModal}
              onConfirmCompare={onConfirmCompare}
              showOnlyTopProduct={showOnlyTopProduct}
              productListCategory={category}
              inModal={inModal}
              isFilterHidden={!showSortAndCompare}
              showCompareButton={showCompareButton}
            />
          )}

          {showQuickPeriod && !isLoading && (
            <div
              className={classNames('wrapper-period', {
                'wrapper-period-inmodal': inModal,
              })}
              data-testid='filter-sort-action-return-period'
            >
              <Text htmlTag='span' type='body14r'>
                {flagReturnPeriod ? t('urut_berdasarkan') : t('periode_return')}
              </Text>
              {flagReturnPeriod ? (
                <div className='text-filter-period'>
                  <Text htmlTag='p' type='body13r'>
                    {renderTextSortBy()}{' '}
                    {statementRenderTextSortPeriod && (
                      <>
                        <span>•</span> {renderTextSortPeriod()}
                      </>
                    )}
                  </Text>
                  <CloseFilterIcon
                    onClick={handleCloseFilter}
                    data-testid='filter-sort-action-close-sort'
                  />
                </div>
              ) : (
                <div
                  className='text-period'
                  onClick={handleOpenModalReturnPeriod}
                >
                  <Text htmlTag='p' color='greenDefault' type='heading14b'>
                    {renderTextSortPeriod()}
                  </Text>
                  <img src={ArrowDown} alt='arrow-down' />
                </div>
              )}
            </div>
          )}

          <Show when={!isLoading && !!errMsg}>
            <ErrorCard
              message={errMsg ?? 'Terjadi kesalahan. Coba kembali'}
              onReload={handlePullToRefresh}
            />
          </Show>

          <div
            className={classNames({
              paddingTop20: (!showQuickPeriod && inModal) || !showQuickPeriod,
            })}
          />

          {/* ReksaDana Card List */}
          <Show when={category !== 'best' && category !== 'highreturn'}>
            <div
              className={classNames(
                'bit-reksa-cards-wrap',
                'paddingBottom50',
                'bit-reksa-fullheight',
                listWrapperClassName,
                {
                  'bit-reksa-compare-maximum-padding compare-modal-padding-bottom':
                    visibilityCompareModal,
                  paddingTop20: !inModal && showQuickPeriod,
                }
              )}
            >
              {!isLoading && (
                <ReksaDanaCardList
                  loading={isLoading}
                  category={category}
                  funds={data?.allData}
                  sortBy={filterIsSorting ? String(filterSortBy) : ''}
                  sortPeriod={filterSortPeriod}
                  loadMoreLoading={isFetchingNextPage}
                  isSorting={filterIsSorting && numberOfActiveSort > 0}
                  onCardClick={handleCardClickInside}
                  isComparing={visibilityCompareModal}
                  selected={
                    visibilityCompareModal ? comparedProductsSymbol : selected
                  }
                  handleShowInstantModal={handleShowInstantModal}
                  handleShowTopCompanyModal={handleShowTopCompanyModal}
                  showTopProductRibbon={!showOnlyTopProduct}
                  showLoading={false}
                  showLink={showLink}
                  ableToSelectProduct={ableToSelectProduct}
                  onCustomNavigateMutualFundDetail={
                    onCustomNavigateMutualFundDetail
                  }
                  isInsideModal={inModal}
                  showMixedProducts={showMixedProducts}
                />
              )}
              {(isLoading || isFetchingNextPage) && (
                <div className='boxloader'>
                  <div className='spinner' />
                </div>
              )}
            </div>
          </Show>

          <Show when={category === 'best' || category === 'highreturn'}>
            <div
              className={classNames(
                'bit-reksa-cards-wrap bibit-plus-reksa-cards',
                listWrapperClassName
              )}
            >
              <CommonBestTab
                isInsideModal
                onChangeTab={(data) => {
                  setCurrentCategoryTab(data?.type || 'moneymarket');
                }}
              >
                <div
                  className={classNames({
                    paddingTop20: !showQuickPeriod,
                  })}
                />
                <ReksaDanaCardList
                  category={currentCategoryTab}
                  showLink={showLink}
                  funds={data?.allData}
                  sortBy={filterIsSorting ? String(filterSortBy) : ''}
                  sortPeriod={filterSortPeriod}
                  loading={isLoading}
                  loadMoreLoading={isFetchingNextPage}
                  isSorting={filterIsSorting && numberOfActiveSort > 0}
                  onCardClick={handleCardClickInside}
                  isComparing={visibilityCompareModal}
                  selected={
                    visibilityCompareModal ? comparedProductsSymbol : selected
                  }
                  handleShowInstantModal={handleShowInstantModal}
                  handleShowTopCompanyModal={handleShowTopCompanyModal}
                  ableToSelectProduct={ableToSelectProduct}
                  onCustomNavigateMutualFundDetail={
                    onCustomNavigateMutualFundDetail
                  }
                  showTopProductRibbon={false}
                  isInsideModal={inModal}
                />
              </CommonBestTab>
            </div>
          </Show>
        </PullerReksadaProductList>

        {/* Pencairan Instan Modal */}
        <IntroInstantRedemptionModal
          showModal={showIRModal}
          onClose={handleCloseInstantModal}
        />

        {/* Top Company Info Modal */}
        <TopCompanyInfoModal
          onClose={handleCloseTopCompanyModal}
          visible={showTopCompanyModal}
        />

        <ModalReturnByPeriod
          categoryType={categoryType}
          isOpen={showReturnPeriodModal}
          onClose={toggleModalReturnPeriod}
          onClick={handlePeriodReturn}
          activeChips={activeChipsReturnPeriod}
          setActiveChips={setActiveChipsReturnPeriod}
          inModalQuickSort={inModal}
        />

        {/* Infinite Scroll */}
        {Array.isArray(data?.allData) &&
          data?.allData.length > 0 &&
          hasNextPage &&
          !showHighestReturn && <Waypoint onEnter={() => fetchNextPage()} />}
      </ContentBox>
    </div>
  );
};

const ReksadanaProductListWithTabBarHOC = tabBarHoc(ReksadanaProductList);

export const ReksadanaProductListWithoutTabBarHOC = ReksadanaProductList;

export default ReksadanaProductListWithTabBarHOC;
