import {
  checkTokenExpiration,
  handleRefreshTokenAxios,
} from 'core/refresh-token';
import { AxiosRequestConfig } from 'axios';
import { sendToDebugger } from 'devtools/components/tools/NativeDebugger/NativeDebuggerProvider';
import Analytics from 'utils/Analytics';
import jwtDecode from 'jwt-decode';
import { JwtAcToken } from 'core/refresh-token/refresh-token.type';
import i18n from 'i18n';

const requestInterceptors = async (config: AxiosRequestConfig<any>) => {
  // current actoken
  const acToken = (config.headers?.common as any)?.Authorization;

  // check token expiration every request come out
  const tokenExp = checkTokenExpiration(acToken);

  // if token expired and url not `/auth/token`
  if (tokenExp && config.url !== '/auth/token') {
    let decodedJwt: JwtAcToken | undefined;
    try {
      decodedJwt = jwtDecode<JwtAcToken>(acToken);
    } catch (error) {}

    Analytics.logEventAction({
      eventName: 'refresh_token_action',
      parameter: {
        action: 'expired_token',
        context: 'token',
        trigger: 'api',
        data: {
          expired: decodedJwt?.exp,
          endpoint: config.url,
        },
      },
    });

    // then do refresh token
    await handleRefreshTokenAxios(config);
  }

  /** Set networkID: [GET] /portfolio */
  const url = config.url || '';
  const method = config.method || '';
  const networkID = `[${method.toUpperCase()}] ${url}`;

  config.headers = {
    ...config.headers,
    'Accept-Language': i18n.language,
  };

  /** Set network request to native debugger */
  if (config) {
    sendToDebugger({
      idevent: networkID,
      status: 'waiting',
      response: undefined,
      request: config,
      type: 'network',
    });
  }
};

export default requestInterceptors;
