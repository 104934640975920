import { postData } from 'core/Parent';
import React, { memo, useEffect } from 'react';
import { PropsWithChildren } from 'react';
import { randomString } from 'utils/stringHelper';

/**
 * Fire `stopCacheAssets` to tell Native to stop caching behavior.
 */
const StopCacheNative = memo((props: PropsWithChildren) => {
  useEffect(() => {
    postData(`stopCacheAssets_${randomString(2)}`, {
      fn: 'stopCacheAssets',
    });
  }, []);

  return <>{props.children}</>;
});

StopCacheNative.displayName = 'StopCacheNative';

export default StopCacheNative;
