import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useGlobalRecurringContext } from '../contexts/GlobalRecurringContext';

type handleBackFn = (params: { fallbackURL: string }) => void;

type HooksFn = () => {
  entryPoint: string;
  handleBack: handleBackFn;
};

const useTriggerEntryPoint: HooksFn = () => {
  const { handleSetEntryPoint, entryPoint } = useGlobalRecurringContext();
  const location = useLocation<{ triggeredFrom: string }>();
  const history = useHistory();

  useEffect(() => {
    if (location.state?.triggeredFrom) {
      handleSetEntryPoint(location.state?.triggeredFrom);
    }
  }, [location.state, handleSetEntryPoint]);

  /**
   * Handling which ruote should we take if this function runs.
   * if has entry point, go back to it and set empty the entryPoint
   * else, throw to fallbackURL
   */
  const handleBack: handleBackFn = (params) => {
    if (entryPoint) {
      history.replace(entryPoint);
      return handleSetEntryPoint('');
    }

    return history.replace(params.fallbackURL);
  };

  return { entryPoint, handleBack };
};

export default useTriggerEntryPoint;
