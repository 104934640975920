export interface LivePriceData {
  [key: string]: any;
  channel: string;
  companySymbol: string;
  lastUpdate: string;
  lastUpdateFormatted: string;
  adjClose: string;
  open: string;
  high: string;
  low: string;
  close: string;
  volume: string;
  lastPrice: string;
  change: string;
  percentageChange: string;
  dividend: string;
  foreignBuy: string;
  foreignFlow: string;
  foreignSell: string;
  frequency: string;
  marketCap: string;
  value: string;
  shareoutStanding: string;
  annEps: string;
  annPe: string;
  avgPrice: string;
  previous: string;
  frequencyAnalyzer: string;
  sysSendtime: string;
}

export interface OrderBookLiveData {
  [key: string]: any;
  channel: string;
  companySymbol: string;
  status: string;
  sysSendtime: string;
  queue: {
    price: string;
    queue: string;
    volume: string;
  }[];
}

export enum RandomStringFormat {
  Mix = 'mix',
  Num = 'num',
}
