import {
  LogActionParameterTypes,
  LogDebugParameterTypes,
  LogNavigationParameterTypes,
} from 'utils/Analytics/types';
import { isInsideNativeContainer } from 'utils/validator';

type EventProps = {
  eventName: string;
  parameter:
    | LogActionParameterTypes
    | LogNavigationParameterTypes
    | LogDebugParameterTypes;
};

/**
 * record user data on login
 */
export const clevertapRecordUser = (props: Record<string, any>) => {
  if (isInsideNativeContainer() || !window.clevertap) return;
  return window.clevertap?.onUserLogin.push({
    Site: {
      ...props,
    },
  });
};

/**
 * logout user
 */
export const clevertapLogoutUser = () => {
  if (isInsideNativeContainer() || !window.clevertap) return;

  if (typeof window.clevertap?.logout === 'function') {
    return window.clevertap?.logout();
  }
};

/**
 * track event
 */
export const clevertapTrackEvent = (
  _eventKey: 'navigate' | 'action' | 'debug',
  { eventName, parameter }: EventProps
) => {
  if (isInsideNativeContainer() || !window.clevertap) return;

  const parameterData =
    typeof parameter.data === 'object' ? parameter.data : {};

  let evtName = eventName;
  let additionalParameter: Record<string, any> = {};

  if (_eventKey === 'navigate') {
    const param = parameter as LogNavigationParameterTypes;
    evtName = `${eventName}_${param.page}_${param.view}`;
  }

  if (_eventKey === 'action') {
    const param = parameter as LogActionParameterTypes;
    additionalParameter.user_action = param.action;
  }

  const params = {
    ...parameter,
    ...parameterData,
    ...additionalParameter,
  };

  delete params.data;

  return window.clevertap?.event.push(evtName, params);
};
