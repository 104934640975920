import React, { FC } from 'react';
import { Flex } from '@bibitid/uikit-v1';
import style from './InfoProductList.module.css';
import IconLamp from 'assets/images/home/onboarding-modal-flow/icon-lamp/IconLamp';

interface InfoProductListProps {
  infoMessage: string | React.ReactNode;
}
const InfoProductList: FC<React.PropsWithChildren<InfoProductListProps>> = ({
  infoMessage,
}) => {
  return (
    <Flex
      justify='start'
      align='center'
      className={style['bibit-product-info-container']}
    >
      <Flex.Item
        className={style['bibit-product-info-icon']}
        style={{ flexGrow: 0 }}
      >
        <IconLamp />
      </Flex.Item>
      <Flex.Item className={style['bibit-product-info-text']}>
        {infoMessage}
      </Flex.Item>
    </Flex>
  );
};

export default InfoProductList;
