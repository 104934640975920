import * as React from 'react';
import {
  SkeletonWrapper,
  Table,
  TableBody,
  TableContainer,
  TableDefinition,
  TableHead,
  TableHeader,
  TableRow,
  TableWrapper,
} from '../StockTable.styled';
import { SkeletonLoading } from 'features/common/components';
import { useWindowResize } from 'hooks';

const TableShimmerLoader: React.FC<{ isDesktop?: boolean }> = ({
  isDesktop = false,
}) => {
  const { width: screenWidth } = useWindowResize();
  let theScreenWidth = isDesktop ? 768 - 40 : screenWidth - 40;

  const totalColumn = 3;
  const defaultWidthTitle = isDesktop ? '338px' : '170px';

  const forceColumnWidth = React.useMemo(() => {
    let baseWidth =
      theScreenWidth - Number(defaultWidthTitle?.replace('px', '') ?? 0);
    let keyStatsWidth = baseWidth / 2;

    return {
      keyStatsWidth: `${keyStatsWidth}px`,
    };
  }, [theScreenWidth, defaultWidthTitle]);

  return (
    <TableContainer>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              {Array(totalColumn)
                ?.fill(null)
                .map((_, idx) => (
                  <TableHeader
                    key={idx}
                    hideBorder
                    width={
                      idx === 0
                        ? defaultWidthTitle
                        : forceColumnWidth?.keyStatsWidth
                    }
                  >
                    <SkeletonWrapper
                      showBorder={false}
                      width={
                        idx === 0
                          ? defaultWidthTitle
                          : forceColumnWidth?.keyStatsWidth
                      }
                      position={idx === 0 ? 'flex-start' : 'flex-end'}
                    >
                      <SkeletonLoading width={'50px'} height={'12px'} />
                    </SkeletonWrapper>
                  </TableHeader>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array(7)
              .fill(null)
              ?.map((_, idx) => (
                <TableRow key={idx}>
                  {Array(totalColumn)
                    ?.fill(null)
                    .map((_, index) => (
                      <>
                        {index === 0 ? (
                          <TableDefinition key={index}>
                            <SkeletonWrapper
                              height='52px'
                              width={defaultWidthTitle}
                              position='flex-start'
                              verticalPosition='center'
                            >
                              <SkeletonLoading
                                borderRadius={'50%'}
                                width={'32px'}
                                height={'32px'}
                              />
                              <SkeletonLoading width={'70px'} height={'12px'} />
                            </SkeletonWrapper>
                          </TableDefinition>
                        ) : (
                          <TableDefinition>
                            <SkeletonWrapper
                              width={forceColumnWidth?.keyStatsWidth}
                              verticalPosition='center'
                              height='52px'
                            >
                              <SkeletonLoading width={'50px'} height={'12px'} />
                            </SkeletonWrapper>
                          </TableDefinition>
                        )}
                      </>
                    ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </TableContainer>
  );
};

export default TableShimmerLoader;
