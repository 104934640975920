import {
  getAnalytics,
  logEvent,
  isSupported,
  Analytics,
  setUserProperties,
  setUserId,
} from 'firebase/analytics';
import * as Sentry from '@sentry/react';

import {
  AnalyticsLogActionTypes,
  AnalyticsLogDebugTypes,
  AnalyticsLogNavigationTypes,
  LogActionParameterTypes,
  LogDebugParameterTypes,
  LogNavigationParameterTypes,
} from 'utils/Analytics/types';
import { firebaseApp } from 'core/firebase';

/**
 * Initialize analytics. Returns firebase analytics instance
 */

export const initFirebaseAnalytics = async () => {
  try {
    const envSupported = await isSupported();

    // make sure env is supported and has firebase instance
    if (envSupported && firebaseApp) {
      /**
       * analytics instance
       */
      const analytics = getAnalytics(firebaseApp);
      return analytics;
    }
    return undefined;
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTag('domain', 'initFirebaseAnalytics');
      Sentry.captureException(error);
    });

    /**
     * handle `FirebaseError` on initialize analytics
     */
    return undefined;
  }
};

/**
 * send a log event to firebase
 */
export const firebaseAnalyticsLogEvent = (
  firebaseAnalytics: Analytics,
  eventName: string,
  parameter:
    | LogActionParameterTypes
    | LogNavigationParameterTypes
    | LogDebugParameterTypes
) => {
  logEvent(firebaseAnalytics, eventName, parameter);
};

/**
 * set an user properties to firebase
 */
export const firebaseSetUserProps = (
  firebaseAnalytics: Analytics,
  params: Record<string, any>
) => {
  setUserProperties(firebaseAnalytics, params);
};

/**
 * set an user id to firebase
 */
export const firebaseSetUserId = (firebaseAnalytics: Analytics, id: string) => {
  setUserId(firebaseAnalytics, id);
};

/**
 * Record an analytics event
 * @description There is 2 event type of analytics (navigate & action). You should choose one of _eventKey first to know keys of the parameter
 * @param {Object} event Analytics event object
 * @param {string} event.eventName Event type name
 * @param {Object} event.parameter The event parameter
 * */
export const firebaseTrackEvent = async (
  event:
    | AnalyticsLogNavigationTypes
    | AnalyticsLogActionTypes
    | AnalyticsLogDebugTypes
) => {
  const { parameter, eventName } = event;

  const parameterData = JSON.stringify(parameter.data);

  const eventParams = {
    ...parameter,
    data: parameterData,
  };

  try {
    const firebaseAnalytics = await initFirebaseAnalytics();
    if (firebaseAnalytics) {
      firebaseAnalyticsLogEvent(firebaseAnalytics, eventName, eventParams);
    }
  } catch (err) {}
};

export const firebaseRecordUser = async ({
  id,
  ...props
}: Record<string, any>) => {
  try {
    const firebaseAnalytics = await initFirebaseAnalytics();
    if (firebaseAnalytics) {
      firebaseSetUserId(firebaseAnalytics, id);
      //if props is an object
      if (!!props) firebaseSetUserProps(firebaseAnalytics, props);
    }
  } catch (err) {}
};
