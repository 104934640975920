import useStockbitWS, { WSChannelProps } from '../hooks/useStockbitWS';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';

interface StockbitWSContextProps {
  setChannels: React.Dispatch<React.SetStateAction<WSChannelProps>>;
  message?: {
    livePrice: {
      [key: string]: any;
    };
    orderBook: {
      [key: string]: any;
    };
  };
  stockbitWs?: {
    [key: string]: any;
  };
}

const StockbitWSContext = createContext<StockbitWSContextProps>({
  setChannels: () => null,
});

export const useStockbitWSContext = () => {
  const context = useContext(StockbitWSContext);

  return context;
};

let message = {
  livePrice: {},
  orderBook: {},
};

const StockbitWSProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [channels, setChannels] = useState<WSChannelProps>({});
  const prevChannels = useRef<WSChannelProps>({});
  const { message: stockbitWs } = useStockbitWS({
    channels,
  });

  useEffect(() => {
    // reset message every user change page
    message = {
      livePrice: {},
      orderBook: {},
    };
  }, []);

  useEffect(() => {
    const pauseWebSocket = () => {
      if (document.visibilityState === 'visible') {
        setChannels(prevChannels.current);
        return;
      }
      prevChannels.current = channels;
      setChannels({});
    };

    document.addEventListener('visibilitychange', pauseWebSocket);
    return () => {
      document.removeEventListener('visibilitychange', pauseWebSocket);
    };
  }, [channels]);

  message = {
    livePrice: {
      ...message.livePrice,
      [stockbitWs?.livePrice?.companySymbol]: {
        ...stockbitWs?.livePrice,
      },
    },
    orderBook: {
      ...message.orderBook,
      [stockbitWs?.orderBook?.companySymbol]: {
        ...stockbitWs?.orderBook,
      },
    },
  };

  return (
    <StockbitWSContext.Provider
      value={{
        setChannels,
        message,
      }}
    >
      {children}
    </StockbitWSContext.Provider>
  );
};

export default StockbitWSProvider;
