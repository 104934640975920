import {
  PortfolioCategoryDetailResponse,
  PortfolioResponse,
  PortfolioAllocationSummaryResponse,
  PortfolioAllocationDetailResponse,
  PortfolioAllocationResponse,
  PortfolioGoalOnTrackResponse,
  PortfolioGoalDetailResponse,
  PortfolioSwitchProductResponse,
  PortfolioGoalProgressChartResponse,
  PortfolioRecurringDetailResponse,
  GetCouponHistoriesResponse,
  PortfolioPerformanceResponse,
  PortfolioAssetClassesResponse,
  PortfolioRealizedProfitStatsResponse,
  PortfolioRiskResponse,
  RebalanceAmountResponse,
} from '../types';
import http from 'core/http';
import endpoints from './endpoints';
import { removeStockbitMaintenanceFlag } from 'features/explore/utils';

export const getPortfolioCategory = (
  categoryId: string
): PortfolioCategoryDetailResponse => {
  return http.get(endpoints.portfolioCategoryID(categoryId));
};

export const getPortfolio = (): PortfolioResponse => {
  return http.get(endpoints.portfolio);
};

export const getPortfolioAllocationSummary =
  (): PortfolioAllocationSummaryResponse => {
    return http.get(endpoints.portfolioAllocationSummary);
  };

export const getPortfolioAllocation = (
  type: string,
  filter?: string
): PortfolioAllocationResponse => {
  return http
    .get(endpoints.portfolioAllocation(type), filter && { filter })
    .then((data) => {
      removeStockbitMaintenanceFlag(data);
      return data;
    });
};

export const getPortfolioAllocationDetail = (
  symbol: string
): PortfolioAllocationDetailResponse => {
  return http.get(endpoints.portfolioAllocationDetail(symbol));
};

export const getPortfolioCouponHistories = (
  filter: object
): GetCouponHistoriesResponse => {
  return http
    .get(endpoints.portfolioCouponHistories, filter)
    .then((data) => data);
};

export const getPortfolioGoalOnTrack = (
  categoryId: string
): PortfolioGoalOnTrackResponse => {
  return http.get(endpoints.portfolioGoalOnTrack(categoryId));
};

export const getPortfolioGoalDetail = (
  categoryId: string
): PortfolioGoalDetailResponse => {
  return http.get(endpoints.portfolioGoalDetail(categoryId));
};

export const getPortfolioGoalProgressChart = (
  categoryId: string
): PortfolioGoalProgressChartResponse => {
  return http.get(endpoints.portfolioGoalChart(categoryId));
};

export const getPortfolioSwitchProducts = (
  symbol: string,
  categoryId: string,
  is_robo: boolean,
  is_sharia: number
): PortfolioSwitchProductResponse => {
  return http.get(endpoints.switchProductAvailable, {
    symbol,
    categoryId,
    is_robo,
    ...(!!is_sharia ? { is_sharia } : {}),
  });
};

export const getPortfolioRecurringListByPorto = (
  categoryId: string
): PortfolioRecurringDetailResponse => {
  return http.get(endpoints.recurringListByPorto(categoryId));
};

export const patchRearrangePortfolio = (categoryIds: number[]) => {
  return http.patch(endpoints.patchRearrangePortfolio, {
    category_ids: categoryIds,
  });
};

export const getPortfolioPerformance = (): PortfolioPerformanceResponse => {
  return http.get(endpoints.portfolioPerformance);
};

export const getPortfolioAssetClasses = (): PortfolioAssetClassesResponse => {
  return http.get(endpoints.portfolioAssetClasses);
};

export const getPortfolioRealizedProfitStats =
  (): PortfolioRealizedProfitStatsResponse => {
    return http.get(endpoints.portfolioRealizedProfitStats);
  };
export const getPortfolioRisk = (): PortfolioRiskResponse => {
  return http.get(endpoints.portfolioRisk);
};

export const getRebalanceAmount = ({
  productType,
  symbol,
}: {
  productType: 'FR' | 'SE';
  symbol: string;
}): RebalanceAmountResponse => {
  return http.get(endpoints.calculateRebalanceAmount(productType, symbol));
};
