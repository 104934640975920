import React, { useEffect, useState } from 'react';
import useDeviceInfo from 'hooks/useDeviceInfo';
import queryClient from 'network/queryClient';
import SplashScreen from 'features/common/BibitLoader/BibitLoader';

const DeviceInfo: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const deviceInfoCount = React.useRef(0);
  const [isReady, setIsReady] = useState(false);

  const { isFetching: fetchingDeviceInfo, data: deviceInfo } = useDeviceInfo();

  useEffect(() => {
    if (fetchingDeviceInfo) {
      return;
    }

    let timeout: ReturnType<typeof setTimeout>;

    if (deviceInfo === null && deviceInfoCount.current < 2) {
      timeout = setTimeout(() => {
        deviceInfoCount.current = deviceInfoCount.current + 1;
        queryClient.removeQueries(['getDeviceInfo']);
      }, 500);
      return;
    }

    setIsReady(true);

    return () => {
      clearTimeout(timeout);
    };
  }, [fetchingDeviceInfo, deviceInfo]);

  return <>{isReady ? children : <SplashScreen />}</>;
};

export default DeviceInfo;
