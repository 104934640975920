import { MIN_AGE_USER } from 'features/register-account/constants';

/**
 * Get max options date for date picker with subtraction from `MIN_AGE_USER`
 *
 * ex:
 * - Date now (2022-4-21) and Min age user (17), we expect max date picker is `2005-4-21`
 */
export const getMaxDatePicker = () => {
  const date = new Date();

  const yearNow = date.getFullYear();
  const dateNow = date.getDate();
  const monthNow = date.getMonth();

  const maxDatePicker = new Date(yearNow - MIN_AGE_USER, monthNow, dateNow);
  return maxDatePicker;
};

/**
 * Validate if string contains emoji or symbol unicode character
 */
export const checkStringWithEmoji = (value: string) => {
  return /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu.test(
    value
  );
};

/**
 * Validate name for registration
 */

export const isValidName = (str: string) => {
  const checkString = /^[.,"'‘’-]+$/i.test(str);
  if (!checkString) {
    return /^[a-z0-9\s.,"'‘’-]+$/i.test(str);
  }

  return !checkString;
};
