import Storage from 'core/Storage';
import IsEmpty from 'utils/isEmpty';
import { isJSON } from 'utils/validator';
import {
  PersistProperties,
  PersistPropertiesType,
  PersistStorageKey,
} from './types';

/**
 * Saving latest state data persist into localStorage
 * so we can restore
 *
 * Promise::true -> success saving
 *
 * Promise::false -> failed saving
 */
export const saveDataPersistsProperties = async (params: PersistProperties) => {
  // use type as storage key
  const { data, type: storageKey } = params;

  const dataStringfy = JSON.stringify(data);
  try {
    await Storage.set(storageKey, dataStringfy);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Get Latest data persist properties from local Storage
 *
 * Promise::false -> the value not available
 *
 */
export const getLatestDataPersistProperties = async <
  K extends PersistStorageKey
>(
  storageKey: K
) => {
  try {
    const storageData = await Storage.get(storageKey);
    const storageIsJSON = isJSON(storageData);

    let validateData: PersistPropertiesType[K] | undefined;

    // If storage is JSON -> parse it
    if (storageData && storageIsJSON) {
      validateData = JSON.parse(storageData);
    }

    // In iOS, storage is already an object, we use it directly
    if (storageData && typeof storageData === 'object') {
      validateData = storageData;
    }

    if (!storageData) {
      return false;
    }

    if (IsEmpty(validateData)) return false;

    return validateData;
  } catch (error) {
    return false;
  }
};

/**
 * remove Latest Data Persist Properties in LocalStorage
 *
 * Promise::true --> success remove
 *
 * Promise::false --> failed remove
 */
export const removeLatestDataPersistProperties = async (
  storageKey: PersistStorageKey
) => {
  try {
    await Storage.remove(storageKey);
    return true;
  } catch (error) {
    return false;
  }
};
