import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { Props } from './ModalCompareReksaDana';

const ModalCompareReksaDanaLazy = React.lazy(
  () => import('./ModalCompareReksaDana')
);

const ModalCompareReksaDana: React.FC<Props> = (props) => {
  return (
    <LazyModal
      visible={props.compareSelected}
      component={ModalCompareReksaDanaLazy}
      {...props}
    />
  );
};

export default ModalCompareReksaDana;
