import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Text } from '@bibitid/uikit-v1';
import { ReactComponent as Arrow } from 'assets/images/bank-jago/bank-jago-modal/grey-chevron-arrow-up.svg';
import { format } from 'utils/date';
import {
  JAGO_CASHBACK_END,
  JAGO_CASHBACK_START,
  JAGO_CASHBACK_BLOG_URL,
  JAGO_CASHBACK_AMOUNT,
} from 'features/bankjago/constants/common';
import '../../../pages/BankJagoCoregistPage/coregis.css';
import CashbackIcon from 'assets/images/bank-jago/cashback-icon.svg';

type ContentType = {
  description: string | ReactNode;
};

const promoStart = format(new Date(JAGO_CASHBACK_START), 'dd MMMM yyyy');
const promoEnd = format(new Date(JAGO_CASHBACK_END), 'dd MMMM yyyy');

function formatDateRange(start: string, end: string) {
  if (start === end) {
    return start;
  }

  const monthYearStart = start.slice(start.indexOf(' ') + 1);
  const monthYearEnd = end.slice(end.indexOf(' ') + 1);

  if (monthYearStart === monthYearEnd) {
    return `${start.split(' ')[0]} - ${end}`;
  }

  return `${start} - ${end}`;
}

const content: ContentType[] = [
  {
    description:
      'Promo khusus pengguna yang menyelesaikan registrasi Bank Jago atau Jago Syariah selama periode promo melalui Bibit',
  },
  {
    description:
      'Lakukan pembelian produk reksadana/saham/obligasi FR/stable earn apa aja dengan metode pembayaran Bank Jago maupun RDN Jago dalam satu kali transaksi dengan minimal transaksi Rp.100,000',
  },
  {
    description: `Cashback Rp ${JAGO_CASHBACK_AMOUNT} berupa saldo Bank Jago akan dikirim ke akun Bank Jago kamu paling 
lambat tanggal 16 pada bulan berikutnya.`,
  },
  {
    description: `Periode promo ${formatDateRange(promoStart, promoEnd)}`,
  },
  {
    description: (
      <>
        Ketentuan promo lebih lengkap cek di tautan{' '}
        <Link
          to={`/articles/detail?link=${encodeURIComponent(
            JAGO_CASHBACK_BLOG_URL
          )}`}
        >
          berikut
        </Link>
      </>
    ),
  },
];

const BankJagoTncCashback = () => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const handleAccordion = () => {
    setIsDropdownOpened(!isDropdownOpened);
  };
  return (
    <div className='bit-jago-tnc-cashback bit-jago-tnc-box'>
      {/* Header */}
      <div
        data-testid='jago-advantages-dropdown'
        className='bit-jago-benefit-wrapper'
        onClick={handleAccordion}
      >
        <div className='bit-jago-benefit-subtitle-wrapper'>
          <div className='app-logo'>
            <img src={CashbackIcon} alt='logo cashback' />
          </div>
          <Text type='subtitle2' htmlTag='p'>
            Syarat dan Ketentuan Cashback
          </Text>
        </div>

        <div
          className={classNames({
            'bit-jago-arrow-grey': true,
            'arrow-down': !isDropdownOpened,
          })}
        >
          <Arrow />
        </div>
      </div>
      {/* List of Bank jago advantages */}
      <div
        className={classNames({
          'bit-jago-advantages-accordion-body': true,
          'hide-dropdown-body': !isDropdownOpened,
        })}
      >
        <ol>
          {content.map((item: ContentType, index) => (
            <li key={index}>
              <Text className='margin-left-10 bit-jago-tnc-item' type='body1'>
                {item.description}
              </Text>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default BankJagoTncCashback;
