import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import LazyModal from 'features/common/LazyModal';
import queryClient from 'network/queryClient';
import {
  useRecurringContextFunction,
  useRecurringContextState,
} from 'features/recurring/contexts/RecurringContext';
import { useQueryParams } from 'utils/routeHelper';
import ExpiredModal from './components/ExpiredModal';
import { useStorageCurrentInstutiton } from 'features/institution/hooks';
import { removeCurrentInstitution } from 'features/institution/utils';
import { setIsRoboReducer } from 'features/portfolio/portfolioPage.reducer';
import { useFlexibleSipModal } from './hooks/useFlexibleSipModal';
import { useHistory } from 'react-router-dom';

const FlexibleSIPModal = React.lazy(() => import('./FlexibleSIPModal'));

const IndexFlexibleSIPModal: React.FC<React.PropsWithChildren> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const query = useQueryParams();
  const entryFrom = query.get('from') || '';
  const isFromExternal = ['email', 'push-notif'].includes(entryFrom);

  const {
    visible,
    isExpired,
    isLoading: loadingShouldShowSipModal,
    recurringId,
  } = useFlexibleSipModal();

  const { flexiSIPModalVisible, recurringOrderExpiredVisible } =
    useRecurringContextState();
  const { setFlexiSIPModalVisible, setRecurringOrderExpiredModal } =
    useRecurringContextFunction();
  // Check current user is institution
  const { data: currentInstitution } = useStorageCurrentInstutiton();

  const onExpiredModalClose = useCallback(() => {
    query.delete('error');
    history.replace(history?.location?.pathname);
  }, [history, query]);

  React.useEffect(() => {
    if (currentInstitution?.institution_id && isFromExternal) {
      // reset isRobo flag in reducer
      dispatch(setIsRoboReducer(false));
      removeCurrentInstitution();
      queryClient.invalidateQueries(['Product Offering List']);
    }
  }, [currentInstitution?.institution_id, dispatch, isFromExternal]);

  React.useEffect(() => {
    if (loadingShouldShowSipModal) {
      return;
    }

    if (visible && !isExpired) {
      setFlexiSIPModalVisible(true, recurringId);
    } else if (isExpired) {
      setRecurringOrderExpiredModal({
        visible: true,
        recurringId,
        onClose: onExpiredModalClose,
      });
    }
  }, [
    loadingShouldShowSipModal,
    recurringId,
    setFlexiSIPModalVisible,
    visible,
    isExpired,
    setRecurringOrderExpiredModal,
    onExpiredModalClose,
  ]);

  const otherProps = {
    ...props,
    recurringId: recurringOrderExpiredVisible?.recurringId,
    onClose: recurringOrderExpiredVisible?.onClose,
  };

  return (
    <>
      <LazyModal
        component={ExpiredModal}
        visible={recurringOrderExpiredVisible?.visible}
        {...otherProps}
      />
      <LazyModal component={FlexibleSIPModal} visible={flexiSIPModalVisible} />
    </>
  );
};

export { IndexFlexibleSIPModal };
