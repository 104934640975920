import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import Analytics from 'utils/Analytics';
import { PremiumTransactionDetail } from '../types';

interface State {
  dataPremiumOrderModal: {
    /** WS trans code for fetch `getPremiumTransactionDetail` */
    wsTransCode: string;
  };
  dataPremiumSellModal: {
    /** WS trans code for fetch `getPremiumTransactionDetail` */
    wsTransCode: string;
  };
  dataPremiumSwitchModal: {
    /** WS trans code for fetch `getPremiumTransactionDetail` */
    wsTransCode: string;
  };
  dataPremiumErrorSellOrSwitchModal: {
    /** WS trans code for fetch `getPremiumTransactionDetail` */
    wsTransCode?: string;

    /** Determine of IFA transaction */
    isIfaTransaction?: boolean;

    /**
     * Handle click button change for request cancel pending premium transactions
     * and next step to sell or switch
     */
    onClickButtonChange: () => void;
  };
  dataPremiumSuccessPaymentModal: {
    premiumTransDetail?: PremiumTransactionDetail;
  };
  dataPremiumTransactionsExpiredModal: {
    /** WS trans code for fetch `getPremiumTransactionDetail` */
    wsTransCode?: string;
  };
  dataIfaRdnWithdrawalTransModal: {
    /** WS trans code for IFA RDN withdrawal transaction */
    wsTransCode: string;
    /** RDN withdrawal balance */
    withdrawalBalance: number;
  };
  visiblePremiumOrderModal: boolean;
  visiblePremiumSellModal: boolean;
  visiblePremiumSwitchModal: boolean;
  visiblePremiumErrorSellOrSwitchModal: boolean;
  visiblePremiumSuccessPaymentModal: boolean;
  visiblePremiumTransactionsExpiredModal: boolean;
  visibleIfaTransactionExpiredModal: boolean;
  visibleIfaTransCancelledByAgentModal: boolean;
  visibleIfaTransactionMaintenanceModal: boolean;
  visibleIfaRdnWithdrawalTransModal: boolean;
  visibleIfaWithdrawalExpiredModal: boolean;
}

interface Function {
  setDataPremiumOrderModal: Dispatch<
    SetStateAction<State['dataPremiumOrderModal']>
  >;
  setDataPremiumSellModal: Dispatch<
    SetStateAction<State['dataPremiumSellModal']>
  >;
  setdataPremiumSwitchModal: Dispatch<
    SetStateAction<State['dataPremiumSwitchModal']>
  >;
  setDataPremiumErrorSellOrSwitchModal: Dispatch<
    SetStateAction<State['dataPremiumErrorSellOrSwitchModal']>
  >;
  setDataPremiumSuccessPaymentModal: Dispatch<
    SetStateAction<State['dataPremiumSuccessPaymentModal']>
  >;
  setDataPremiumTransactionsExpiredModal: Dispatch<
    SetStateAction<State['dataPremiumTransactionsExpiredModal']>
  >;
  setDataIfaRdnWithdrawalTransModal: Dispatch<
    SetStateAction<State['dataIfaRdnWithdrawalTransModal']>
  >;
  togglePremiumOrderModal: () => void;
  togglePremiumSellModal: () => void;
  togglePremiumSwitchModal: () => void;
  togglePremiumErrorSellOrSwitchModal: () => void;
  togglePremiumSuccessPaymentModal: () => void;
  togglePremiumTransactionsExpiredModal: () => void;
  handleCloseIfaTransactionMaintenanceModal: () => void;
  resetPremiumTransactionExpiredModal: () => void;
  toggleIfaTransactionExpiredModal: () => void;
  toggleIfaTransCancelledByAgentModal: () => void;
  toggleIfaTransactionMaintenanceModal: () => void;
  toggleIfaRdnWithdrawalTransModal: () => void;
  toggleIfaWithdrawalExpiredModal: () => void;
}

const ContextState = React.createContext<State | undefined>(undefined);
const ContextFunction = React.createContext<Function | undefined>(undefined);

/**
 * Context for handling visibility and data from
 * - PremiumOrderModal
 * - PremiumSellModal
 * - PremiumSwitchProductModal
 * - PremiumErrorSellOrSwitchModal
 * - PremiumSuccessPaymentModal
 * - PremiumTransactionsExpiredModal
 * - IfaTransactionExpiredModal
 * - IfaTransCancelledByAgentModal
 * - IfaTransactionMaintenanceModal
 * - IfaRdnWithdrawalModal
 */
export const PremiumTransactionsModalsContext: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [dataPremiumOrderModal, setDataPremiumOrderModal] = useState<
    State['dataPremiumOrderModal']
  >({ wsTransCode: '' });

  const [dataPremiumSellModal, setDataPremiumSellModal] = useState<
    State['dataPremiumSellModal']
  >({ wsTransCode: '' });

  const [dataPremiumSwitchModal, setdataPremiumSwitchModal] = useState<
    State['dataPremiumSwitchModal']
  >({ wsTransCode: '' });

  const [
    dataPremiumErrorSellOrSwitchModal,
    setDataPremiumErrorSellOrSwitchModal,
  ] = useState<State['dataPremiumErrorSellOrSwitchModal']>({
    wsTransCode: '',
    onClickButtonChange: () => {},
  });

  const [dataPremiumSuccessPaymentModal, setDataPremiumSuccessPaymentModal] =
    useState<State['dataPremiumSuccessPaymentModal']>({
      premiumTransDetail: undefined,
    });

  const [
    dataPremiumTransactionsExpiredModal,
    setDataPremiumTransactionsExpiredModal,
  ] = useState<State['dataPremiumTransactionsExpiredModal']>({
    wsTransCode: undefined,
  });

  const [dataIfaRdnWithdrawalTransModal, setDataIfaRdnWithdrawalTransModal] =
    useState<State['dataIfaRdnWithdrawalTransModal']>({
      wsTransCode: '',
      withdrawalBalance: 0,
    });

  const [visiblePremiumOrderModal, setVisiblePremiumOrderModal] =
    useState(false);

  const [visiblePremiumSellModal, setVisiblePremiumSellModal] = useState(false);

  const [visiblePremiumSwitchModal, setVisiblePremiumSwitchModal] =
    useState(false);

  const [
    visiblePremiumErrorSellOrSwitchModal,
    setVisiblePremiumErrorSellOrSwitchModal,
  ] = useState(false);

  const [
    visiblePremiumSuccessPaymentModal,
    setVisiblePremiumSuccessPaymentModal,
  ] = useState(false);

  const [
    visiblePremiumTransactionsExpiredModal,
    setVisiblePremiumTransactionsExpiredModal,
  ] = useState(false);

  const [
    visibleIfaTransactionExpiredModal,
    setVisibleIfaTransactionExpiredModal,
  ] = useState(false);

  const [
    visibleIfaTransCancelledByAgentModal,
    setVisibleIfaTransCancelledByAgentModal,
  ] = useState(false);

  const [
    visibleIfaTransactionMaintenanceModal,
    setVisibleIfaTransactionMaintenanceModal,
  ] = useState(false);

  const [
    visibleIfaRdnWithdrawalTransModal,
    setVisibleIfaRdnWithdrawalTransModal,
  ] = useState(false);

  const [
    visibleIfaWithdrawalExpiredModal,
    setVisibleIfaWithdrawalExpiredModal,
  ] = useState(false);

  const togglePremiumOrderModal = () => {
    setVisiblePremiumOrderModal(!visiblePremiumOrderModal);
  };

  const togglePremiumSellModal = () => {
    setVisiblePremiumSellModal(!visiblePremiumSellModal);
  };

  const togglePremiumSwitchModal = () => {
    setVisiblePremiumSwitchModal(!visiblePremiumSwitchModal);
  };

  const togglePremiumErrorSellOrSwitchModal = () => {
    setVisiblePremiumErrorSellOrSwitchModal(
      !visiblePremiumErrorSellOrSwitchModal
    );
  };

  const togglePremiumSuccessPaymentModal = () => {
    setVisiblePremiumSuccessPaymentModal(!visiblePremiumSuccessPaymentModal);
  };

  const togglePremiumTransactionsExpiredModal = () => {
    setVisiblePremiumTransactionsExpiredModal(
      !visiblePremiumTransactionsExpiredModal
    );
  };

  const toggleIfaTransactionExpiredModal = () => {
    setVisibleIfaTransactionExpiredModal(!visibleIfaTransactionExpiredModal);
  };

  const toggleIfaTransCancelledByAgentModal = () => {
    setVisibleIfaTransCancelledByAgentModal(
      !visibleIfaTransCancelledByAgentModal
    );
  };

  const toggleIfaTransactionMaintenanceModal = () => {
    setVisibleIfaTransactionMaintenanceModal(
      !visibleIfaTransactionMaintenanceModal
    );
  };

  const toggleIfaRdnWithdrawalTransModal = () => {
    setVisibleIfaRdnWithdrawalTransModal(!visibleIfaRdnWithdrawalTransModal);
  };

  const toggleIfaWithdrawalExpiredModal = () => {
    setVisibleIfaWithdrawalExpiredModal(!visibleIfaWithdrawalExpiredModal);
  };

  const handleCloseIfaTransactionMaintenanceModal = () => {
    if (visibleIfaTransactionMaintenanceModal) {
      Analytics.logEventAction({
        eventName: 'home_action',
        parameter: {
          action: 'close',
          context: 'home.ifa_maintenance',
          trigger: 'click',
        },
      });

      toggleIfaTransactionMaintenanceModal();
    }
  };

  const resetPremiumTransactionExpiredModal = () => {
    setDataPremiumTransactionsExpiredModal({ wsTransCode: undefined });
    setVisiblePremiumTransactionsExpiredModal(false);
  };

  return (
    <ContextState.Provider
      value={{
        dataPremiumOrderModal,
        dataPremiumSellModal,
        dataPremiumSwitchModal,
        dataPremiumErrorSellOrSwitchModal,
        dataPremiumSuccessPaymentModal,
        dataPremiumTransactionsExpiredModal,
        dataIfaRdnWithdrawalTransModal,
        visiblePremiumOrderModal,
        visiblePremiumSellModal,
        visiblePremiumSwitchModal,
        visiblePremiumErrorSellOrSwitchModal,
        visiblePremiumSuccessPaymentModal,
        visiblePremiumTransactionsExpiredModal,
        visibleIfaTransactionExpiredModal,
        visibleIfaTransCancelledByAgentModal,
        visibleIfaTransactionMaintenanceModal,
        visibleIfaRdnWithdrawalTransModal,
        visibleIfaWithdrawalExpiredModal,
      }}
    >
      <ContextFunction.Provider
        value={{
          setDataPremiumOrderModal,
          setDataPremiumSellModal,
          setdataPremiumSwitchModal,
          setDataPremiumErrorSellOrSwitchModal,
          setDataPremiumSuccessPaymentModal,
          setDataPremiumTransactionsExpiredModal,
          setDataIfaRdnWithdrawalTransModal,
          togglePremiumOrderModal,
          togglePremiumSellModal,
          togglePremiumSwitchModal,
          togglePremiumErrorSellOrSwitchModal,
          togglePremiumSuccessPaymentModal,
          togglePremiumTransactionsExpiredModal,
          toggleIfaTransactionExpiredModal,
          toggleIfaTransCancelledByAgentModal,
          resetPremiumTransactionExpiredModal,
          toggleIfaTransactionMaintenanceModal,
          toggleIfaRdnWithdrawalTransModal,
          toggleIfaWithdrawalExpiredModal,
          handleCloseIfaTransactionMaintenanceModal,
        }}
      >
        {children}
      </ContextFunction.Provider>
    </ContextState.Provider>
  );
};

export default PremiumTransactionsModalsContext;

export const usePremiumTransactionsModalsState = () => {
  const ctx = useContext(ContextState);
  if (!ctx) {
    throw new Error('Context usePremiumTransactionsModalsState out of bound');
  }

  return ctx;
};

export const usePremiumTransactionsModalsFunction = () => {
  const ctx = useContext(ContextFunction);
  if (!ctx) {
    throw new Error(
      'Context usePremiumTransactionsModalsFunction out of bound'
    );
  }

  return ctx;
};
