import React from 'react';
import { Modal, Text } from '@bibitid/uikit-v1';
import PortoMax from 'assets/images/portfolio/produk-tidak-tersedia/ProdukTidakTersedia';
import {
  useAppModalGlobalFunctions,
  useAppModalGlobalStateContext,
} from '../AppModalGlobalContext';

const ProductNotBuyableModal = () => {
  const { notBuyableProductModalOpen } = useAppModalGlobalStateContext();
  const { toggleNotBuyableProductModal } = useAppModalGlobalFunctions();

  return (
    <Modal
      visible={notBuyableProductModalOpen}
      onClose={toggleNotBuyableProductModal}
      className='max-porto-modal'
    >
      <Modal.Header showClose={true} showBackButton={false} />
      <Modal.Body className='text-center'>
        <PortoMax alt='Portfolio Telah Melebihi Batas' />
        <Text type='subtitle2' className='margin-top-10 margin-bottom-8'>
          Produk Belum Tersedia
        </Text>
        <Text>Saat ini produk sedang tidak dapat dibeli</Text>
      </Modal.Body>
      <Modal.Footer
        primaryButton={'Oke'}
        onPrimaryClick={toggleNotBuyableProductModal}
      />
    </Modal>
  );
};

export default ProductNotBuyableModal;
