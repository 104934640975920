import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Container,
  ExtraWhiteSpace,
  Table,
  TableBody,
  TableDefinition,
  TableHead,
  TableHeader,
  TableRow,
} from './StockTable.styled';
import {
  HeaderFieldProps,
  SortBy as SortByProps,
  StockTableProps,
  TableBodyProps,
  TableDefinitionConfig,
} from './StockTable.type';
import TableHeadText from './components/TableHeadText';
import TableHeadSort from './components/TableHeadSort';
import TableBodyText from './components/TableBodyText';
import TableBodyEmitten from './components/TableBodyEmitten';
import TableBodyProfitLoss from './components/TableBodyProfitLoss';
import { useWindowResize } from 'hooks';
import {
  DEFAULT_TABLE_BODY_CONFIG,
  DEFAULT_TABLE_HEAD_CONFIG,
} from './constants';
import TableShimmerLoader from './components/TableShimmerLoader';

const StockTable: React.FC<StockTableProps> = ({
  headerField = [],
  headerConfig = [],
  defaultHeaderType = 'TEXT',
  defaultBodyType = 'TEXT',
  data = [],
  bodyConfig = [],
  stickyHeader = false,
  isLoading = false,
  sortSide = 'client',
  sortBy = 'DESC',
  sortColumn = '',
  responsive = false,
  onSortData,
  onClick,
  marginLeft = 0,
  testId = '',
}) => {
  const { width: screenWidth } = useWindowResize();

  const tableRef = useRef<HTMLTableElement>(null);

  const [isCompleteRender, setIsCompleteRender] = useState<boolean>(false);
  const [tableData, setTableData] = useState<TableBodyProps[]>([]);
  const [tableHead, setTableHead] = useState<HeaderFieldProps[]>([]);
  const [isForceClientSort, setIsForceClientSort] = useState<boolean>(false);

  const isDesktopView = screenWidth >= 1030;
  const isSmallScreen = screenWidth <= 320;

  const leftWhitespace = `${marginLeft ?? 0}px`;

  /* 40 is total padding */
  const theScreenWidth = isDesktopView ? 768 - 40 : screenWidth - 40;

  const widthSource = isSmallScreen ? 'smallScreenWidth' : 'width';
  const widthProps = useMemo(() => {
    return isDesktopView ? 'desktop' : 'mobile';
  }, [isDesktopView]);

  const isResponsiveWidth = useMemo(() => {
    const configurationWidth = [...headerConfig]
      ?.map((data) =>
        Number(data?.[widthProps]?.[widthSource]?.replace('px', ''))
      )
      ?.reduce((total, num) => total + num);

    const totalNotConfigurableColumn =
      headerField?.length - headerConfig?.length;

    const responsiveWidth = theScreenWidth - configurationWidth;

    const totalWidth =
      configurationWidth +
      Number(
        DEFAULT_TABLE_HEAD_CONFIG?.[widthProps]?.[widthSource]?.replace(
          'px',
          ''
        )
      ) *
        totalNotConfigurableColumn;

    return {
      isResponsive: totalWidth <= theScreenWidth || headerField?.length <= 3,
      responsiveWidth: `${responsiveWidth / totalNotConfigurableColumn}px`,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerConfig, headerField, widthProps, screenWidth]);

  const forceColumnWidth = useMemo(() => {
    if (responsive && isResponsiveWidth?.isResponsive) {
      return [
        ...headerField?.map((_, idx) => {
          const rowData = headerConfig[idx];

          if (rowData) {
            return rowData?.[widthProps]?.[widthSource];
          }

          return isResponsiveWidth?.responsiveWidth;
        }),
      ];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsive, headerConfig, headerField, widthProps, screenWidth]);

  const generateTestId = (key: string) =>
    String(key).toLowerCase().split(' ').join('-');

  const handleHeaderSort = (
    sortBy: SortByProps,
    sortColumn: string,
    label: string,
    forceClientSort?: boolean
  ) => {
    const clientSide = forceClientSort || sortSide === 'client';
    onSortData?.({ sortBy, sortColumn, label, forceClientSort: clientSide });
  };

  const activeSortIndex = useMemo(() => {
    return (
      [...headerField]?.findIndex(
        (header) => String(header?.id) === String(sortColumn)
      ) || 0
    );
  }, [sortColumn, headerField]);

  const manipulateData = (data: TableBodyProps[]): TableBodyProps[] => {
    if (sortSide === 'client' || isForceClientSort) {
      return data?.sort((a, b) => {
        const sortColumn = a?.[activeSortIndex]?.sortColumn ?? 'raw';
        const firstValue: any = a?.[activeSortIndex]?.[sortColumn];
        const secondValue: any = b?.[activeSortIndex]?.[sortColumn];

        // Word sorting
        if (isNaN(Number(firstValue)) || isNaN(Number(secondValue))) {
          if (firstValue < secondValue) return sortBy === 'ASC' ? -1 : 1;
          if (firstValue > secondValue) return sortBy === 'ASC' ? 1 : -1;
          return 0;
        }

        /** Amount Sorting */
        if (sortBy === 'ASC') {
          return firstValue - secondValue;
        }

        return secondValue - firstValue;
      });
    }

    return data;
  };

  const renderTableHead = () => (
    <TableHead stickyHeader={stickyHeader} data-testid={`${testId}-table-head`}>
      <TableRow data-testid={`${testId}-table-head-row`}>
        {[...tableHead]?.map((data, idx) => {
          const rowConfiguration = [...headerConfig]?.find(
            (row) => row?.row === idx
          );

          const isHaveRowConfiguration = !!rowConfiguration;
          const isActiveSort = idx === activeSortIndex;

          const tableType = isHaveRowConfiguration
            ? rowConfiguration?.type
            : defaultHeaderType;

          const configData = isHaveRowConfiguration
            ? rowConfiguration?.[widthProps]
            : DEFAULT_TABLE_HEAD_CONFIG?.[widthProps];

          const configuration = {
            ...configData,
            width: isResponsiveWidth?.isResponsive
              ? forceColumnWidth?.[idx]
              : configData?.[widthSource],
            leftPos: idx === 1 ? leftWhitespace : undefined,
          };

          switch (tableType) {
            case 'TEXT':
              return (
                <TableHeader
                  key={idx}
                  data-testid={`${generateTestId(String(data?.label))}`}
                  leftPos={leftWhitespace}
                  columnSticky={configData?.columnSticky}
                >
                  <TableHeadText
                    config={configuration as TableDefinitionConfig}
                    label={data?.label ?? '-'}
                  />
                </TableHeader>
              );

            case 'SORT':
              return (
                <TableHeader
                  key={idx}
                  data-testid={`${generateTestId(String(data?.label))}`}
                  leftPos={leftWhitespace}
                  columnSticky={configData?.columnSticky}
                >
                  <TableHeadSort
                    {...data}
                    forceClientSort={rowConfiguration?.forceClientSort}
                    config={configuration as TableDefinitionConfig}
                    isActiveSort={isActiveSort}
                    onSort={handleHeaderSort}
                    sortBy={sortBy}
                    sortColumn={sortColumn}
                    setIsForceClientSort={setIsForceClientSort}
                  />
                </TableHeader>
              );

            default:
              return (
                <TableHeader
                  key={idx}
                  data-testid={`${generateTestId(String(data?.label))}`}
                  leftPos={leftWhitespace}
                  columnSticky={configData?.columnSticky}
                >
                  <TableHeadText
                    config={configuration as TableDefinitionConfig}
                    label={data?.label ?? '-'}
                  />
                </TableHeader>
              );
          }
        })}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = () => {
    if (!isCompleteRender) setIsCompleteRender(true);
    const manipulateTableData =
      sortSide === 'server' ? tableData : manipulateData([...tableData]);
    /* Set Is Complete Render */
    return (
      <TableBody data-testid={`${testId}-table-body`}>
        {manipulateTableData?.map((value, idx) => (
          <TableRow
            key={idx}
            showBorder
            onClick={() => onClick?.(value)}
            data-testid={`${testId}-table-body-row-${idx + 1}`}
          >
            {[...value]?.map((row, index) => {
              const headerConfiguration = [...headerConfig]?.find(
                (row) => row?.row === index
              );

              const tHeader = [...tableHead][index];

              const rowConfiguration = [...bodyConfig]?.find(
                (row) => row?.row === index
              );
              const isHaveRowConfiguration = !!rowConfiguration;
              const isHaveHeaderConfiguration = !!headerConfiguration;

              const tableType = isHaveRowConfiguration
                ? rowConfiguration?.type
                : defaultBodyType;

              const configHeader = isHaveHeaderConfiguration
                ? headerConfiguration?.[widthProps]
                : DEFAULT_TABLE_HEAD_CONFIG?.[widthProps];

              const configData = isHaveRowConfiguration
                ? rowConfiguration?.[widthProps]
                : DEFAULT_TABLE_BODY_CONFIG?.[widthProps];

              const textAlign = () => {
                if (configData?.textAlign === 'left') return 'flex-start';
                if (configData?.textAlign === 'right') return 'flex-end';

                return 'center';
              };

              const configuration = {
                ...configData,
                textAlign: textAlign(),
                width: isResponsiveWidth?.isResponsive
                  ? forceColumnWidth?.[index]
                  : configHeader?.width,
                leftPos: index === 1 ? leftWhitespace : undefined,
              };

              switch (tableType) {
                case 'TEXT':
                  return (
                    <TableDefinition
                      key={index}
                      leftPos={leftWhitespace}
                      data-testid={`${generateTestId(
                        String(tHeader?.label)
                      )}-value`}
                      columnSticky={configHeader?.columnSticky}
                    >
                      <TableBodyText
                        config={configuration as TableDefinitionConfig}
                        {...row}
                      />
                    </TableDefinition>
                  );

                case 'EMITTEN':
                  return (
                    <TableDefinition
                      key={index}
                      leftPos={leftWhitespace}
                      data-testid={`${generateTestId(
                        String(tHeader?.label)
                      )}-value`}
                      columnSticky={configHeader?.columnSticky}
                    >
                      <TableBodyEmitten
                        config={configuration as TableDefinitionConfig}
                        {...row}
                      />
                    </TableDefinition>
                  );

                case 'PROFIT_LOSS':
                  return (
                    <TableDefinition
                      key={index}
                      leftPos={leftWhitespace}
                      data-testid={`${generateTestId(
                        String(tHeader?.label)
                      )}-value`}
                      columnSticky={configHeader?.columnSticky}
                    >
                      <TableBodyProfitLoss
                        config={configuration as TableDefinitionConfig}
                        {...row}
                      />
                    </TableDefinition>
                  );

                case 'CUSTOM':
                  return (
                    <TableDefinition
                      key={index}
                      leftPos={leftWhitespace}
                      data-testid={`${generateTestId(
                        String(tHeader?.label)
                      )}-value`}
                      columnSticky={configHeader?.columnSticky}
                    >
                      {rowConfiguration?.customComponent}
                    </TableDefinition>
                  );

                default:
                  return (
                    <TableDefinition
                      key={index}
                      leftPos={leftWhitespace}
                      data-testid={`${generateTestId(
                        String(tHeader?.label)
                      )}-value`}
                      columnSticky={configHeader?.columnSticky}
                    >
                      <TableBodyText
                        config={configuration as TableDefinitionConfig}
                        {...row}
                      />
                    </TableDefinition>
                  );
              }
            })}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  useEffect(() => {
    if (data?.length > 0) setTableData(data);
  }, [data]);

  useEffect(() => {
    if (headerField?.length > 1) setTableHead(headerField);
  }, [headerField]);

  /* Loading State */
  if (isLoading && !isCompleteRender)
    return <TableShimmerLoader isDesktop={isDesktopView} />;

  return (
    <Container>
      <ExtraWhiteSpace paddingLeft={leftWhitespace} />
      <Table
        data-testid={`${testId}-table`}
        ref={tableRef}
        isLoading={isLoading}
        paddingLeft={leftWhitespace}
      >
        {renderTableHead()}
        {renderTableBody()}
      </Table>
    </Container>
  );
};

export default StockTable;
