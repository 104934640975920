import { useAuth, useProfileData } from 'hooks';
import { useUserInInstitution } from './currentInstitution';

const usePinSetupInstitution = () => {
  const { isLogin } = useAuth();

  const userInInstitution = useUserInInstitution();

  // profile data
  const { data: profileData } = useProfileData(isLogin);
  const profileDetail = profileData?.data?.data;
  const userHasPin = !!profileDetail?.pin;

  const isRegisteredAsInstiAccountOnly =
    !!profileDetail?.preference?.is_registered_as_institutional_account_only;

  const showPinSetupInstitution =
    userInInstitution && isRegisteredAsInstiAccountOnly && !userHasPin;

  return {
    /**
     * Show Pin Setup Institution when
     * - user in env institution
     * - and user registered as institution account only
     * - and user doesn't have pin
     */
    showPinSetupInstitution,
  };
};

export default usePinSetupInstitution;
