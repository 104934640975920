import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { Props } from './ModalSortReksaDana';

const ModalSortReksaDanaLazy = React.lazy(() => import('./ModalSortReksaDana'));

const ModalSortReksaDana: React.FC<Props> = (props) => {
  return (
    <LazyModal
      visible={props.sortSelected}
      component={ModalSortReksaDanaLazy}
      {...props}
    />
  );
};

export default ModalSortReksaDana;
