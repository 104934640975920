import React, { lazy } from 'react';
import LazyLoad from 'features/common/lazyload';
import tabBarHOC from 'withoutTabBar';

const CorpActionPageLazy = lazy(() => import('./CorpActionPage'));

const CorpActionPage: React.FC<React.PropsWithChildren<unknown>> = (
  props: any
) => <LazyLoad component={CorpActionPageLazy} animationLoading {...props} />;

export default tabBarHOC(CorpActionPage);
