import { useJagoContextState } from 'features/bankjago';
import { useAccountType } from 'features/institution/hooks';
import { useFetchQueryDetailRecurring } from 'features/recurring/hooks';
import useFetchSIP from 'features/recurring/hooks/fetchSIP';
import { useAuth, useProfileData } from 'hooks';
import queryClient from 'network/queryClient';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useFlag } from 'utils/feature-flag';
import {
  getLatestDataPersistProperties,
  saveDataPersistsProperties,
} from 'utils/helpers/persistProperties';
import { useQueryParams } from 'utils/routeHelper';
import { isInsideNativeContainer } from 'utils/validator';

/**
 * This hooks is used for Ingatkan Lagi feature.
 * But that feature has moved to next phase.
 */
// const useShouldShowToday = () => {
//   const { data, isLoading } = useQuery(['last-flexib-modal'], async () => {
//     const lastVisible = await Storage.get('last-flexib-modal');
//     if (!lastVisible) return true;

//     const date = new Date().getDate();
//     return lastVisible.toString() !== date?.toString();
//   });

//   return { shouldShowToday: !!data, isLoading };
// };

const useGetSipAnnouncementFromStorage = () => {
  const { data, isFetching } = useQuery(
    ['persist_sip_announcement'],
    async () => {
      const sipAnnouncementData = await getLatestDataPersistProperties(
        'persist_sip_announcement'
      );
      return sipAnnouncementData;
    },
    {
      staleTime: 0,
    }
  );

  let hasShown = false;
  if (typeof data === 'object') {
    hasShown = !!data.show_modal;
  }

  return { hasShown, isFetching };
};

export const useAnnouncementFlexibleSipModal = () => {
  const { hasShown, isFetching: loadingSipAnnouncementFromStorage } =
    useGetSipAnnouncementFromStorage();

  const flexibleSIPFF = useFlag('web_bibit_flexible_sip');
  const userAccounntType = useAccountType();
  const individualUser = userAccounntType === 'individual';
  const { userHasBeenVerifiedBibit } = useJagoContextState();

  const { isLogin } = useAuth();
  const { data: dataProfile } = useProfileData(isLogin);

  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;
  const isHaveFlexibleRecurring = !!profileDetail?.is_have_flexible_recurring;

  const { data: SIPData, isLoading: loadingSIP } = useFetchSIP(
    flexibleSIPFF &&
      !loadingSipAnnouncementFromStorage &&
      !hasShown &&
      !!individualUser &&
      userHasBeenVerifiedBibit &&
      isHaveFlexibleRecurring,
    {
      is_auto_debet: 0,
    }
  );

  const isLoading = loadingSipAnnouncementFromStorage || loadingSIP;

  const visible = !!SIPData && SIPData?.data?.data?.length > 0 && !hasShown;

  const onCloseAnnouncement = useCallback(
    async (invalidateQuery: boolean = true) => {
      await saveDataPersistsProperties({
        type: 'persist_sip_announcement',
        data: {
          show_modal: true,
        },
      });

      if (!!invalidateQuery) {
        queryClient.invalidateQueries(['persist_sip_announcement']);
      }
    },
    []
  );

  return {
    visible,
    isLoading,
    onClose: onCloseAnnouncement,
  };
};

/**
 * This hooks is only used for Email, Push Notif and App first open.
 * If the recurringId is set in RecurringContext, then this hooks will not be used.
 */
export const useFlexibleSipModal = (): {
  visible: boolean;
  isLoading: boolean;
  recurringId?: number;
  isExpired?: boolean;
} => {
  const history = useHistory();
  const {
    visible: isAnnouncementModalVisible,
    isLoading: loadingSipAnnouncementFromStorage,
  } = useAnnouncementFlexibleSipModal();

  // const { shouldShowToday, isLoading: isLoadingShouldShowToday } =
  //   useShouldShowToday();

  const flexibleSIPFF = useFlag('web_bibit_flexible_sip');
  const userAccounntType = useAccountType();
  const individualUser = userAccounntType === 'individual';

  const query = useQueryParams();
  const { isLogin: isLoggedIn } = useAuth();
  const { data: dataProfile } = useProfileData(isLoggedIn);

  /** Profile detail from hook useProfileData */
  const profileDetail = dataProfile?.data?.data;
  const isHaveFlexibleRecurring = !!profileDetail?.is_have_flexible_recurring;

  const entryFrom = query.get('from') || '';

  const isFromExternal = ['email', 'push-notif'].includes(entryFrom);
  let recurringId = query.get('recurring_id');

  const shouldFetchData = isFromExternal;
  // (shouldShowToday && !isLoadingShouldShowToday) || isFromExternal;

  const { data: singleSIPDetail, isLoading: isLoadingSingleSIPDetail } =
    useFetchQueryDetailRecurring(
      recurringId!,
      !!recurringId && shouldFetchData && !!individualUser
    );
  const singleSIPDetailData = singleSIPDetail?.data?.data;

  /**
   * Fetch Recurrings Data
   * if data.length > 1 -> render List,
   * else render Single
   */
  const {
    data: sipList,
    isLoading: isLoadingSipList,
    isError: errorSipList,
  } = useFetchSIP(
    flexibleSIPFF &&
      !recurringId &&
      isLoggedIn &&
      isHaveFlexibleRecurring &&
      shouldFetchData,
    {
      is_auto_debet: 0,
      period: 0,
      ...(isFromExternal ? {} : { is_reminder: 1 }),
    }
  );
  const sipListData = useMemo(() => sipList?.data?.data || [], [sipList]);
  const sipListDataWithoutPaidOrderDetail = useMemo(
    () =>
      sipListData?.filter((item) => !(item.order && item.status === 'finish')),
    [sipListData]
  );

  const isLoading =
    // isLoadingShouldShowToday ||
    loadingSipAnnouncementFromStorage ||
    isLoadingSipList ||
    isLoadingSingleSIPDetail;

  if (isLoading) {
    return {
      visible: false,
      isLoading: true,
    };
  }

  // If shouldFetchData is false, that means user has seen the modal today.
  // But we need to have below condition, because react query still cached the recurring data.
  if (!shouldFetchData) {
    return {
      visible: false,
      isLoading: false,
    };
  }

  if (isAnnouncementModalVisible || errorSipList) {
    return {
      visible: false,
      isLoading: false,
    };
  }

  if (!!recurringId && !singleSIPDetailData) {
    return {
      visible: false,
      isLoading: false,
    };
  }

  if (!recurringId && sipListData?.length < 1) {
    return {
      visible: false,
      isLoading: false,
    };
  }

  // If recurringId has value, that means it comes from email and it will always trigger Single SIP.
  // If it comes from external, we want to redirect to the order detail page else just stay at homepage.
  if (!!recurringId) {
    // status failed means the recurring order detail has expired.
    if (
      singleSIPDetailData?.status === 'failed' &&
      singleSIPDetailData?.is_order_expired
    ) {
      return {
        visible: false,
        isLoading: false,
        recurringId: Number(recurringId),
        isExpired: true,
      };
    } else if (
      singleSIPDetailData?.order?.payment?.invoice_number !== undefined
    ) {
      if (isFromExternal) {
        history.replace(
          `/orders/${singleSIPDetailData?.order?.payment?.invoice_number}/detail`
        );
      }
      return {
        visible: false,
        isLoading: false,
      };
    }
  } else {
    // If recurringId is not set, this means it comes from push notif or app first open.
    // If there is only 1 recurring data, we will treat it as single SIP.
    if (
      sipListData?.length === 1 ||
      sipListDataWithoutPaidOrderDetail.length === 1
    ) {
      const recurringData =
        sipListDataWithoutPaidOrderDetail[0] || sipListData[0];
      //Set recurring_id if sipListData only has 1 item.
      //So we can treat it as single SIP.
      recurringId = recurringData.recurring_id!.toString();

      if (recurringData.status === 'failed' && recurringData.is_order_expired) {
        return {
          visible: false,
          isLoading: false,
          recurringId: Number(recurringId),
          isExpired: true,
        };
      } else if (!!recurringData?.order) {
        if (isFromExternal) {
          history.replace(
            `/orders/${recurringData?.order?.payment?.invoice_number}/detail`
          );
        }
        return {
          visible: false,
          isLoading: false,
        };
      }
    } else {
      const expiredRecurrings = sipListDataWithoutPaidOrderDetail.filter(
        (t) => t.status === 'failed' && t.is_order_expired
      );
      if (
        expiredRecurrings.length > 0 &&
        expiredRecurrings.length === sipListDataWithoutPaidOrderDetail.length
      ) {
        return {
          visible: false,
          isLoading: false,
          isExpired: true,
        };
      } else if (sipListDataWithoutPaidOrderDetail.length === 0) {
        if (isFromExternal) {
          history.replace('/transactions/order/all');
        }
        return {
          visible: false,
          isLoading: false,
        };
      }
    }
  }

  return {
    visible: true,
    isLoading: false,
    recurringId: recurringId ? Number(recurringId) : undefined,
    isExpired: false,
  };
};

export const useFlexibleSipRedirect = () => {
  const query = useQueryParams();
  const entryFrom = query.get('from') || '';
  const isFromExternal = ['email', 'push-notif'].includes(entryFrom);
  const isNative = isInsideNativeContainer();

  const history = useHistory();

  const redirect = useCallback(
    (url: string) => {
      if (isFromExternal && isNative) {
        /**
         * If comes from external (email, push-notif)
         * We need to replace the url to remove the query params
         * So that when user do the back action, the modal won't show again.
         */
        history.replace(history.location.pathname);
        history.push(url);
      } else {
        history.push(url);
      }
    },
    [history, isFromExternal, isNative]
  );

  return { redirect };
};
