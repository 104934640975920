import React, { useState } from 'react';
import { Text, Modal, ButtonNew } from '@bibitid/uikit-v1';
import styles from './ExerciseableModal.module.css';
import AnnouncementStockImg from 'assets/images/stock/announcement/Announcement';
import errorStockImg from 'assets/images/stock/error-stock.svg';
import { warningType } from 'features/stock/types';
import MaintenanceIllustration from 'assets/images/explore/Maintance';

interface ExercisePeriodEndSesi1ModalProps {
  active: boolean;
  message: string;
  title: string;
  type: warningType;
  date?: string;
  onOk: () => void;
}

/**
 *
 * Desain https://www.figma.com/file/jXKvCP8sUIOw4j0JKvxYkd/Bibit-Saham?node-id=4258%3A472777
 */

const actionText = {
  MAX_EXERCISE_AT: 'Lanjut Beli',
  EXPIRED: 'Oke, Mengerti',
  TRANSACTION_BUY_PERIOD_ENDED: '',
  TRANSACTION_SELL_PERIOD_ENDED: 'Mengerti',
  TRANSACTION_EXERCISE_PERIOD_ENDED: 'Jual',
  TRANSACTION_UNABLE_EXERCISE: 'Mengerti',
  TRANSACTION_RIGHT_BEYOND_SESSION: 'Oke',
  SAME_DAY_TRANSACTION: 'Mengerti',
  AUDIT_MODE: 'Chat Support',
  EXERCISE_NOT_FOUND: 'Oke',
  '': 'Mengerti',
};

const actionImg = {
  MAX_EXERCISE_AT: <AnnouncementStockImg />,
  EXPIRED: <AnnouncementStockImg />,
  TRANSACTION_BUY_PERIOD_ENDED: <AnnouncementStockImg />,
  TRANSACTION_SELL_PERIOD_ENDED: <AnnouncementStockImg />,
  TRANSACTION_EXERCISE_PERIOD_ENDED: <AnnouncementStockImg />,
  TRANSACTION_UNABLE_EXERCISE: <AnnouncementStockImg />,
  TRANSACTION_RIGHT_BEYOND_SESSION: errorStockImg,
  SAME_DAY_TRANSACTION: <AnnouncementStockImg />,
  AUDIT_MODE: <AnnouncementStockImg />,
  EXERCISE_NOT_FOUND: <MaintenanceIllustration />,
  '': <AnnouncementStockImg />,
};

const ExercisePeriodEndSesi1Modal = ({
  active,
  onOk,
  title,
  type,
  date,
  message,
}: ExercisePeriodEndSesi1ModalProps) => {
  const [visible, setVisible] = useState(active);

  const handleClick = () => {
    setVisible(false);
    onOk();
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <Modal visible={visible} onClose={handleClose}>
      <Modal.Header
        showBackButton={false}
        showClose={type === 'TRANSACTION_BUY_PERIOD_ENDED'}
      />
      <Modal.Body>
        <div className={styles['exercise-modal']}>
          {actionImg[type]}
          <Text type='subtitle2'>{title}</Text>
          <Text type='body1' htmlTag='p'>
            {message}
            {type === 'MAX_EXERCISE_AT' && date && (
              <Text type='body2' htmlTag='p' color='greenDefault'>
                {date}
              </Text>
            )}
          </Text>

          {type !== 'TRANSACTION_BUY_PERIOD_ENDED' && (
            <ButtonNew
              onClick={handleClick}
              variant='primary'
              colorScheme={
                type === 'TRANSACTION_EXERCISE_PERIOD_ENDED' ? 'red' : 'green'
              }
              role='button'
              content={actionText[type]}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ExercisePeriodEndSesi1Modal;
