export const translation = {
  bank_penampung: 'Depository Bank',
  semua_bank: 'All Banks',
  pilih_bank_penampung: 'Select Custodian Bank',
  urutkan_berdasarkan: 'Sort By',
  minimum_pembelian: 'Minimum Buy',
  tertinggi: 'Highest',
  terendah: 'Lowest',
  periode: 'Period',
  pilih_bandingkan_produk: 'Select a minimum of 2 products to compare',
  bandingkan_produk: 'Compare Products',
  pilih_reksadana: 'Choose Mutual Funds',
  bandingkan: 'Compare',
  tidak_ada_produk: 'No Products Found',
  pencarian_tidak_ditemukan: 'The product you searched for was not found',
  tidak_ada_produk_kategori: 'No products are available in this category.',
  periode_return: 'Return Period',
  urut_berdasarkan: 'Sort By',
  pilih_periode_return: 'Select Return Period',
  terapkan: 'Apply',
  data_belum_tersedia: 'The data is not available',
  hari_ini: 'Today',
  investasi_saya: 'My investment',
  tingkat_risiko: 'Risk level',
  informasi_investasi: 'Investment Information',
  pencairan: 'Withdrawal',
  bank_kustodian: 'Custodian Bank',
  biaya_investasi: 'Investment Fee',
  gratis: 'Free',
  komisi_penjualan: 'Sell Commision',
  komisi_pembelian: 'Buy Commision',
  biaya_penjualan_bibit: 'The fee charged by Bibit when you make a sale.',
  investasi_rutin_produk_ini: 'Invest in this product regularly.',
  nilai_investasi: 'Investment Value',
  contoh_investasi: 'Example: {{amount}}',
  frekuensi: 'Frequency',
  bulanan: 'Monthly',
  mingguan: 'Weekly',
  harian: 'Daily',
  tiap_tanggal: 'On each transaction date',
  berlaku_hingga: 'Valid Until',
  selamanya: 'Permanently',
  pilih_bulan_berakhir: 'Choose Month End',
  bandingkan_reksadana: 'Compare Mutual Funds',
  mesin_waktu: 'Time Machine',
  jika_berinvestasi: 'If you invest',
  rutin_perbulan: 'Regularly per month',
  nabung_sekali: 'Invest once',
  nilai_invest_bulan: 'Investment Amount per Month',
  mulai_investasi_sejak: 'How Many Years Ago Did You Start Investing?',
  tahun: 'Year',
  nilai_investasi_hari_ini: 'Then the Estimated Investment Value Today',
  alokasi_asset: 'Asset Allocation',
  sukuk_korp: 'Corporate Bonds',
  sukuk_pemerintah: 'Government Bonds',
  kas_deposito_syariah: 'Shariah Cash & Deposits',
  daftar_switching: 'Switching List',
  pembagian_dividen: 'Dividend Distribution',
  tanggal: 'Date',
  jumlah_rp_unit: 'Amount (IDR) / Unit',
  dokumen: 'Document',
  sebulan_terakhir: 'Last 1 Month',
  tiga_bulan_terakhir: 'Last 3 Month',
  setahun_terakhir: 'Last 1 Year',
  tiga_tahun_terakhir: 'Last 3 Year',
  lima_tahun_terakhir: 'Last 5 Year',
  sepuluh_tahun_terakhir: 'Last 10 Year',
  biaya_transfer_penjualan: 'Sell Transfer Fee',
  reksadana_baru: 'Newly Issued Mutual Funds',
  lihat_selengkapnya: 'View More',
  aktifkan_sip: 'Activate SIP',
  keuntungan: 'Return',
  tinggi: 'High',
  sedang: 'Moderate',
  rendah: 'Low',
  instan: 'Instant',
  tiap_hari: 'Choose Day',
  senin: 'Monday',
  selasa: 'Tuesday',
  rabu: 'Wednesday',
  kamis: 'Thursday',
  jumat: 'Friday',
  sabtu: 'Saturday',
  minggu: 'Sunday',
  pilih_tanggal_berakhir: 'Choose End Date',
  expense_ratio_info:
    "Expense Ratio measures how much it costs to manage a mutual fund. A smaller expense ratio reflects the fund manager's skill in efficiently managing the fund for its investors.",
  average_yield_info_satu:
    'Weighted Average Yield to Maturity or Average Yield is an indication of the return on the asset holdings of a mutual fund if the fund manager holds the asset holdings until maturity. The higher the yield, the higher the indication of return.',
  average_yield_info_dua:
    'It should be emphasized that Average Yield is not a guaranteed return because the fund manager may sell mutual fund asset holdings to manage liquidity and change investment strategies depending on market movements, and it does not yet account for management fees from the investment manager.',
  average_yield_info_tiga:
    'Average Yield should be used in conjunction with other factors when evaluating bond or money market mutual funds, such as the duration and quality of their asset holdings.',
  total_aum_info: 'Total funds managed in a mutual fund product.',
  bank_kustodian_info:
    'The Custodian Bank is a bank authorized by the OJK to hold your investment funds, including receiving dividends and representing you as its customer. Your investment funds are not held at Bibit.',
  bank_penampung_info:
    'The Custodian Bank is a bank authorized by the OJK to hold your investment funds, including receiving dividends',
  biaya_transfer_penjualan_info:
    'The fee charged by the Custodian Bank (not Bibit) when you make a sale.',
  exit_load_info:
    'Exit load is the fee charged by the investment manager for selling within a certain period.',
  tingkat_risiko_tinggi_info:
    'Stock mutual funds have the highest market fluctuation rate among other types of mutual funds, with the potential for high returns.',
  tingkat_risiko_sedang_info:
    'Bond mutual funds have a moderate market fluctuation rate with the potential for expected profits to be higher than the inflation rate and deposit interest rates.',
  tingkat_risiko_rendah_info:
    'Money market mutual funds have the lowest market fluctuation rate with the potential for returns to be consistently above the inflation rate.',
  daftar_switching_info:
    'List of products available for switching to other mutual funds. Switching can only be done within the same Investment Manager.',
  cari_reksa_dana: 'Search mutual fund',
  tampilkan_produk: 'Display {{total}} Products',
  return_yang_cukup_stabil_risiko:
    'A relatively stable return with moderate risk.',
  tipe_pengelolaan: 'Fund Management Type',
  koleksi_reksa_dana: 'Mutual Fund Collection',
  target_harga_analis: 'Analyst Price Target',
  reksa_dana_syariah:
    'The best mutual funds for those who want to adhere to Islamic Sharia principles.',
  tipe_pencairan: 'Disbursement Type',
  manajer_investasi: 'Investment Manager',
  filter_berdasarkan: 'Filter By',
  tidak_butuh_waktu_lama_pencairan:
    'Quick fund disbursement, just like transferring money.',
  cari_produk_investasi: 'Search for investment products.',
  reksa_dana_portofolio_indeks:
    'Mutual funds whose portfolios are benchmarked against specific indices.',
  produk_investasi: 'Investment Products',
  pilihan_reksa_dana_berkualitas:
    'The curated selection of high-quality mutual funds by Bibit.',
  return_tinggi_untuk_risiko:
    'High returns for those willing to take greater risks.',
  analisa_penilaian: '{{total_analyst}} Valuation Analysis',
  semua: 'All',
  simpan_produk_favoritmu:
    'Save your favorite product to monitor price movements.',
  pilihan_reksa_dana: 'Mutual Fund Options',
  saham_terkait: 'Related Stocks',
  risiko_rendah_return: 'Low risk with returns higher than deposits.',
  transaksi_reksa_dana_dengan: 'Mutual fund transactions in US Dollars.',
  reksa_dana_return_tinggi: 'Mutual funds with high returns in the last year.',
  tambah_produk_ke_watchlist: 'Add Product to Watchlist',
  reksadana_campuran:
    'Balanced mutual funds consist of multiple securities simultaneously for investors seeking alternatives.',
  benchmark_reksadana:
    'Compare the performance of mutual funds with that of other financial/investment instruments.',
  koleksi_reksadana: 'Mutual Funds Collection',
  top_reksadana: 'Top Mutual Funds',
  investasi_minimum: 'Invest with a minimum capital of Rp10,000.',
  cari_produk: 'Search products',
  terakhir_dilihat: 'Last viewed',
  terakhir_dicari: 'Last searched',
  tidak_ditemukan: 'Product Not Found',
  coba_kata_kunci_lain:
    'Try another keyword or search for other products listed on the KSEI.',
  reksadana_pasar_uang: 'Money Market Mutual Funds',
  reksadana_obligasi: 'Bond Mutual Funds',
  reksadana_syariah: 'Shariah Mutual Funds',
  reksadana_saham: 'Stock Mutual Funds',
  pencairan_instan: 'Instant Redemption',
  reksadana_lainnya: 'Other Mutual Funds',
  index_fund: 'Index Fund',
  reksadana_usd: 'USD Mutual Funds',
  reksadana_minimal: 'Minimum 10k',
  reksadana: 'Mutual Funds',
  saham_risiko_jangka_panjang:
    'Highest returns for those willing to take long-term investment risks.',
  obligasi_risiko_moderat: 'Stable returns with moderate risk.',
  performa_reksadana_lainnya:
    'Compare mutual fund performance with other investment instruments.',
  pilihan_reksadana: 'Mutual Fund Options',
  konvensional: 'Conventional',
  syariah: 'Shariah',
};
