import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import getSafely from 'utils/safely';
import {
  handleResponseErrorCase,
  handleResponseGeneral,
} from 'utils/http-response';
import { filterProduct } from 'services/product';
import { addMultipleReksadanaEntry } from 'entities/reksadana.reducer';
import axios from 'axios';

// Initial state for this reducer
const defaultState = {
  loading: false,
  loadMoreLoading: false,
  favoriteLoading: true,
  favoriteLoadMoreLoading: false,
  result: [],
  favorites: [],
  typing: false,
  keyword: '',
  filterPage: false,
  filter: {
    type: [],
    isSyariah: false,
    riskProfile: [],
    aum: [],
    minCAGR1yr: 0,
    maxCAGR1yr: 0,
    minCAGR3yr: 0,
    maxCAGR3yr: 0,
    minCAGR5yr: 0,
    maxCAGR5yr: 0,
    minExpRatio: 0,
    maxExpRatio: 0,
    minDrawdown: 0,
    maxDrawdown: 0,
    max_buy: 0,
    isTopProduct: false,
    isHighestReturn: false,
  },
  sortBy: undefined,
  sort: '',
  sortPeriod: '1y',
  sortOther: null,
  page: 1,
  size: 20,
  hasMore: true,
  sortTerapkan: false,
  investmentManagers: [],
  meta: '',
  im: '',
  currency: 'all',
  axiosSource: null,
  isInstantRedemptionPage: false,
};

// Actions
const [
  discoverPageResultLoading,
  discoverPageWatchlistResultLoading,
  discoverPageLoadMoreLoading,
  discoverPageFavoriteLoadMoreLoading,
  discoverPageResultLoaded,
  discoverPageWatchlistResultLoaded,
  discoverPageResultLoadedMore,
  discoverPageWatchlistResultLoadedMore,
  discoverPageSetPage,
  discoverPageSetSize,
  discoverPageFilterSetType,
  discoverPageFilterIsSyariah,
  discoverPageFilterMaxBuy,
  discoverPageReset,
  discoverPageResetResult,
  discoverPageSetSortBy,
  discoverPageSetSort,
  discoverPageSetSortPeriod,
  discoverPageSortTerapkan,
  discoverPageSortTerapkanOff,
  discoverPageToggleTyping,
  discoverPageGetResultSearch,
  setManagerInvestment,
  discoverPageSetMeta,
  discoverPageSetIM,
  discoverPageSetCurrency,
  discoverPageSetFavorites,
  discoverPageSetAxiosSource,
  discoverPageSetInstantRedemption,
  discoverPageSetSortOther,
  discoverPageSetIsTopProduct,
  discoverPageSetIsHighestReturn,
] = [
  'DISCOVER_PAGE_RESULT_LOADING',
  'DISCOVER_PAGE_WATCHLIST_RESULT_LOADING',
  'DISCOVER_PAGE_LOAD_MORE_LOADING', //
  'DISCOVER_PAGE_FAVORITE_LOAD_MORE_LOADING', //
  'DISCOVER_PAGE_RESULT_LOADED',
  'DISCOVER_PAGE_WATCHLIST_RESULT_LOADED',
  'DISCOVER_PAGE_RESULT_LOADED_MORE',
  'DISCOVER_PAGE_WATCHLIST_RESULT_LOADED_MORE',
  'DISCOVER_PAGE_SET_PAGE',
  'DISCOVER_PAGE_SET_SIZE',
  'DISCOVER_PAGE_FILTER_SET_TYPE',
  'DISCOVER_PAGE_FILTER_IS_SYARIAH',
  'DISCOVER_PAGE_FILTER_MAX_BUY',
  'DISCOVER_PAGE_RESET',
  'DISCOVER_PAGE_RESET_RESULT',
  'DISCOVER_PAGE_SET_SORT_BY',
  'DISCOVER_PAGE_SET_SORT',
  'DISCOVER_PAGE_SET_SORT_PERIOD',
  'DISCOVER_PAGE_SORT_TERAPKAN',
  'DISCOVER_PAGE_SORT_TERAPKAN_OFF',
  'DISCOVER_PAGE_TOGGLE_TYPING',
  'DISCOVER_PAGE_GET_RESULT_SEARCH',
  'DISCOVER_PAGE_SET_MANAGER_INVESTMENT',
  'DISCOVER_PAGE_SET_META',
  'DISCOVER_PAGE_SET_IM',
  'DISCOVER_PAGE_SET_CURRENCY',
  'DISCOVER_PAGE_SET_FAVORITES',
  'DISCOVER_PAGE_SET_AXIOS_SOURCE',
  'DISCOVER_PAGE_SET_INSTANT_REDEMPTION',
  'DISCOVER_PAGE_SET_SORT_OTHER',
  'DISCOVER_PAGE_SET_IS_TOP_PRODUCT',
  'DISCOVER_PAGE_SET_IS_HIGHEST_RETURN',
].map(createAction);

export {
  discoverPageResultLoaded,
  discoverPageWatchlistResultLoaded,
  discoverPageFilterSetType,
  discoverPageFilterIsSyariah,
  discoverPageFilterMaxBuy,
  discoverPageSetPage,
  discoverPageSetSize,
  discoverPageReset,
  discoverPageResetResult,
  discoverPageSetSortBy,
  discoverPageSetSort,
  discoverPageSetSortPeriod,
  discoverPageSortTerapkan,
  discoverPageSortTerapkanOff,
  discoverPageToggleTyping,
  discoverPageSetMeta,
  discoverPageSetIM,
  setManagerInvestment,
  discoverPageSetCurrency,
  discoverPageSetFavorites,
  discoverPageSetAxiosSource,
  discoverPageSetInstantRedemption,
  discoverPageSetSortOther,
  discoverPageSetIsTopProduct,
  discoverPageSetIsHighestReturn,
};

// Reducer
const discoverPage = createReducer(
  {
    [discoverPageSortTerapkanOff]: (state, payload) => {
      return update(state, {
        sortTerapkan: { $set: false },
      });
    },
    [discoverPageSortTerapkan]: (state, payload) => {
      return update(state, {
        sortTerapkan: { $set: true },
      });
    },

    [discoverPageResultLoading]: (state, payload) => {
      return update(state, {
        loading: { $set: true },
        typing: { $set: false },
      });
    },
    [discoverPageWatchlistResultLoading]: (state, payload) => {
      return update(state, {
        favoriteLoading: { $set: true },
        typing: { $set: false },
      });
    },
    [discoverPageLoadMoreLoading]: (state, payload) => {
      return update(state, {
        loadMoreLoading: { $set: true },
      });
    },
    [discoverPageFavoriteLoadMoreLoading]: (state, payload) => {
      return update(state, {
        favoriteLoadMoreLoading: { $set: true },
      });
    },
    [discoverPageResultLoaded]: (state, payload) => {
      return update(state, {
        typing: { $set: false },
        loading: { $set: false },
        result: { $set: payload },
        hasMore: { $set: payload.length > 0 },
        page: { $set: defaultState.page },
        size: { $set: defaultState.size },
        loadMoreLoading: { $set: defaultState.loadMoreLoading },
      });
    },
    [discoverPageWatchlistResultLoaded]: (state, payload) => {
      return update(state, {
        typing: { $set: false },
        favoriteLoading: { $set: false },
        favoriteLoadMoreLoading: { $set: false },
        favorites: { $set: payload },
        hasMore: { $set: payload.length > 0 },
        page: { $set: defaultState.page },
        size: { $set: defaultState.size },
      });
    },
    [discoverPageResultLoadedMore]: (state, payload) => {
      return update(state, {
        typing: { $set: false },
        loadMoreLoading: { $set: false },
        result: { $push: payload },
        hasMore: { $set: payload.length > 0 },
      });
    },
    [discoverPageWatchlistResultLoadedMore]: (state, payload) => {
      return update(state, {
        typing: { $set: false },
        favoriteLoadMoreLoading: { $set: false },
        favorites: { $push: payload },
        hasMore: { $set: payload.length > 0 },
      });
    },
    [discoverPageSetPage]: (state, payload) => {
      return update(state, {
        page: { $set: payload },
      });
    },
    [discoverPageSetSize]: (state, payload) => {
      return update(state, {
        size: { $set: payload },
      });
    },
    [discoverPageFilterSetType]: (state, payload) => {
      return update(state, {
        filter: {
          type: { $set: payload },
        },
      });
    },
    [discoverPageFilterIsSyariah]: (state, payload) => {
      return update(state, {
        filter: {
          isSyariah: { $set: payload },
        },
      });
    },
    [discoverPageSetIsTopProduct]: (state, payload) => {
      return update(state, {
        filter: {
          isTopProduct: { $set: payload },
        },
      });
    },
    [discoverPageSetIsHighestReturn]: (state, payload) => {
      return update(state, {
        filter: {
          isHighestReturn: { $set: payload },
        },
      });
    },
    [discoverPageFilterMaxBuy]: (state, payload) => {
      return update(state, {
        filter: {
          max_buy: { $set: payload },
        },
      });
    },
    [discoverPageReset]: (state) => {
      return update(state, {
        $set: {
          ...defaultState,
          investmentManagers: [...state.investmentManagers],
          axiosSource: state.axiosSource,
        },
      });
    },
    [discoverPageResetResult]: (state, payload) => {
      return update(state, {
        result: { $set: [] },
        favorites: { $set: [] },
        page: { $set: defaultState.page },
        size: { $set: defaultState.size },
        keyword: { $set: defaultState.keyword },
      });
    },
    [discoverPageSetSortBy]: (state, payload) => {
      return update(state, {
        sortBy: { $set: payload },
      });
    },
    [discoverPageSetSort]: (state, payload) => {
      return update(state, {
        sort: { $set: payload },
      });
    },
    [discoverPageSetSortPeriod]: (state, payload) => {
      return update(state, {
        sortPeriod: { $set: payload },
      });
    },
    [discoverPageToggleTyping]: (state, payload) => {
      return update(state, {
        typing: { $set: payload },
      });
    },
    [discoverPageGetResultSearch]: (state, payload) => {
      return update(state, {
        result: { $set: payload },
      });
    },
    [setManagerInvestment]: (state, payload) => {
      return update(state, {
        investmentManagers: { $set: payload },
      });
    },
    [discoverPageSetMeta]: (state, payload) => {
      return update(state, {
        meta: { $set: payload },
      });
    },
    [discoverPageSetIM]: (state, payload) => {
      return update(state, {
        im: { $set: payload },
      });
    },
    [discoverPageSetCurrency]: (state, payload) => {
      return update(state, {
        currency: { $set: payload },
      });
    },
    [discoverPageSetFavorites]: (state, payload) => {
      return update(state, {
        favorites: { $set: payload },
      });
    },
    [discoverPageSetAxiosSource]: (state, payload) => {
      return update(state, {
        axiosSource: { $set: payload },
      });
    },
    [discoverPageSetInstantRedemption]: (state, payload) => {
      return update(state, {
        isInstantRedemptionPage: { $set: payload },
      });
    },
    [discoverPageSetSortOther]: (state, payload) => {
      return update(state, {
        sortOther: { $set: payload },
      });
    },
  },
  defaultState
);

export function getSearchResultBySymbol(state, symbol) {
  return getSafely(['discoverPage', 'result'], state, []).find(
    (product) => product.symbol === symbol
  );
}

/**
 * Get the product specified by filter
 * @param {number=} page - Page number
 * @param {number=} size - Number of items per page
 * @param {boolean} isScrolled - Determine if this function is on scrolled or just an initial load
 */
export function getFilteredProduct(page, size, isScrolled = false) {
  return (dispatch, getState) => {
    const keyword = getState().discoverPage.keyword;
    const type = getState().discoverPage.filter.type;
    const isSyariah = getState().discoverPage.filter.isSyariah;
    const resultPage = getState().discoverPage.page;
    const resultSize = getState().discoverPage.size;
    const sortBy = getState().discoverPage.sortBy;
    const sort = getState().discoverPage.sort;
    const sortPeriod = getState().discoverPage.sortPeriod;
    const meta = getState().discoverPage.meta;
    const im = getState().discoverPage.im;
    const currency = getState().discoverPage.currency;
    const axiosSource = getState().discoverPage.axiosSource;
    const isTopProduct = getState().discoverPage.filter.isTopProduct;
    const isHighestReturn = getState().discoverPage.filter.isHighestReturn;
    const isInstantRedemptionPage =
      getState().discoverPage.isInstantRedemptionPage;
    const max_buy = getState().discoverPage.filter.max_buy;

    // cancel request before send new request
    if (axiosSource) {
      axiosSource.cancel('cancel request');
    }

    if (resultPage === 1) {
      dispatch(discoverPageResetResult());
    }

    // When scrolled -> Set Infitite scroll loading
    if (isScrolled) {
      // set loadMoreLoading to true
      dispatch(discoverPageLoadMoreLoading());
    } else {
      // When in initial render/fetch -> Set normal loading
      // Set 'loading' to true
      dispatch(discoverPageResultLoading());
    }

    const param = {
      name: keyword,
      type: type.join(','),
      tradable: 1,
      page: page || resultPage,
      limit: isHighestReturn ? 5 : size || resultSize,
      sort,
      currency,
      ...(!!sortBy ? { sort_by: sortBy } : {}),
      ...(!!sortPeriod ? { sort_period: sortPeriod } : {}),
      ...(!!isInstantRedemptionPage ? { is_instant_redemption: 1 } : {}),
      ...(!!meta ? { meta } : {}),
      ...(!!im ? { im } : {}),
      ...(max_buy > 0 ? { max_buy: max_buy } : {}),
      ...(Boolean(isTopProduct) ? { is_top_product: 1 } : {}),
      ...(Boolean(isHighestReturn) ? { is_highest_return: 1 } : {}),
    };

    if (isSyariah) {
      param.syariah = 1;
    }

    // create and store calcel token
    const source = axios.CancelToken.source();
    dispatch(discoverPageSetAxiosSource(source));

    const req = filterProduct(param, source.token).then((response) => {
      const { data } = handleResponseGeneral(response);
      dispatch(addMultipleReksadanaEntry(data));
      // For product list page
      if (param.page === 1) {
        return dispatch(discoverPageResultLoaded(data));
      }
      return dispatch(discoverPageResultLoadedMore(data));
    });

    return handleResponseErrorCase(req, ({ err }) => {
      if (err !== 'cancel request') {
        dispatch(discoverPageResultLoaded([]));
      }
    });
  };
}

/**
 * Get the product specified by filter
 * @param {number=} page - Page number
 * @param {number=} size - Number of items per page
 * @param {boolean} isScrolled - Determine if this function is on scrolled or just an initial load
 */
export function getFavoritedProducts(page, size, isScrolled = false) {
  return (dispatch, getState) => {
    const keyword = getState().discoverPage.keyword;
    const type = getState().discoverPage.filter.type;
    const isSyariah = getState().discoverPage.filter.isSyariah;
    const resultPage = getState().discoverPage.page;
    const resultSize = getState().discoverPage.size;
    const sortBy = getState().discoverPage.sortBy;
    const sort = getState().discoverPage.sort;
    const sortPeriod = getState().discoverPage.sortPeriod;
    const meta = getState().discoverPage.meta;
    const im = getState().discoverPage.im;
    const currency = getState().discoverPage.currency;
    const axiosSource = getState().discoverPage.axiosSource;
    const max_buy = getState().discoverPage.filter.max_buy;

    // cancel request before send new request
    if (axiosSource) {
      axiosSource.cancel('cancel request');
    }

    // When scrolled -> Set Infitite scroll loading
    if (isScrolled) {
      // Set favoriteLoadMoreLoading to true
      dispatch(discoverPageFavoriteLoadMoreLoading());
    } else {
      // When in initial render/fetch -> Set normal loading
      // Set 'favoriteLoading' to true
      dispatch(discoverPageWatchlistResultLoading());
    }

    const param = {
      name: keyword,
      type: type.join(','),
      tradable: '', // to show tradable and non-tradable
      page: page || resultPage,
      limit: size || resultSize,
      sort_by: sortBy, // in watchlistPage = default sort_by: 7
      sort, // in watchlistPage = default sort: 'asc'
      currency,
      sort_period: sortPeriod,
      is_favorited: 1,
      ...(!!meta ? { meta } : {}),
      ...(!!im ? { im } : {}),
      ...(max_buy > 0 ? { max_buy: max_buy } : {}),
    };

    if (isSyariah) {
      param.syariah = 1;
    }

    // create and store calcel token
    const source = axios.CancelToken.source();
    dispatch(discoverPageSetAxiosSource(source));

    const req = filterProduct(param, source.token).then((response) => {
      const { data } = handleResponseGeneral(response);
      dispatch(addMultipleReksadanaEntry(data));
      if (param.page === 1) {
        return dispatch(discoverPageWatchlistResultLoaded(data));
      }
      return dispatch(discoverPageWatchlistResultLoadedMore(data));
    });

    return handleResponseErrorCase(req, ({ err }) => {
      if (err !== 'cancel request') {
        dispatch(discoverPageWatchlistResultLoaded([]));
      }
    });
  };
}

// This is used in /watchlist page to delete a product from user's watchlist
export const deleteSingleFavorite = (symbol) => (dispatch, getState) => {
  const favorites = getState().discoverPage.favorites;
  const newFavorites = favorites?.filter(
    (product) => product.symbol !== symbol
  );
  dispatch(discoverPageSetFavorites(newFavorites));
};

export default discoverPage;
