import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import { uploadToS3 } from 'utils/s3/upload';
import { base64ToFile } from 'utils/fileReader';
import * as Aws from 'core/Aws';
import { openErrorModal } from '../../features/errors/errorModal.reducer';

const defaultState = {
  is_fetching_upload: false,
  is_fetching: false,
  err: '',
  err_upload: '',
  images: [],
  files: [],
  redirect: 0,
  output: '',
};

const [
  removeImage,
  changeOutput,
  changeImage,
  changeImageFile,
  request,
  failure,
  success,
  resetRedirect,
] = [
  'REMOVE_BANKACCOUNTSELFIE_IMAGE',
  'CHANGE_BANKACCOUNTSELFIE_OUTPUT',
  'CHANGE_BANKACCOUNTSELFIE_IMAGE',
  'CHANGE_BANKACCOUNTSELFIE_IMAGEFILE',
  'LOAD_BANKACCOUNTSELFIE_REQUEST',
  'LOAD_BANKACCOUNTSELFIE_FAILURE',
  'LOAD_BANKACCOUNTSELFIE_SUCCES',
  'RESET_BANKACCOUNTSELFIE_STATUS',
].map(createAction);

const [requestUpload, failureUpload, successUpload] = [
  'UPLOAD_BANKACCOUNTSELFIE_REQUEST',
  'UPLOAD_BANKACCOUNTSELFIE_FAILURE',
  'UPLOAD_BANKACCOUNTSELFIE_SUCCESS',
].map(createAction);

const reducer1 = {
  [removeImage]: (state) => {
    return update(state, {
      images: { $set: [] },
      files: { $set: [] },
    });
  },
  [changeImageFile]: (state, payload) => {
    const { key, value } = payload;
    const { url } = value;
    return update(state, {
      images: {
        [key]: { $set: url },
      },
      files: {
        [key]: { $set: value },
      },
    });
  },
  [changeImage]: (state, payload) => {
    const { key, value } = payload;
    return update(state, {
      images: {
        [key]: { $set: value },
      },
    });
  },
  [changeOutput]: (state, payload) => {
    const { url } = payload;
    return update(state, {
      output: { $set: url },
    });
  },
  [request]: (state) => {
    return update(state, {
      is_fetching: { $set: true },
      err: { $set: null },
      output: { $set: '' },
    });
  },
  [failure]: (state, payload) => {
    const { err } = payload;
    return update(state, {
      is_fetching: { $set: false },
      err: { $set: err },
    });
  },
  [success]: (state, payload) => {
    const { output } = payload;
    return update(state, {
      is_fetching: { $set: false },
      redirect: { $set: 1 },
      output: { $set: output },
    });
  },
  [resetRedirect]: (state) => {
    return update(state, {
      redirect: { $set: 0 },
    });
  },
};

const reducer2 = {
  [requestUpload]: (state) => {
    return update(state, {
      is_fetching_upload: { $set: true },
      err_upload: { $set: null },
    });
  },
  [failureUpload]: (state, payload) => {
    const { err } = payload;
    return update(state, {
      is_fetching_upload: { $set: false },
      err_upload: { $set: err },
    });
  },
  [successUpload]: (state) => {
    return update(state, {
      is_fetching_upload: { $set: false },
    });
  },
};

const mergeReducer = Object.assign(reducer1, reducer2);

const reducer = createReducer(mergeReducer, defaultState);

export {
  resetRedirect,
  removeImage,
  changeImage,
  changeImageFile,
  changeOutput,
};

export function postImage() {
  return async (dispatch, getState) => {
    const {
      bankaccountselfie: { images },
    } = getState();

    let image = images[0];

    const ext = Aws.getExtFromBase64(image);
    let default_filename = 'bankaccountselfie' + ext;
    let filename = Aws.formatUploadFileName(default_filename);

    // Convert base64 to File
    let rawFile = image;
    let mimeType = image.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)?.[0];
    if (!mimeType) throw new Error('Unrecognized file type');

    const file = await base64ToFile(rawFile, filename, mimeType);

    dispatch(requestUpload());

    try {
      const uploaded = await uploadToS3(file, {
        fileName: filename,
        uploadType: 'bank_selfie',
      }).then((res) => res.data?.data);

      let fileUrl = uploaded.file_url;

      dispatch(successUpload());
      dispatch(request());
      dispatch(success({ output: fileUrl }));
    } catch (err) {
      let errMessage = err.response?.data?.message || 'Something went wrong...';

      if (!!err.request) {
        errMessage =
          'Koneksi kamu tidak stabil. Silakan coba beberapa saat lagi.';
      }

      dispatch(
        openErrorModal({
          type: 'BANKACCOUNT_PROCESSED',
          message: errMessage,
        })
      );

      return dispatch(failureUpload({ err: errMessage }));
    }
  };
}

export default reducer;
