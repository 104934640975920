import { BodyConfigProps, HeaderConfigProps } from './StockTable.type';

export const DEFAULT_TABLE_HEAD_CONFIG: HeaderConfigProps = {
  mobile: {
    fontSize: '12px',
    width: '100px',
    fontWeight: 'bold',
    textAlign: 'right',
    smallScreenWidth: '100px',
  },
  desktop: {
    fontSize: '12px',
    width: '100px',
    fontWeight: 'bold',
    textAlign: 'right',
  },
};

export const DEFAULT_TABLE_BODY_CONFIG: BodyConfigProps = {
  mobile: {
    fontSize: '12px',
    textAlign: 'right',
  },
  desktop: {
    fontSize: '12px',
    textAlign: 'right',
  },
};
