import React from 'react';
import PaddingHorizontal from 'features/common/PaddingHorizontal';
import { Text } from '@bibitid/uikit-v1';
import IconBenchmark from 'assets/images/explore/ProductCategoryIcons/IconBenchmark/IconBenchmark';
import styles from './BibitPlusCategoryBanner.module.css';
import IconPasarUang from 'assets/images/explore/ProductCategoryIcons/IconPasarUang/IconPasarUang';
import IconObligasi from 'assets/images/explore/ProductCategoryIcons/IconObligasi/IconObligasi';
import IconReksadanaSaham from 'assets/images/explore/ProductCategoryIcons/IconReksadanaSaham/IconReksadanaSaham';
import IconSyariah from 'assets/images/explore/ProductCategoryIcons/IconSyariah/IconSyariah';
import IconLainnya from 'assets/images/explore/ProductCategoryIcons/IconLainnya/IconLainnya';
import IconIndexFund from 'assets/images/explore/ProductCategoryIcons/IconIndexFund/IconIndexFund';
import IconUSD from 'assets/images/explore/ProductCategoryIcons/IconUSD/IconUSD';
import IconMinimum from 'assets/images/explore/ProductCategoryIcons/IconMinimum/IconMinimum';
import IconInstant from 'assets/images/explore/ProductCategoryIcons/IconInstant/IconInstant';
import IconWrapper from 'utils/IconWrapper/IconWrapper';
import IconBestReksadana from 'features/explore/pages/ExploreBestPage/assets/IconBestSquare';
import IconHighReturn from 'assets/images/explore/MutualFundCollectionIcons/IconHighReturn/IconHighReturn';
import { useTranslation } from 'react-i18next';

export interface CategoryBannerProps {
  category:
    | 'equity'
    | 'debt'
    | 'syariah'
    | 'moneymarket'
    | 'minimum'
    | 'indexfund'
    | 'im'
    | 'benchmark'
    | 'usd'
    | 'others'
    | 'instant'
    | 'best'
    | 'highreturn';
  removeDivider?: boolean;
}

const banners = {
  equity: {
    image: <IconReksadanaSaham />,
    title: 'saham',
    description: 'saham_risiko_jangka_panjang',
    backgroundColor: {
      default: 'var(--violet-low)',
      inModal: 'var(--modal-violet-low)',
    },
  },
  debt: {
    image: <IconObligasi />,
    title: 'obligasi',
    description: 'obligasi_risiko_moderat',
    backgroundColor: {
      default: 'var(--cobalt-low)',
      inModal: 'var(--modal-cobalt-low)',
    },
  },
  syariah: {
    image: <IconSyariah />,
    title: 'syariah',
    description: 'reksa_dana_syariah',
    backgroundColor: {
      default: 'var(--olive-low)',
      inModal: 'var(--modal-olive-low)',
    },
  },
  moneymarket: {
    image: <IconPasarUang />,
    title: 'pasar_uang',
    description: 'risiko_rendah_return',
    backgroundColor: {
      default: 'var(--green-low)',
      inModal: 'var(--modal-green-low)',
    },
  },
  minimum: {
    image: <IconMinimum />,
    title: 'minimal_reksadana',
    description: 'investasi_minimum',
    backgroundColor: {
      default: 'var(--magenta-low)',
      inModal: 'var(--modal-magenta-low)',
    },
  },
  indexfund: {
    image: <IconIndexFund />,
    title: 'index_fund',
    description: 'reksa_dana_portofolio_indeks',
    backgroundColor: {
      default: 'var(--brown-low)',
      inModal: 'var(--modal-brown-low)',
    },
  },
  im: null,
  benchmark: {
    image: <IconBenchmark />,
    title: 'benchmark',
    description: 'performa_reksadana_lainnya',
    backgroundColor: {
      default: 'var(--peach-low)',
      inModal: 'var(--modal-peach-low)',
    },
  },
  usd: {
    image: <IconUSD />,
    title: 'reksadana_usd',
    description: 'transaksi_reksa_dana_dengan',
    backgroundColor: {
      default: 'var(--navy-low)',
      inModal: 'var(--modal-navy-low)',
    },
  },
  others: {
    image: <IconLainnya />,
    title: 'lainnya',
    description: 'reksadana_campuran',
    backgroundColor: {
      default: 'var(--blue-low)',
      inModal: 'var(--modal-blue-low)',
    },
  },
  instant: {
    image: <IconInstant />,
    title: 'pencairan_instant',
    description: 'tidak_butuh_waktu_lama_pencairan',
    backgroundColor: {
      default: 'var(--yellow-low)',
      inModal: 'var(--modal-yellow-low)',
    },
  },
  best: {
    image: <IconBestReksadana />,
    title: 'top_reksadana',
    description: 'pilihan_reksa_dana_berkualitas',
    backgroundColor: {
      default: 'var(--gold-medium)',
      inModal: 'var(--modal-gold-low)',
    },
  },
  highreturn: {
    image: <IconHighReturn />,
    title: 'return_tertinggi',
    description: 'reksa_dana_return_tinggi',
    backgroundColor: {
      default: 'var(--orange-low)',
      inModal: 'var(--modal-orange-low)',
    },
  },
};

const BibitPlusCategoryBanner: React.FC<
  React.PropsWithChildren<CategoryBannerProps>
> = ({ category, removeDivider }) => {
  const { t } = useTranslation(['explore', 'common']);
  if (category === 'im') {
    return null;
  }
  return (
    <PaddingHorizontal>
      <div
        className={styles[removeDivider ? 'wrapper-remove-divider' : 'wrapper']}
      >
        <IconWrapper
          className={styles['icon']}
          $size='72px'
          $borderRadius='4px'
          backgroundColor={banners[category]?.backgroundColor}
        >
          {banners[category]?.image}
        </IconWrapper>
        <div className={styles['category-details']}>
          <Text type='body2'>
            {t(banners[category]?.title, { ns: 'common' })}
          </Text>
          <Text type='caption1' htmlTag='p'>
            {t(banners[category]?.description)}
          </Text>
        </div>
      </div>
    </PaddingHorizontal>
  );
};

export default BibitPlusCategoryBanner;
