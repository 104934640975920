import React from 'react';
import ReksaDanaGenericCard from './ReksaDanaGenericCard';
import ReksaDanaMaxDrawdownCard from './ReksaDanaMaxDrawdownCard';
import ReksaDanaMinimumAmountCard from './ReksaDanaMinimumAmountCard';
import { ReksaDana } from 'entities/reksadana.reducer';
import BenchmarkIndexCard from './BenchmarkIndexCard';
import ReksaDanaAverageYieldCard from './ReksaDanaAverageYieldCard';
import { useAuth, useProfileData, useToggle } from 'hooks';
import { useAccountType } from 'features/institution/hooks';

interface ReksaDanaCardProps {
  fundData: ReksaDana;
  category?: string;
  isComparing?: boolean;
  showLink?: boolean;
  selected?: boolean;
  sortBy?: string;
  sortPeriod?: string;
  kebabIconConfig?: { show: boolean; onClick: (fundsymbol: string) => void };
  onClick: (roboCategory: string, symbol: string) => void;
  showProductType?: boolean;
  dropdownElement?: any;
  selectedIcon?: boolean;
  rightContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  containerClassname?: string;
  onCustomNavigateMutualFundDetail?: (symbol: string) => void;
}

const ReksaDanaCard: React.FC<React.PropsWithChildren<ReksaDanaCardProps>> = (
  props
) => {
  const { isLogin } = useAuth();

  const { data: profile } = useProfileData(isLogin);

  const accountType = useAccountType();

  const profileUser = profile?.data?.data;

  const isUserInstitution =
    profileUser?.institutions && profileUser?.institutions.length > 0;

  const institutionAccount = isUserInstitution && accountType === 'institution';

  const { sortBy } = props;

  const { active, toggleActive } = useToggle();

  if (props.category && props.category === 'benchmark') {
    return <BenchmarkIndexCard {...props} />;
  }

  switch (sortBy) {
    case '2':
      // Total AUM
      return <ReksaDanaGenericCard {...props} />;
    case '3':
      return <ReksaDanaGenericCard {...props} />;
    case '4':
      return (
        <ReksaDanaMaxDrawdownCard
          {...props}
          showModalInstantRedemption={active}
          toggleActiveModalInstantRedemption={toggleActive}
          isInsti={institutionAccount}
        />
      );
    case '5':
    case '10':
      return <ReksaDanaGenericCard {...props} />;
    case '6':
      return (
        <ReksaDanaMinimumAmountCard
          {...props}
          showModalInstantRedemption={active}
          toggleActiveModalInstantRedemption={toggleActive}
          isInsti={institutionAccount}
        />
      );
    case '9':
      return (
        <ReksaDanaAverageYieldCard
          {...props}
          showModalInstantRedemption={active}
          toggleActiveModalInstantRedemption={toggleActive}
          isInsti={institutionAccount}
        />
      );
    default:
      return <ReksaDanaGenericCard {...props} />;
  }
};

export default ReksaDanaCard;
