import React, { useContext, useState } from 'react';
import { StockExercisePreview } from 'features/stock/types';

export type StockExerciseStep =
  | 'exercise_preview'
  | 'exercise_success'
  | 'exercise_fee';

interface ContextState {
  exerciseShare: number;
  stockExerciseModalStep?: StockExerciseStep;
  stockExercisePreviewData?: StockExercisePreview;
  orderId: string;
  stockExerciseTimeLeft: string | null;
  isCashOnHandNotEnough?: boolean;
}

interface ContextFunction {
  setExerciseShare: (share: number) => void;
  setStockExerciseModalStep: (step?: StockExerciseStep) => void;
  setStockExercisePreviewData: (data: StockExercisePreview) => void;
  setOrderId: (orderId: string) => void;
  setStockExerciseTimeLeft: (date: string) => void;
  setCashOnHandNotEnough: (isNotEnough: boolean) => void;
}

const StockExerciseContextState = React.createContext<ContextState | undefined>(
  undefined
);
const StockExerciseContextFunction = React.createContext<
  ContextFunction | undefined
>(undefined);

const StockExerciseContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  /**
   * total share that user want to exercise
   */
  const [exerciseShare, setExerciseShare] = useState(0);

  /**
   * determine the modal that must be shown
   */
  const [stockExerciseModalStep, setStockExerciseModalStep] = useState<
    StockExerciseStep | undefined
  >();

  const [stockExercisePreviewData, setStockExercisePreviewData] = useState<
    StockExercisePreview | undefined
  >(undefined);

  const [orderId, setOrderId] = useState('');

  const [stockExerciseTimeLeft, setStockExerciseTimeLeft] = useState<
    string | null
  >(null);

  const [isCashOnHandNotEnough, setCashOnHandNotEnough] =
    useState<boolean>(false);

  return (
    <StockExerciseContextState.Provider
      value={{
        exerciseShare,
        stockExerciseModalStep,
        stockExercisePreviewData,
        stockExerciseTimeLeft,
        orderId,
        isCashOnHandNotEnough,
      }}
    >
      <StockExerciseContextFunction.Provider
        value={{
          setExerciseShare,
          setStockExerciseModalStep,
          setStockExercisePreviewData,
          setStockExerciseTimeLeft,
          setOrderId,
          setCashOnHandNotEnough,
        }}
      >
        {children}
      </StockExerciseContextFunction.Provider>
    </StockExerciseContextState.Provider>
  );
};

export default StockExerciseContextProvider;

export const useStockExerciseContextState = () => {
  const ctx = useContext(StockExerciseContextState);

  if (!ctx) {
    throw new Error('StockExerciseContextState out of boundary');
  }

  return ctx;
};

export const useStockExerciseContextFunction = () => {
  const ctx = useContext(StockExerciseContextFunction);

  if (!ctx) {
    throw new Error('StockExerciseContextFunction out of boundary');
  }

  return ctx;
};
