import { BestTabsType } from './types';

export const bestTabs: BestTabsType[] = [
  {
    title: 'pasar_uang',
    type: 'moneymarket',
  },
  {
    title: 'obligasi',
    type: 'debt',
  },
  {
    title: 'saham',
    type: 'equity',
  },
  {
    title: 'syariah',
    type: 'syariah',
  },
];

export const bestTabsInsti: BestTabsType[] = [
  {
    title: 'Pasar Uang',
    type: 'moneymarket',
  },
  {
    title: 'Obligasi',
    type: 'debt',
  },
  {
    title: 'Saham',
    type: 'equity',
  },
];

export const bestShariahTabs: BestTabsType[] = [
  {
    title: 'Pasar Uang',
    type: 'moneymarket',
  },
  {
    title: 'Obligasi',
    type: 'debt',
  },
  {
    title: 'Saham',
    type: 'equity',
  },
];
