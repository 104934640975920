import { useLayoutEffect, useRef } from 'react';
import React from 'react';
import chartStyle from './StockChart.module.css';
import classnames from 'classnames';

interface TooltipProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  top: number;
  left: number;
}

const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({
  top,
  left,
  style,
  children,
  className,
}) => {
  const tooltipWrapper = useRef<HTMLDivElement>(null);

  /**
   * when tooltip position is over the App root width
   * change the tooltip position from right to the left
   */
  useLayoutEffect(() => {
    const rootWidth = document.querySelector('#root');

    if (!tooltipWrapper.current || !rootWidth) {
      return;
    }

    // add more distance so that before touching the right boundary, the tooltip has moved to the left
    const tooltipwidth = tooltipWrapper.current?.clientWidth + 10;
    const tooltipPosition = tooltipwidth / 2 + left;
    tooltipWrapper.current.style.top = top + 'px';

    if (tooltipPosition >= rootWidth.clientWidth) {
      const lengthTooltipToClientWidth =
        rootWidth.clientWidth - (tooltipwidth + left);
      tooltipWrapper.current.style.transition = 'all .3s';
      tooltipWrapper.current.style.left =
        left + lengthTooltipToClientWidth + 'px';
      return;
    }

    if (tooltipPosition <= tooltipwidth) {
      tooltipWrapper.current.style.transition = 'all .3s';
      tooltipWrapper.current.style.left = 10 + 'px';
      return;
    }

    tooltipWrapper.current.style.transition = 'none';
    tooltipWrapper.current.style.left = left - tooltipwidth / 2 + 'px';
  }, [top, left]);

  return (
    <div
      ref={tooltipWrapper}
      className={classnames(chartStyle['bit-chart-tooltip'], className)}
      style={{
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Tooltip;
