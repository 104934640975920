import React from 'react';
import LazyModal from 'features/common/LazyModal';
import { Props } from './FilterReksaDanaModal';

const FilterReksaDanaModalLazy = React.lazy(
  () => import('./FilterReksaDanaModal')
);

const FilterReksaDanaModal: React.FC<Props> = (props) => {
  return (
    <LazyModal
      visible={props.visibility}
      component={FilterReksaDanaModalLazy}
      {...props}
    />
  );
};

export default FilterReksaDanaModal;
