import React, { useState } from 'react';
import classNames from 'classnames';
import { Modal, ButtonNew, Text, WhiteSpace, Radio } from '@bibitid/uikit-v1';
import PeriodicContent from 'features/common/PeriodicContent';
import {
  JAGO_CASHBACK_END,
  JAGO_CASHBACK_START,
} from 'features/bankjago/constants/common';
import BankJagoTncCashback from './BankJagoTncCashback';
import './BankJagoTncModal.css';
import BibitLogo from 'assets/images/referral-tracker-asset/bibit-logo20px.svg';
import BankJagoLogo from 'assets/images/bank-jago/bank-jago-logo-white.svg';

export interface Props {
  handleSubmit: React.MouseEventHandler<HTMLButtonElement>;
  onClose: () => void | undefined;
  showModal: boolean;
  handleExtraCheckTerms?: () => void | undefined;
  theme?: 'orange' | 'default';
}

const BankJagoTncModal: React.FC<React.PropsWithChildren<Props>> = ({
  onClose,
  showModal,
  handleSubmit,
  handleExtraCheckTerms,
  theme = 'default',
}) => {
  const [termsAgreed, setTermsAgreed] = useState(false);

  const handleCheckTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    setTermsAgreed(value);

    if (handleExtraCheckTerms) {
      handleExtraCheckTerms();
    }
  };

  const handleClose = () => {
    return onClose();
  };

  return (
    <Modal
      className='bit-jago-terms-conditions-modal-container'
      visible={showModal}
      onClose={handleClose}
    >
      <Modal.Header
        title='Syarat Ketentuan &amp; Kebijakan Privasi'
        showBackButton={false}
        showClose={true}
      />
      <Modal.Body>
        <div className='bit-jago-terms-conditions-modal'>
          <div className='bit-jago-terms-conditions-card'>
            <div className='bit-jago-terms-conditions-upper-side'>
              <div className='tnc-modal-subtitle-wrapper'>
                <div className='app-logo'>
                  <img src={BibitLogo} alt='logo bibit' />
                </div>
                <Text
                  htmlTag='p'
                  type='subtitle2'
                  className='tnc-modal-subtitle'
                >
                  Saya menyetujui Bibit untuk:
                </Text>
              </div>
              <ol className='bit-jago-terms-conditions-list'>
                <li className='item-wrapper'>
                  <Text type='button1' className='tnc-modal-description-text'>
                    Memberikan kepada Bank Jago data yang dibutuhkan dalam
                    rangka pembukaan rekening Bank Jago serta menampilkan data
                    total portofolio Bibit di aplikasi Jago
                  </Text>
                </li>
                <li className='item-wrapper'>
                  <Text type='button1' className='tnc-modal-description-text'>
                    Menghubungkan akun Bibit saya ke aplikasi Bank Jago
                  </Text>
                </li>
              </ol>
              <WhiteSpace size='md' />
              <div className='tnc-modal-subtitle-wrapper'>
                <div className='app-logo'>
                  <img src={BankJagoLogo} alt='logo bank jago' />
                </div>
                <Text
                  htmlTag='p'
                  type='subtitle2'
                  className='tnc-modal-subtitle'
                >
                  Saya menyetujui Bank Jago untuk:
                </Text>
              </div>
              <ol className='bit-jago-terms-conditions-list'>
                <li className='item-wrapper'>
                  <Text type='button1' className='tnc-modal-description-text'>
                    Menghubungkan rekening saya ke aplikasi Bibit
                  </Text>
                </li>
                <li className='item-wrapper'>
                  <Text type='button1' className='tnc-modal-description-text'>
                    Memberikan informasi rekening, termasuk namun tidak terbatas
                    pada nomor rekening saya, kepada Bibit untuk investasi
                  </Text>
                </li>
                <li className='item-wrapper'>
                  <Text type='button1' className='tnc-modal-description-text'>
                    Mendebit rekening Jago saya atas transaksi di aplikasi Bibit
                    yang saya instruksikan menggunakan Jago
                  </Text>
                </li>
              </ol>

              <Text htmlTag='p' type='body12r' color='secondary'>
                PT Bank Jago Tbk berizin dan diawasi oleh Otoritas Jasa Keuangan
                (OJK) dan Bank Indonesia, serta merupakan peserta penjaminan
                LPS.
              </Text>

              <PeriodicContent
                fallback={<div style={{ margin: '10px 0px' }} />}
                startDate={JAGO_CASHBACK_START}
                endDate={JAGO_CASHBACK_END}
              >
                <WhiteSpace size='lg' />
                <BankJagoTncCashback />
              </PeriodicContent>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        topBorder={true}
        extra={
          <div className={classNames('bit-jago-terms-conditions-bottom-side')}>
            <div className='bit-jago-terms-conditions-bottom-side-inside bit-jago-tnc-form'>
              <Radio
                checked={termsAgreed}
                onChange={handleCheckTerms}
                data-testid='success-registration-checkbox'
                className={`${!termsAgreed && 'unchecked'}`}
              >
                <p className={'bit-jago-terms-conditions-text'}>
                  Dengan registrasi Bank Jago, saya setuju dengan Syarat
                  Ketentuan &amp; Kebijakan Privasi di atas.
                </p>
              </Radio>
            </div>
          </div>
        }
        primaryButton={
          <ButtonNew
            testId='modal-lanjut-registration-jago-button'
            content='Lanjutkan'
            disabled={!termsAgreed}
            onClick={handleSubmit}
            className={classNames(
              'bit-uikit-text-size-14 bit-uikit-font-bold bit-uikit-items-center margin-bottom-10',
              {
                disabled: !termsAgreed,
              }
            )}
          />
        }
      />
    </Modal>
  );
};

export default BankJagoTncModal;
