import React, {
  ChangeEvent,
  SyntheticEvent,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { showDevtools } from 'devtools/constants/common';
import { getInstance } from 'core/Parser/CallbackMapper';

export interface DataPostMessageDebugger {
  idevent: string;
  status: 'waiting' | 'success' | 'timeout' | 'fire-forget';
  statusNetwork?: number;
  response: any;
  request?: any;
  type?: 'event' | 'network';
  emitter?: () => void;
}

// pooling debbuger
export const dataPostMessageDebugger = new Map<
  string,
  DataPostMessageDebugger
>();

const MAX_SIZE_POOL = 200;
const MAX_SIZE_STRING_DATA = 500;

export const setDataPostMessageDebugger = (data: DataPostMessageDebugger) => {
  const sizeData = dataPostMessageDebugger.size;

  if (sizeData >= MAX_SIZE_POOL) {
    // delete element in first element
    dataPostMessageDebugger.delete(dataPostMessageDebugger.keys().next().value);
  }

  // limiting string size data
  if (typeof data.response === 'string') {
    data.response = data.response.slice(0, MAX_SIZE_STRING_DATA);
  }

  dataPostMessageDebugger.set(data.idevent, data);
  // get emitter
  const emit = dataPostMessageDebugger.get('emitter');
  if (!emit?.emitter) return;

  // TODO: explain what emitter is;

  // call emitter
  emit.emitter();
};

export const updateDataPostMessageDebugger = (
  data: DataPostMessageDebugger
) => {
  // check element id
  const elementExist = dataPostMessageDebugger.get(data.idevent);
  if (!elementExist) return; // do nothing

  // update the data with the same eventId
  dataPostMessageDebugger.set(elementExist.idevent, data);

  // get emitter
  const emit = dataPostMessageDebugger.get('emitter');
  if (!emit?.emitter) return;

  // call emitter
  emit.emitter();
};

export const getDataPostMessageDebugger = () => dataPostMessageDebugger;

export const sendToDebugger = (data: DataPostMessageDebugger) => {
  if (!showDevtools) return;

  if (['waiting', 'fire-forget'].includes(data.status)) {
    setDataPostMessageDebugger(data);
    return;
  }

  updateDataPostMessageDebugger(data);
};

const callbackPostmessage = getInstance();

/**
 * Data pooled in Callback Mapper
 */
export const pooledPostMessage = callbackPostmessage.getAllHashFunction();

/**
 * data pooled in debugger
 */
export const dataPooled = getDataPostMessageDebugger();

type ContextType = {
  setVisibleMenu: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenDetail: (e: SyntheticEvent<HTMLDivElement>) => void;
  shouldShowCollapse: (id: string) => boolean;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleClearData: (e: SyntheticEvent<HTMLDivElement>) => void;
  elementRender: DataPostMessageDebugger[];
  poolSize: number;
  search: string;
};

const NativeDebuggerContext = createContext<ContextType>({
  setVisibleMenu: () => null,
  handleOpenDetail: () => null,
  shouldShowCollapse: () => false,
  handleChangeInput: () => null,
  handleClearData: () => null,
  elementRender: [],
  poolSize: 0,
  search: '',
});

const NativeDebuggerProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [detailId, setDetailId] = useState(['']);
  const [elementRender, setElementRender] = useState<DataPostMessageDebugger[]>(
    []
  );
  const [poolSize, setPoolSize] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [visibleMenu, setVisibleMenu] = useState(false);

  useEffect(() => {
    if (visibleMenu) {
      const sizePool = Object.keys(pooledPostMessage).length;
      setPoolSize(sizePool);
    }
  }, [visibleMenu]);

  useEffect(() => {
    const updatedPooled = getDataPostMessageDebugger();
    /** initiate data for emitter callback debugger */
    dataPooled.set('emitter', {
      idevent: 'emitter',
      status: 'success',
      response: 'any',
      emitter: () => {
        const data = [...updatedPooled.values()].reverse();
        setElementRender(data);
      },
      request: {
        data: 'hello-world',
      },
    });

    return () => {
      // clear data pool
      updatedPooled.clear();
    };
  }, []);

  useEffect(() => {
    if (!visibleMenu) return;

    const data = [...dataPooled.values()].reverse();
    setElementRender(data);
  }, [visibleMenu]);

  const shouldShowCollapse = (id: string) => {
    return detailId.includes(id);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setSearch(val);

    const dataPooled = getDataPostMessageDebugger();

    // filtering data;
    const str = val?.toLowerCase();

    const nextData = [...dataPooled.values()].filter(
      (item) => !!item.idevent?.toLowerCase().includes(str)
    );

    setElementRender(nextData);
  };

  const handleOpenDetail = (e: SyntheticEvent<HTMLDivElement>) => {
    const postid = e.currentTarget.dataset.postid;
    if (!postid) return;

    const alreadyOpen = shouldShowCollapse(postid);
    if (!alreadyOpen) {
      setDetailId([...detailId, postid]);
      return;
    }

    // delete postid
    setDetailId([...detailId].filter((item) => postid !== item));
  };
  const handleClearData = (e: SyntheticEvent<HTMLDivElement>) => {
    setElementRender([]);

    dataPooled.clear();
  };

  return (
    <NativeDebuggerContext.Provider
      value={{
        setVisibleMenu,
        handleOpenDetail,
        shouldShowCollapse,
        handleChangeInput,
        handleClearData,
        elementRender,
        poolSize,
        search,
      }}
    >
      {children}
    </NativeDebuggerContext.Provider>
  );
};

export const useNativeDebugger = () => {
  return useContext(NativeDebuggerContext);
};

export default NativeDebuggerProvider;
