import { useQuery } from 'react-query';
import {
  getRecurring,
  GetRecurringParams,
} from 'features/recurring/networks/services';

/**
 * Fetching Recurring List data
 */
export const useFetchSIP = (
  enable: boolean = true,
  params?: GetRecurringParams
) => {
  return useQuery(['SIP List', params], () => getRecurring({ ...params }), {
    enabled: enable,
    refetchOnMount: 'always',
    cacheTime: 60 * (60 * 1000), // 1 hour
  });
};
export default useFetchSIP;
