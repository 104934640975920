import React, { useEffect, useState } from 'react';
import { Home, PieChart, User, X } from 'react-feather';
import { ReactComponent as HomeNew } from 'assets/images/tab-icon-asset/home.svg';
import { ReactComponent as PieChartNew } from 'assets/images/tab-icon-asset/pie-icon.svg';
import { ReactComponent as UserNew } from 'assets/images/tab-icon-asset/user.svg';
import { useQuery } from 'react-query';
import BibitInstitutionSwitchAccountModal from 'features/institution/components/BibitInstitutionSwitchAccount/BibitInstitutionSwithAccount';
import { TabBar } from '@bibitid/uikit-v1';
import { useToggle, useLongPress, useProfileData } from 'hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as InsitutionIcon } from 'assets/images/tab-icon-asset/insitution-tab.svg';
import { Search as SearchIcon } from 'react-feather';
import { ReactComponent as ActivityTabImageNew } from 'assets/images/tab-icon-asset/order-icon.svg';
import { ReactComponent as ActivityTabImageNewDM } from 'assets/images/tab-icon-asset/order-icon-ffdarkmode-on.svg';

import { ReactComponent as InsitutionIconNew } from 'assets/images/tab-icon-asset/institution-tab.svg';
import { ReactComponent as SearchIconNew } from 'assets/images/tab-icon-asset/search.svg';
import { getPreferenceValueFromString } from 'utils';
import Analytics from 'utils/Analytics';
import { tabbarActive } from './utils';
import {
  activeHome,
  activePortofolio,
  activeProfile,
  activeTransaction,
  activeExplore,
  bibitPlusActiveHome,
} from './constants/activeTabbar';

import useAuth from 'hooks/useAuth';
import useRenewForm from 'features/periodic-data-update/hooks/useRenewForm';
import { useFlag } from 'utils/feature-flag';
import { useFetchPremiumStats } from 'features/premium/networks/resolver';
import { useStorageCurrentInstutiton } from 'features/institution/hooks';
import style from 'routes/MainRoute/components/TabBar/TabBar.module.css';
import {
  useStorageInstitutionProfileNotification,
  useUpdateInstitutionProfileNotification,
} from 'features/institution/hooks/notificationInstitution';
import usePostMessage from 'core/Parser/hooks/usePostMessage';
import rqClient from 'network/queryClient';
import useSettingPreference from 'features/periodic-data-update/hooks/useSettingPreference';
import { COLOR_BAR_DEFAULT } from 'core/statusbar/constants';
import { postDataSetAppBottomBarColor } from 'core/statusbarbottom';

const { TabBarItem } = TabBar;

/**
 * Bottom Tab Bar main of our app
 *
 * Tab Bar uses uikit-v1 component
 * Contain:
 * - Home
 * - Portofolio
 * - Transaksi
 * - Support
 * - Profile
 */
const TabBarComp: React.FC<React.PropsWithChildren<unknown>> = () => {
  // MARK: - Hooks Helper
  const history = useHistory();

  const { pathname: pathLocation } = useLocation();

  const { postData } = usePostMessage();

  const { isLogin } = useAuth();

  // get profile data
  const { data: profileData } = useProfileData(isLogin);
  const profileDetail = profileData?.data?.data;

  // get `institution account only` data
  const isIndividualDummyAccount = profileDetail?.user?.status === 5;
  const isRegisteredAsInstiAccountOnly =
    !!profileDetail?.preference?.is_registered_as_institutional_account_only;
  const isInstitutionAccountOnly =
    isIndividualDummyAccount && isRegisteredAsInstiAccountOnly;

  const { data: currentInstitution, isFetching: loadingInstitution } =
    useStorageCurrentInstutiton(isLogin);

  const institutionAvailable =
    (profileData?.data?.data?.institutions || []).length > 0;

  const institutionMoreThanOne =
    (profileData?.data?.data?.institutions || []).length > 1;

  const institutionState = !!currentInstitution?.institution_id;

  const fetchInstiNotifForUserWithIndividual =
    isLogin && !isInstitutionAccountOnly && institutionAvailable;

  const fetchInstiNotifForUserWithoutIndividual =
    isLogin && isInstitutionAccountOnly && institutionMoreThanOne;

  /** Need NotificationStats 'dot_notifications' for institution switch profile */
  const { data: notificationStatus } = useStorageInstitutionProfileNotification(
    fetchInstiNotifForUserWithIndividual ||
      fetchInstiNotifForUserWithoutIndividual
  );

  // show switch account tooltip on tabbar profile when
  // - for insti with dummy account: `tooltips.switch_institution_account === 0`
  // - for insti with normal account: `tooltips.switch_institution_account === 1`
  const [switchAccountTooltipVisible, setSwitchAccountTooltipVisible] =
    useState<boolean>(
      isIndividualDummyAccount
        ? notificationStatus?.tooltips?.switch_institution_account === 0
        : notificationStatus?.tooltips?.switch_institution_account === 1
    );

  const { mutateAsync: mutateNotif } =
    useUpdateInstitutionProfileNotification(notificationStatus);

  /** List of setting preference, get from API */
  const { data: settingPreference } = useSettingPreference(isLogin);

  /** Bibit Darkmode FF */

  const ff_darkmode = useFlag('web_bibit_darkmode');

  /** React query data for showing TabBar */
  const { data: showTabBarData } = useQuery('showTabBar', () => {
    return rqClient.getQueryData<{ visible?: boolean }>('showTabBar');
  });

  /**
   * This is temporary, all will be replaced with react query later on.
   * Determine to use which state according to props passed on route level.
   * Check LazyRouteApp.
   */
  const showTabbar = !!showTabBarData ? showTabBarData?.visible : true;

  /**
   * Get Renewal Form
   * @summary - to guard invalid renewal that show the red dot but doesn't have renewal form
   */

  const {
    error: renewalError,
    data: renewalData,
    isLoading: renewalLoading,
  } = useRenewForm();

  /** Tell us is user needs Data Renewal */
  const isDataRenewal = getPreferenceValueFromString(
    settingPreference || [],
    'is_request_amend'
  );

  /**
   * @summary Check if Renewal Form Data (Pengkinian Data Form Data) is not an empty array
   * @description updated sept 2023, /user/renewal-requests API returns 200 empty array when pengkinian not available
   * it used to return 422 error
   */
  const hasRenewalFormData =
    !renewalLoading && Array.isArray(renewalData)
      ? renewalData.length > 0
      : false;
  const showRenewalDot =
    isDataRenewal && !renewalError && hasRenewalFormData && !renewalLoading;

  /**
   * Showing green dot when user is eligible to Bibit Premium
   * Green dot will disappear:
   *  - When user has visited Profile Page, Or
   *  - After 1 Month, if user hasn't visited Bibit Premium Page yet
   */

  const { data: statsData } = useFetchPremiumStats(isLogin);
  /**
   * Green dot only displayed for new almost eligible and eligible
   * new_premium_user_status:
   *   0: In-eligible,
   *   1: Almost Eligible,
   *   2: Eligible,
   */
  const newPremiumUser = statsData?.data?.data?.new_premium_user_status ?? 0;

  const showPremiumDot = !!newPremiumUser;

  const showInstitutionProfileDot =
    !!notificationStatus?.dot_notifications.profile;

  /**
   * Institution long press handler
   */
  // TODO: can probably lazy load modal switch component
  const { data: userData } = useProfileData(isLogin);
  const hasInstitution =
    Boolean(userData?.data?.data?.institutions?.length || [].length > 0) ||
    false;
  const hasMoreThanOneInstitution =
    !!userData?.data?.data?.institutions &&
    userData?.data?.data?.institutions?.length > 1;

  const {
    active: switchModalActive,
    toggleActive: switchModalToggle,
    setActive: setSwithModalToggle,
  } = useToggle();

  const handleOpenSwitchAccount = () => {
    Analytics.logEventAction({
      eventName: 'institution_profile_action',
      parameter: {
        action: 'profile',
        context: '',
        trigger: 'long_press',
        data: {
          from_account_type: institutionState ? 'institution' : 'individual',
        },
      },
    });
    // send to Native
    postData(
      'vibrateDevice',
      {
        fn: 'vibrateDevice',
        timeout: 0,
        data: {},
      },
      () => {}
    );
    switchModalToggle();
  };

  const handleSwitchModalClose = () => {
    setSwithModalToggle(false);
  };

  /**
   * Long press for open switch account modal when
   * - Normal account has institution
   * - or Institution account only has more than 1 institution
   */
  const instLongPress = useLongPress(
    (!isInstitutionAccountOnly && hasInstitution) ||
      (isInstitutionAccountOnly && hasMoreThanOneInstitution)
      ? handleOpenSwitchAccount
      : null,
    {
      threshold: 500,
      captureEvent: true,
      cancelOnMovement: false,
    }
  );

  /**
   * Show Red dot if it's data-renewal notification or bibitInstitution profile tooltip notification
   * Show Green dot if it's bibit-premium notification
   * If both are true, then show data-renewal notification first
   */
  const showDot = showInstitutionProfileDot || showRenewalDot || showPremiumDot;
  const dotColor =
    showInstitutionProfileDot || showPremiumDot
      ? 'green'
      : showRenewalDot
      ? 'red'
      : '';

  // set local steps tooltip from storage, show switch account tooltip on tabbar profile when
  // - for insti with dummy account: `tooltips.switch_institution_account === 0`
  // - for insti with normal account: `tooltips.switch_institution_account === 1`
  useEffect(() => {
    setSwitchAccountTooltipVisible(
      isIndividualDummyAccount
        ? notificationStatus?.tooltips?.switch_institution_account === 0
        : notificationStatus?.tooltips?.switch_institution_account === 1
    );
  }, [isIndividualDummyAccount, notificationStatus]);

  /**
   * determine should show tabbar or not based on
   * - flag reducer
   * - is login user
   */
  const shouldShowTabbar = showTabbar && isLogin;

  // MARK: - Action Fuction

  const handleGoHome = () => {
    if (tabbarActive(pathLocation, activeHome)) {
      return;
    }

    history.push('/', {
      preserveScrollPosition: false,
    });

    Analytics.logEventAction({
      eventName: 'navigation_action',
      parameter: {
        action: 'home',
        trigger: 'click',
        context: '',
      },
    });
  };

  const handleGoPortfolio = () => {
    if (tabbarActive(pathLocation, ['/portfolio'])) {
      return;
    }

    history.push('/portfolio', {
      fromTabBar: true,
      preserveScrollPosition: false,
    });

    Analytics.logEventAction({
      eventName: 'navigation_action',
      parameter: {
        action: 'portfolio',
        trigger: 'click',
        context: '',
      },
    });
  };

  const handleGoTransaction = () => {
    if (tabbarActive(pathLocation, ['/orders', '/transactions/order/all'])) {
      return;
    }

    Analytics.logEventAction({
      eventName: 'navigation_action',
      parameter: {
        action: 'transactions',
        trigger: 'click',
        context: '',
      },
    });

    if (!institutionState) {
      return history.push('/transactions/order/all', {
        preserveScrollPosition: false,
      });
    }

    history.push('/orders', {
      preserveScrollPosition: false,
    });
  };

  const handleGoProfile = () => {
    if (tabbarActive(pathLocation, ['/profile'])) {
      return;
    }

    Analytics.logEventAction({
      eventName: 'navigation_action',
      parameter: {
        action: 'profile',
        trigger: 'click',
        context: '',
      },
    });

    // hide green dot profile when user click tabbar profile
    if (showInstitutionProfileDot) {
      mutateNotif({ profile: 0 });
    }
    return history.push('/profile', {
      preserveScrollPosition: false,
    });
  };

  const handleGoDiscover = () => {
    if (tabbarActive(pathLocation, ['/explore'])) {
      return;
    }

    Analytics.logEventAction({
      eventName: 'navigation_action',
      parameter: {
        action: 'explore',
        trigger: 'click',
        context: '',
      },
    });

    return history.push('/explore', {
      preserveScrollPosition: false,
    });
  };

  const handleSwitchAccountTooltipOnClose = () => {
    Analytics.logEventAction({
      eventName: 'institution_profile_action',
      parameter: {
        action: 'close_tooltip',
        context: '',
        trigger: 'click',
        data: {
          type: 'long press',
        },
      },
    });

    // update local state for hide tooltip
    setSwitchAccountTooltipVisible(false);

    // request api for update tooltip switch account and update storage
    // - for insti with dummy account: updated into 1 for show tooltip on toggle
    // - for insti with normal account: updated into 2 for hide all tooltip
    mutateNotif({
      tooltipSwitchAccount: isIndividualDummyAccount ? 1 : 2,
    });
  };

  const iconHome = () => {
    if (ff_darkmode) {
      return <HomeNew strokeWidth='1.5' />;
    } else if (institutionState) {
      return <Home size={20} strokeWidth='1.5' />;
    } else {
      return <Home size={20} strokeWidth='1.5' />;
    }
  };

  const iconUser = () => {
    if (ff_darkmode) {
      return <UserNew strokeWidth='1.5' />;
    } else {
      return <User size={20} strokeWidth='1.5' />;
    }
  };

  const iconPieChart = () => {
    if (ff_darkmode) {
      return <PieChartNew strokeWidth='1.5' />;
    } else if (institutionState) {
      return <PieChart size={20} strokeWidth='1.5' />;
    } else {
      return <PieChart size={20} strokeWidth='1.5' />;
    }
  };

  const iconSearch = () => {
    if (ff_darkmode) {
      return <SearchIconNew strokeWidth='1.5' />;
    } else {
      return <SearchIcon size={20} strokeWidth='1.5' />;
    }
  };

  const iconActivityTab = () => {
    if (ff_darkmode) {
      return <ActivityTabImageNewDM />;
    } else {
      return <ActivityTabImageNew />;
    }
  };

  const iconInstitution = () => {
    if (ff_darkmode) {
      return <InsitutionIconNew />;
    } else {
      return <InsitutionIcon />;
    }
  };

  useEffect(() => {
    if (shouldShowTabbar) {
      const surfaceColor = COLOR_BAR_DEFAULT();
      // @ set color bottom to default
      postDataSetAppBottomBarColor(
        surfaceColor.bottom.bgColor,
        surfaceColor.bottom.foregroundColor
      );
    }
    // trigger on first time load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // return empty component when `shouldShowTabbar` is false
  if (!shouldShowTabbar || loadingInstitution) return null;

  if (institutionState) {
    return (
      <div className={style['tabbar-bibit']}>
        <TabBar>
          {/* Home Menu */}
          <TabBarItem
            icon={iconHome()}
            title=''
            active={tabbarActive(pathLocation, activeHome)}
            onClick={handleGoHome}
            testId='tabbar-home'
          />

          {/* Portofolio Menu */}
          <TabBarItem
            icon={iconPieChart()}
            title=''
            active={tabbarActive(pathLocation, activePortofolio)}
            onClick={handleGoPortfolio}
            testId='tabbar-portofolio'
          />

          <TabBarItem
            icon={iconSearch()}
            active={tabbarActive(pathLocation, activeExplore)}
            onClick={handleGoDiscover}
            title={''}
            testId='tabbar-explore'
          />

          {/* Transaction Menu */}
          <TabBarItem
            icon={iconActivityTab()}
            title=''
            active={tabbarActive(pathLocation, activeTransaction)}
            onClick={handleGoTransaction}
            testId='tabbar-transaksi'
          />

          {/* Profile Menu */}
          <div
            className={style['tabbar-profile-switch-account-tooltip-wrapper']}
            {...instLongPress()}
          >
            <TabBarItem
              icon={iconInstitution()}
              showDot={showInstitutionProfileDot}
              dotColor={dotColor}
              title=''
              active={tabbarActive(pathLocation, activeProfile)}
              onClick={handleGoProfile}
              testId='tabbar-profile-institution'
            />

            {switchAccountTooltipVisible && (
              <div className={style['tabbar-profile-switch-account-tooltip']}>
                Tekan dan tahan ikon profil untuk switch akun
                <X
                  className={style['tabbar-profile-switch-account-tooltip-svg']}
                  color='#fff'
                  strokeWidth={3}
                  size={12}
                  onClick={handleSwitchAccountTooltipOnClose}
                />
              </div>
            )}
          </div>
        </TabBar>
        {hasInstitution && (
          <BibitInstitutionSwitchAccountModal
            visible={switchModalActive}
            onClose={switchModalToggle}
            onSwitch={handleSwitchModalClose}
          />
        )}
      </div>
    );
  }

  return (
    <div className={style['tabbar-bibit']}>
      <TabBar>
        {/* Home Menu */}
        <TabBarItem
          icon={iconHome()}
          title=''
          active={tabbarActive(pathLocation, bibitPlusActiveHome)}
          onClick={handleGoHome}
          testId='tabbar-home'
        />

        {/* Portofolio Menu */}
        <TabBarItem
          icon={iconPieChart()}
          title=''
          active={tabbarActive(pathLocation, activePortofolio)}
          onClick={handleGoPortfolio}
          testId='tabbar-portofolio'
        />

        <TabBarItem
          icon={iconSearch()}
          active={tabbarActive(pathLocation, activeExplore)}
          onClick={handleGoDiscover}
          title={''}
          testId='tabbar-explore'
        />

        {/* Transaction Menu */}
        <TabBarItem
          icon={iconActivityTab()}
          title=''
          active={tabbarActive(pathLocation, activeTransaction)}
          onClick={handleGoTransaction}
          testId='tabbar-transaksi'
        />

        {/* Profile Menu */}
        <div
          className={style['tabbar-profile-switch-account-tooltip-wrapper']}
          {...instLongPress()}
        >
          <TabBarItem
            icon={iconUser()}
            showDot={showDot}
            dotColor={dotColor}
            title=''
            active={tabbarActive(pathLocation, activeProfile)}
            onClick={handleGoProfile}
            testId='tabbar-profile'
          />

          {switchAccountTooltipVisible && (
            <div className={style['tabbar-profile-switch-account-tooltip']}>
              Tekan dan tahan ikon profil untuk switch akun
              <X
                className={style['tabbar-profile-switch-account-tooltip-svg']}
                color='#fff'
                strokeWidth={3}
                size={12}
                onClick={handleSwitchAccountTooltipOnClose}
              />
            </div>
          )}
        </div>
      </TabBar>

      {hasInstitution && (
        <BibitInstitutionSwitchAccountModal
          visible={switchModalActive}
          onClose={switchModalToggle}
          onSwitch={handleSwitchModalClose}
        />
      )}
    </div>
  );
};

export default TabBarComp;
