export const translation = {
  upload_gambar: 'Uploading image',
  ambil_foto: 'Take a Photo',
  diawasi_ojk:
    'Bibit is licensed and supervised by the OJK (Otoritas Jasa Keuangan) as a Mutual Fund Sales Agent with number',
  lanjutkan_registrasi_kamu: 'Continue Your Registration',
  mengunggah: 'Uploading…',
  lanjutkan: 'Next',
  foto_kamera: 'Photo from Camera',
  cek_keakuratkan_ktp: 'Check the Accuracy of Your ID Data',
  nik: 'NIK',
  dokumen_foto: 'Document Photo',
  foto_ktp: 'ID Card Photo',
  rekening_bank_diperlukan: 'Bank account is required for your withdrawal',
  nama_bank: 'Bank Name',
  nomor_rekening: 'Account Number',
  melihat_nomor_rekening: 'Where can I see the account number?',
  panduan_melihat_rekening: 'Guide to Viewing Account Numbers',
  pastikan_nomor_tertera:
    'Ensure that the number you enter is the account number listed in the bank, <strong>not the ATM number.</strong>',
  nomor_kartu_atm: 'ATM Card Number',
  buku_tabungan: 'in the Savings Book',
  bank_digital: 'Digital Banking App',
  mobile_banking: 'Mobile Banking App',
  nama_pemilik_rekening: "Account Holder's Name",
  pastikan_nama_rekening:
    "Make sure the account you use is under your name, not someone else's.",
  belum_punya_rekening: "Don't have a bank account yet?",
  belum_memiliki_bank: "Don't have a bank yet?",
  kenapa_harus_jago: 'Why Bank Jago?',
  perlu_rekening_bank:
    'You need a bank account to start investing in Bibit based on OJK regulations.',
  kamu_setuju_buka_rekening:
    'By clicking Continue, you agree to open a <strong>Bank Jago</strong> account at the end of the Bibit registration.',
  bank_partner: 'Gojek and Bibit Bank Partners',
  bank_digital_berkolaborasi:
    'Collaborative digital bank with Gojek and Bibit to help you manage your finances',
  gratis_biaya_bulanan: 'Free Monthly Fee Bank Jago',
  buka_rekening: 'Just 4 Minutes to Open an Account',
  buka_akun_bank_jago:
    'Open a Jago bank account hassle-free, just through your phone',
  banyak_keuntungan: 'Many Benefits at Bibit with Jago',
  transaksi_mudah_instan:
    'Easy transactions without switching applications. Disciplined investment with auto-debit feature and instant withdrawal.',
  gratis_biaya_admin:
    'Free admin fees and free interbank transfers up to <1>25 transactions per month</1>.',
  transaksi_mudah:
    'Easy transactions without switching applications. Disciplined investment with auto-debit feature.',
  konfirmasi_bank: 'Confirm Bank Account',
  nama_ktp: 'Name on ID Card',
  pastikan_data_benar:
    'Ensure the data you enter is correct as it will affect the disbursement process.',
  tanda_tangan_digital: 'Provide Digital Signature',
  tanda_tangan_ktp: 'Make sure your signature matches the one on your ID Card.',
  tanda_tangan_disini: 'Please draw your digital signature here',
  syarat_ketentuan_reksadana:
    'I have read and agree to the <1>Privacy Policy</1> as well as the <4>Terms and Conditions</4> of Opening Securities and Mutual Fund Account and Online Mutual Fund Transactions at PT Bibit Tumbuh Bersama.',
  mengunggah_tanda_tangan: 'Uploading Signature…',
  memproses_pendaftaran: 'Processing Registration…',
  daftar_sekarang: 'Register Now',
  langkah_terakhir: 'Last Step',
  buat_pin: 'Create Your PIN',
  setup_pin: 'Setup PIN',
  konfirmasi_pin: 'Confirm PIN',
  pendaftaran_berhasil: 'Registration Successful!',
  proses_verifikasi_data:
    'Please wait for the data verification process so that you can start investing. Estimated completion by',
  segera_verifikasi_email: 'Verify your email immediately',
  verifikasi_email_mulai_investasi: 'Verify your email to start investing',
  harap_cantumkan_email: 'Please provide your email before verification',
  kami_telah_mengirimkan: 'We have sent a verification email to',
  mulai_verifikasi: 'Start Email Verification',
  cek_email: 'Check Email',
  ganti_email: 'Change Email Address',
  belum_menerima_email: "Haven't received an email yet?",
  kirim_ulang: 'Resend',
  kirim_ulang_email: 'Resend Email',
  verifikasi_mulai_investasi: 'Email Verification to Start Investing',
  verifikasi_email_kamu: 'Email Verification by You',
  verifikasi_jago: 'Bank Jago Verification',
  verifikasi_bibit: 'Verification by Bibit',
  verifikasi_ksei: 'Verification by KSEI',
  dalam_proses_verifikasi:
    'Your Bibit Registration is Currently Being Verified. Estimated Completion:',
  masukkan_email_baru: 'Enter Your New Email Address',
  email_berhasil_dikirim:
    'Verification Email Successfully Sent. Please Check Your Email.',
  ubah_email: 'Change Email',
  sudah_mengirimkan_email: 'Bibit has sent a verification email to:',
  cek_email_kamu: 'Please check your email to verify your email.',
  data_diri: 'Fill in Personal Information',
  isi_email: 'Fill in Email',
  selfie_ktp: 'Selfie Photo with ID Card',
  isi_data_ktp: 'Fill in ID Card Information',
  isi_data_bank: 'Fill in Bank Information',
  tanda_tangan: 'Digital Signature',
  selesai_registrasi: 'Registration Completed',
  Lainnya: 'Others',
  '- Pilih -': '- Select -',
  ambil_ulang: 'Retake',
  peraturan_ojk:
    'We only request data in accordance with OJK regulations. Your data is encrypted and will not be shared with third parties without your consent.',
  mohon_tunggu: 'Please Wait',
  dua_juta: 'Rp2 million',
  lima_juta: 'Rp5 million',
  tiga_juta: 'Rp3 million',
  data_terenkripsi: 'Data Safe & Encrypted',
  uang_tersimpan_aman: 'Your Money Is Safely Stored in Custodian Bank',
  pencairan_nama_rekening: 'Withdrawals Only in Your Account Name',
  dana_disimpan_aman:
    'The funds you invest will be securely stored in a Custodian Bank and can be withdrawn at any time.',
  dana_investasi_dicairkan:
    'Your investment funds can only be withdrawn to a bank account registered under your name as stated on your ID card.',
  pilih_bank: 'Choose a Bank',
  bank_atas_nama_sendiri:
    'Make sure the bank you choose is an account in your own name 👌',
  melanjutkan: 'Continue',
  investasi_bulanan_error_msg:
    'The monthly investment must be filled in with a value greater than 0.',
  investasi_awal_error_msg: 'The initial investment must be filled in.',
  'Jual sebagian': 'Sell a portion',
  'Simpan semua': 'Save all',
  'Beli lagi': 'Buy more',
  'Keputusan kamu di saat kondisi pasar naik turun sangat penting untuk membantu kami mempelajari toleransi kamu terhadap risiko.':
    'Your decision in times of market fluctuations is crucial to help us understand your risk tolerance.',
  profil_risiko: 'Risk Profile',
  simulasikan_investasimu:
    'Simulate your investment on Bibit and see how much your money can grow',
  lanjut_registrasi: 'Continue Registration',
  ulangi_pertanyaan: 'Repeat Question',
  investor_moderat:
    'Moderate investors have a moderate tolerance for risk where you expect returns above deposit interest rates with moderate market fluctuations.',
  investor_aggresif:
    'Aggressive investors have a high tolerance for risk where you can accept high market fluctuations to achieve the highest return.',
  investor_conservatif:
    'Conservative investors have a low tolerance for risk where you expect minimal returns equivalent to deposit interest rates with minimal market fluctuations.',
  konservatif: 'Conservative',
  moderat: 'Moderate',
  agresif: 'Aggressive',
  deskripsi_pasar_uang:
    'Investment allocation is placed 100% in Money Market instruments such as bank deposits and Bank Indonesia Certificates (SBI).',
  deskripsi_obligasi:
    'Most of the investment allocation is placed in securities such as debt and bonds.',
  deskripsi_saham:
    'Most of the investment allocation is placed in shares of companies listed on the Stock Exchange.',
  alokasi_dana_otomatis:
    'Our technology will automatically allocate your money to 3 different mutual funds depending on your answers.',
  alokasi_dana_rekomendasi:
    'If our recommendation is 20% Money Market, 50% Bonds, 30% Stocks, then if you invest Rp10 million, your investment value will be divided into :',
  isi_data_diri: 'Fill in Your Data Now!',
  pendidikan_terakhir: 'Highest Education Attained',
  pilih_pendidikan_terakhir: 'Select Highest Education Attained',
  SD: 'Elementary School',
  SMP: 'Junior High School',
  SMA: 'High School',
  Diploma: 'Diploma',
  S1: "Bachelor's Degree",
  S2: "Master's Degree",
  S3: 'Doctorate Degree',
  lainnya: 'Others',
  pendapatan_per_tahun: 'Annual Income',
  '< Rp10 juta per tahun': '< Rp10 million per year',
  'Rp10-50 juta per tahun': 'Rp10-50 million per year',
  '> Rp50-100 juta per tahun': '> Rp50-100 million per year',
  '> Rp100-500 juta per tahun': '> Rp100-500 million per year',
  '> Rp500 juta-1 miliar per tahun': '> Rp500 million-1 billion per year',
  '> Rp1 miliar per tahun': '> Rp1 billion per year',
  sumber_penghasilan: 'Source of Income',
  Gaji: 'Salary',
  'Keuntungan Bisnis': 'Business Profit',
  'Bunga Simpanan': 'Savings Interest',
  Warisan: 'Inheritance',
  'Orang Tua/Anak': 'Parents/Children',
  Undian: 'Lottery',
  Pasangan: 'Spouse',
  Tabungan: 'Savings',
  'Hasil Investasi': 'Investment Results',
  nama_gadis_ibu: "Mother's Maiden Name",
  isi_email_kamu: 'Fill in Your Email!',
  alamat_email: 'Email Address',
  foto_ktp_kamu: 'Your ID Card Photo',
  foto_jelas_terbaca:
    'Make sure your photo is clear and readable for identity verification purposes.',
  salah: 'Wrong',
  benar: 'Correct',
  foto_ktp_pembelian_reksadana:
    'Photo ID card is required for mutual fund purchases as per OJK regulations.',
  ambil_dari_gallery: 'Choose from Gallery',
  ojk: 'OJK',
  keamanan: 'Security',
  foto_buram: 'Blurry Photo',
  pantulan_cahaya: 'Light Reflection',
  foto_terpotong: 'Cropped Photo',
  foto_jelas: 'Clear Photo',
  hasil_foto_memenuhi: 'Make sure the photo meets the requirements:',
  ktp_sesuai_identitas: 'ID Card Matches Your Identity',
  ktp_tidak_silau: 'ID Card Not Glare & Not Blurred',
  ktp_terbaca_jelas: 'ID Card is Clearly Readable and Not Cropped',
  tidak_ada_objek: 'There are no other objects besides ID Card in the photo',
  ulangi: 'Retake',
  harap_tunggu: 'Please Wait',
  foto_selfie_ktp: 'Selfie with Your ID Card',
  pastikan_wajah_dan_ktp:
    'Make sure your face and ID Card are clearly visible to ensure the ID Card belongs to you.',
  selfie_dengan_ktp:
    'Selfie with KTP is required for purchasing mutual funds in accordance with OJK regulations.',
  foto_selfie: 'Selfie Without ID Card',
  foto_berbayang: 'Shadowy Photo Not Visible',
  ktp_dan_wajah: 'ID Card and Face Clearly Visible',
  hasil_foto_kamu: 'Have your photos met the requirements below?',
  pencahayaan_yang_baik: 'Good lighting, not dark, and without reflection',
  ktp_dibawah_dagu: 'ID Card Below Chin and Not Covering the Face',
  wajah_tidak_tertutup: 'Face not covered by hair, mask, hat, or glasses',
  wajah_dan_ktp: 'Face and ID card are clear and not blurry.',
  isi_data_rekening: 'Fill in Your Bank Account Data',
  nama_lengkap: 'Full Name',
  tanggal_lahir: 'Date of Birth',
  kode_refferal: 'Referral Code (Optional)',
  kode_whatsapp:
    'Enter the code sent via Whatsapp to your phone (+{{code}}) {{phone}}',
  kode_default: 'Enter the code sent via SMS to your phone',
  data_saya_aman: 'Is My Data Safe?',
  data_kamu_dienkripsi:
    'Your data is encrypted and will not be given to third parties without your consent',
  selanjutnya: 'Next',
  simulasi_investasi: 'Investment Simulation',
  investasi_awal: 'Initial Investment',
  investasi_bulanan: 'Monthly Investment',
  tipe_investor: 'Investor Type',
  nilai_investasi_kamu: 'Your Investment Value This Year',
  investasi_bibit: 'Investment in Bibit',
  tabungan_biasa: 'Regular Savings',
  simulasi_deskripsi:
    'Simulations are made based on past performance and do not guarantee future performance.',
  simpan_hasil: 'Save results',
  sumber_penghasilan_lain: 'Other Source of Income',
  pengecekan_email: 'Checking Email…',
  masukkan_nomor_hp: 'Enter Your Phone Number',
  registrasi: 'Registration',
  kode_email: 'Enter the code sent via your email',
  kode_verifikasi: 'Enter Verification Code',
  melalui_sms_ke_nomor_hp:
    'Enter the code sent via SMS to your phone (+{{code}}) {{phone}}',
  kirim_ulang_kode: 'Resend Code',
  verifikasi: 'Verification',
  metode_pengiriman_verifikasi: 'Choose Verification Code Delivery Method',
  kirim_melalui: 'Send via {{type}}',
  profil_risiko_kamu: 'Know Your Risk Profile',
  robo_advisor:
    'Robo Advisor will provide recommendations according to your personal data and risk tolerance.',
  data_aman_terenkripsi: 'Your data is safe and encrypted',
  mulai: 'Start',
  'Kapan ulang tahun kamu?': 'When is your birthday?',
  total_pertanyaan: '{{total}} out of 6 questions',
  'Apakah kamu sudah menikah dan memiliki tanggungan keluarga?':
    'Are you married and have dependents?',
  'Belum, masih single': 'Not yet still single',
  'Sudah menikah, belum punya tanggungan': 'Married, no dependents',
  'Sudah menikah, anak 1': 'Married, 1 child',
  'Sudah menikah, anak 2 atau lebih': 'Married, 2 or more children',
  'Sudah pensiun, atau sudah mapan keuangan':
    'Already retired or financially independent',
  'Berapa pendapatan bulanan dalam rumah tangga kamu?':
    'What is your monthly household income?',
  'Termasuk penghasilan istri/suami, pendapatan sewa dll':
    "Including spouse's income, rental income, etc.",
  'Berapa nilai total kekayaan kamu?':
    'What is the total value of your wealth?',
  'Dapat berupa kas, deposito, properti, emas, saham atau reksa dana.':
    'Can be in the form of cash deposits property gold stocks or mutual funds.',
  'Apa yang lebih kamu utamakan dalam berinvestasi?':
    'What do you prioritize in investing?',
  'Maksimalkan keuntungan': 'Maximize Profit',
  'Menghindari kerugian': 'Avoiding Losses',
  'Keduanya sama penting': 'Both are equally important',
  'Jika nilai investasi kamu turun 15% dalam sebulan dengan keadaan pasar yang tidak menentu, apa yang akan kamu lakukan?':
    'If your investment value drops by 15% in a month in uncertain market conditions, what will you do?',
  'Jual semua': 'Sell all',
};
