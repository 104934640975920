import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Line, Bar, LinePath, AreaClosed } from '@vx/shape';
import { scaleLinear, scaleBand } from '@vx/scale';
import { max, min, minIndex, maxIndex } from 'd3-array';
import { curveMonotoneX } from '@vx/curve';
import useTooltip from './useTooltip';
import { localPoint } from '@vx/event';
import { CompanyChartPrice } from 'features/stock/types';
import Tooltip from './Tooltip';
import styles from './StockChart.module.css';
import { Text } from '@bibitid/uikit-v1';
import { format } from 'utils/date';
import { numberToGeneralValueFormat } from 'utils/stringHelper';
import parse from 'date-fns/parse';
import classnames from 'classnames';
import subHours from 'date-fns/subHours';
import { isNumber } from 'utils/number';
import { ISimplifyObjectPoint } from './utils/simplify';
import IpoEmptyState from 'assets/images/stock/ipo-empty-chart.svg';

/**
 * Desain https://www.figma.com/file/Yx4RBgtQPeZ4N80VrSFgh5/Discovery-2.0?node-id=2855%3A384781
 */

interface StockChartProps {
  width: number;
  height: number;
  data: CompanyChartPrice[];
  previous?: number;
  status: 'down' | 'up' | 'default';
  period?: string;
  suspended?: boolean;
  symbol?: string;
  withPadding?: boolean;
  ipo?: boolean;
  onMouseLeave?: (
    event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>
  ) => void;
}

export interface AppleStock {
  date: string;
  close: number;
}

// formatted_date(yyyy-MM-dd HH:mm:ss) date format not working on safari
// to handle this issue parsed formatted_date to date object
const parseDate = (date: string) => {
  if (date.length > 10) {
    return parse(String(date), 'yyyy-MM-dd HH:mm:ss', new Date());
  }
  return parse(String(date), 'yyyy-MM-dd', new Date());
};

// accessors
const getStockValue = (d: CompanyChartPrice) => parseInt(d?.value ?? 0);

const defaultChart: CompanyChartPrice[] = [
  {
    date: 0,
    formatted_date: format(subHours(new Date(), 1), 'yyyy-MM-dd HH:mm:ss'),
    value: '0',
    xlabel: 0,
  },
  {
    date: 0,
    formatted_date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    value: '0',
    xlabel: 0,
  },
];

const StockChart: React.FC<React.PropsWithChildren<StockChartProps>> = ({
  width,
  height,
  data,
  previous,
  status,
  period,
  suspended,
  symbol,
  withPadding,
  ipo,
  onMouseLeave,
}) => {
  const isEmpty = data.length === 0;

  const isOneData = data.length === 1;

  const chartData = useMemo(() => {
    if (isEmpty) {
      return defaultChart;
    }
    if (isOneData) {
      return [
        {
          date: 0,
          formatted_date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          value: previous,
          xlabel: 0,
        },
        ...data,
      ] as CompanyChartPrice[];
    }
    return data;
  }, [data, isEmpty, isOneData, previous]);

  const { showTooltip, hideTooltip, tooltipData, tooltipTop, tooltipLeft } =
    useTooltip<CompanyChartPrice>();

  const pathRef = useRef<SVGPathElement | null>(null);

  const color = {
    up: {
      gradient: ['#00AB6B', '#00AB6B'],
      lineColor: '#00AB6B',
    },
    down: {
      gradient: ['#EE4A49', '#EE4A49'],
      lineColor: '#EE4A49',
    },
    default: {
      gradient: ['#00AB6B', '#00AB6B'],
      lineColor: '#00AB6B',
    },
  }[isEmpty ? 'default' : status];

  useEffect(() => {
    hideTooltip();

    // Hide tooltip every data props changed
    // eslint-disable-next-line
  }, [chartData]);

  // bounds
  const xMax = withPadding ? width - 6 : width;
  const yMax = height;

  const minValue = min(chartData, getStockValue) || 0;

  const maxValue = max(chartData, getStockValue) || 0;

  // used for show label minimal value
  const minValueIndex = minIndex(chartData, getStockValue);

  // used for show label maximal value
  const maxValueIndex = maxIndex(chartData, getStockValue);

  // scales
  const dateScale = useMemo(
    () =>
      scaleBand({
        range: [withPadding ? 6 : 0, xMax],
        paddingInner: 1,
        paddingOuter: 0,
        align: 0,
        domain: chartData.map((_data, i) => i),
      }),
    [xMax, chartData, withPadding]
  );

  const stockValueScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax - 30, 30],
        domain: [
          Math.min(minValue || 0, previous || 0),
          Math.max(maxValue || 0, previous || 0),
        ],
        nice: true,
      }),
    [yMax, minValue, maxValue, previous]
  );

  const handleOnMouseLeave = (
    event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    hideTooltip();

    if (onMouseLeave) {
      onMouseLeave(event);
    }
  };

  // tooltip handler
  const handleTooltip = (
    event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    // get the coordinate x from mouse event
    const { x } = localPoint(event) || { x: 0 };

    // get xScale value from the coodinate x
    const index = Math.round(x / dateScale.step());

    if (index < 0 || index > data.length) {
      return;
    }

    const chartDataByIndex = chartData[index];

    const tooltipTop = stockValueScale(getStockValue(chartDataByIndex));

    showTooltip({
      tooltipData: chartDataByIndex,
      tooltipLeft: dateScale(index),
      tooltipTop: tooltipTop || 0,
    });
  };

  // normalize label when the position is below 0 and over device width
  const normalizePositionLabel = useCallback(
    (value: number, labelWidth: number = 30) => {
      if (value + labelWidth > width) {
        return value - 26;
      }
      if (value - labelWidth < 0) {
        return value + 26;
      }
      return value;
    },
    [width]
  );

  const generatePath = useMemo(
    () =>
      chartData.map((d, i) => ({
        x: dateScale(i) || 0,
        y: stockValueScale(getStockValue(d)) || 0,
      })),
    [chartData, dateScale, stockValueScale]
  );

  const isPreviousLineOverlap = useMemo(() => {
    const fontSize = 15;
    const minLabelPosition = [
      (stockValueScale(getStockValue(chartData[minValueIndex])) || 0) + 20,
      normalizePositionLabel(dateScale(minValueIndex) || 1),
    ];
    const maxLabelPosition = [
      (stockValueScale(getStockValue(chartData[maxValueIndex])) || 0) - 10,
      normalizePositionLabel(dateScale(maxValueIndex) || 1),
    ];

    if (!previous) return 0;

    const previousLinePosition = stockValueScale(previous) || 0;

    if (
      previousLinePosition > minLabelPosition[0] - fontSize &&
      previousLinePosition < minLabelPosition[0]
    ) {
      return minValueIndex;
    }

    if (
      previousLinePosition > maxLabelPosition[0] - fontSize &&
      previousLinePosition < maxLabelPosition[0]
    ) {
      return maxValueIndex;
    }

    return null;
  }, [
    minValueIndex,
    maxValueIndex,
    previous,
    chartData,
    dateScale,
    normalizePositionLabel,
    stockValueScale,
  ]);

  if (ipo) {
    return (
      <div
        className={styles['stock-chart-ipo']}
        style={{ paddingLeft: withPadding ? 6 : 0 }}
      >
        <img src={IpoEmptyState} alt='Saham dalam Proses e-IPO' />
        <div>Saham dalam Proses e-IPO</div>
      </div>
    );
  }

  return (
    <div className={styles['stock-chart']}>
      <svg width={width} height={height}>
        <linearGradient id='area-gradient' x1='0' y1='0' x2='0' y2='1'>
          <stop offset='0%' stopColor={color.gradient[0]} stopOpacity='0.2' />
          <stop offset='70%' stopColor={color.gradient[0]} stopOpacity='0.1' />
          <stop offset='100%' stopColor={color.gradient[1]} stopOpacity='0' />
        </linearGradient>
        <AreaClosed<ISimplifyObjectPoint>
          data={generatePath}
          key={`area-${period}`}
          x={(d) => d.x}
          y={(d) => d.y}
          yScale={stockValueScale}
          strokeWidth={1}
          stroke={'url(#area-gradient)'}
          fill={'url(#area-gradient)'}
          curve={curveMonotoneX}
        />

        <LinePath
          innerRef={pathRef}
          key={`line-${period}`}
          data={generatePath}
          x={(d) => d.x}
          y={(d) => d.y}
          strokeWidth={2.5}
          stroke={color.lineColor}
          curve={curveMonotoneX}
        />

        {/* previous line */}
        {isNumber(previous) &&
          // if previous line overlap with label
          // use double lines to avoid overlap
          // first line draw from x=0 to x=label's x position
          // second line draw from x=label's x position to x=max width container
          (isPreviousLineOverlap ? (
            <>
              <Line
                from={{ x: 0, y: stockValueScale(previous || 0) }}
                to={{
                  x:
                    normalizePositionLabel(
                      dateScale(isPreviousLineOverlap) || 1
                    ) - 20,
                  y: stockValueScale(previous || 0),
                }}
                stroke={'#969696'}
                strokeWidth={1}
                pointerEvents='none'
                strokeDasharray='2,5'
                className={styles['animation-fade']}
              />

              <Line
                from={{
                  x:
                    normalizePositionLabel(
                      dateScale(isPreviousLineOverlap) || 1
                    ) + 20,
                  y: stockValueScale(previous || 0),
                }}
                to={{ x: xMax, y: stockValueScale(previous || 0) }}
                stroke={'#969696'}
                strokeWidth={1}
                pointerEvents='none'
                strokeDasharray='2,5'
                className={styles['animation-fade']}
              />
            </>
          ) : (
            <Line
              from={{ x: 0, y: stockValueScale(previous || 0) }}
              to={{ x: xMax, y: stockValueScale(previous || 0) }}
              stroke={'#969696'}
              strokeWidth={1}
              pointerEvents='none'
              strokeDasharray='2,5'
              className={styles['animation-fade']}
            />
          ))}
        {/* Lowest value's label */}
        {!suspended && !isEmpty && (
          <text
            fill='#B5B5B5'
            fontSize={12}
            textAnchor='middle'
            className={classnames(styles['animation-fade'], {
              hide: !!tooltipData,
              show: !tooltipData,
            })}
            y={
              (stockValueScale(getStockValue(chartData[minValueIndex])) || 0) +
              20
            }
            x={normalizePositionLabel(dateScale(minValueIndex) || 0)}
          >
            {symbol !== 'ihsg' ? 'Rp' : ''}
            {numberToGeneralValueFormat(minValue, {
              thousandSeparated: true,
            })}
          </text>
        )}
        {/* Highest value's label */}
        {!suspended && !isEmpty && (
          <text
            fill='#B5B5B5'
            fontSize={12}
            textAnchor='middle'
            className={classnames(styles['animation-fade'], {
              hide: !!tooltipData,
              show: !tooltipData,
            })}
            y={
              (stockValueScale(getStockValue(chartData[maxValueIndex])) || 0) -
              10
            }
            x={normalizePositionLabel(dateScale(maxValueIndex) || 1)}
          >
            {symbol !== 'ihsg' ? 'Rp' : ''}
            {numberToGeneralValueFormat(maxValue, {
              thousandSeparated: true,
            })}
          </text>
        )}

        <Bar
          x={0}
          y={0}
          width={width}
          height={height}
          fill='transparent'
          rx={14}
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onTouchEnd={handleOnMouseLeave}
          onMouseMove={handleTooltip}
          onMouseLeave={handleOnMouseLeave}
        />
        {!isEmpty &&
          tooltipData &&
          !Array.isArray(tooltipTop) &&
          !Array.isArray(tooltipLeft) && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: 20 }}
                to={{ x: tooltipLeft, y: yMax }}
                stroke={color.lineColor}
                strokeWidth={1}
                pointerEvents='none'
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={color.lineColor}
                pointerEvents='none'
              />
            </g>
          )}
      </svg>
      {!isEmpty &&
        tooltipData &&
        !Array.isArray(tooltipData) &&
        !Array.isArray(tooltipLeft) && (
          <Tooltip top={0} left={tooltipLeft || 0}>
            <Text type={'caption1'} color='#b5b5b5'>
              {period === '1d'
                ? format(
                    new Date(parseDate(tooltipData.formatted_date)),
                    'HH:mm'
                  )
                : format(
                    new Date(parseDate(tooltipData.formatted_date)),
                    'dd MMM yyyy'
                  )}
            </Text>
          </Tooltip>
        )}
      <div
        className={styles['background-animation']}
        style={{
          width,
          height,
        }}
      />
    </div>
  );
};

export default StockChart;
