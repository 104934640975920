export const SORT_BY_ENUM = ['2', '3', '4', '5', '6', '7', '8', '9'];
export const SORT_PERIOD_ENUM = ['5y', '3y', '1y', '3m', '1m', '1d', 'ytd'];
export const SORT_ORDER_ENUM = ['asc', 'desc'];

export const IS_INSTANT_REDEMPTION_ENUM = ['1', '0'];
export const IS_SYARIAH_ENUM = ['1', '0'];

export const SORT_BY_PARAM_KEY = 'sort_by';
export const SORT_PERIOD_PARAM_KEY = 'sort_period';
export const SORT_ORDER_PARAM_KEY = 'sort_order';

export const IS_INSTANT_REDEMPTION_PARAM_KEY = 'is_instant_redemption';
export const IS_TOP_COMPANY_PARAM_KEY = 'is_top_product';
export const IS_HIGH_RETURN = 'is_highest_return';
export const BANK_ID_PARAM_KEY = 'bank_id';
export const IS_SYARIAH_PARAM_KEY = 'is_syariah';
export const META_PARAM_KEY = 'meta';
export const IM_PARAM_KEY = 'im';
export const MAX_BUY_PARAM_KEY = 'max_buy';

export const FLAG_RETURN_PERIOD = 'flag_return_period';
