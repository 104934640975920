export const flagKeys = {
  web_bibit_recap: false,
  web_catatan_cuan: false,
  web_jago_linking: false,
  web_jago_coregist: false,
  web_sbn: false,
  web_jago_instant_redemption: false,
  web_linkaja_applink: false,
  web_bibit_darkmode: false,
  web_new_instant_redemption: false,
  web_bibit_junior: false,
  web_bibit_sso: false,
  web_idle_timeout: false,
  web_bibit_premium_bisnis_banner: false,
  web_bibit_appcheck: false,
  web_bibit_sbn_skip_tnc: false,
  web_bibit_convenience_fee_animation: false,
  web_bibit_convenience_fee_payment: false,
  web_jago_snap: false,
  web_jago_snap_2: false,
  web_show_performance_chart: false,
  web_cashback_referral: false,
  web_bibit_auto_rutin: false,
  web_bibit_portfolio_risk: false,
  web_bibit_flexible_sip: false,
  web_bibit_register_with_stockbit: false,
  web_multilanguange: false,
  web_show_notifications: false,
  web_bibit_sip_saham: false,
};

export type FlagKeys = keyof typeof flagKeys;

export type FlagKeyValue = boolean | { overrideValue: boolean };

export type FlagKeysObject = Record<FlagKeys, FlagKeyValue>;

export default flagKeys as FlagKeysObject;
