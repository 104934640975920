import storage from 'core/Storage';
import {
  COLOR_BAR_DEFAULT,
  STATUS_BAR_COLOR_KEY,
} from 'core/statusbar/constants';
import { useStatusBarFunction } from 'core/statusbar/context/StatusBarContext';
import { postDataSetAppBottomBarColor } from 'core/statusbarbottom';
import { ThemeTypes } from 'core/theme/ThemeProvider';
import { mappedColors } from 'core/theme/mapped-color';
import { isInsideNativeContainer } from 'utils/validator';

const disableChangeStatusBar = () => {
  const list = [
    '/giftcard/detail',
    '/bibitplus-list',
    '/institution/tutorial',
    '/categorization/stock',
  ];

  return list.some((data) => !!window.location.pathname.match(data));
};

const getColorHex = (value: string, theme: 'dark' | 'light') => {
  const selectedColor = mappedColors[theme] as any;

  const colorVariable = Object.keys(selectedColor.colors).find(
    (data) => selectedColor.colors[data]?.toLowerCase() === value?.toLowerCase()
  );

  const color = mappedColors[theme === 'light' ? 'dark' : 'light'] as any;

  if (!colorVariable) return color['surface'];

  return color.colors[colorVariable];
};

export const useHandleStatusBar = () => {
  const { onSetStatusBarColor } = useStatusBarFunction();

  const handleStatusBar = async (theme: ThemeTypes) => {
    // disable change status bar when user on giftcard detail page
    if (disableChangeStatusBar()) return;
    if (!isInsideNativeContainer()) return;

    let currentStatusBar = await storage.getObject(STATUS_BAR_COLOR_KEY);
    const defaultColor = COLOR_BAR_DEFAULT(theme);

    if (!currentStatusBar) {
      currentStatusBar = {
        backgroundColor: defaultColor.top.bgColor,
        foregroundColor: defaultColor.top.foregroundColor,
      };
    }

    // @ set color bottom
    postDataSetAppBottomBarColor(
      defaultColor.bottom.bgColor,
      defaultColor.bottom.foregroundColor
    );

    if (currentStatusBar.backgroundColor === '#124a35') {
      onSetStatusBarColor({
        backgroundColor: '#124a35',
        foregroundColor: 'light',
      });
      return;
    }

    // @ set color status bar
    onSetStatusBarColor({
      backgroundColor: getColorHex(
        currentStatusBar.backgroundColor,
        theme === 'light' ? 'dark' : 'light'
      ),
      foregroundColor: defaultColor.top.foregroundColor,
    });
  };

  return {
    handleStatusBar,
  };
};
