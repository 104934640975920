import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import {
  actionType,
  CompanyChartParameter,
  PeriodType,
  ReportType,
} from '../types';

import {
  addWatchlist,
  getCompanyStats,
  getCompanyDetail,
  getStockPortfolioBySymbol,
  getRdnDetail,
  getRdnCashOnHand,
  getStockFee,
  postStockBuy,
  postStockBuyPreview,
  postStockBuyAmend,
  postStockBuyAmendPreview,
  postStockSell,
  postStockSellPreview,
  postStockSellAmend,
  postStockSellAmendPreview,
  postCancelStockBuy,
  postCancelStockSell,
  getStockCompaniesSymbolCorpAction,
  getStockCompanyChart,
  getCompaniesRelated,
  getCompanyExerciseable,
  postStockExercise,
  postStockExercisePreview,
  getOrderBookBySymbol,
  getCompanyKeyStats,
  getFinancialStatement,
  getFinancialStatementChart,
  getStockCategorizationDetailById,
} from './services';
import { useAuth, useProfileData } from 'hooks';

export const useFetchCompanyDetail = (
  symbol: string,
  enable: boolean = true
) => {
  return useQuery(['Company Detail', symbol], () => getCompanyDetail(symbol), {
    enabled: enable,
  });
};

export const useFetchCompanyDetailStats = (
  symbol: string,
  enable: boolean = true
) => {
  return useQuery(
    ['Company Detail Stats', symbol],
    () => getCompanyStats(symbol),
    {
      enabled: enable,
    }
  );
};

export const useAddWatchlist = () => {
  return useMutation(
    ({
      companyID,
      isFavorited,
    }: {
      companyID: string | number;
      isFavorited: 0 | 1;
    }) => addWatchlist({ is_favorited: isFavorited, company_id: companyID })
  );
};

export const useFetchStockPortfolioBySymbol = (
  symbol: string,
  enabled = true
) => {
  return useQuery(
    ['Stock Portfolio Symbol', symbol],
    () => getStockPortfolioBySymbol(symbol),
    {
      enabled,
      cacheTime: 0,
    }
  );
};

export const useFetchRdnDetail = (enable: boolean = true) => {
  const { isLogin } = useAuth();
  const { data: profile } = useProfileData(isLogin);

  // fetch RDN when the user upgrade to Bibit Plus is finished
  return useQuery(['RDN Detail'], getRdnDetail, {
    enabled: profile?.data?.data?.upgrade_status === 4 && enable,
  });
};

export const useFetchRdnCashOnHand = (enable: boolean = true) => {
  return useQuery(['RDN Cash On Hand'], getRdnCashOnHand, {
    enabled: enable,
  });
};

export const useFetchStockFee = (enable: boolean = true) => {
  return useQuery(['Stock Fee'], getStockFee, {
    enabled: enable,
  });
};

export const usePostStockBuy = () => {
  return useMutation(postStockBuy);
};

export const usePostStockBuyPreview = () => {
  return useMutation(postStockBuyPreview);
};

export const usePostStockBuyAmend = () => {
  return useMutation(postStockBuyAmend);
};

export const usePostStockBuyAmendPreview = () => {
  return useMutation(postStockBuyAmendPreview);
};

export const useCancelStockBuy = () => {
  return useMutation(postCancelStockBuy);
};

export const usePostStockSell = () => {
  return useMutation(postStockSell);
};

export const usePostStockSellPreview = () => {
  return useMutation(postStockSellPreview);
};

export const usePostStockSellAmend = () => {
  return useMutation(postStockSellAmend);
};

export const usePostStockSellAmendPreview = () => {
  return useMutation(postStockSellAmendPreview);
};

export const usePostStockExercise = () => {
  return useMutation(postStockExercise);
};

export const usePostStockExercisePreview = () => {
  return useMutation(postStockExercisePreview);
};

export const useCancelStockSell = () => {
  return useMutation(postCancelStockSell);
};

export const useFetchStockCompaniesSymbolCorpAction = (
  params: {
    symbol: string;
    nextCursor?: string;
    body: {
      type: string;
    };
  },
  enabled: boolean = true
) => {
  return useInfiniteQuery(
    ['Stock Corp Action', params.symbol, params.body?.type],
    ({ pageParam }) =>
      getStockCompaniesSymbolCorpAction(params.symbol, {
        ...params.body,
        page: pageParam || 1,
      }),
    {
      enabled,
      getNextPageParam: (lastPage, pages) => {
        const maxPage = lastPage?.data?.meta?.max_page;
        const nextPage = pages.length + 1;
        if (nextPage > maxPage) return;

        return nextPage;
      },
    }
  );
};

export const useFetchCompanyChart = (
  props: CompanyChartParameter,
  enabled: boolean = true
) => {
  return useQuery(
    ['Stock Company Chart', props.symbol, props?.period],
    () => getStockCompanyChart(props),
    {
      enabled,
    }
  );
};

export const useFetchCompaniesRelated = (
  symbol: string,
  enabled: boolean = true
) => {
  return useQuery(
    ['Stock Related Companies ', symbol],
    () => getCompaniesRelated(symbol),
    {
      enabled,
    }
  );
};

export const useFetchCompanyExerciseable = () => {
  return useMutation(
    ({
      symbol,
      body,
    }: {
      symbol: string;
      body: {
        type: actionType;
      };
    }) => {
      return getCompanyExerciseable(symbol, body);
    }
  );
};

export const useFetchOrderBook = (symbol: string) => {
  return useQuery(['Stock order book', symbol], () =>
    getOrderBookBySymbol(symbol)
  );
};

export const useFetchCompanyKeystats = (symbol: string) => {
  return useQuery(['Stock Company Key Stats', symbol], () =>
    getCompanyKeyStats(symbol)
  );
};

export const useFetchFinancialStatement = (
  symbol: string,
  report_type: ReportType,
  period: PeriodType,
  lang: 'id' | 'en' = 'id',
  enabled: boolean = true
) => {
  return useQuery(
    ['Stock Financial Statement', symbol, report_type, period],
    () => getFinancialStatement(symbol, report_type, period, lang),
    {
      enabled,
    }
  );
};

export const useFetchFinancialStatementChart = (
  symbol: string,
  report_type: ReportType,
  period: PeriodType,
  enabled: boolean = true
) => {
  return useQuery(
    ['Stock Financial Statement Chart', symbol, report_type, period],
    () => getFinancialStatementChart(symbol, report_type, period),
    {
      enabled,
    }
  );
};

export const useFetchGetStockCategorizationDetailById = (
  id: number,
  page: number,
  limit: number,
  sort_by: string | undefined,
  sort_column: string | undefined,
  enabled: boolean = true
) => {
  return useInfiniteQuery(
    ['Get Data Stock Categorization Detail By ID', sort_by, sort_column, id],
    () => {
      const param = {
        page,
        limit,
        v: 2,
      };

      if (sort_by && sort_column) {
        Object.assign(param, { sort_by, sort_column });
      }

      return getStockCategorizationDetailById(id, param);
    },
    {
      enabled,
      cacheTime: 0,
      getNextPageParam: (lastPage: any) => {
        const nextPage = lastPage.data.meta.current_page;
        if (!nextPage) return false;

        return nextPage;
      },
    }
  );
};
