import { useStorageCurrentInstutiton } from 'features/institution/hooks';
import { useQuery, useMutation, UseQueryOptions } from 'react-query';
import {
  getPortfolioCategory,
  getPortfolio,
  getPortfolioAllocationSummary,
  getPortfolioAllocation,
  getPortfolioAllocationDetail,
  getPortfolioGoalOnTrack,
  getPortfolioGoalDetail,
  getPortfolioSwitchProducts,
  getPortfolioGoalProgressChart,
  getPortfolioRecurringListByPorto,
  patchRearrangePortfolio,
  getPortfolioCouponHistories,
  getPortfolioPerformance,
  getPortfolioAssetClasses,
  getPortfolioRealizedProfitStats,
  getPortfolioRisk,
  getRebalanceAmount,
} from './services';
import endpoints from './endpoints';

export const useFetchPortfolioCategoryId = (
  categoryId: string,
  enabled = true
) => {
  const { data, isLoading } = useStorageCurrentInstutiton();
  const inInstitutionAccount = !!data?.institution_id;

  const isCategoryIdUndefined = categoryId === 'undefined';

  const eligibleToFetch =
    enabled &&
    !inInstitutionAccount &&
    !isLoading &&
    !!categoryId &&
    !isCategoryIdUndefined;

  return useQuery(
    ['Portfolio Category Detail', categoryId],
    () => getPortfolioCategory(categoryId),
    { enabled: eligibleToFetch }
  );
};

export const KEY_PORTFOLIO_LIST = 'Portfolio List';

export const useFetchPortfolio = (enabled = true) => {
  const { data } = useStorageCurrentInstutiton();
  const inInstitutionAccount = !!data?.institution_id;

  return useQuery(
    [KEY_PORTFOLIO_LIST],
    () => getPortfolio().then((item) => item?.data?.data),
    {
      enabled: enabled && !inInstitutionAccount,
      // staleTime: 1 * 60_000, // 1 mins
    }
  );
};

export const useFetchHistoriesCoupons = (
  orderCodeOrSymbol: object,
  enable: boolean | undefined
) => {
  return useQuery(
    ['Histories Coupons', orderCodeOrSymbol],
    () =>
      getPortfolioCouponHistories(orderCodeOrSymbol).then((item) =>
        Object.assign(item?.data?.data || {})
      ),
    { enabled: enable }
  );
};

export const useFetchPortfolioAllocationSummary = (enabled = true) => {
  return useQuery(
    ['Portfolio Allocation Summary'],
    () => getPortfolioAllocationSummary().then((item) => item?.data?.data),
    {
      enabled,
    }
  );
};

export const useFetchPortfolioAllocation = (
  type: string,
  filter: string = '',
  hidePorto = false,
  enabled = true
) => {
  return useQuery(
    ['Portfolio Allocation', type, filter, hidePorto],
    () =>
      getPortfolioAllocation(type, filter).then((item) =>
        Object.assign(item?.data?.data || {}, { meta: item?.data?.meta })
      ),
    {
      enabled,
    }
  );
};

export const useFetchPortfolioAllocationDetail = (
  symbol: string,
  enabled = true
) => {
  return useQuery(
    ['Portfolio Allocation Detail', symbol],
    () => getPortfolioAllocationDetail(symbol).then((item) => item?.data?.data),
    {
      enabled,
    }
  );
};

export const useFetchPortfolioGoalOnTrack = (
  categoryId: string,
  enabled?: boolean
) => {
  return useQuery(
    ['Portfolio Goal On Track', categoryId],
    () => getPortfolioGoalOnTrack(categoryId),
    { enabled }
  );
};

export const useFetchPortfolioGoalDetail = (
  categoryId: string,
  enabled?: boolean
) => {
  return useQuery(
    ['Portfolio Goal Detail', categoryId],
    () => getPortfolioGoalDetail(categoryId),
    { enabled }
  );
};

export const useFetchPortfolioGoalProgressChart = (
  categoryId: string,
  enabled?: boolean
) => {
  return useQuery(
    ['Portfolio Goal Progress Chart', categoryId],
    () => getPortfolioGoalProgressChart(categoryId),
    { enabled }
  );
};

export const useFetchPortfolioSwitchProducts = (
  symbol: string,
  categoryId: string,
  is_robo: boolean,
  is_sharia: number,
  enabled: boolean
) => {
  return useQuery(
    ['Get Portfolio switch product', symbol],
    () => getPortfolioSwitchProducts(symbol, categoryId, is_robo, is_sharia),
    { enabled }
  );
};

export const useFetchRecurringListByPorto = (
  categoryId: string,
  enabled?: boolean
) => {
  return useQuery(
    ['Portfolio Recurring List By Porto'],
    () => getPortfolioRecurringListByPorto(categoryId),
    { enabled }
  );
};

export const usePatchRearrangePortfolio = () => {
  return useMutation(['patch-rearrange-portfolio'], patchRearrangePortfolio);
};

export const useFetchPortfolioPerformance = (
  pathKey: string = '',
  enabled = true
) => {
  return useQuery(
    ['Portfolio Performance', pathKey],
    () => getPortfolioPerformance().then((item) => item?.data?.data),
    {
      enabled,
    }
  );
};

export const useFetchPortfolioAssetClasses = (enabled = true) => {
  return useQuery(
    ['Portfolio Asset Classes'],
    () => getPortfolioAssetClasses().then((item) => item?.data?.data),
    { enabled }
  );
};

export const useFetchPortfolioRealizedProfitStats = (enabled = true) => {
  return useQuery(
    ['Portfolio Realized Profit Stats'],
    () => getPortfolioRealizedProfitStats().then((item) => item?.data?.data),
    { enabled }
  );
};
export const KEY_PORTFOLIO_RISK = endpoints.portfolioRisk;

export const useGetPortfolioRisk = ({ enabled }: UseQueryOptions = {}) => {
  return useQuery([KEY_PORTFOLIO_RISK], () => getPortfolioRisk(), {
    enabled,
  });
};

export const useGetRebalanceAmount = ({
  productType,
  symbol,
  shouldPrefilled = false,
}: {
  productType: 'FR' | 'SE';
  symbol: string;
  shouldPrefilled: boolean;
} & UseQueryOptions) => {
  return useQuery(
    [endpoints.calculateRebalanceAmount(productType, symbol), shouldPrefilled],
    () => getRebalanceAmount({ productType, symbol }),
    {
      enabled: !!productType && !!symbol && shouldPrefilled,
    }
  );
};
