import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Check, X } from 'react-feather';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionToast from './toast.reducer';
import './Toast.css';
import history from 'utils/History';

/**
 * @todo need refactor this toast
 */
class Toast extends Component {
  handleRedirect = () => {
    const { toggleToastBottom, type } = this.props;
    toggleToastBottom({
      visible: false,
      type,
      textMessage: '',
      bottomPosition: '',
      withIcon: false,
      redirectLink: '',
      redirectButtonMessage: '',
    });
    history.push(this.props.redirectLink);
  };

  handleClose = () => {
    const { toggleToastBottom, onClose } = this.props;

    onClose && onClose();
    toggleToastBottom({
      ...this.props,
      visible: false,
    });
  };

  render() {
    const {
      textMessage,
      visibleToast,
      type,
      bottomPosition,
      withIcon,
      withCloseButton,
      redirectLink,
      redirectButtonMessage,
    } = this.props;

    return ReactDOM.createPortal(
      <div
        data-testid='toast-info'
        style={{ bottom: visibleToast ? bottomPosition : 0 }}
        className={classNames('toastSlideUp', {
          'move-up-toast': visibleToast,
          red: type === 'error',
          blue: type === 'success',
          lightgreen: type === 'lightgreensuccess',
          black: type === 'blacksuccess',
        })}
      >
        <div>
          {withIcon && (
            <span>
              {type === 'error' && <X color='#fff' size='12' />}
              {type === 'success' && <Check color='#fff' size='12' />}
              {type === 'lightgreensuccess' && (
                <Check color='#00AB6B' size='16' />
              )}
              {type === 'blacksuccess' && <Check color='#FFFFFF' size='14' />}
            </span>
          )}
          {textMessage}
        </div>
        {redirectLink && (
          <div
            onClick={this.handleRedirect}
            className={classNames('toast-redirect-link')}
          >
            {redirectButtonMessage}
          </div>
        )}
        {withCloseButton && (
          <div onClick={this.handleClose} className='toast-close-button'>
            <X color='#969696' size={16} />
          </div>
        )}
      </div>,
      document.body
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(actionToast, dispatch);
const mapStateToProps = (state) => {
  return {
    visibleToast: state.toast.visibleToast,
    textMessage: state.toast.textMessage,
    type: state.toast.type,
    bottomPosition: state.toast.bottomPosition,
    withIcon: state.toast.withIcon,
    withCloseButton: state.toast.withCloseButton,
    redirectLink: state.toast.redirectLink,
    redirectButtonMessage: state.toast.redirectButtonMessage,
    onClose: state.toast.onClose,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
