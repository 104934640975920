import React, { useEffect } from 'react';
import { Image, Wrapper } from './MaintenanceBanner.styled';
import { MaintenanceBannerProps } from './types';
import Description from './components/Description';
import Analytics from 'utils/Analytics';
import MinispotMaintenance from 'features/stock/assets/MinispotMaintenance/MinispotMaintenance';

const MaintenanceBanner: React.FC<MaintenanceBannerProps> = ({
  time,
  type,
}) => {
  useEffect(() => {
    Analytics.logEventNavigation({
      eventName: 'navigate',
      parameter: {
        page: 'fr_bond',
        view: 'fr_maintenance_banner',
      },
    });
  }, []);

  return (
    <Wrapper>
      <Image>
        <MinispotMaintenance alt='maintenance' />
      </Image>
      <Description time={time} type={type} />
    </Wrapper>
  );
};

export default MaintenanceBanner;
