import React, { useContext, useState } from 'react';
import { StockSellPreview } from 'features/stock/types';

export type StockSellStep = 'sell_preview' | 'sell_success' | 'sell_fee';

interface ContextState {
  sellLot: number;
  sellUnitPrice: number;
  stockSellModalStep?: StockSellStep;
  stockSellPreviewData?: StockSellPreview;
}

interface ContextFunction {
  setSellLot: (lot: number) => void;
  setSellUnitPrice: (price: number) => void;
  setStockSellModalStep: (step?: StockSellStep) => void;
  setStockSellPreviewData: (data: StockSellPreview) => void;
}

const StockSellContextState = React.createContext<ContextState | undefined>(
  undefined
);
const StockSellContextFunction = React.createContext<
  ContextFunction | undefined
>(undefined);

const StockSellContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  /**
   * total lot that user want to order
   */
  const [sellLot, setSellLot] = useState(0);

  /**
   * price offered by users
   */
  const [sellUnitPrice, setSellUnitPrice] = useState(0);

  /**
   * determine the modal that must be shown
   */
  const [stockSellModalStep, setStockSellModalStep] = useState<
    StockSellStep | undefined
  >();

  const [stockSellPreviewData, setStockSellPreviewData] = useState<
    StockSellPreview | undefined
  >(undefined);

  return (
    <StockSellContextState.Provider
      value={{
        sellLot,
        sellUnitPrice,
        stockSellModalStep,
        stockSellPreviewData,
      }}
    >
      <StockSellContextFunction.Provider
        value={{
          setSellLot,
          setSellUnitPrice,
          setStockSellModalStep,
          setStockSellPreviewData,
        }}
      >
        {children}
      </StockSellContextFunction.Provider>
    </StockSellContextState.Provider>
  );
};

export default StockSellContextProvider;

export const useStockSellContextState = () => {
  const ctx = useContext(StockSellContextState);

  if (!ctx) {
    throw new Error('StockSellContextState out of boundary');
  }

  return ctx;
};

export const useStockSellContextFunction = () => {
  const ctx = useContext(StockSellContextFunction);

  if (!ctx) {
    throw new Error('StockSellContextFunction out of boundary');
  }

  return ctx;
};
