import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ThemeProvider as Provider } from '@bibitid/uikit-v1';
import { mappedColors } from './mapped-color';
import { ThemeColor } from './mapped-color.type';
import storage from 'core/Storage';
import { getTheme, isNotSupportSystemSettingVersion } from 'utils/theme';
import { useHandleStatusBar } from 'features/profile/darkmode/hooks/useHandleStatusBar';
import useDeviceInfo from 'hooks/useDeviceInfo';

export type ThemeTypes = 'light' | 'dark';
export type StorageThemeTypes = 'light' | 'dark' | 'system';
export type DeviceSetting = 'system' | null;

// Temporary THeme

type themeContextTypes = {
  theme: ThemeTypes;
  setTheme: (val: ThemeTypes) => void;
  deviceSetting: DeviceSetting;
  setDeviceSetting: (val: DeviceSetting) => void;
  getDarkmodeHex: (value: string) => string;
};
const noop = () => {};

export const ThemeContext = React.createContext<themeContextTypes>({
  theme: 'light',
  setTheme: noop,
  deviceSetting: 'system',
  setDeviceSetting: noop,
  getDarkmodeHex: (value: string) => '#ffffff',
});
// Get the hexadecimal color value for dark mode by converting the light mode value
export const getDarkmodeHex = (value: string) => {
  const selectedColor = mappedColors['light'] as any;
  const theme = getTheme();

  if (theme === 'light') return value;

  const colorVariable = Object.keys(selectedColor.colors).find(
    (data) => selectedColor.colors[data].toLowerCase() === value.toLowerCase()
  );

  const darkColor = mappedColors['dark'] as any;

  if (!colorVariable) return darkColor['surface'];

  return darkColor.colors[colorVariable];
};
/**
 * Wrapper ThemeProvider from UIKIT-V1
 *
 * TODO: might be a better coding pattern for this
 * ? please advice if you have any improvement from the uikit-v1 side
 */
const ThemeProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { data: deviceInfo } = useDeviceInfo();

  const bibitThemeStorage = window.localStorage.getItem(
    'bibit-theme'
  ) as StorageThemeTypes;
  const { handleStatusBar } = useHandleStatusBar();
  const isNotSupportSystemSettings =
    isNotSupportSystemSettingVersion(deviceInfo);

  const [theme, setTheme] = useState<ThemeTypes>('light');
  const [deviceSetting, setDeviceSetting] = useState<DeviceSetting>(
    bibitThemeStorage === 'system' ? 'system' : null
  );

  const bibiThemeSetStorage = async (value: StorageThemeTypes) => {
    await storage.set('bibit-theme', value);
    window.localStorage.setItem('bibit-theme', value);
  };

  const updateMetaTagColorScheme = (value: StorageThemeTypes) => {
    const colorScheme = document.getElementsByName('color-scheme');
    const supportedColorScheme = document.getElementsByName(
      'supported-color-schemes'
    );
    if (!colorScheme[0] || !supportedColorScheme[0]) return;

    if (value === 'system') {
      colorScheme[0].setAttribute('content', 'light dark');
      supportedColorScheme[0].setAttribute('content', 'light dark');
      return;
    }
    colorScheme[0].setAttribute('content', value);
    supportedColorScheme[0].setAttribute('content', value);
  };

  const injectCSSVariable = (color: ThemeColor) => {
    return `
      --modal-surface: ${color.modal.surface};
      --modal-surface-transparent: ${color.modal.surface}00;
      --modal-surface-gray: ${color.modal.surfaceGray};
      --modal-highlight: ${color.modal.highlight};
      --modal-border: ${color.modal.border};
      --modal-divider-thin: ${color.modal.dividerThin};
      --modal-divider-thick: ${color.modal.dividerThick};

      --modal-green-low: ${color.colors.modalGreenLow};
      --modal-green-medium: ${color.colors.modalGreenMedium};
      --modal-green-high: ${color.colors.modalGreenHigh};
      --modal-green-default: ${color.colors.modalGreenDefault};

      --modal-teal-low: ${color.colors.modalTealLow};
      --modal-teal-medium: ${color.colors.modalTealMedium};
      --modal-teal-high: ${color.colors.modalTealHigh};
      --modal-teal-default: ${color.colors.modalTealDefault};

      --modal-aquamarine-low: ${color.colors.modalAquamarineLow};
      --modal-aquamarine-medium: ${color.colors.modalAquamarineMedium};
      --modal-aquamarine-high: ${color.colors.modalAquamarineHigh};
      --modal-aquamarine-default: ${color.colors.modalAquamarineDefault};

      --modal-olive-low: ${color.colors.modalOliveLow};
      --modal-olive-medium: ${color.colors.modalOliveMedium};
      --modal-olive-high: ${color.colors.modalOliveHigh};
      --modal-olive-default: ${color.colors.modalOliveDefault};

      --modal-cobalt-low: ${color.colors.modalCobaltLow};
      --modal-cobalt-medium: ${color.colors.modalCobaltMedium};
      --modal-cobalt-high: ${color.colors.modalCobaltHigh};
      --modal-cobalt-default: ${color.colors.modalCobaltDefault};

      --modal-periwinkle-low: ${color.colors.modalPeriwinkleLow};
      --modal-periwinkle-medium: ${color.colors.modalPeriwinkleMedium};
      --modal-periwinkle-high: ${color.colors.modalPeriwinkleHigh};
      --modal-periwinkle-default: ${color.colors.modalPeriwinkleDefault};

      --modal-violet-low: ${color.colors.modalVioletLow};
      --modal-violet-medium: ${color.colors.modalVioletMedium};
      --modal-violet-high: ${color.colors.modalVioletHigh};
      --modal-violet-default: ${color.colors.modalVioletDefault};

      --modal-magenta-low: ${color.colors.modalMagentaLow};
      --modal-magenta-medium: ${color.colors.modalMagentaMedium};
      --modal-magenta-high: ${color.colors.modalMagentaHigh};
      --modal-magenta-default: ${color.colors.modalMagentaDefault};

      --modal-peach-low: ${color.colors.modalPeachLow};
      --modal-peach-medium: ${color.colors.modalPeachMedium};
      --modal-peach-high: ${color.colors.modalPeachHigh};
      --modal-peach-default: ${color.colors.modalPeachDefault};

      --modal-red-low: ${color.colors.modalRedLow};
      --modal-red-medium: ${color.colors.modalRedMedium};
      --modal-red-high: ${color.colors.modalRedHigh};
      --modal-red-default: ${color.colors.modalRedDefault};

      --modal-blue-low: ${color.colors.modalBlueLow};
      --modal-blue-medium: ${color.colors.modalBlueMedium};
      --modal-blue-high: ${color.colors.modalBlueHigh};
      --modal-blue-default: ${color.colors.modalBlueDefault};

      --modal-navy-low: ${color.colors.modalNavyLow};
      --modal-navy-medium: ${color.colors.modalNavyMedium};
      --modal-navy-high: ${color.colors.modalNavyHigh};
      --modal-navy-default: ${color.colors.modalNavyDefault};

      --modal-brown-low: ${color.colors.modalBrownLow};
      --modal-brown-medium: ${color.colors.modalBrownMedium};
      --modal-brown-high: ${color.colors.modalBrownHigh};
      --modal-brown-default: ${color.colors.modalBrownDefault};

      --modal-orange-low: ${color.colors.modalOrangeLow};
      --modal-orange-medium: ${color.colors.modalOrangeMedium};
      --modal-orange-high: ${color.colors.modalOrangeHigh};
      --modal-orange-default: ${color.colors.modalOrangeDefault};

      --modal-yellow-low: ${color.colors.modalYellowLow};
      --modal-yellow-medium: ${color.colors.modalYellowMedium};
      --modal-yellow-high: ${color.colors.modalYellowHigh};
      --modal-yellow-default: ${color.colors.modalYellowDefault};

      --modal-gold-low: ${color.colors.modalGoldLow};
      --modal-gold-medium: ${color.colors.modalGoldMedium};
      --modal-gold-high: ${color.colors.modalGoldHigh};
      --modal-gold-default: ${color.colors.modalGoldDefault};

      --surface: ${color.surface};
      --surface-gray: ${color.surfaceGray};
      --highlight: ${color.highlight};
      --border: ${color.border};
      --divider-thin: ${color.dividerThin};
      --divider-thick: ${color.dividerThick};

      --text-primary: ${color.textPrimary};
      --text-secondary: ${color.textSecondary};
      --text-tertiary: ${color.textTertiary};
      --text-white: ${color.textWhite};

      --inactive: ${color.inactive};
      --inactive-button: ${color.inactiveButton};
      --icon-default: ${color.iconDefault};

      --green-default: ${color.colors.greenDefault};
      --green-low: ${color.colors.greenLow};
      --green-medium: ${color.colors.greenMedium};
      --green-high: ${color.colors.greenHigh};

      --red-default: ${color.colors.redDefault};
      --red-low: ${color.colors.redLow};
      --red-medium: ${color.colors.redMedium};
      --red-high: ${color.colors.redHigh};

      --blue-default: ${color.colors.blueDefault};
      --blue-low: ${color.colors.blueLow};
      --blue-medium: ${color.colors.blueMedium};
      --blue-high: ${color.colors.blueHigh};

      --navy-default: ${color.colors.navyDefault};
      --navy-low: ${color.colors.navyLow};
      --navy-medium: ${color.colors.navyMedium};
      --navy-high: ${color.colors.navyHigh};

      --brown-default: ${color.colors.brownDefault};
      --brown-low: ${color.colors.brownLow};
      --brown-medium: ${color.colors.brownMedium};
      --brown-high: ${color.colors.brownHigh};

      --orange-default: ${color.colors.orangeDefault};
      --orange-low: ${color.colors.orangeLow};
      --orange-medium: ${color.colors.orangeMedium};
      --orange-high: ${color.colors.orangeHigh};

      --yellow-default: ${color.colors.yellowDefault};
      --yellow-low: ${color.colors.yellowLow};
      --yellow-medium: ${color.colors.yellowMedium};
      --yellow-high: ${color.colors.yellowHigh};

      --gold-default: ${color.colors.goldDefault};
      --gold-low: ${color.colors.goldLow};
      --gold-medium: ${color.colors.goldMedium};
      --gold-high: ${color.colors.goldHigh};

      --teal-default: ${color.colors.tealDefault};
      --teal-low: ${color.colors.tealLow};
      --teal-medium: ${color.colors.tealMedium};
      --teal-high: ${color.colors.tealHigh};

      --aquamarine-default: ${color.colors.aquamarineDefault};
      --aquamarine-low: ${color.colors.aquamarineLow};
      --aquamarine-medium: ${color.colors.aquamarineMedium};
      --aquamarine-high: ${color.colors.aquamarineHigh};

      --olive-default: ${color.colors.oliveDefault};
      --olive-low: ${color.colors.oliveLow};
      --olive-medium: ${color.colors.oliveMedium};
      --olive-high: ${color.colors.oliveHigh};

      --cobalt-default: ${color.colors.cobaltDefault};
      --cobalt-low: ${color.colors.cobaltLow};
      --cobalt-medium: ${color.colors.cobaltMedium};
      --cobalt-high: ${color.colors.cobaltHigh};

      --periwinkle-default: ${color.colors.periwinkleDefault};
      --periwinkle-low: ${color.colors.periwinkleLow};
      --periwinkle-medium: ${color.colors.periwinkleMedium};
      --periwinkle-high: ${color.colors.periwinkleHigh};

      --violet-default: ${color.colors.violetDefault};
      --violet-low: ${color.colors.violetLow};
      --violet-medium: ${color.colors.violetMedium};
      --violet-high: ${color.colors.violetHigh};

      --magenta-default: ${color.colors.magentaDefault};
      --magenta-low: ${color.colors.magentaLow};
      --magenta-medium: ${color.colors.magentaMedium};
      --magenta-high: ${color.colors.magentaHigh};

      --peach-default: ${color.colors.peachDefault};
      --peach-low: ${color.colors.peachLow};
      --peach-medium: ${color.colors.peachMedium};
      --peach-high: ${color.colors.peachHigh};

      --fr-background: ${color.colors.FRBackground};
      --fr-skeleton: ${color.colors.FRSkeleton};


      --jago-default: ${color.colors.jagoDefault};
      --jago-low: ${color.colors.jagoLow};
      --jago-medium: ${color.colors.jagoMedium};
      --jago-high: ${color.colors.jagoHigh};

      --modal-jago-default: ${color.colors.modalJagoDefault};
      --modal-jago-low: ${color.colors.modalJagoLow};
      --modal-jago-medium: ${color.colors.modalJagoMedium};
      --modal-jago-high: ${color.colors.modalJagoHigh};

      --jago-syariah-default: ${color.colors.jagoSyariahDefault};
      --jago-syariah-low: ${color.colors.jagoSyariahLow};
      --jago-syariah-medium: ${color.colors.jagoSyariahMedium};
      --jago-syariah-high: ${color.colors.jagoSyariahHigh};

      --modal-jago-syariah-default: ${color.colors.modalJagoSyariahDefault};
      --modal-jago-syariah-low: ${color.colors.modalJagoSyariahLow};
      --modal-jago-syariah-medium: ${color.colors.modalJagoSyariahMedium};
      --modal-jago-syariah-high: ${color.colors.modalJagoSyariahHigh};

      --gopay-default: ${color.colors.gopayDefault};
      --gopay-low: ${color.colors.gopayLow};
      --gopay-medium: ${color.colors.gopayMedium};
      --gopay-high: ${color.colors.gopayHigh};

      --modal-gopay-default: ${color.colors.modalGopayDefault};
      --modal-gopay-low: ${color.colors.modalGopayLow};
      --modal-gopay-medium: ${color.colors.modalGopayMedium};
      --modal-gopay-high: ${color.colors.modalGopayHigh};

      --blibli-default: ${color.colors.blibliDefault};
      --blibli-low: ${color.colors.blibliLow};
      --blibli-medium: ${color.colors.blibliMedium};
      --blibli-high: ${color.colors.blibliHigh};

      --modal-blibli-default: ${color.colors.modalBlibliDefault};
      --modal-blibli-low: ${color.colors.modalBlibliLow};
      --modal-blibli-medium: ${color.colors.modalBlibliMedium};
      --modal-blibli-high: ${color.colors.modalBlibliHigh};

      --primary-skeleton: ${color.primarySkeleton};
      --gradient-skeleton: ${color.gradientSkeleton};



      --premium-default: ${color.colors.premiumDefault};
      --community: ${color.colors.community};
      --bibit-plus: ${color.colors.bibitPlus};
      --story-bibit-business: ${color.colors.storyBibitBusiness};
    `;
  };

  const handleSetThemeAndDataTheme = useCallback((value: ThemeTypes) => {
    // add to global document atttribute
    document.documentElement.setAttribute('data-theme', value);

    setTheme(value);
  }, []);

  useEffect(() => {
    const light = mappedColors['light'];
    const dark = mappedColors['dark'];

    // check if there's any style-tag exist
    let styleTag = document.getElementById('bibit-theme-variables');

    if (!styleTag) {
      styleTag = document.createElement('style');
      styleTag.setAttribute('id', 'bibit-theme-variables');
      styleTag.setAttribute('type', 'text/css');
    }

    // inject theme colors
    styleTag.innerHTML = `
        :root {
          ${injectCSSVariable(light)} 
        }
 
        [data-theme="dark"] {
          ${injectCSSVariable(dark)} 
        }
  `;
    const headTag = document.getElementsByTagName('head')[0];

    // @ set css to variable modal
    headTag.appendChild(styleTag);

    // add to global document atttribute
    document.documentElement.setAttribute('data-theme', theme);

    // Ignore dependency theme to avoid rerunning this effect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const colorSchemeQueryList = window.matchMedia(
      '(prefers-color-scheme: dark)'
    );

    const setColorScheme = (e: MediaQueryListEvent) => {
      if (isNotSupportSystemSettings) return;
      if (deviceSetting !== 'system') return;

      if (e.matches) {
        // Dark
        handleSetThemeAndDataTheme('dark');
        handleStatusBar('dark');
      } else {
        // Light
        handleSetThemeAndDataTheme('light');
        handleStatusBar('light');
      }
    };

    if (typeof colorSchemeQueryList?.addEventListener === 'function') {
      colorSchemeQueryList.addEventListener('change', setColorScheme);
    }

    if (deviceSetting === 'system') {
      const themeColorScheme = colorSchemeQueryList?.matches ? 'dark' : 'light';

      handleStatusBar(themeColorScheme);
      handleSetThemeAndDataTheme(themeColorScheme);
    }

    return () => {
      if (typeof colorSchemeQueryList?.removeEventListener === 'function') {
        colorSchemeQueryList.removeEventListener('change', setColorScheme);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceSetting, isNotSupportSystemSettings]);

  // Sync between latest theme mode by get value from localStorage
  useEffect(() => {
    const theme = window.localStorage.getItem('bibit-theme');

    if (!theme) {
      bibiThemeSetStorage('light');
      handleSetTheme('light');
      return;
    }

    if (theme === 'system') {
      handleDeviceSetting('system');
      return;
    }

    handleSetTheme(theme as ThemeTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetTheme = async (value: ThemeTypes) => {
    const deviceTheme = window.matchMedia('(prefers-color-scheme: dark)')
      ?.matches
      ? 'dark'
      : 'light';

    if (deviceTheme !== value) {
      setDeviceSetting(null);
    }
    await bibiThemeSetStorage(value);

    handleSetThemeAndDataTheme(value);
    updateMetaTagColorScheme(value);
  };

  const handleDeviceSetting = async (value: DeviceSetting) => {
    if (value === 'system') {
      await bibiThemeSetStorage('system');
      updateMetaTagColorScheme('system');
      setDeviceSetting('system');
      return;
    }

    const theme = getTheme();
    await bibiThemeSetStorage(theme);

    handleSetThemeAndDataTheme(theme);
    updateMetaTagColorScheme(theme);
    handleStatusBar(theme);

    setDeviceSetting(null);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme: theme,
        setTheme: handleSetTheme,
        deviceSetting: deviceSetting,
        setDeviceSetting: handleDeviceSetting,
        getDarkmodeHex: getDarkmodeHex,
      }}
    >
      <Provider theme={theme}>
        {/* <GlobalStyle /> */}
        {children}
      </Provider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
