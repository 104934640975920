import classNames from 'classnames';
import Puller from 'features/common/Puller';
import React from 'react';

const PullerReksadaProductList: React.FC<
  React.PropsWithChildren<{
    renderPuller: boolean;
    showPullToRefresh: boolean;
    isLoading: boolean;
    removePullerBox?: boolean;
    dataEmpty: boolean;
    classNamesPuller?: string;
    handlePullToRefresh: () => void;
  }>
> = ({
  children,
  renderPuller,
  showPullToRefresh,
  isLoading,
  removePullerBox,
  dataEmpty,
  classNamesPuller,
  handlePullToRefresh,
}) => {
  return (
    <>
      {!renderPuller ? (
        children
      ) : (
        <Puller
          className={classNames({
            'puller-box': isLoading || !removePullerBox || !dataEmpty,
            fullheight: true,
            [classNamesPuller ?? '']: !!classNamesPuller,
          })}
          onRefresh={handlePullToRefresh}
          disabled={!showPullToRefresh}
          removePullerBox={removePullerBox}
        >
          {children}
        </Puller>
      )}
    </>
  );
};

export default PullerReksadaProductList;
