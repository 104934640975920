import { useQuery } from 'react-query';
import queryClient from 'network/queryClient';

/**
 * hook helper to get app state
 * - active: the web view is currently in screen
 * - background: the web view is currently running in background
 */
const useAppState = () => {
  const { data } = useQuery(
    ['appState'],
    () => {
      return queryClient.getQueryData<'active' | 'background'>(['appState'], {
        exact: true,
      });
    },
    { staleTime: Infinity }
  );
  return data || 'active';
};

export default useAppState;
